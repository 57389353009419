import { z } from 'zod';
import { GenericError, Measurement } from './generics';

export const AppraisalsError = GenericError;

export const AppraisalView = z
    .object({
        maxPrice: z.string(),
        ganvam: z.string(),
        motorEs: z.string(),
        bca: z.string(),
        carMatrix: z.string(),
    })
    .or(AppraisalsError);

export const GetAppraisalsResponse = z.object({
    maxPrice: Measurement,
    ganvam: Measurement,
    motorEs: Measurement,
    bca: Measurement,
    carMatrix: Measurement,
});

/* - Bulk APPRAISAL - */
export enum BulkAppraisalErrors {
    not_found = 'not_found',
    required_field = 'required_field',
    not_allowed = 'not_allowed',
    invalid_number = 'invalid_number',
    invalid_email = 'invalid_email',
    vehicle_not_allowed = 'vehicle_not_allowed',
}

export const BulkAppraisalErrorsMap = {
    not_found: 'No encontrado',
    required_field: 'Es un campo requerido',
    not_allowed: 'Valor no permitido',
    invalid_number: 'Número introducido no válido',
    invalid_email: 'Email introducido no válido',
    vehicle_not_allowed: 'Tasación masiva no permitida para el vehículo',
};

export type AppraisalView = z.infer<typeof AppraisalView>;
export type GetAppraisalsResponse = z.infer<typeof GetAppraisalsResponse>;
export type AppraisalsError = z.infer<typeof AppraisalsError>;
