import { z } from 'zod';

export const Attachment = z.object({
    uuid: z.string(),
    fileName: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
});

export const Author = z.object({
    uuid: z.string(),
    firstName: z.string(),
    lastName: z.string(),
});

export const Note = z.object({
    uuid: z.string(),
    title: z.string(),
    content: z.string(),
    isPinned: z.boolean(),
    author: Author.nullable(),
    attachments: z.array(Attachment),
    createdAt: z.string(),
    updatedAt: z.string(),
});

export const NoteFilters = z.object({
    page: z.string().optional(),
    max: z.string().or(z.number()).optional(),
    offset: z.string().or(z.number()).optional(),
    totalItems: z.string().or(z.number()).optional(),
});

export const GetNotesResponse = z
    .object({
        max: z.number(),
        offset: z.number(),
        totalItems: z.number(),
        items: z.array(Note),
    })
    .required();

export const EditDealNoteRequest = z.object({
    title: z.string().optional(),
    content: z.string().optional(),
    isPinned: z.boolean().optional(),
});

export const NotesPageData = z.object({
    items: z.array(Note).default([]),
    pages: z.number(),
    offset: z.number(),
    totalItems: z.number(),
});

export const GetAttachmentPressignedUrlRequest = z.object({
    fileName: z.string(),
});

export const GetAttachmentUploadUrlResponse = z.object({
    uploadUrl: z.string(),
    location: z.string(),
});

export const GetAttachmentRequest = z.object({
    location: z.string(),
});

export const GetAttachmentResponse = Attachment;

export const GetSingleNoteResponse = Note;

export const NoteDialogFormData = z.object({
    content: z.string(),
    previousAttachments: z.array(Attachment),
    newAttachments: z.any(),
});

export const GetAttachmentDownloadUrlResponse = z.object({
    downloadUrl: z.string(),
});

export type FilesToUpload = Promise<{
    file: File;
    uploadData: GetAttachmentUploadUrlResponse;
}>[];

export type GetNotesResponse = z.infer<typeof GetNotesResponse>;
export type Note = z.infer<typeof Note>;
export type Attachment = z.infer<typeof Attachment>;
export type EditDealNoteRequest = z.infer<typeof EditDealNoteRequest>;
export type GetSingleNoteResponse = z.infer<typeof GetSingleNoteResponse>;
export type NotesPageData = z.infer<typeof NotesPageData>;
export type NoteFilters = z.infer<typeof NoteFilters>;
export type Author = z.infer<typeof Author>;
export type GetAttachmentUploadUrlResponse = z.infer<typeof GetAttachmentUploadUrlResponse>;
export type GetAttachmentPressignedUrlRequest = z.infer<typeof GetAttachmentPressignedUrlRequest>;
export type GetAttachmentResponse = z.infer<typeof GetAttachmentResponse>;
export type GetAttachmentRequest = z.infer<typeof GetAttachmentRequest>;
export type NoteDialogFormData = z.infer<typeof NoteDialogFormData>;
export type GetAttachmentDownloadUrlResponse = z.infer<typeof GetAttachmentDownloadUrlResponse>;
