import * as React from 'react';
import { DayPicker } from 'react-day-picker';

import { buttonCnVariants as buttonVariants } from '@/components/Button';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className, classNames, showOutsideDays = true, ...props }: CalendarProps) {
    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={`p-3 ${className}`}
            classNames={{
                months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
                month: 'space-y-4',
                caption: 'flex justify-center pt-1 relative items-center',
                caption_label: 'text-sm font-medium',
                nav: 'space-x-1 flex items-center',
                nav_button: `
                    ${buttonVariants({ variant: 'outline' })},
                    h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100
                `,
                nav_button_previous: 'absolute left-1',
                nav_button_next: 'absolute right-1',
                table: 'w-full border-collapse space-y-1',
                head_row: 'flex',
                head_cell: 'text-gray-400 rounded-md w-8 font-normal text-[0.8rem]',
                row: 'flex w-full mt-2',
                cell: `
                    relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-gray-200 [&:has([aria-selected].day-outside)]:bg-gray-200/50 [&:has([aria-selected].day-range-end)]:rounded-r-md
                    ${
                        props.mode === 'range'
                            ? '[&:has(>.day-range-end)]:rounded-r-md [&:has(>.day-range-start)]:rounded-l-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md'
                            : '[&:has([aria-selected])]:rounded-md'
                    }
                `,
                day: `h-8 w-8 p-0 font-normal aria-selected:opacity-100 hover:bg-gray-400 hover:text-white
                    ${buttonVariants({ variant: 'ghost' })}
                `,
                day_range_start: 'day-range-start bg-blue-500 text-white',
                day_range_end: 'day-range-end bg-blue-500 text-white',
                day_selected: 'bg-primary text-black',
                day_today: 'underline text-blue-500',
                day_outside: 'day-outside text-gray-400 opacity-50 aria-selected:opacity-30',
                day_disabled: 'text-black opacity-50',
                day_range_middle: 'aria-selected:bg-accent aria-selected:text-accent-foreground',
                day_hidden: 'invisible',
                ...classNames,
            }}
            components={{
                IconLeft: ({ ...props }) => (
                    <span {...props} className="pointer-events-none">
                        ←
                    </span>
                ),
                IconRight: ({ ...props }) => (
                    <span {...props} className="pointer-events-none">
                        →
                    </span>
                ),
            }}
            {...props}
        />
    );
}
Calendar.displayName = 'Calendar';

export { Calendar };
