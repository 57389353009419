import { Card } from '@/components/Card';

export function CalculatorFormSkeleton() {
    return (
        <Card>
            <div className="space-y-10">
                <div className="min-w-[320px] animate-pulse space-y-6">
                    <div className="h-2.5 w-24 rounded-lg bg-gray-200 " />
                    <div className="h-5 w-[150px] rounded-lg bg-gray-200 " />
                    <div className="h-4 w-24 rounded-lg bg-gray-200 " />
                    <div className="h-5 rounded-lg bg-gray-200 " />
                    <div className="h-4 w-24 rounded-lg bg-gray-200 " />
                    <div className="h-5 rounded-lg bg-gray-200 " />
                </div>
                <ul className="grid grid-cols-2 gap-10">
                    {Array.from(Array(4).keys()).map((_) => (
                        <li className="flex items-center gap-6" key={_}>
                            <div className="h-4 w-4 bg-gray-200 " />
                            <div className=" h-2.5 w-24 rounded-lg bg-gray-200 " />
                        </li>
                    ))}
                </ul>
                <div className="flex justify-end">
                    <div className=" h-10 w-24 rounded-lg bg-gray-200 " />
                </div>
            </div>
        </Card>
    );
}
