import { Button, DropdownButton } from '@/components/Button';
import { TableSkeleton } from '@/components/Table';
import { useStorage } from '@/hooks';
import { tierIcons } from '@/lib/shared/DealsData';
import { getTotalPages } from '@/lib/utils/helpers';
import { queryStringToObject } from '@/lib/utils/queryParams';
import { Roles, useProfileStore } from '@/stores';
import { DealFormFilters, DealListViewSkeleton, Tier } from '@/types';
import DealFilterForm from '@/views/deals/list/DealFilterForm';
import DealsTable from '@/views/deals/list/DealsTable';
import { getOffset } from '@/views/operations/helpers';
import { add } from 'date-fns';
import { Pagination } from 'flowbite-react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HiPlus } from 'react-icons/hi';
import { useDealListCsvDownloadMutation, useDealListFiltersMutation } from './useDealListQuery';

export function DealListView({ deals }: { readonly deals: DealListViewSkeleton }) {
    const { setItem, getItem } = useStorage();
    const router = useRouter();
    const { isAdmin, hasRequiredRoles } = useProfileStore();

    const { mutateAsync: filterDeals } = useDealListFiltersMutation();
    const { mutateAsync: downloadCsv, isPending } = useDealListCsvDownloadMutation();

    const [initialParams, setInitialParams] = useState(queryStringToObject(getItem('dealsInitialParams')));
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(parseInt(getItem('ITEMS_PER_PAGE')) || 10);
    const [selectedDealTier, setSelectedDealTier] = useState<Tier>(getItem('dealTier') || 'ALL');
    const [formFilters, setFormFilters] = useState<DealFormFilters>({
        ...initialParams,
    });

    const methods = useForm<DealFormFilters>({
        values: {
            ...initialParams,
            ...(initialParams?.label && {
                salesAgent: {
                    label: decodeURIComponent(initialParams.label),
                    value: initialParams.value,
                },
            }),
        },
        mode: 'onSubmit',
    });

    useEffect(() => {
        const tier = initialParams?.tier || selectedDealTier;
        setItem('dealTier', tier);
        setSelectedDealTier(tier);

        setItem('dealsInitialParams', '');
        setInitialParams(undefined);
    }, []);

    async function handleDealTierChange(tier: Tier) {
        setItem('dealTier', tier);
        setSelectedDealTier(tier);
        setLoading(true);

        delete formFilters.tier;

        await filterDeals({
            ...formFilters,
            max: itemsPerPage,
            ...(tier !== 'ALL' && { tier: tier }),
            ...(tier === 'C2C' && { includeLastNote: true }),
        });
        setLoading(false);
    }

    async function handleItemsPerPageChange(numberOfItems: number) {
        setItem('ITEMS_PER_PAGE', numberOfItems.toString());
        setCurrentPage(1);
        setItemsPerPage(numberOfItems);
        setLoading(true);
        await filterDeals({
            ...formFilters,
            max: numberOfItems,
            ...(selectedDealTier !== 'ALL' && { tier: selectedDealTier }),
            ...(selectedDealTier === 'C2C' && { includeLastNote: true }),
        });
        setLoading(false);
    }

    async function handlePageChange(page: number) {
        setLoading(true);
        setCurrentPage(page);
        await filterDeals({
            ...formFilters,
            offset: getOffset(itemsPerPage, page),
            max: itemsPerPage,
            ...(selectedDealTier !== 'ALL' && { tier: selectedDealTier }),
            ...(selectedDealTier === 'C2C' && { includeLastNote: true }),
        });
        setLoading(false);
    }

    function formatFormFilters(filters: DealFormFilters) {
        let formattedFormFilters: DealFormFilters = Object.fromEntries(
            Object.entries(filters).filter(([_, value]) => value !== '' && value),
        );

        let firstWonTimeFrom: Date | undefined = undefined;
        let firstWonTimeTo: Date | undefined = undefined;

        if (formattedFormFilters.licensePlate) {
            formattedFormFilters.licensePlate = String(formattedFormFilters.licensePlate).toUpperCase();
        }

        if (filters.salesAgent && typeof filters.salesAgent === 'object') {
            formattedFormFilters.salesAgentUuid = filters.salesAgent?.value;
            delete formattedFormFilters.salesAgent;
        }

        if (formattedFormFilters.firstWonTimeBetween) {
            firstWonTimeFrom = add(new Date(formattedFormFilters.firstWonTimeBetween?.[0]), {
                hours: 1,
            });

            firstWonTimeTo = formattedFormFilters.firstWonTimeBetween?.[1]
                ? add(new Date(formattedFormFilters.firstWonTimeBetween?.[1]), {
                      hours: 24,
                      minutes: 59,
                      seconds: 59,
                  })
                : undefined;
        } else {
            delete formattedFormFilters.firstWonTimeFrom;
            delete formattedFormFilters.firstWonTimeTo;
        }

        delete formattedFormFilters.firstWonTimeBetween;

        formattedFormFilters = {
            ...formattedFormFilters,
            ...(firstWonTimeFrom && { firstWonTimeFrom }),
            ...(firstWonTimeTo && { firstWonTimeTo }),
        };

        return formattedFormFilters;
    }

    const handleSubmitFilterForm = methods.handleSubmit(async (filters, e) => {
        e?.preventDefault();

        let formattedFormFilters = formatFormFilters(filters);
        setFormFilters(formattedFormFilters);

        setLoading(true);

        await filterDeals({
            ...formattedFormFilters,
            max: itemsPerPage,
            ...(selectedDealTier !== 'ALL' && { tier: selectedDealTier }),
            ...(selectedDealTier === 'C2C' && { includeLastNote: true }),
        });
        setCurrentPage(1);
        setLoading(false);
    });

    const tiers = ['PAID', 'RENT', 'C2C', 'ALL'];

    const handleDownloadCSV = async () => {
        const res = await downloadCsv({
            ...formFilters,
            ...(selectedDealTier !== 'ALL' && { tier: selectedDealTier }),
            max: itemsPerPage,
            offset: getOffset(itemsPerPage, currentPage),
        });

        const url = window.URL.createObjectURL(new Blob([await res.text()]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'deals.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    return (
        <>
            <div className="flex gap-8 items-center pb-6">
                <h1 className="font-semibold text-2xl">Negocios</h1>
                <DropdownButton
                    className="w-8/12 justify-between min-w-fit"
                    type={'button'}
                    color="light-gray"
                    variant="outline"
                    size="small"
                    label={
                        selectedDealTier && selectedDealTier !== 'ALL'
                            ? `${tierIcons[selectedDealTier]} ${selectedDealTier}`
                            : 'Todos'
                    }
                >
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                        {tiers.map((tier) => (
                            <li
                                key={tier}
                                className="block px-4 py-2 hover:bg-gray-100"
                                onClick={() => {
                                    handleDealTierChange(tier);
                                }}
                            >
                                {`${tier !== 'ALL' ? tierIcons[tier] : ''} ${tier}`}
                            </li>
                        ))}
                    </ul>
                </DropdownButton>
                {selectedDealTier === 'C2C' &&
                    (isAdmin() || hasRequiredRoles([Roles.DELIVERIES, Roles.C2C_SALES_AGENT])) && (
                        <Button
                            color="white"
                            onClick={() => {
                                setItem('dealAccessedFrom', 'Negocios');
                                router.push('/deals/create');
                            }}
                        >
                            <HiPlus /> Crear nuevo negocio
                        </Button>
                    )}
            </div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmitFilterForm}>
                    <DealFilterForm
                        handleSubmitFilterForm={handleSubmitFilterForm}
                        selectedDealTier={selectedDealTier}
                    />
                </form>
            </FormProvider>

            {loading ? (
                <div className="mt-[78px]">
                    <TableSkeleton rows={10} cols={6} />
                </div>
            ) : (
                <DealsTable
                    isCsvDownloadPending={isPending}
                    deals={deals}
                    itemsPerPage={itemsPerPage}
                    changeItemsPerPage={handleItemsPerPageChange}
                    selectedDealTier={selectedDealTier}
                    handleDownloadCSV={handleDownloadCSV}
                />
            )}

            {deals && (
                <div className="flex justify-center">
                    <Pagination
                        className="mx-auto"
                        theme={{
                            pages: {
                                selector: {
                                    base: 'w-12 border border-gray-300 bg-white py-2 leading-tight aria-[current]:bg-blue-500 aria-[current]:text-white aria-[current]:hover:bg-blue-500 hover:bg-gray-100 hover:text-gray-700',
                                },
                            },
                        }}
                        currentPage={Number(currentPage ?? 1)}
                        onPageChange={handlePageChange}
                        showIcons
                        totalPages={getTotalPages(deals.totalItems, itemsPerPage)}
                        previousLabel={''}
                        nextLabel={''}
                    />
                </div>
            )}
        </>
    );
}
