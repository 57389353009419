import { useFeatureFlagEnabled } from 'posthog-js/react';

export enum FeatureFlag /* FeatureFlagName, */ {}

const FeatureFlagsMap: { [key in FeatureFlag]: string } = {
    // [FeatureFlag.FeatureFlagName]: 'feature-flag-name',
};

// To use a feature flag, import the useFeatureFlag hook and pass the desired flag as an argument.
//
// Example: const featureFlagName = useFeatureFlag(FeatureFlag.featureFlagName);
//

export const useFeatureFlag = (flag: FeatureFlag): boolean | undefined => {
    return useFeatureFlagEnabled(FeatureFlagsMap[flag]);
};
