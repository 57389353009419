import { PutDealDocumentationLinkRequest } from '@/types/deals';
import { useApiClient } from '@/hooks/useApiClient';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const queryKey = ['dealDocumentation'];
const apiClient = useApiClient();

export function useDealDocumentationLinkQuery(dealUuid: string) {
    return useQuery({
        queryKey,
        retry: false,
        queryFn: () => apiClient.getDealDocumentationLink(dealUuid),
    });
}

export function useDealDocumentationLinkMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ dealUuuid, data }: { dealUuuid: string; data: PutDealDocumentationLinkRequest }) =>
            await apiClient.putDealDocumentationLink(dealUuuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}
