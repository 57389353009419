import { useSubmissionListPageData } from '../views/deals/details/submissions/list';
import { useEffect } from 'react';
import { useDealsStore } from '@/stores/useDealsStore';

export function useSubmissionsList(dealId: string) {
    const { data, ...rest } = useSubmissionListPageData(dealId);
    const { setSelectedDealHasContractedOffer } = useDealsStore();

    useEffect(() => {
        const hasContractedOffer = data?.COLLECTED?.clientStatus === 'CONTRACTED';
        setSelectedDealHasContractedOffer(hasContractedOffer);
    }, [data]);

    return { data, ...rest };
}
