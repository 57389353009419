import { Tooltip } from 'flowbite-react';
import { HiMagnifyingGlass } from 'react-icons/hi2';

interface DetailsButtonProps {
    readonly onClick: (e?: any) => void;
    readonly className?: string;
}

export function DetailsButton({ onClick, className }: DetailsButtonProps) {
    return (
        <Tooltip content="Detalles" style={'light'}>
            <button className={`flex flex-col justify-center items-center w-fit ${className}`} onClick={onClick}>
                <span className="border-1 border-blue-500 p-1 rounded-lg bg-white text-xs">
                    <HiMagnifyingGlass className="w-4 h-4  text-blue-500" />
                </span>
            </button>
        </Tooltip>
    );
}
