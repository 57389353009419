import { Card } from '@/components/Card';

export function NoteCardSkeleton() {
    return (
        <Card>
            <div className="w-full space-y-3 bg-white transition-colors duration-200">
                <div className="flex justify-between">
                    <div className="h-8 w-[300px] animate-pulse rounded bg-gray-300 p-1" />
                    <div className="flex gap-4">
                        <div className="h-8 w-[200px] animate-pulse rounded bg-gray-300 p-1" />
                        <div className="h-10 w-8 animate-pulse rounded bg-gray-300 p-1" />
                    </div>
                </div>
                <div className="">
                    <div className="animate-pulse h-16 rounded bg-gray-300" />
                    <div className="grid grid-cols-4 gap-10 pt-4">
                        <div className="h-6 animate-pulse rounded bg-gray-300" />
                        <div className="grid grid-cols-2 gap-5 col-span-3">
                            <div className="h-6 animate-pulse rounded bg-gray-300" />
                            <div className="h-6 animate-pulse rounded bg-gray-300" />
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
}
