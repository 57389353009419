import { Card } from '@/components/Card';
import { HiOutlineArrowNarrowRight, HiOutlineExclamation } from 'react-icons/hi';
import { eligibleFinancierRejections } from '@/types';

interface BankRejectionReasonsSectionProps {
    readonly reasons: Record<string, string[]>;
}

export function BankRejectionReasonsSection({ reasons }: BankRejectionReasonsSectionProps) {
    const filteredReasons = Object.entries(reasons).filter(([key, values]) => values.length > 0);

    if (filteredReasons.length === 0) {
        return null;
    }

    return (
        <Card>
            <div className="flex gap-3">
                <div className="flex items-center justify-center rounded-lg bg-orange-100 p-1 px-2 h-fit">
                    <HiOutlineExclamation className="h-8 w-6 text-orange-400" />
                </div>
                <div className="text-gray-500">
                    <h3 className="text-xs">Deal no elegido por los siguientes bancos debido a :</h3>
                    <ul className="list-disc ms-4">
                        {filteredReasons.map(([finnancierName, noElegibleReasons]) => {
                            return (
                                <li key={finnancierName} className="text-sm font-bold">
                                    <div className="flex gap-4">
                                        <div className="flex gap-2 items-center justify-between">
                                            <span className="min min-w-[175px]">{finnancierName}</span>{' '}
                                            <HiOutlineArrowNarrowRight />
                                        </div>

                                        <div>
                                            {noElegibleReasons.map((reason, index) => {
                                                return (
                                                    <span key={reason}>
                                                        {
                                                            eligibleFinancierRejections[
                                                                reason as keyof typeof eligibleFinancierRejections
                                                            ]
                                                        }
                                                        {index < noElegibleReasons.length - 1 ? ', ' : ''}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </Card>
    );
}
