import { Card } from '@/components/Card';

export function OfferCardSkeleton() {
    return (
        <Card>
            <div className="w-full space-y-3 bg-white transition-colors duration-200">
                <div className="flex justify-between">
                    <div className="h-5 w-[100px] animate-pulse rounded bg-gray-300 p-1" />
                    <div className="flex gap-6">
                        <div className="h-10 w-[100px] animate-pulse rounded bg-gray-300 p-1" />
                        <div className="h-10 w-[100px] animate-pulse rounded bg-gray-300 p-1" />
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-3">
                    <div className="h-12 animate-pulse rounded bg-gray-300" />
                    <div className="h-12 animate-pulse rounded bg-gray-300" />
                    <div className="h-12 animate-pulse rounded bg-gray-300" />
                </div>
            </div>
        </Card>
    );
}
