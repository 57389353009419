import { BadgeColor } from '@/components/Badge';
import { Situation, Stage, Tier } from '@/types';

type Colors = {
    [key: string]: BadgeColor;
};

export const situations: Record<Situation, string> = {
    OPEN: 'ABIERTO',
    WON: 'GANADO',
    LOST: 'PERDIDO',
    DELETED: 'ELIMINADO',
};
export const stages: Record<Stage, string> = {
    LEAD_CALLED: 'Lead Called',
    LEAD_CREATED: 'Lead Created',
    LEAD_PRE_QUOTED: 'Lead Pre-Quoted',
    LEAD_OPPORTUNITY: 'Lead Opportunity',
    LEAD_QUOTED: 'Lead Quoted',
    DEAL_CREATED: 'Deal Created',
    DEAL_QUALIFIED: 'Deal Qualified',
    DEAL_WON: 'Deal Won',
    CLIENT_CREATED: 'Client Created',
    CLIENT_CANCELED: 'Client Canceled',
};

export const tiers: {
    [key in Tier]: string;
} = {
    RENT: 'Rent',
    FINANCED: 'Financed',
    PAID: 'Paid',
};

export const situationColors: Colors = {
    OPEN: 'blue',
    WON: 'green',
    LOST: 'red',
    DELETED: 'orange',
};

export const tierIcons: Record<keyof typeof tiers, string> = {
    RENT: '🛟',
    FINANCED: '💰',
    PAID: '🚘',
    C2C: '🚀',
};
