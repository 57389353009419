import { Label } from 'flowbite-react';
import React, { ForwardedRef } from 'react';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers';

import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { BsAsterisk } from 'react-icons/bs';
interface InputProps extends DatePickerProps<any> {
    id?: string;
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
    required?: boolean;
    withTime?: boolean;
}

export const DateInput = React.forwardRef((props: InputProps, ref: ForwardedRef<any>) => {
    const LabelTheme = {
        root: {
            base: `text-xs font-normal ${props.disabled && 'text-gray-300'}`,
        },
    };

    const inputProps = { ...props, label: '', defaultValue: null };
    return (
        <div className="flex h-full flex-col">
            <Label
                theme={LabelTheme}
                htmlFor={props.id}
                className="flex w-full flex-col justify-between"
                color={props.error && 'failure'}
            >
                <div className="flex gap-1 items-center">
                    {props.label}
                    {props.required && <BsAsterisk className={props.error ? 'text-red-500' : 'text-blue-600'} />}
                </div>
                <DatePicker
                    format={props.withTime ? 'dd/MM/yyyy – HH:mm' : 'dd/MM/yyyy'}
                    className="bg-gray-50 rounded-lg"
                    sx={{
                        color: 'white',
                        '& .MuiInputBase-root > input': {
                            paddingLeft: 1,
                            paddingY: '8px',
                            fontSize: 14,
                            outline: 'none',
                            boxShadow: 'none',
                            borderColor: props.error ? '#E02424' : '#D1D5DB',
                            color: props.error ? '#E02424' : '#000000',
                        },

                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: props.error ? '#E02424' : '#D1D5DB',
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: 2,
                            borderColor: props.error ? '#E02424' : '#D1D5DB',
                            border: 'none',
                        },
                        '.MuiSvgIcon-root': {
                            width: 16,
                            height: 16,
                            color: props.error ? '#E02424' : '#9CA3AF',
                        },
                        border: `1px solid ${props.error ? '#E02424' : 'lightGray'} `,
                    }}
                    {...inputProps}
                    disableFuture={props.disableFuture ?? true}
                    ref={ref}
                />
            </Label>
            <span className={`mt-1 text-xs ${props.error ? 'text-red-600' : ''}`}>
                {props.error?.message?.toString()}
            </span>
        </div>
    );
});
