import { Card } from '@/components/Card';
import { useGetVehicleDetailByLicensePlate } from '@/layouts/VehicleDetailLayout/useVehicleDetailQuery';
import { removeAccents } from '@/lib/utils/helpers';
import { DealBasicInfo } from '@/types';
import { GetDealVehicleReponse } from '@/types/Deal';
import { HiOutlineExclamation } from 'react-icons/hi';

export function VehicleMatchCard({
    vehicle,
    dealBasicInfo,
}: {
    readonly vehicle: GetDealVehicleReponse;
    readonly dealBasicInfo: DealBasicInfo;
}) {
    const { data: catalogueVehicleDetails } = useGetVehicleDetailByLicensePlate(
        dealBasicInfo?.vehicle?.licensePlate,
        dealBasicInfo?.tier,
    );

    let carMatching = [
        catalogueVehicleDetails?.make?.name?.toLowerCase() !== removeAccents(vehicle.brand?.toLowerCase()) && 'Marca',
        catalogueVehicleDetails?.model?.name?.toLowerCase() !== removeAccents(vehicle.model?.toLowerCase()) && 'Modelo',
        catalogueVehicleDetails?.version?.name?.toLowerCase() !== removeAccents(vehicle.version?.toLowerCase()) &&
            'Versión',
        catalogueVehicleDetails?.yearRegistration !== vehicle.yearRegistration && 'Año',
        catalogueVehicleDetails?.combustibleType !== vehicle.combustibleType && 'Combustible',
        catalogueVehicleDetails?.registrationDate !== vehicle.firstRegistrationDate && 'Fecha de matriculación',
        catalogueVehicleDetails?.vin !== vehicle.vin && 'Número de bastidor (VIN)',
    ];

    const filteredCarMatching = carMatching.filter(Boolean);

    if (filteredCarMatching.length === 0) return null;

    return (
        <Card>
            <div className="flex justify-between items-center">
                <div className="flex gap-4">
                    <div className="flex rounded-lg bg-orange-100 p-1 px-2 h-fit">
                        <HiOutlineExclamation className="w-6 h-8 text-orange-400" />
                    </div>
                    <div className="flex flex-col">
                        <h2 className="font-semibold">
                            Atención: los siguientes datos de Carfax no coinciden con los del vehículo del catálogo:
                        </h2>
                        <ul className="text-gray-500 text-sm">
                            {carMatching.map((field) => field && <li key={field}>- {field}</li>)}
                        </ul>
                    </div>
                </div>
            </div>
        </Card>
    );
}
