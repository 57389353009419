import { Card } from '@/components/Card';
import { Button } from '@/components/Button';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { OfferDetailsDialog } from '@/components/Dialog';
import {
    HiCheck,
    HiOutlineBriefcase,
    HiOutlineCalendar,
    HiOutlineExclamation,
    HiOutlineReceiptTax,
    HiX,
} from 'react-icons/hi';
import { MdOutlineEuro } from 'react-icons/md';
import { lifeInsuranceEnum, LifeInsuranceEnum, OfferView } from '@/types/offer';
import { formatPercentage, parseAmountToString } from '@/lib/utils/amount';
import { Roles, useProfileStore } from '@/stores';
import { tiers } from '@/types';
import { parseNumber } from '@/lib/utils/helpers';
import { GetCatalogueVehicleDetails } from '@/types/Vehicle';

export function OfferCard({
    financier,
    details,
    selected,
    offerSelected,
    dealTier,
    vehicleDetails,
}: {
    readonly financier: string;
    readonly details: OfferView;
    readonly selected: boolean;
    readonly offerSelected: () => void;
    readonly dealTier: string | null | undefined;
    readonly vehicleDetails: GetCatalogueVehicleDetails | undefined;
}) {
    const dialog = useDialog();
    const { hasRequiredRoles, isAdmin } = useProfileStore();

    const financerOfferInfo = {
        [tiers.PAID]: [
            {
                label: 'Cantidad solicitada',
                value: details.askedAmount,
            },
            {
                label: 'Cantidad max ofrecida por el banco',
                value: details.loanAmount,
            },
            {
                label: 'Importe seguro',
                value: details.insuranceFee,
            },
            {
                label: 'TIN/TAE',
                value: `${formatPercentage(details.tin, false)} / ${formatPercentage(details.tae, false)}`,
            },
            {
                label: 'Cuota mensual',
                value: details.monthlyFee,
            },
            {
                label: 'Número de quotas',
                value: details.terms,
            },
        ],
        [tiers.C2C]: [
            {
                label: 'PVP',
                value: vehicleDetails?.agreedPrices?.sellingPrice
                    ? parseAmountToString(vehicleDetails.agreedPrices.sellingPrice)
                    : '⚠️ Precio no conseguido',
            },
            {
                label: 'Entrada',
                value: `${
                    (parseNumber(parseAmountToString(vehicleDetails?.agreedPrices.sellingPrice)) || 0) -
                    parseNumber(details.askedAmount)
                } €`,
            },
            {
                label: 'Financiado',
                value: details.askedAmount,
            },
            {
                label: 'TIN',
                value: formatPercentage(details.tin),
            },
            {
                label: 'Nº Cuotas',
                value: details.terms,
            },
            {
                label: 'Cuota',
                value: details.monthlyFee,
            },
        ],
    };

    const financerDetails = [
        {
            label: 'Cantidad solicitada',
            icon: <HiOutlineCalendar />,
            value: details.askedAmount,
        },
        {
            label: 'TIN/TAE',
            icon: <HiOutlineReceiptTax />,
            value: `${formatPercentage(details.tin)} / ${formatPercentage(details.tae)}`,
        },
        {
            label: 'Cuotas',
            icon: <MdOutlineEuro />,
            value: details.terms,
        },
        {
            label: 'Cuota mensual',
            icon: <MdOutlineEuro />,
            value: details.monthlyFee,
        },

        {
            label: 'Coste total crédito',
            icon: <HiOutlineBriefcase />,
            value: details.loanTotalCost,
        },
        {
            label: 'Admin Fee',
            icon: <HiOutlineCalendar />,
            value: details.adminFee,
        },
        {
            label: 'Seguro',
            icon: <MdOutlineEuro />,
            value: details.insuranceFee,
        },
    ];

    const handleOpenOfferDetail = (data: { financier: string }) => {
        dialog({
            type: 'modal',
            content: <OfferDetailsDialog financier={financier} details={financerDetails} />,
        });
    };

    if (details.notAllowedReason) {
        return (
            <Card>
                <div className="flex justify-between items-center p-2">
                    {((dealTier === 'C2C' && !(hasRequiredRoles([Roles.SALES_AGENT]) && !isAdmin())) ||
                        dealTier !== 'C2C') && (
                        <img
                            src={'/images/banks/' + details.financier + '.png'}
                            alt={details.financier}
                            className="h-4 object-contain"
                        />
                    )}
                    <div className="flex items-center gap-4">
                        <p className="text-gray-500 text-sm">{details.notAllowedReason}</p>
                        <div className="bg-orange-100 rounded-lg p-1">
                            <HiOutlineExclamation className="w-5 h-5 text-orange-400" />
                        </div>
                    </div>
                </div>
            </Card>
        );
    }

    return (
        <Card>
            {((dealTier === 'C2C' && !(hasRequiredRoles([Roles.SALES_AGENT]) && !isAdmin())) || dealTier !== 'C2C') && (
                <div className="flex items-center justify-between">
                    <img src={'/images/banks/' + financier + '.png'} alt={financier} className="h-4 object-contain" />

                    <div className="flex gap-7">
                        <Button
                            color="gray"
                            size="xs"
                            variant="ghost"
                            onClick={() => handleOpenOfferDetail({ financier })}
                        >
                            Ver detalle
                        </Button>

                        <Button
                            color="green"
                            size="xs"
                            variant={selected ? 'flat' : 'outline'}
                            onClick={offerSelected}
                            disabled={selected}
                        >
                            {selected ? 'Financiación Creada' : 'Crear Financiación'}
                        </Button>
                    </div>
                </div>
            )}

            <div className="col-span-3 grid grid-cols-3 gap-3">
                {financerOfferInfo[dealTier ?? tiers.PAID].map((detail) => (
                    <OfferCardInfo key={detail.label} label={detail.label} value={detail.value} />
                ))}
            </div>

            <div className="bg-gray-100 p-1 rounded-lg flex justify-center">
                <span className="font-light flex items-center gap-1">
                    Seguro:
                    {parseNumber(details.insuranceFee) > 0 ? (
                        <HiCheck className="text-green-500 h-6 w-6" />
                    ) : (
                        <HiX className="text-red-500 h-6 w-6" />
                    )}
                    {details.insuranceType && lifeInsuranceEnum[details.insuranceType as LifeInsuranceEnum]}
                </span>
            </div>
        </Card>
    );
}

function OfferCardInfo({ label, value }: { readonly label: string; readonly value: string | number }) {
    return (
        <div>
            <p className="text-sm font-light text-gray-500">{label}</p>
            <p className="text-xl font-bold">{value}</p>
        </div>
    );
}
