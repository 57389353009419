import { DialogDismissButton, useDialogHandler, handleDismissModal } from './DialogHandler';
import { Button } from '@/components/Button';

type DetailItem<T> = {
    label: string;
    value: T;
    icon: React.ReactNode;
};

export function OfferDetailsDialog({
    financier,
    details,
}: {
    financier: string;
    details: DetailItem<string | number>[];
}) {
    const { state, setState } = useDialogHandler();
    return (
        <div className="fixed z-[99999] flex h-auto min-h-screen w-screen items-center justify-center bg-gray-900/50 text-black">
            <div className="absolute flex flex-col rounded-lg bg-white  opacity-100">
                <div className="flex items-center p-6">
                    <h2 className="flex-1 text-lg font-semibold">Detalle Condiciones {financier}</h2>
                    <DialogDismissButton />
                </div>
                <div className="mx-auto border-y border-y-gray-300">
                    <div className="grid grid-cols-2 gap-4 p-6">
                        {details.map((detail) => (
                            <div
                                key={detail.label}
                                className="flex min-w-[270px] items-center gap-4 rounded-lg border border-gray-300 p-3"
                            >
                                <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-blue-100 p-2">
                                    {detail.icon}
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-xs text-gray-500">{detail.label}</span>
                                    <span className=" text-sm font-bold text-gray-900">{detail.value}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="p-6">
                    <Button
                        color="blue"
                        size="xs"
                        onClick={() => {
                            handleDismissModal(state, setState);
                        }}
                    >
                        Cerrar
                    </Button>
                </div>
            </div>
        </div>
    );
}
