import { useApiClient } from '@/hooks/useApiClient';
import { Asnef, AsnefProfiles } from '@/types';
import { CoownerDetail } from '@/types/Deal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const queryKey = ['asnef'];
const apiClient = useApiClient();

export function useAsnefQuery(uuid: string, profile: AsnefProfiles) {
    return useQuery({
        queryKey: [...queryKey, uuid, profile],
        queryFn: () => apiClient.getAsnef(uuid, profile),
        select: (data: Asnef) => data,
        enabled: !!uuid,
        refetchOnWindowFocus: false,
    });
}

export function useGetCoownerDetailsQuery(uuid: string) {
    return useQuery({
        queryKey: [...queryKey, uuid, 'dealCoownerDetail'],
        queryFn: () => apiClient.getDealCoownerDetail(uuid),
        select: (data: CoownerDetail) => data,
        enabled: !!uuid,
        refetchOnWindowFocus: false,
    });
}

export function usePostAsnefMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ dealUuid, profile }: { dealUuid: string; profile: AsnefProfiles }) =>
            await apiClient.postAsnef(dealUuid, profile),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}

export const useAsnefPageData = (dealUuid: string, profile: AsnefProfiles) => useAsnefQuery(dealUuid, profile);
