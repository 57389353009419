import { FilesToUpload } from '@/types';
import { useApiClient } from './useApiClient';
import { useUploadFiles } from './useUploadFiles';

const apiClient = useApiClient();

export async function useAttachmentPressignedUrl(dealUuid: string, noteUuid: string, attachments: File[]) {
    return attachments.map(async (attachment) => {
        return {
            file: attachment,
            uploadData: await apiClient.getAtachmentUploadUrl(dealUuid, noteUuid, { fileName: attachment.name }),
        };
    });
}

export async function usePostAttachment(dealUuid: string, noteUuid: string, attachments: FilesToUpload) {
    Promise.all(
        attachments.map(async (fileUploadData) => {
            try {
                await apiClient.postAttachment(dealUuid, noteUuid, {
                    location: (await fileUploadData).uploadData.location,
                });
            } catch (error) {
                console.log('Error al subir archivos al servidor');
            }
        }),
    );
}

export async function useUploadAttachment(dealUuid: string, noteUuid: string, attachmentsToUpload: File[]) {
    try {
        const attachments = await useAttachmentPressignedUrl(dealUuid, noteUuid, attachmentsToUpload);

        await useUploadFiles(attachments);

        await usePostAttachment(dealUuid, noteUuid, attachments);
    } catch (error) {
        console.log('Error al subir archivos');
    }
}
