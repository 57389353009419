import { Card } from '@/components/Card';
import { Checkbox, Select } from '@/components/Inputs';
import { useForm } from 'react-hook-form';
import { HiInformationCircle, HiOutlineCurrencyEuro } from 'react-icons/hi';
import { IoWarningOutline } from 'react-icons/io5';

import { Button } from '@/components/Button';
import { formatPercentage } from '@/lib/utils/amount';
import { CalculatorFormData, lifeInsuranceType } from '@/types/offer';
import { DealBasicInfo } from '@/types';
import { Tooltip } from 'flowbite-react';
import { getOptions } from '@/lib/utils/getters';

export function CalculatorForm({
    formData,
    onSubmit,
    dealBasicInfo,
    isBeforeDealCreatedC2C,
}: {
    formData: CalculatorFormData;
    onSubmit: (data: CalculatorFormData) => void;
    readonly dealBasicInfo: DealBasicInfo | undefined;
    isBeforeDealCreatedC2C: boolean;
}) {
    const dueList = [24, 36, 48, 60, 72, 84, 96, 108, 120];
    const tinList = [3.95, 5.99, 6.99, 7.99, 8.99, 9.5, 9.99, 10.49, 10.5, 10.99, 11.5, 11.99, 12.99, 13.99];

    function getSelectOptions(options: number[]) {
        return options.map((value) => {
            return {
                label: formatPercentage(value, false),
                value: value,
            };
        });
    }

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            pvpMax: formData.pvpMax,
            tin: formData.tin,
            terms: formData.terms,
            lifeInsurance: formData.lifeInsurance,
            insuranceType: formData.insuranceType,
        },
        mode: 'onSubmit',
    });
    const watchPvpMax = watch('pvpMax');

    const handleCalculateOffer = handleSubmit((data: CalculatorFormData) => {
        onSubmit(data);
    });

    return (
        <div className="space-y-2">
            {dealBasicInfo?.tier === 'PAID' && (
                <div className="flex items-center gap-4 rounded-lg bg-white p-3 shadow-md">
                    <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-blue-100 p-1">
                        <HiOutlineCurrencyEuro className="h-5 w-5 bg-blue-100" />
                    </div>
                    <div className="flex flex-col">
                        <span className="text-xs text-gray-500">Cantidad Máxima</span>
                        <span className=" text-sm font-bold text-gray-900">{formData.pvpMax} €</span>
                    </div>
                </div>
            )}

            <Card>
                <form className="w-[300px] space-y-10">
                    <div className="space-y-2">
                        <h3 className="text-xs">Cantidad solicitada</h3>
                        <div className="flex gap-4">
                            <div className="flex flex-col">
                                <div
                                    className={`flex w-[150px] items-center rounded-lg border-2 bg-gray-50 px-2  outline-none  ${
                                        errors.pvpMax
                                            ? 'border-red-600 hover:border-red-600 focus:border-red-600'
                                            : 'border-gray-300'
                                    }`}
                                >
                                    <input
                                        type="number"
                                        step={1}
                                        {...register('pvpMax', {
                                            min: {
                                                value: 0,
                                                message: 'El precio no puede ser negativo',
                                            },
                                            pattern: {
                                                value: /^\d+$/i,
                                                message: 'El valor tiene que ser un entero',
                                            },
                                            required: 'Introduzca una cantidad válida',
                                        })}
                                        className="w-[90%] border-none bg-transparent text-sm  focus:ring-0"
                                    />
                                    <HiOutlineCurrencyEuro
                                        className={` text-gray-500 ${errors.pvpMax ? 'text-red-600' : ''}`}
                                    />
                                </div>

                                {errors.pvpMax && (
                                    <span className="mt-1 text-xs text-red-600">{errors.pvpMax.message}</span>
                                )}
                            </div>
                        </div>
                        {watchPvpMax > formData.pvpMax && (
                            <span className="flex items-center gap-2 text-xs border-1 border-orange-300 rounded-lg p-1 text-orange-500 bg-orange-100">
                                <IoWarningOutline className="h-5 w-8" />
                                ¡Cuidado! El importe es superior a la valoración del vehículo
                            </span>
                        )}
                    </div>

                    <div className="space-y-6">
                        <Select
                            label="TIN"
                            placeholder="Selecciona una opción"
                            options={getSelectOptions(tinList)}
                            {...register('tin', {
                                required: 'Selecciona un TIN',
                            })}
                            error={errors.tin}
                        />

                        <Select
                            label="CUOTAS"
                            placeholder="Selecciona una opción"
                            options={getSelectOptions(dueList)}
                            {...register('terms', {
                                required: 'Selecciona una cuota',
                            })}
                            error={errors.terms}
                        />

                        {dealBasicInfo?.tier === 'C2C' ? (
                            <Select
                                label="Seguro"
                                placeholder="Selecciona una opción"
                                options={getOptions(lifeInsuranceType)}
                                {...register('insuranceType', {
                                    required: 'Selecciona un seguro',
                                })}
                                error={errors.insuranceType}
                            />
                        ) : (
                            <ul className="grid grid-cols-2 gap-y-10  text-xs">
                                <li>
                                    <Checkbox label="Seguro de vida" {...register('lifeInsurance')} />
                                </li>
                            </ul>
                        )}
                    </div>
                    <div className="space space-y-4">
                        {watchPvpMax < formData.pvpMax && (
                            <span className="flex items-center gap-2 text-sm border-1 border-blue-300 rounded-lg p-1 text-blue-500 bg-blue-100">
                                <HiInformationCircle className="h-8 w-10" />
                                Recuerda que puedes ofrecer hasta el 100% de la valoración del vehículo :)
                            </span>
                        )}
                        <div className="flex justify-end w-full">
                            <Tooltip
                                content="El cliente ya ha aceptado la oferta y no se pueden crear nuevas"
                                className={`${(isBeforeDealCreatedC2C || dealBasicInfo?.tier !== 'C2C') && 'hidden'}`}
                            >
                                <Button
                                    color="blue"
                                    variant="outline"
                                    disabled={!isBeforeDealCreatedC2C && dealBasicInfo?.tier === 'C2C'}
                                    onClick={handleCalculateOffer}
                                >
                                    Calcular
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </form>
            </Card>
        </div>
    );
}
