import { Accordion } from '@/components/Accordion';
import { TextInput } from '@/components/Inputs';
import { DealBasicInfo, FormProps } from '@/types';
import { MktDetail } from '@/types/Deal';

const productSourceMap = {
    CREDIT_CAR: 'CreditCar',
    CLIDRIVE_SAVE: 'Clidrive Save',
    CLIDRIVE_RENT: 'Clidrive Rent',
    CLIDRIVE_PAID: 'Clidrive Paid',
    CLIDRIVE_FINANCED: 'Clidrive Financed',
    C2C_COCHESNET: 'Coches.net',
    C2C_AUTOCASION: 'Autocasión',
    C2C_AUTOSCOUT: 'Autoscout',
    C2C_MILANUNCIOS: 'Milanuncios',
    C2C_WALLAPOP: 'Wallapop',
    // C2C_COCHESCOM: 'Coches.com',
};

const originSourceMap = {
    WHATSAPP: 'Whatsapp',
    WEB: 'Web',
    CALL: 'Llamada',
    PARTNER: 'Referido',
    AFFILIATE: 'Afiliado',
    EMAIL: 'Correo electrónico',
    CLIENT_VISIT: 'Visita cliente',
};

export function MarketingDataForm({
    mktDetail,
    dealBasicInfo,
}: FormProps & {
    mktDetail: MktDetail;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    return (
        <div className="space-y-2">
            <Accordion title="Datos Marketing" className="bg-gray-100">
                <div className="grid grid-cols-3 gap-4 p-5">
                    {dealBasicInfo?.tier !== 'C2C' && (
                        <>
                            <TextInput label="MKT - Source" value={mktDetail?.utmSource} type="text" disabled />
                            <TextInput label="MKT - Medium" value={mktDetail?.utmMedium} type="text" disabled />
                            <TextInput label="MKT - Campaign" value={mktDetail?.utmCampaign} type="text" disabled />
                        </>
                    )}
                    {dealBasicInfo?.tier === 'C2C' && (
                        <>
                            <TextInput
                                label="Portal"
                                value={productSourceMap[mktDetail?.productSource as keyof typeof productSourceMap]}
                                type="text"
                                disabled
                            />
                            <TextInput
                                label="Fuente"
                                value={originSourceMap[mktDetail?.originSource as keyof typeof originSourceMap]}
                                type="text"
                                disabled
                            />
                        </>
                    )}
                </div>
            </Accordion>
        </div>
    );
}
