import { Card } from '@/components/Card';
import { HiOutlineExclamation } from 'react-icons/hi';
import { eligibleFinancierRejections } from '@/types';

interface GeneralRejectionReasonsSectionProps {
    readonly reasons: string[];
}

export function GeneralRejectionReasonsSection({ reasons }: GeneralRejectionReasonsSectionProps) {
    return (
        <Card>
            <div className="flex gap-3">
                <div className="flex items-center justify-center rounded-lg bg-orange-100 p-1 px-2 h-fit">
                    <HiOutlineExclamation className="h-8 w-6 text-orange-400" />
                </div>
                <div className="text-gray-500">
                    <h3 className="text-xs">Rechazado. Revisa la información</h3>
                    <ul className="list-disc ms-4">
                        {reasons.map((reason) => {
                            return (
                                <li key={reason} className="text-sm font-bold">
                                    {eligibleFinancierRejections[reason as keyof typeof eligibleFinancierRejections] ||
                                        `<${reason}>`}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </Card>
    );
}
