import { useApiClient } from '@/hooks/useApiClient';
import { GetDealSubmissionList, Submission } from '@/types/submission';

import { useQuery } from '@tanstack/react-query';

const apiClient = useApiClient();

function useSubmissionListQuery(uuid: string) {
    return useQuery({
        queryKey: ['submissionsList', uuid],
        queryFn: () => apiClient.getDealSubmissions(uuid),
        select: (data: GetDealSubmissionList) => transformSubmissionList(data),
        enabled: !!uuid,
        refetchOnWindowFocus: false,
    });
}

function transformSubmissionList(data: GetDealSubmissionList) {
    if (!data)
        return {
            REJECTED: [],
            PENDING: [],
            ONGOING: [],
            RETURNED: [],
            COLLECTED: null,
        };

    let rejectedSubmissions: Submission[] = [];
    let pendingSubmissions: Submission[] = [];
    let ongoingSubmissions: Submission[] = [];
    let returnedSubmissions: Submission[] = [];
    let collectedSubmission: Submission | null = null;

    data.items.forEach((item) => {
        if (['REJECTED', 'UNSUCCESSFULLY_APPROVED', 'CANCELLED'].includes(item.mainStatus)) {
            rejectedSubmissions = [item, ...rejectedSubmissions];
            return;
        }

        if (['PENDING'].includes(item.mainStatus)) {
            pendingSubmissions.push(item);
            return;
        }

        if (['RETURN_IN_PROCESS', 'RETURN', 'EARLY_TERMINATION'].includes(item.mainStatus)) {
            returnedSubmissions.push(item);
            return;
        }

        if (['COLLECTED'].includes(item.mainStatus)) {
            collectedSubmission = item;
            return;
        }

        ongoingSubmissions.push(item);
    });

    return {
        REJECTED: rejectedSubmissions,
        PENDING: pendingSubmissions,
        ONGOING: ongoingSubmissions,
        RETURNED: returnedSubmissions,
        COLLECTED: collectedSubmission as Submission | null,
    };
}

export const useSubmissionListPageData = (uuid: string) => useSubmissionListQuery(uuid);
