import { useApiClient } from '@/hooks/useApiClient';
import {
    DealBasicInfo,
    GetVehicleDetailsResponse,
    LostReason,
    PatchDealRequest,
    PutDealAssignedAgentRequest,
    Situation,
    Stage,
} from '@/types';
import { GetDealPersonalDetailsResponse } from '@/types/Deal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const queryKey = ['dealBasicInfo'];
const client = useApiClient();

export function useDealBasicInfoQuery(dealId: string, select?: (data: DealBasicInfo) => DealBasicInfo) {
    return useQuery<DealBasicInfo>({
        queryKey,
        queryFn: async () => await client.getDealBasicInfo(dealId),
        select,
        throwOnError: true,
    });
}

export const useDealBasicInfoData = (dealId: string) => useDealBasicInfoQuery(dealId);

export function useClientBasicInfoQuery(uuid: string) {
    return useQuery<GetDealPersonalDetailsResponse>({
        queryKey: ['clientBasicInfo', uuid],
        queryFn: async () => await client.getDealPersonalDetail(uuid),
        throwOnError: true,
    });
}

export function usePatchDealMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ dealUuid, data }: { dealUuid: string; data: PatchDealRequest }) => {
            return await client.patchDeal(dealUuid, data);
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function useVehicleDetailsQuery(licensePlate: string | undefined, tier: string | null | undefined) {
    return useQuery<GetVehicleDetailsResponse>({
        enabled: !!licensePlate && tier === 'C2C',
        queryKey: ['vehicleDetails', licensePlate],
        queryFn: async () => {
            if (licensePlate) return await client.getVehicleDetails(licensePlate);
            return {} as GetVehicleDetailsResponse;
        },
        throwOnError: false,
    });
}

export function useDealSituationMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({
            dealId,
            situation,
            lostReason,
        }: {
            dealId: string;
            situation: Situation;
            lostReason?: LostReason;
        }) => {
            const data = await client.updateDealSituation(dealId, { situation, lostReason });
            return data;
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function useDealStageMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ dealId, stage }: { dealId: string; stage: Stage }) => {
            const data = await client.updateDealStage(dealId, { stage });
            return data;
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function useDealAssignedAgentMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ dealId, agents }: { dealId: string; agents: PutDealAssignedAgentRequest }) => {
            const data = await client.putDealAssignedAgents(dealId, agents);
            return data;
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}
