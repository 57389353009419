import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { RadioGroup, TextInput } from '@/components/Inputs';
import { useUuid } from '@/hooks/useUuid';
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning';
import { DealBasicInfo } from '@/types';
import { BankDetail } from '@/types/Deal';
import { FormProps } from '@/types/generics';
import { updateDealBankDetail } from '@/views/deals/details/info';
import { useForm } from 'react-hook-form';

export function BankDetailsForm({
    bankDetail,
    onSuccess,
    onError,
    dealBasicInfo,
}: FormProps & {
    readonly bankDetail: BankDetail;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const { mutateAsync: updateBankDetail } = updateDealBankDetail();
    const dealUuid = useUuid();
    const {
        register,
        handleSubmit,

        formState: { errors, isDirty, isSubmitting },
    } = useForm({
        values: {
            ...bankDetail,
            isAccountAtLeast6MonthsOlder: String(bankDetail?.isAccountAtLeast6MonthsOlder),
            isBalanceAtLeast200: String(bankDetail?.isBalanceAtLeast200),
        },
        mode: 'onSubmit',
    });

    useUnsavedChangesWarning(isDirty, () => {
        return confirm('Parece que tienes cambios sin guardar, ¿deseas abandonar la página?');
    });

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            await updateBankDetail({
                uuid: dealUuid,
                data: {
                    ...data,
                    isAccountAtLeast6MonthsOlder: data.isAccountAtLeast6MonthsOlder === 'true',
                    isBalanceAtLeast200: data.isBalanceAtLeast200 === 'true',
                },
            });
            onSuccess();
        } catch (e) {
            onError();
        }
    });

    return (
        <div className="space-y-2">
            <Accordion title="Datos bancarios" className="bg-gray-100">
                <form
                    className="grid grid-cols-2 gap-4 form-enabled p-5"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmitForm();
                    }}
                >
                    <RadioGroup
                        label="Antigüedad de la cuenta mayor de 6 meses"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('isAccountAtLeast6MonthsOlder', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'Este campo es requerido' : false,
                        })}
                        inputs={[
                            {
                                label: 'Sí',
                                value: 'true',
                            },
                            {
                                label: 'No',
                                value: 'false',
                            },
                        ]}
                        error={errors.isAccountAtLeast6MonthsOlder}
                    />

                    <RadioGroup
                        label="Saldo de la cuenta superior a 200€"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('isBalanceAtLeast200', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'Este campo es requerido' : false,
                        })}
                        inputs={[
                            {
                                label: 'Sí',
                                value: 'true',
                            },
                            {
                                label: 'No',
                                value: 'false',
                            },
                        ]}
                        error={errors.isBalanceAtLeast200}
                    />
                    <TextInput
                        label="IBAN"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('iban', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El IBAN es obligatorio' : false,
                        })}
                        type="text"
                        error={errors.iban}
                    />
                    <TextInput label="SWIFT" {...register('swift', {})} type="text" error={errors.swift} />

                    <div className="grid col-span-2 grid-cols-6 gap-10">
                        <Button
                            disabled={!isDirty || isSubmitting}
                            className=" col-span-2 col-start-5 col-end-7"
                            type="submit"
                            color="blue"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Accordion>
        </div>
    );
}
