import { z } from 'zod';
import { Author } from '@/types';

export const RefTypes = z.enum(['catalogue_vehicle']);

export const SimplifiedNote = z.object({
    uuid: z.string(),
    refId: z.number(),
    refType: RefTypes,
    title: z.string(),
    content: z.string(),
    author: Author.nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
});

export const GetSimplifiedNotesResponse = z
    .object({
        max: z.number(),
        offset: z.number(),
        items: z.array(SimplifiedNote),
        totalItems: z.number(),
    })
    .required();

export const PostSimplifiedNoteRequest = z.object({
    title: z.string(),
    content: z.string(),
});

export type SimplifiedNote = z.infer<typeof SimplifiedNote>;
export type GetSimplifiedNotesResponse = z.infer<typeof GetSimplifiedNotesResponse>;
export type PostSimplifiedNoteRequest = z.infer<typeof PostSimplifiedNoteRequest>;
