import { Badge } from '@/components/Badge';
import { Button } from '@/components/Button';
import { ChangeSubmissionStatusDialog } from '@/components/Dialog/ChangeSubmissionStatusDialog';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { useUuid } from '@/hooks/useUuid';
import { Roles, useProfileStore } from '@/stores/useProfileStore';
import { DealBasicInfo } from '@/types';
import { ChangeSubmissionsStatusProps, GetSubmissionDetails, mainStatuses, subStatuses } from '@/types/submission';
import { usePostSubmissionStatusChange } from '@/views/deals/details/submissions/details/useSubmissionDetailsQuery';

type DealSubmissionStatusProps = {
    submissionDetails: GetSubmissionDetails;
    dealBasicInfo: DealBasicInfo | undefined;
};

export function DealSubmissionStatus({ submissionDetails, dealBasicInfo }: Readonly<DealSubmissionStatusProps>) {
    const dealUuid = useUuid();
    const dialog = useDialog();
    const { mutateAsync: submissionPostStatusMutation } = usePostSubmissionStatusChange();
    const { hasRequiredRoles } = useProfileStore();

    const successToast = (message: string) => {
        dialog({
            type: 'toast',
            variant: 'success',
            content: message,
        });
    };

    const errorToast = (message: string) => {
        dialog({
            type: 'toast',
            variant: 'error',
            content: message,
        });
    };

    const handleChangeStatus = async (data: ChangeSubmissionsStatusProps) => {
        try {
            await submissionPostStatusMutation({
                dealUuid,
                submissionUuid: submissionDetails.uuid,
                data,
            });
            successToast('Estado actualizado correctamente 👍🏼');
        } catch (error: any) {
            errorToast(error.message);
        }
    };

    const handleOpenStatusModal = async () => {
        dialog({
            type: 'modal',
            content: (
                <ChangeSubmissionStatusDialog submissionDetails={submissionDetails} changeStatus={handleChangeStatus} />
            ),
        });
    };

    return (
        <div className="flex border-x border-x-gray-200 shadow-sm">
            <div className="flex flex-1 gap-6 bg-white px-5 py-5 rounded-lg border border-gray-300">
                <div className={'flex justify-between w-full'}>
                    <div className={'flex gap-8'}>
                        <div className="flex items-center gap-4">
                            <span className={'text-xs uppercase text-gray-400 font-bold'}>Estado</span>
                            <Badge color="indigo">{mainStatuses[submissionDetails.mainStatus] || ' - '}</Badge>
                        </div>

                        <div className="flex items-center gap-4">
                            <span className={'text-xs uppercase text-gray-400 font-bold'}>Subestado</span>
                            <Badge color="green">
                                {submissionDetails.subStatus ? subStatuses[submissionDetails.subStatus] : ' - '}
                            </Badge>
                        </div>
                    </div>
                    <div className={'flex items-center'}>
                        {hasRequiredRoles([Roles.FINANCIAL_ANALYST, Roles.FINANCE_USER]) && (
                            <Button
                                color="gray"
                                variant={'outline'}
                                size={'small'}
                                className={'px-10'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleOpenStatusModal();
                                }}
                            >
                                Cambiar
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
