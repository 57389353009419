import { z } from "zod"
import { Measurement } from "./generics"

export const PreOfferData = z.object({
    ask: z.string(),
    pvpMax: z.string(),
    pvp: z.string(),
    term: z.number(),
    due: z.string(),
    tin: z.number(),
    tae: z.number(),
    total: z.string(),
    totalInterest: z.string(),
    totalCostCredit: z.string(),
})

export const PreOfferFinancer = z.object({
    ganvam: z.string().nullable(),
    motorEs: z.string().nullable(),
    bca: z.string().nullable(),
    carMatrix: z.string().nullable(),
})

export const PreOfferView = PreOfferData.nullable()


export const GetPreOfferProposal = z.object({
    uuid: z.string(),
    term: z.number(),
    due: Measurement,
    tin: z.number(),
    tae: z.number(),
    total: Measurement,
    totalInterest: Measurement,
    totalCostCredit: Measurement,
    isSelected: z.boolean(),
})

export const GetPreOfferResponse = z.object({
    uuid: z.string(),
    error: z.string().nullable(),
    licensePlate: z.string(),
    pvpAsk: Measurement.nullable(),
    monthlyIncome: Measurement.nullable(),
    pvp: Measurement.nullable(),
    pvpMax: Measurement.nullable(),
    ganvam: Measurement.nullable(),
    motorEs: Measurement.nullable(),
    bca: Measurement.nullable(),
    carMatrix: Measurement.nullable(),
    commission: Measurement.nullable(),
    proposals: z.array(GetPreOfferProposal).default([])
})

export const ApiGetPreOfferResponse = GetPreOfferResponse.or(z.object({}))

export const FinancerDetails = z.object({
    pvpMax: Measurement,
    tin: z.number(),
    tae: z.number(),
    monthlyIncome: Measurement,
    totalCostCredit: Measurement,
    fee: Measurement,
    insurance: Measurement,
})


export type PreOfferView = z.infer<typeof PreOfferView>
export type PreOfferData = z.infer<typeof PreOfferData>
export type PreOfferFinancer = z.infer<typeof PreOfferFinancer>
export type GetPreOfferProposal = z.infer<typeof GetPreOfferProposal>
export type GetPreOfferResponse = z.infer<typeof GetPreOfferResponse>
export type FinancerDetails = z.infer<typeof FinancerDetails>
export type ApiGetPreOfferResponse = z.infer<typeof ApiGetPreOfferResponse>
