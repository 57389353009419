import { Accordion } from '@/components/Accordion/Accordion';
export function AccordionSkeleton({
    children,
    columns,
    cards,
}: {
    children?: React.ReactNode;
    columns?: number;
    cards?: number;
}) {
    const cols = columns ?? 3;
    const crds = cards ?? 9;

    return (
        <Accordion title="" className="w-full">
            <div className="w-full divide-y bg-white p-6 transition-colors duration-200 flex flex-col gap-4">
                {children ?? (
                    <div className={`grid grid-cols-${cols} gap-6`}>
                        {Array.from(Array(crds).keys()).map((row) => (
                            <div className="h-12 animate-pulse rounded bg-gray-300 p-1 " key={row} />
                        ))}
                    </div>
                )}
            </div>
        </Accordion>
    );
}
