import { z } from 'zod';

export const AsnefProfiles = z.enum(['CLIENT', 'CO_OWNER']);

export const Asnef = z
    .object({
        identificationNumber: z.string().optional(),
        numberOfOperations: z.number().optional(),
        totalAmountOfOperations: z.number().optional(),
        numberOfUnpaidOperations: z.number().optional(),
        numberOfUnpaidPayments: z.number().optional(),
        totalUnpaidPaymentAmount: z.number().optional(),
        maximumUnpaidPaymentAmount: z.number().optional(),
    })
    .nullable();

export type Asnef = z.infer<typeof Asnef>;
export type AsnefProfiles = z.infer<typeof AsnefProfiles>;
