import { useRouter } from "next/router";



export function useRouteMatcher() {
    const router = useRouter()
    const match = (route: string) => router.asPath === route.split('?')[0];

    const isUnderRoute = (route: string) => router.asPath.startsWith(route);

    return { match, isUnderRoute }
};
