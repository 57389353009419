import { Button } from '@/components/Button';
import { Asnef, AsnefProfiles, DealBasicInfo } from '@/types';
import { HiCheckCircle, HiDotsCircleHorizontal, HiOutlineExclamation, HiXCircle } from 'react-icons/hi';
import { usePostAsnefMutation } from './useAsnefQuery';
import { useUuid } from '@/hooks/useUuid';
import { useToast } from '@/hooks/useToast';
import { stages } from '@/lib/shared/DealsData';

const asnefStatusStyles = {
    PERMITIDO: {
        icon: <HiCheckCircle className="h-5 w-5 text-green-400" />,
        iconBg: 'bg-green-100',
        text: 'Permitido en PAID/FINANCED',
    },
    PERMITIDO_LENDROCK: {
        icon: <HiOutlineExclamation className="h-5 w-5 text-orange-400" />,
        iconBg: 'bg-orange-100',
        text: 'Permitido únicamente para Lendrock',
    },
    NO_PERMITIDO: {
        icon: <HiXCircle className="h-5 w-5 text-red-400" />,
        iconBg: 'bg-red-100',
        text: 'NO permitido en PAID/FINANCED',
    },
    NO_EN_ASNEF: {
        icon: <HiDotsCircleHorizontal className="h-5 w-5 text-gray-400" />,
        iconBg: 'bg-gray-100',
        text: 'No hay consulta',
    },
};

type AsnefViewProps = {
    readonly dealBasicInfo: DealBasicInfo | undefined;
    readonly data: {
        readonly client: Asnef | undefined;
        readonly coOwnerExists: boolean | undefined;
        readonly coOwner: Asnef | undefined;
    };
};

export function DealAsnefView({ data, dealBasicInfo }: AsnefViewProps) {
    const dealUuid = useUuid();
    const { mutateAsync: usePostAsnef } = usePostAsnefMutation();
    const { successToast, errorToast } = useToast();

    const getAsnefStatus = (profile: Asnef | undefined) => {
        if (!profile || profile.totalUnpaidPaymentAmount === undefined) {
            return 'NO_EN_ASNEF';
        }

        if (profile.totalUnpaidPaymentAmount > 0) {
            if (profile.totalUnpaidPaymentAmount <= 1000) {
                return 'PERMITIDO_LENDROCK';
            }
            return 'NO_PERMITIDO';
        }

        return 'PERMITIDO';
    };

    const asnefInfoCard = (status: keyof typeof asnefStatusStyles) => {
        return (
            <div className="w-full">
                <div className="flex items-center gap-4 rounded-lg border border-gray-300 p-3">
                    <div
                        className={`flex h-8 w-8 items-center justify-center rounded-lg p-1 ${asnefStatusStyles[status].iconBg}`}
                    >
                        {asnefStatusStyles[status].icon}
                    </div>
                    <div className="flex flex-col">
                        <span className="text-xs text-gray-500">EN ASNEF</span>
                        <span className=" text-sm font-bold text-gray-900">{asnefStatusStyles[status].text}</span>
                    </div>
                </div>
            </div>
        );
    };

    const handleAsnefConsult = async (profile: AsnefProfiles) => {
        const stagePosition = Object.keys(stages).indexOf(dealBasicInfo?.stage?.stage ?? '');
        if (stagePosition < 2) {
            errorToast('La consulta ASNEF sólo puede realizarse a partir de la etapa Lead pre-quoted');
            return;
        }

        try {
            await usePostAsnef({
                dealUuid: dealUuid,
                profile: profile,
            });
            successToast('Consulta ASNEF realizada con éxito');
        } catch {
            errorToast('Error al realizar la consulta ASNEF');
        }
    };

    return (
        <div className="flex flex-1 flex-col border-x border-x-gray-200 shadow-sm">
            <div className="rounded-t-lg border-b border-b-gray-200 bg-gray-100 px-6 py-4">
                <div className="flex items-center">
                    <span className="text-gray-900">Consulta ASNEF</span>
                </div>
            </div>
            <div className="flex flex-1 rounded-b-lg flex-col gap-8 bg-white p-6">
                <div className="flex flex-col gap-4">
                    <span className="font-medium text-lg">Consultar Titular</span>
                    <div className="flex gap-8 justify-between items-center">
                        {asnefInfoCard(getAsnefStatus(data.client))}
                        <div>
                            <Button
                                variant="outline"
                                color="gray"
                                className="whitespace-nowrap"
                                onClick={async () => await handleAsnefConsult('CLIENT')}
                            >
                                Consultar ASNEF
                            </Button>
                        </div>
                    </div>
                </div>
                {data.coOwnerExists && (
                    <div className="flex flex-col gap-4">
                        <span className="font-medium text-lg">Consultar Co-Titular</span>
                        <div className="flex gap-8 justify-between items-center">
                            {asnefInfoCard(getAsnefStatus(data.coOwner))}
                            <div>
                                <Button
                                    variant="outline"
                                    color="gray"
                                    className="whitespace-nowrap"
                                    onClick={async () => await handleAsnefConsult('CO_OWNER')}
                                >
                                    Consultar ASNEF
                                </Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
