import { Badge } from '@/components/Badge';
import { Button, DropdownButton } from '@/components/Button';
import { DealAssignDialog } from '@/components/Dialog/DealAssignDialog';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { useCopyToClipboard } from '@/hooks/useCopyToClipboard';
import {
    useDealAssignedAgentMutation,
    useDealSituationMutation,
    useDealStageMutation,
    usePatchDealMutation,
} from '@/layouts/useDealBasicInfoQuery';
import { getClientEnv } from '@/lib/getEnv';
import { situationColors, situations, stages, tierIcons } from '@/lib/shared/DealsData';
import { throwConfetti } from '@/lib/utils/confettiAnimation';
import { Agent, Stage } from '@/types';
import { DealBasicInfo, LostReason, Situation } from '@/types/deals';
import { SubmissionWithAggregates } from '@/types/submission';
import { HiOutlineDotsVertical, HiOutlineStar, HiStar } from 'react-icons/hi';
import { MdContentCopy } from 'react-icons/md';
import { DealLostDialog } from '../Dialog';
import { Roles } from '@/stores';

export function DealDetailHeader({ info }: { readonly info: DealBasicInfo }) {
    const { mutateAsync: updateSituation } = useDealSituationMutation();
    const { mutateAsync: updateStage } = useDealStageMutation();
    const { mutateAsync: patchDeal } = usePatchDealMutation();
    const { mutateAsync: updateAssignedAgent } = useDealAssignedAgentMutation();

    const dialog = useDialog();

    const [_, setCopy] = useCopyToClipboard();
    const { PIPEDRIVE_URL } = getClientEnv();
    const showAlertToast = (message: string) => {
        dialog({
            type: 'toast',
            variant: 'warning',
            content: message,
        });
    };

    const showErrorToast = (message: string) => {
        dialog({
            type: 'toast',
            variant: 'error',
            content: message,
        });
    };

    const handleSelectDealStage = async (stage: Stage) => {
        try {
            await updateStage({ dealId: info.uuid, stage });
        } catch (e: any) {
            showErrorToast(e.message);
        }
    };

    const isSubmissionCompleted = async () => {
        try {
            const response = await fetch(`/api/deals/${info.uuid}/submissions?max=100`, {
                method: 'GET',
            });

            const submissionsList = await response.json();

            const submission = submissionsList.items.find(
                (submission: SubmissionWithAggregates) => submission.clientStatus === 'CONTRACTED',
            );

            return submission;
        } catch (e) {
            showErrorToast('Error al intentar obtener las solicitudes');
            return undefined;
        }
    };

    const handleSelectSituation = async (situation: Situation, lostReason?: LostReason) => {
        if (situation === 'WON') {
            if ((await isSubmissionCompleted()) === undefined) {
                showAlertToast('Para marcar como “GANADO” debe haber una financiación cuyo estado sea "Cobrada"');
                return;
            }
            if (info.stage?.stage !== 'DEAL_WON') {
                showAlertToast('Para marcar como “GANADO” la etapa del deal debe ser "Deal Won"');
                return;
            }
        }

        try {
            await updateSituation({
                dealId: info.uuid,
                situation,
                lostReason,
            });
            if (situation === 'WON') {
                throwConfetti();
            }
        } catch (e: any) {
            showErrorToast(e.message);
        }
    };

    const handleSelectLostReason = async (reason: LostReason) => {
        try {
            await handleSelectSituation('LOST', reason);
        } catch (e: any) {
            showErrorToast(e.message);
        }
    };

    const handleOpenLostSituationModal = () => {
        dialog({
            type: 'modal',
            content: <DealLostDialog onConfirm={handleSelectLostReason} tier={info?.tier} />,
        });
    };

    const handleOpenAssignModal = async (roles: Roles, agent: Agent | null, selfAssign?: boolean) => {
        dialog({
            type: 'modal',
            content: (
                <DealAssignDialog
                    role={roles}
                    agent={agent}
                    onConfirm={handleAssignAgentToDeal}
                    selfAssign={selfAssign}
                />
            ),
        });
    };

    const handleAssignAgentToDeal = async (agentUuid: string | null) => {
        try {
            await updateAssignedAgent({
                dealId: info.uuid,
                agents: {
                    certifierAgentExternalId: null,
                    financeAgentExternalId: agentUuid,
                },
            });
            dialog({
                type: 'toast',
                variant: 'success',
                content: info.financeAgent ? 'Deal desasignado con éxito 👍' : 'Deal asignado con éxito 👍',
            });
        } catch (e: any) {
            showErrorToast(e.message);
        }
    };

    const financersList = () => {
        if (info.selectedFinancier) {
            return (
                <img
                    src={'/images/banks/' + info.selectedFinancier + '.png'}
                    alt={info.selectedFinancier || 'Sin banco seleccionado'}
                    className="h-4 object-contain"
                />
            );
        }

        if (info.eligibleFinanciers && info.eligibleFinanciers?.length > 0) {
            return info.eligibleFinanciers?.map((financier) => (
                <img
                    src={'/images/banks/' + financier + '.png'}
                    alt={financier}
                    key={financier}
                    className="h-4 object-contain"
                />
            ));
        }

        return <span className="uppercase text-sm text-gray-500 ">No hay bancos elegibles</span>;
    };

    const handleSwitchStarred = async (uuid: string, starred: boolean | undefined) => {
        try {
            await patchDeal({
                dealUuid: uuid,
                data: { isStarred: !starred },
            });
        } catch (e: any) {
            showErrorToast(e.message);
        }
    };

    return (
        <div className="w-full divide-y sticky top-0 z-10">
            <section className="flex border-separate items-center justify-between gap-6 bg-gray-50 ps-4 pe-5 py-1">
                <div className="flex gap-6">
                    <div className="flex gap-2">
                        <button
                            className="p-2 hover:bg-gray-200 rounded-lg"
                            onClick={() => handleSwitchStarred(info.uuid, info.isStarred)}
                        >
                            {info.isStarred ? (
                                <HiStar className="w-5 h-5 text-blue-500"></HiStar>
                            ) : (
                                <HiOutlineStar className="w-5 h-5 text-gray-400"></HiOutlineStar>
                            )}
                        </button>
                        {info.opsDealId && (
                            <div className="flex flex-1 items-center space-x-3 text-sm font-extrabold text-blue-500 ">
                                <a
                                    href={`${PIPEDRIVE_URL}/deal/${info.opsDealId}`}
                                    target="_blank"
                                    className="flex items-center gap-2 "
                                >
                                    <span className="text-black">Deal ID:</span> {info.opsDealId}
                                </a>
                                <MdContentCopy
                                    className=" h-4 w-4 cursor-pointer text-gray-900"
                                    onClick={() => setCopy(info.opsDealId ?? '')}
                                />
                            </div>
                        )}
                    </div>
                    |
                    <div className="flex gap-2 justify-center items-center">
                        <span className="m-auto">🗣️</span>
                        {info.salesAgent?.name ? (
                            <span className="font-semibold">{info.salesAgent?.name}</span>
                        ) : (
                            <span className="text-sm"> Sin Asignar</span>
                        )}
                    </div>
                    {info.tier !== 'RENT' && (
                        <>
                            |
                            <div className="flex gap-2 justify-center items-center">
                                <span className="m-auto">🏦</span>
                                {info.financeAgent?.name ? (
                                    <span className="font-semibold">{info.financeAgent?.name}</span>
                                ) : (
                                    <span className="text-sm"> Sin Asignar</span>
                                )}
                            </div>
                            <span
                                className="flex items-center gap-2 hover:font-semibold underline cursor-pointer text-xs"
                                onClick={() =>
                                    handleOpenAssignModal(Roles.FINANCIAL_ANALYST, info.financeAgent ?? null, true)
                                }
                            >
                                {info.financeAgent ? 'Desasignar analista' : 'Asignarme esta operación'}
                            </span>
                        </>
                    )}
                </div>

                <div className={`flex justify-end items-center gap-6`}>
                    {info.tier && (
                        <Button color="white" variant="ghost">
                            {tierIcons[info.tier]} {info.tier}
                        </Button>
                    )}

                    {info.situation && (
                        <Badge className="min-w-[96px]" color={situationColors[info.situation]}>
                            {situations[info.situation]}
                        </Badge>
                    )}
                    <DropdownButton
                        color="gray"
                        variant="outline"
                        size="xs"
                        chevron={false}
                        label={<HiOutlineDotsVertical className="h-5 w-5" />}
                    >
                        <ul className="cursor-pointer py-1 text-sm text-gray-700">
                            {info.situation === 'OPEN' ? (
                                <>
                                    <li
                                        className="block px-4 py-2 hover:bg-gray-100"
                                        onClick={() => handleSelectSituation('WON')}
                                    >
                                        Mover a Ganado
                                    </li>
                                    <li
                                        className="block px-4 py-2 hover:bg-gray-100"
                                        onClick={() => handleOpenLostSituationModal()}
                                    >
                                        Mover a Perdido
                                    </li>
                                </>
                            ) : (
                                <>
                                    <li
                                        className="block px-4 py-2 hover:bg-gray-100"
                                        onClick={() => handleSelectSituation('OPEN')}
                                    >
                                        Reabrir
                                    </li>
                                </>
                            )}
                            <li
                                className="block px-4 py-3 text-red-500 hover:bg-gray-100 border-t-1"
                                onClick={() => handleSelectSituation('DELETED')}
                            >
                                Eliminar Negocio
                            </li>
                        </ul>
                    </DropdownButton>
                </div>
            </section>

            <section className="flex justify-between bg-gray-50 px-5 py-4">
                <div className="flex gap-x-4">
                    {info.vehicle?.licensePlate && (
                        <div className="flex flex-col gap-2">
                            <span className="text-xs text-gray-500">Matrícula</span>
                            <span className=" text-sm font-semibold text-gray-700">{info?.vehicle?.licensePlate}</span>
                        </div>
                    )}
                    {(info.eligibleFinanciers || info.selectedFinancier) && info.tier === 'PAID' && (
                        <div
                            className={`flex flex-col ${
                                info.eligibleFinanciers?.length && info.eligibleFinanciers?.length > 0
                                    ? 'gap-3'
                                    : 'gap-2'
                            }`}
                        >
                            <span className="text-xs text-gray-500">
                                {info.selectedFinancier ? 'Banco Contratado' : 'Bancos Elegibles'}
                            </span>

                            <div className="flex gap-6">{financersList()}</div>
                        </div>
                    )}
                </div>
                <div className="flex items-center">
                    {info.stage && (
                        <DropdownButton variant="outline" color="purple" size="xs" label={stages[info.stage.stage]}>
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                {Object.entries(stages).map(([key, value]) => (
                                    <li
                                        key={key}
                                        className="block px-4 py-2 hover:bg-gray-100"
                                        onClick={() => handleSelectDealStage(key as Stage)}
                                    >
                                        {value}
                                    </li>
                                ))}
                            </ul>
                        </DropdownButton>
                    )}
                </div>
            </section>
        </div>
    );
}
