import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Checkbox, TextInput } from '@/components/Inputs';
import { useUuid } from '@/hooks/useUuid';
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning';
import { isValidPhoneNumber } from '@/lib/validators/phoneFormValidator';
import { DealBasicInfo, FormProps } from '@/types';
import { CoownerDetail } from '@/types/Deal';
import { updateDealCoownerDetail } from '@/views/deals/details/info';
import { useForm } from 'react-hook-form';

export function CoownerDetailsForm({
    coownerDetail,
    onSuccess,
    onError,
    dealBasicInfo,
}: FormProps & {
    readonly coownerDetail: CoownerDetail;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const { mutateAsync: updateCoownerDetail } = updateDealCoownerDetail();
    const dealUuid = useUuid();
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        reset,
        formState: { errors, isDirty, isSubmitting },
    } = useForm({
        values: coownerDetail,
        mode: 'onSubmit',
    });

    useUnsavedChangesWarning(isDirty, () => {
        return confirm('Parece que tienes cambios sin guardar, ¿deseas abandonar la página?');
    });

    const handleResetFields = () => {
        reset(
            {
                ...getValues(),
                name: null,
                lastName: null,
                secondLastName: null,
                identificationNumber: null,
                email: null,
                phone: null,
            },
            { keepDirty: true },
        );
    };

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            await updateCoownerDetail({
                uuid: dealUuid,
                data: {
                    ...data,
                    identificationNumber: data.identificationNumber?.number ? data.identificationNumber : null,
                },
            });
            onSuccess();
        } catch (e) {
            onError();
        }
    });

    const isSubmitButtonDisabled = !isDirty || isSubmitting;

    return (
        <div className="space-y-2 ">
            <Accordion title="Datos co-titular" className="bg-gray-100">
                <form
                    className="grid grid-cols-2 gap-4 p-5 form-enabled"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmitForm();
                    }}
                >
                    <Checkbox
                        {...register('exists')}
                        label="Cotitular"
                        onChange={(e) => {
                            setValue('exists', e.target.checked, { shouldDirty: true });
                            handleResetFields();
                        }}
                    />

                    <TextInput
                        label="DNI / NIE"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('identificationNumber.number', {
                            required: {
                                value: getValues().exists && dealBasicInfo?.tier !== 'C2C',
                                message: 'El DNI / NIE es obligatorio',
                            },
                        })}
                        type="text"
                        error={errors.identificationNumber?.number}
                        disabled={!getValues().exists}
                    />
                    <TextInput
                        label="Nombre"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('name', {
                            required: {
                                value: getValues().exists && dealBasicInfo?.tier !== 'C2C',
                                message: 'Este campo es obligatorio',
                            },
                        })}
                        type="text"
                        error={errors.name}
                        disabled={!getValues().exists}
                    />

                    <TextInput
                        label="Apellido"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('lastName', {
                            required: {
                                value: getValues().exists && dealBasicInfo?.tier !== 'C2C',
                                message: 'Este campo es obligatorio',
                            },
                        })}
                        type="text"
                        error={errors.lastName}
                        disabled={!getValues().exists}
                    />

                    <TextInput
                        label="Segundo apellido"
                        {...register('secondLastName')}
                        type="text"
                        error={errors.secondLastName}
                        disabled={!getValues().exists}
                    />

                    <TextInput
                        label="Email"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('email', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El email es obligatorio' : false,
                            validate: {
                                value: (value?: any) => {
                                    if (value === null || value === '') {
                                        return true;
                                    }
                                    if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[\w-]{2,}$/gm)) {
                                        return 'Email no válido';
                                    }
                                },
                            },
                        })}
                        type="email"
                        error={errors.email}
                        disabled={!getValues().exists}
                    />

                    <TextInput
                        label="Teléfono Móvil"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('phone', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El móvil es obligatorio' : false,
                            minLength: {
                                value: 9,
                                message: 'El móvil ha de tener como mínimo 9 dígitos',
                            },
                            maxLength: {
                                value: 25,
                                message: 'El móvil ha de tener como máximo 25 dígitos',
                            },
                            validate: {
                                value: (value?: any) => {
                                    if (value === null || value === '') {
                                        return true;
                                    }
                                    if (!isValidPhoneNumber(value)) {
                                        return 'Número introducido no válido';
                                    }
                                },
                            },
                        })}
                        type="text"
                        error={errors.phone}
                        disabled={!getValues().exists}
                    />

                    <div className="grid col-span-2 gap-10 grid-cols-6">
                        <Button
                            disabled={isSubmitButtonDisabled}
                            className=" col-span-2 col-start-5"
                            type="submit"
                            color="blue"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Accordion>
        </div>
    );
}
