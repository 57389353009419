import { Button } from '@/components/Button';
import { handleDismissModal, useDialogHandler } from './DialogHandler';
import { useUser } from '@auth0/nextjs-auth0/client';
import { Combobox } from '@/components/Combobox';
import { roles, Roles } from '@/stores';
import { Controller, useForm } from 'react-hook-form';
import { transformUserListItems, useUserListMutation, useUserListPageData } from '@/views';
import { Agent } from '@/types';

export function DealAssignDialog({
    role,
    agent,
    onConfirm,
    selfAssign = false,
}: {
    role: Roles;
    agent: Agent | null;
    onConfirm: (agentUuid: string | null) => void;
    selfAssign?: boolean;
}) {
    const { user } = useUser();
    const { mutateAsync: filterUsers } = useUserListMutation();
    const { data: agents } = useUserListPageData({}, role, 'externalId');
    const { state, setState } = useDialogHandler();

    const getUsers = async (inputValue: string, role?: Roles) => {
        const data = await filterUsers({
            query: inputValue,
        });

        return transformUserListItems(
            data,
            {
                query: inputValue,
            },
            role,
            !selfAssign ? 'externalId' : undefined,
        );
    };

    const { control, handleSubmit, watch } = useForm({
        defaultValues: {
            agent: agent ? { label: agent.name, value: agent.uuid } : undefined,
        },
    });
    const getModalVersion = () => {
        if (!selfAssign) {
            return (
                <>
                    <h2 className="flex-1 text-lg font-semibold">Asignación de {roles[role]}</h2>
                    <Controller
                        name="agent"
                        control={control}
                        render={({ field }) => (
                            <Combobox
                                {...field}
                                defaultValue={undefined}
                                options={(inputValue: string) => getUsers(inputValue, role)}
                                defaultOptions={agents}
                                label={roles[role]}
                            />
                        )}
                    />
                </>
            );
        }

        if (agent) {
            return (
                <div className="flex items-center">
                    <h2 className="flex-1 text-lg font-semibold">Se quitará la asignación. ¿Estás seguro?</h2>
                </div>
            );
        } else {
            return (
                <>
                    <div className="flex items-center">
                        <h2 className="flex-1 text-lg font-semibold">Se asignará a:</h2>
                    </div>
                    <div className="mx-auto">
                        <div className="min-w-[420px]">
                            <span className=" text-base font-semibold text-gray-600">{user?.name}</span>
                        </div>
                    </div>
                </>
            );
        }
    };

    const handleSubmitForm = handleSubmit(async (data, e) => {
        e?.preventDefault();
        if (selfAssign) {
            onConfirm(agent ? null : user?.sub || null);
        } else {
            onConfirm(data.agent?.value || null);
        }
        handleDismissModal(state, setState);
    });

    return (
        <div className="fixed z-[99999] flex h-auto min-h-screen w-screen items-center justify-center bg-gray-900/50 text-black">
            <form
                onSubmit={handleSubmitForm}
                className="absolute flex flex-col gap-6 rounded-lg bg-white opacity-100 p-6 min-w-96"
            >
                {getModalVersion()}
                <div className="flex justify-end gap-4">
                    <Button
                        size="xs"
                        color="blue"
                        variant="outline"
                        onClick={() => handleDismissModal(state, setState)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        size="xs"
                        color="blue"
                        type="submit"
                        disabled={watch('agent')?.label === agent?.name && !selfAssign}
                    >
                        Confirmar
                    </Button>
                </div>
            </form>
        </div>
    );
}
