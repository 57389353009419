import { z } from 'zod';

export const GetCarfaxResponse = z.object({
    bodyType: z.string().nullable().optional(),
    country: z.string().nullable().optional(),
    driveType: z.string().nullable().optional(),
    enginePowerHp: z.number().nullable().optional(),
    enginePowerKW: z.number().nullable().optional(),
    engineSize: z.number().nullable().optional(),
    euBodyworkType: z.string().nullable().optional(),
    euVehicleCategory: z.string().nullable().optional(),
    firstRegistrationDate: z.string().nullable().optional(),
    firstRegistrationDateInCountry: z.string().nullable().optional(),
    firstRegistrationYear: z.number().nullable().optional(),
    lastOdometerReadingValue: z.number().optional().nullable(),
    fiscalPower: z.array(z.string()).nullable().optional(),
    fuelType: z.string().nullable().optional(),
    gearboxType: z.string().nullable().optional(),
    ineMunicipalityVehCode: z.string().nullable().optional(),
    lastOwnerChangeDate: z.string().nullable().optional(),
    make: z.string().nullable().optional(),
    model: z.string().nullable().optional(),
    modelFamily: z.string().nullable().optional(),
    municipality: z.string().nullable().optional(),
    numberOfDoors: z.number().nullable().optional(),
    numberOfLegalOwners: z.number().nullable().optional(),
    numberOfSeats: z.number().nullable().optional(),
    ownerType: z.string().nullable().optional(),
    postalCode: z.string().nullable().optional(),
    province: z.string().nullable().optional(),
    registrationStatus: z.string().nullable().optional(),
    registrationType: z.string().nullable().optional(),
    riskAlertAccident: z.boolean().nullable().optional(),
    riskAlertAirbagDeployed: z.boolean().nullable().optional(),
    riskAlertChassisModification: z.boolean().nullable().optional(),
    riskAlertDamage: z.boolean().nullable().optional(),
    riskAlertDeregistered: z.boolean().nullable().optional(),
    riskAlertFireDamage: z.boolean().nullable().optional(),
    riskAlertFloodDamage: z.boolean().nullable().optional(),
    riskAlertHailDamage: z.boolean().nullable().optional(),
    riskAlertImport: z.boolean().nullable().optional(),
    riskAlertInsuranceClaim: z.boolean().nullable().optional(),
    riskAlertLastInspectionFailed: z.boolean().nullable().optional(),
    riskAlertLeasing: z.boolean().nullable().optional(),
    riskAlertMileageInconsistency: z.boolean().nullable().optional(),
    riskAlertOpenRecall: z.boolean().nullable().optional(),
    riskAlertPotentialRollback: z.boolean().nullable().optional(),
    riskAlertRental: z.boolean().nullable().optional(),
    riskAlertScrapped: z.boolean().nullable().optional(),
    riskAlertStolen: z.boolean().nullable().optional(),
    riskAlertStolenAndRecovered: z.boolean().nullable().optional(),
    riskAlertTaxi: z.boolean().nullable().optional(),
    riskAlertTotalLoss: z.boolean().nullable().optional(),
    riskAlertUSAImport: z.boolean().nullable().optional(),
    riskAlertUSAJunkTitle: z.boolean().nullable().optional(),
    riskAlertUSALemonTitle: z.boolean().nullable().optional(),
    riskAlertUSASalvageTitle: z.boolean().nullable().optional(),
    useType: z.string().nullable().optional(),
    vin: z.string().nullable().optional(),
    prequalificationRejected: z.boolean().nullable().optional(),
    prequalificationRejectedReason: z.string().nullable().optional(),
    vehicleDescription: z.string().nullable().optional(),
});
export const VehicleData = z.object({
    vin: z.string().nullable().optional(),
    make: z.string().nullable().optional(),
    model: z.string().nullable().optional(),
    fuelTyp: z.string().nullable().optional(),
    engineSiz: z.string().nullable().optional(),
    gearboxTyp: z.string().nullable().optional(),
    enginePowerH: z.string().nullable().optional(),
    riskAlertStole: z.string().nullable().optional(),
    firstRegistrationYea: z.string().nullable().optional(),
    lastOdometerReadingValue: z.string().nullable().optional(),
});

export type GetCarfaxResponse = z.infer<typeof GetCarfaxResponse>;
export type VehicleData = z.infer<typeof VehicleData>;
