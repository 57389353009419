import { z } from 'zod';

export enum ConfigurationIds {
    C2C_PUBLICATION_TEMPLATES = 'C2C_PUBLICATION_TEMPLATES',
}

export const GetConfigurationResponse = z.object({
    id: z.string(),
    value: z.record(z.string(), z.any()),
    createdAt: z.string(),
    updatedAt: z.string(),
});

export const PutConfigurationRequest = z.object({
    value: z.record(z.string(), z.any()),
});

export type GetConfigurationResponse = z.infer<typeof GetConfigurationResponse>;
export type PutConfigurationRequest = z.infer<typeof PutConfigurationRequest>;
