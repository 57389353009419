import { SelectOption } from '@/types';

export function getOptions(object: Record<string, string> | string[]): SelectOption<string | number>[] {
    if (!object) return [];

    return Object.entries(object).map(([key, value]) => ({ label: value, value: key }));
}

export function getValueArrayOfOptions(options: SelectOption<string>[]): string[] {
    return options.map(({ value }) => value);
}

export function getFilteredOptions<T extends Object>(data: T, filterFields: string[]) {
    return filterFields.reduce((obj: SelectOption<string>[], key: string) => {
        if (key in data) {
            obj.push({
                label: key,
                value: String(data[key as keyof T] ?? ''),
            });
        }
        return obj;
    }, [] as SelectOption<string>[]);
}
