import { DateInput } from '@/components/Inputs/DateInput';
import { TextInput } from '@/components/Inputs/TextInput';
import { Roles } from '@/stores/useProfileStore';
import { ChangeEvent, ChangeEventHandler } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';
import { HiHashtag, HiOutlineCalendar } from 'react-icons/hi';
import { MdEuro } from 'react-icons/md';
import { TbPercentage } from 'react-icons/tb';

export type HighlightInfo<T> = {
    readonly mapKey: keyof T;
    readonly label: string;
    readonly required: boolean;
    readonly type: 'amount' | 'percentage' | 'currency' | 'date';
    readonly disabled: boolean;
    readonly roles?: Roles[];
    readonly inputType?: 'text' | 'number';
    readonly gtZero?: boolean;
    readonly step?: number;
    readonly min?: number;
    readonly max?: number;
    readonly onValueChange?: ChangeEventHandler<HTMLInputElement>;
};

export default function Highlight<T>({
    mapKey,
    label,
    required,
    type,
    disabled,
    inputType,
    gtZero,
    step,
    min,
    max,
    onValueChange,
}: HighlightInfo<T>) {
    const {
        register,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useFormContext();
    const { onChange } = register(mapKey.toString(), {
        required: {
            value: required,
            message: 'Este campo es obligatorio',
        },
        valueAsNumber: true,
    });

    const getIcon = (iconType: string) => {
        if (iconType === 'amount') {
            return <HiHashtag className="h-6 w-6 text-blue-600" />;
        }
        if (iconType === 'percentage') {
            return <TbPercentage className="h-6 w-6 text-blue-600" />;
        }
        if (iconType === 'currency') {
            return <MdEuro className="h-6 w-6 text-blue-600" />;
        }
        if (iconType === 'date') {
            return <HiOutlineCalendar className="h-6 w-6 text-blue-600" />;
        }
    };

    const customOnChange = (event: ChangeEvent<HTMLInputElement> | Date) => {
        if (event instanceof Date) return;
        if (typeof onChange === 'function') {
            onChange(event);
        }
        if (mapKey === 'loanAmount') {
            const newCommission = Math.round(getValues('loanAmount') * getValues('commissionFeeRate')) / 100;
            if (!isNaN(newCommission)) {
                setValue('commissionFee', newCommission);
            }
        }
        if (mapKey === 'commissionFeeRate') {
            const newCommission = Math.round(getValues('loanAmount') * getValues('commissionFeeRate')) / 100;
            if (!isNaN(newCommission)) {
                setValue('commissionFee', newCommission);
            }
        }
    };

    return (
        <div className={'flex flex-1 gap-3.5 items-center rounded-lg'}>
            <div className="flex h-10 w-11 items-center justify-center rounded-lg bg-blue-100">{getIcon(type)}</div>
            <div className={'flex flex-col w-full'}>
                <span className={'text-xs font-normal leading-tight text-gray-500'}>{label}</span>{' '}
                {type === 'date' ? (
                    <Controller
                        name={mapKey.toString()}
                        control={control}
                        rules={{
                            validate: (date?) => !date || !isNaN(date.getTime()),
                            required: {
                                value: required,
                                message: 'Este campo es obligatorio',
                            },
                        }}
                        render={({ field }) => (
                            <DateInput
                                disabled={disabled}
                                disableFuture
                                error={errors[mapKey] as FieldError}
                                {...field}
                            />
                        )}
                    />
                ) : (
                    <TextInput
                        disabled={disabled}
                        type={inputType ?? 'text'}
                        step={inputType === 'number' ? step : undefined}
                        min={inputType === 'number' ? min : undefined}
                        max={inputType === 'number' ? max : undefined}
                        className={`font-bold ${disabled && 'border-white'}`}
                        {...register(mapKey.toString(), {
                            required: {
                                value: required,
                                message: 'Este campo es obligatorio',
                            },
                            valueAsNumber: true,
                            validate: (value) => {
                                if (gtZero && value <= 0) {
                                    return 'El valor introducido debe ser mayor que 0';
                                }
                            },
                        })}
                        withoutBorder
                        onChange={onValueChange ?? customOnChange}
                        error={errors[mapKey] as FieldError}
                    />
                )}
            </div>
        </div>
    );
}
