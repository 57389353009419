import { useApiClient } from '@/hooks';
import { PutDealAssignedAgentRequest } from '@/types';
import {
    ChangeCatalogueVehicleStatusProps,
    GetCatalogueVehicleDetails,
    PutCatalogueVehicleDetails,
} from '@/types/Vehicle';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const apiClient = useApiClient();
const queryKey = ['vehicleDetails'];

export function useGetVehicleDetail(vehicleUuid: string) {
    return useQuery<GetCatalogueVehicleDetails>({
        queryKey,
        queryFn: async () => await apiClient.getCatalogueVehicleDetails(vehicleUuid),
        retry: 1,
        throwOnError: false,
    });
}

export function useGetVehicleDetailByLicensePlate(licensePlate?: string | null, tier?: string | null) {
    return useQuery<GetCatalogueVehicleDetails>({
        queryKey: ['vehicleDetails', licensePlate],
        queryFn: async () => await apiClient.getCatalogueVehicleDetailsByLicensePlate(licensePlate),
        retry: 1,
        enabled: !!licensePlate && tier === 'C2C',
    });
}

export function usePutVehicleInfoMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ vehicleUuid, data }: { vehicleUuid: string; data: PutCatalogueVehicleDetails }) =>
            await apiClient.putCatalogueVehicleDetails(vehicleUuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSuccess: (data) => {
            queryClient.setQueryData(queryKey, data);
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
    });
}

export function usePostCatalogueVehicleStatusChange() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ uuid, data }: { uuid: string; data: ChangeCatalogueVehicleStatusProps }) =>
            await apiClient.postCatalogueVehicleStatusChange(uuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });
            const prevData = queryClient.getQueryData(queryKey);
            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
        onError: (error: any, _, context) => {
            throw new Error(error.message);
        },
    });
}

export function useCatalogueVehicleAssignedAgentMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ vehicleUuid, agents }: { vehicleUuid: string; agents: PutDealAssignedAgentRequest }) => {
            const data = await apiClient.putCatalogueVehicleAssignedAgents(vehicleUuid, agents);
            return data;
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}
