import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Select, TextInput } from '@/components/Inputs';
import { useUuid } from '@/hooks/useUuid';
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning';
import { getOptions } from '@/lib/utils/getters';
import { DealBasicInfo, FormProps } from '@/types';
import { Address, HouseType, houseType } from '@/types/Deal';
import { updateDealAddress } from '@/views/deals/details/info';
import Script from 'next/script';
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';

export function BillingAddressForm({
    address,
    onSuccess,
    onError,
    dealBasicInfo,
}: FormProps & {
    readonly address: Address | null;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const dealUuuid = useUuid();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { isDirty, errors, isSubmitting },
    } = useForm({
        values: {
            ...address,
            houseType: address?.houseType === null ? '' : address?.houseType,
        },
        mode: 'onSubmit',
    });
    const { mutateAsync: updateAddress } = updateDealAddress();
    const autocomplete = useRef<google.maps.places.Autocomplete>();

    useUnsavedChangesWarning(isDirty, () => {
        return confirm('Parece que tienes cambios sin guardar, ¿deseas abandonar la página?');
    });

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            await updateAddress({
                uuid: dealUuuid,
                data: {
                    billingAddress: {
                        ...data,
                        city: data.city === '' ? null : data.city,
                        domicile: data.domicile === '' ? null : data.domicile,
                        houseType: data.houseType === '' ? null : (data.houseType as HouseType),
                        postalCode: data.postalCode === '' ? null : data.postalCode,
                        province: data.province === '' ? null : data.province,
                        route: data.route === '' ? null : data.route,
                        streetNumber: data.streetNumber === '' ? null : data.streetNumber,
                        complete: `${data.route}, ${data.streetNumber}, ${data.domicile}, ${data.postalCode}`,
                    },
                },
            });
            onSuccess();
        } catch (e) {
            onError();
        }
    });
    const fillInAddress = useCallback(() => {
        const place = autocomplete.current?.getPlace();
        const GoogleMapsValuesMap: {
            [key in string]: keyof Address;
        } = {
            street_number: 'streetNumber',
            route: 'route',
            postal_code: 'postalCode',
            locality: 'city',
            administrative_area_level_2: 'province',
        };
        if (place) {
            for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                const componentType = component.types[0];

                if (GoogleMapsValuesMap[componentType]) {
                    setValue(GoogleMapsValuesMap[componentType] as keyof Address, component.long_name);
                }
            }
        }
    }, [setValue]);

    const initAutocomplete = useCallback(() => {
        const addressInput = document.getElementById('route') as HTMLInputElement;
        autocomplete.current = new window.google.maps.places.Autocomplete(addressInput, {
            componentRestrictions: { country: 'es' },
            fields: ['address_components'],
            types: ['address'],
        });
        autocomplete.current.addListener('place_changed', fillInAddress);
    }, [fillInAddress]);

    useEffect(() => {
        if (window?.google) {
            initAutocomplete();
        }
    }, [window.google]);

    useLayoutEffect(() => {
        window['initMap'] = () => {};
    }, []);

    return (
        <div className="space-y-2">
            <Accordion title="Dirección de facturación" className="bg-gray-100">
                <form
                    className="grid grid-cols-3 gap-4 p-5 form-enabled"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmitForm();
                    }}
                >
                    <Select
                        label="Tipo de vivienda"
                        placeholder="Selecciona una opción"
                        options={getOptions(houseType)}
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('houseType', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El tipo de vivienda es obligatorio' : false,
                        })}
                        error={errors.houseType}
                    />
                    <TextInput
                        label="Dirección"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('route', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'La dirección es obligatoria' : false,
                        })}
                        type="text"
                        id="route"
                        error={errors.route}
                    />
                    <TextInput
                        label="Número de calle"
                        {...register('streetNumber')}
                        type="text"
                        error={errors.streetNumber}
                    />
                    <TextInput
                        label="Número de piso + puerta"
                        {...register('domicile')}
                        type="text"
                        error={errors.domicile}
                    />

                    <TextInput
                        label="Localidad"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('city', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'La localidad es obligatoria' : false,
                        })}
                        type="text"
                        error={errors.city}
                    />
                    <TextInput
                        label="Provincia"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('province', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'La provincia es obligatoria' : false,
                        })}
                        type="text"
                        error={errors.province}
                    />
                    <TextInput
                        label="Código postal"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('postalCode', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El código postal es obligatorio' : false,
                        })}
                        type="text"
                        error={errors.postalCode}
                    />

                    <div className="grid col-span-3 gap-10 grid-cols-6">
                        <Button
                            disabled={!isDirty || isSubmitting}
                            className=" col-span-2 col-start-5"
                            type="submit"
                            color="blue"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
                <Script
                    src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`}
                ></Script>
            </Accordion>
        </div>
    );
}
