import { Card } from '@/components/Card';
import { HiOutlineEmojiSad } from 'react-icons/hi';

interface LostReasonSectionProps {
    readonly reason: string;
}

export function LostReasonCard({ reason }: LostReasonSectionProps) {
    return (
        <Card>
            <div className="flex gap-3">
                <div className="flex items-center justify-center rounded-lg bg-red-100 p-2">
                    <HiOutlineEmojiSad className="h-6 w-6 text-red-400" />
                </div>
                <div className="text-gray-500 flex items-center">
                    <span className="flex items-center gap-3 text-sm font-bold">
                        Razón de perdido{' '}
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M15 1L19 5M19 5L15 9M19 5H1"
                                stroke="#6B7280"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>{' '}
                        {reason}
                    </span>
                </div>
            </div>
        </Card>
    );
}
