import { ProductSources } from '@/types/Vehicle/index';
import { z } from 'zod';

export interface VehiclePublicationFormInput {
    cochesnetURL?: string;
    wallapopURL?: string;
    autocasionURL?: string;
    // cochescomURL?: string;
}

export type VehiclePublicationFormInputKey = keyof VehiclePublicationFormInput;

export const vehiclePublicationFormToProductSource: Record<VehiclePublicationFormInputKey, ProductSources> = {
    cochesnetURL: 'COCHESNET',
    wallapopURL: 'WALLAPOP',
    autocasionURL: 'AUTOCASION',
    // cochescomURL: 'COCHESCOM',
};

export const descriptionTypes = {
    short: 'corta',
    long: 'larga',
};

export const GetCatalogueVehiclePublicationDetails = z.object({
    descriptions: z.object({
        long: z.string(),
        short: z.string(),
    }),
});

export type DescriptionType = keyof typeof descriptionTypes;
export type GetCatalogueVehiclePublicationDetails = z.infer<typeof GetCatalogueVehiclePublicationDetails>;
