import { DropdownButton } from '@/components/Button';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { useStorage } from '@/hooks';
import { usePatchDealMutation } from '@/layouts/useDealBasicInfoQuery';
import { tierIcons } from '@/lib/shared/DealsData';
import { Roles, useProfileStore } from '@/stores';
import { allC2CLostReasons, DealListViewSkeleton, DealTableRow, situationsC2C, stagesC2C, tiers } from '@/types';
import { DetailsButton } from '@/views/shared/DetailsButton';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Table, Tooltip } from 'flowbite-react';
import { PropsWithChildren } from 'react';
import { HiDownload, HiOutlineStar, HiStar } from 'react-icons/hi';

const Header: string[] = [
    '',
    'Producto',
    'Id de Pipedrive',
    'Matrícula',
    'Creación',
    'Situación',
    'Etapa',
    'Cliente',
    'Teléfono',
    'Email',
    '',
];

const HeaderC2C: string[] = [
    '',
    'ID',
    'Matrícula',
    'Marca',
    'Modelo',
    'Agente',
    'Creación',
    'Estado',
    'Subestado',
    'Cliente',
    'Teléfono',
    'Nota',
    'Última fecha nota',
    '',
];

type DealsTableProps = {
    readonly isCsvDownloadPending: boolean;
    readonly deals: DealListViewSkeleton;
    readonly itemsPerPage: number;
    readonly changeItemsPerPage: (page: number) => void;
    readonly selectedDealTier: string;
    readonly handleDownloadCSV: () => void;
};

export default function DealsTable({
    isCsvDownloadPending,
    deals,
    itemsPerPage,
    changeItemsPerPage,
    selectedDealTier,
    handleDownloadCSV,
}: DealsTableProps) {
    const paginationOptions = [10, 25, 50, 100];
    const { setItem } = useStorage();
    const { hasRequiredRoles, isAdmin } = useProfileStore();
    const { mutateAsync: patchDeal } = usePatchDealMutation();
    const dialog = useDialog();

    const showErrorToast = (message: string) => {
        dialog({
            type: 'toast',
            variant: 'error',
            content: message,
        });
    };

    const handleSwitchStarred = async (uuid: string, starred: boolean | undefined) => {
        try {
            const isStarred = !starred;
            await patchDeal({
                dealUuid: uuid,
                data: { isStarred },
            });
            const updatedDeal = deals.items.find((deal) => deal.uuid === uuid);
            if (updatedDeal) {
                updatedDeal.isStarred = isStarred;
            }
        } catch (e: any) {
            showErrorToast(e.message);
        }
    };

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2 py-5">
                    <span>Mostrar</span>
                    <DropdownButton
                        direction="left"
                        className="w-8/12 justify-between min-w-fit"
                        type={'button'}
                        color="light-gray"
                        variant="outline"
                        size="small"
                        label={itemsPerPage}
                    >
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                            {paginationOptions.map((numberOfItems) => (
                                <button
                                    key={numberOfItems}
                                    className="block px-4 py-2 hover:bg-gray-100 w-full text-start"
                                    onClick={() => {
                                        changeItemsPerPage(numberOfItems);
                                    }}
                                >
                                    {numberOfItems}
                                </button>
                            ))}
                        </ul>
                    </DropdownButton>
                    <span>de {deals?.totalItems || 0} Resultados</span>
                </div>
                {deals?.totalItems > 0 && isAdmin() && (
                    <button
                        className="flex items-center gap-2 py-1 px-2 bg-white rounded-md text-sm font-medium hover:bg-gray-100"
                        onClick={handleDownloadCSV}
                        disabled={isCsvDownloadPending}
                    >
                        <HiDownload />
                        {isCsvDownloadPending ? 'Descargando...' : 'Descargar CSV'}
                    </button>
                )}
            </div>
            <Table striped={true} hoverable={true}>
                <Table.Head className="bg-gray-300">
                    {selectedDealTier === 'C2C'
                        ? HeaderC2C.map((label, index) => {
                              if (label === 'ID' && !isAdmin()) return;
                              return (
                                  <Table.HeadCell
                                      theme={{
                                          base: `bg-gray-50 border-b-1 border-b-gray-300 text-gray-400 px-4 py-2 ${
                                              index === 0 ? 'text-start' : 'text-center'
                                          }`,
                                      }}
                                      key={label}
                                  >
                                      {label}
                                  </Table.HeadCell>
                              );
                          })
                        : Header.map((label, index) => {
                              return (
                                  <Table.HeadCell
                                      theme={{
                                          base: `bg-gray-50 border-b-1 border-b-gray-300 text-gray-400 px-4 py-2 ${
                                              index === 0 ? 'text-start' : 'text-center'
                                          }`,
                                      }}
                                      key={label}
                                  >
                                      {label}
                                  </Table.HeadCell>
                              );
                          })}
                </Table.Head>
                <Table.Body className="divide-y text-sm">
                    {deals?.items?.map((deal: DealTableRow) => (
                        <Table.Row
                            key={`TRow-${deal.uuid}`}
                            className="overflow-hidden bg-white dark:border-gray-700 dark:bg-gray-800 text-gray-900 hover:bg-blue-50 dark:hover:bg-gray-90"
                        >
                            <DealListCell>
                                <button
                                    className="p-1 hover:bg-gray-200 rounded-lg"
                                    onClick={() => handleSwitchStarred(deal.uuid, deal.isStarred)}
                                >
                                    {deal.isStarred ? (
                                        <HiStar className="w-5 h-5 text-blue-500"></HiStar>
                                    ) : (
                                        <HiOutlineStar className="w-5 h-5 text-gray-400"></HiOutlineStar>
                                    )}
                                </button>
                            </DealListCell>
                            {selectedDealTier !== 'C2C' && (
                                <DealListCell className="uppercase">{`${deal.tier && tierIcons[deal.tier]} ${
                                    deal.tier && tiers[deal.tier as keyof typeof tiers]
                                }`}</DealListCell>
                            )}
                            {selectedDealTier === 'C2C' && isAdmin() ? (
                                <DealListCell className=" ps-4">{deal.id}</DealListCell>
                            ) : null}
                            {selectedDealTier !== 'C2C' && (
                                <DealListCell className=" ps-4">{`${deal.opsDealId ?? ''}`}</DealListCell>
                            )}
                            <DealListCell className="ps-4">{deal.licensePlate}</DealListCell>
                            {selectedDealTier === 'C2C' && (
                                <DealListCell className=" ps-4">{`${deal.makeName ?? ''}`}</DealListCell>
                            )}
                            {selectedDealTier === 'C2C' && (
                                <DealListCell className=" ps-4">{`${deal.modelName ?? ''}`}</DealListCell>
                            )}
                            {selectedDealTier === 'C2C' && (
                                <DealListCell className="ps-4">{deal.salesAgent?.name}</DealListCell>
                            )}
                            <DealListCell>{deal.createdAt}</DealListCell>
                            {selectedDealTier !== 'C2C' ? (
                                <DealListCell>{deal.situation}</DealListCell>
                            ) : (
                                <DealListCell>
                                    {situationsC2C[deal.situation as keyof typeof situationsC2C] ?? ''}
                                </DealListCell>
                            )}
                            {selectedDealTier !== 'C2C' ? (
                                <DealListCell>{deal.stage}</DealListCell>
                            ) : (
                                <DealListCell>
                                    {deal.situation !== 'LOST'
                                        ? stagesC2C[deal.stage as keyof typeof stagesC2C] ?? ''
                                        : allC2CLostReasons[deal.lostReason as keyof typeof allC2CLostReasons]}
                                </DealListCell>
                            )}
                            <DealListCell>
                                <div className="flex flex-col">
                                    <span>
                                        {deal.client.name} {deal.client.lastName}
                                    </span>
                                </div>
                            </DealListCell>
                            <DealListCell>
                                <div className="flex flex-col">
                                    <span>{deal.client.phone}</span>
                                </div>
                            </DealListCell>
                            {selectedDealTier !== 'C2C' && (
                                <DealListCell>
                                    <div className="flex flex-col">
                                        <span>{deal.client.email}</span>
                                    </div>
                                </DealListCell>
                            )}
                            {selectedDealTier === 'C2C' && (
                                <DealListCell>
                                    <Tooltip content={`${deal.lastNote?.content}`}>
                                        <span className="inline-block truncate max-w-[200px]">
                                            {deal.lastNote?.content}
                                        </span>
                                    </Tooltip>
                                </DealListCell>
                            )}
                            {selectedDealTier === 'C2C' && (
                                <DealListCell>
                                    <Tooltip content={`${deal.lastNote?.content}`}>
                                        <span className="inline-block truncate max-w-[200px]">
                                            {deal.lastNote?.createdAt
                                                ? format(new Date(deal.lastNote?.createdAt), 'dd/MM/yyyy - HH:mm', {
                                                      locale: es,
                                                  })
                                                : ''}
                                        </span>
                                    </Tooltip>
                                </DealListCell>
                            )}
                            <DealListCell className="flex-col justify-center">
                                <DetailsButton
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setItem('dealAccessedFrom', 'Negocios');
                                        if (hasRequiredRoles([Roles.FINANCIAL_ANALYST]) && !isAdmin()) {
                                            window.open(`/deals/${deal.uuid}/submissions`, '_blank');
                                        } else {
                                            window.open(`/deals/${deal.uuid}/info`, '_blank');
                                        }
                                    }}
                                />
                            </DealListCell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </>
    );
}

export function DealListCell({ children, className }: PropsWithChildren<{ className?: string }>) {
    return (
        <Table.Cell
            theme={{
                base: `px-1 py-2 text-gray-900 text-center first:text-start ${className}`,
            }}
        >
            {children}
        </Table.Cell>
    );
}
