import { Label } from 'flowbite-react';
import React, { ForwardedRef } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { BsAsterisk } from 'react-icons/bs';
import AsyncSelect from 'react-select/async';
import { Props } from 'react-select/dist/declarations/src';

interface ComboboxProps extends Props {
    key?: string;
    label: string;
    options: any;
    disabled?: boolean;
    defaultOptions?: any;
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
    onChange?: (event: any) => void;
    required?: boolean;
}

export const Combobox = React.forwardRef((props: ComboboxProps, ref: ForwardedRef<any>) => {
    const LabelTheme = {
        root: {
            base: `text-xs font-normal ${props.disabled && 'text-gray-300'}`,
        },
    };

    const className = props.isMulti
        ? {
              control: ({ isFocused }: { isFocused: boolean }) =>
                  `!bg-gray-50 !rounded-lg ${isFocused && '!border-blue-600 !shadow-[0_0_0_1px_rgb(0,129,168)]'}`,
              menuList: () => 'text-sm ',
              option: ({ isFocused }: { isFocused: boolean }) => `hover:bg-gray-100 ${isFocused && '!bg-gray-100'}`,
              multiValue: () => ' !bg-gray-200',
              multiValueRemove: () => 'hover:!bg-gray-200',
              clearIndicator: () => 'hover:!text-red-500 !cursor-pointer',
              placeholder: () => 'text-sm',
          }
        : {
              container: () => 'hover:text-gray-900',
              control: () =>
                  `bg-gray-50 border-1 w-full rounded-lg px-3 py-0 text-sm ring-0 text-gray-900 border-gray-300 ${
                      props.error ? 'border-red-500' : ''
                  }`,
              valueContainer: () => 'text-black ring-0 !outline-none border-none flex gap-2 ',
              input: () => 'ring-offset-0 focus:shadow-none cursor-text',
              menu: () => 'text-gray-900 hover:text-gray-900',
              menuList: () => 'text-sm bg-white p-4 space-y-3 shadow-lg border-gray-100 rounded-b-lg',
              option: () => 'hover:bg-gray-100 p-1 rounded-lg',
              indicatorSeparator: () => 'hidden',
              indicatorsContainer: () => 'text-gray-400',
              multiValue: () => 'border-1 p-1 gap-2 bg-gray-200',
              multiValueRemove: () => 'p-1  hover:text-red-500',
              clearIndicator: () => 'hover:text-red-500 bg-gray-200 mx-1 px-0.5 rounded-full cursor-pointer',
              placeholder: () => 'text-gray-500',
          };

    return (
        <div>
            <Label
                theme={LabelTheme}
                htmlFor={props.id}
                color={props.error ? 'failure' : undefined}
                className={`${props.disabled ? 'text-gray-300' : ''}`}
            >
                <div className="flex gap-1 items-center">
                    {props.label}
                    {props.required && <BsAsterisk className={props.error ? 'text-red-500' : 'text-blue-600'} />}
                </div>
            </Label>
            <div>
                <AsyncSelect
                    className="react-select-container"
                    classNamePrefix="react-select"
                    unstyled={!props.isMulti}
                    styles={{
                        input: (base) => ({
                            ...base,
                            'input:focus': {
                                boxShadow: 'none',
                            },
                        }),
                        indicatorsContainer: (base) => ({
                            ...base,
                            svg: {
                                width: '16px',
                            },
                        }),
                    }}
                    isClearable={true}
                    cacheOptions
                    isDisabled={props.disabled}
                    escapeClearsValue
                    classNames={className}
                    loadingMessage={() => 'Cargando...'}
                    loadOptions={props.options}
                    noOptionsMessage={() => 'No hay resultados'}
                    defaultOptions={props.defaultOptions}
                    placeholder="Selecciona una opción"
                    {...props}
                    ref={ref}
                />
            </div>
            <span className={`mt-1 text-xs ${props.error ? 'text-red-600' : ''}`}>
                {props.error?.message?.toString()}
            </span>
        </div>
    );
});
