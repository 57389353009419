import { Label, Radio } from 'flowbite-react';
import React, { ComponentProps, ForwardedRef } from 'react';
import { FieldError } from 'react-hook-form';
import { BsAsterisk } from 'react-icons/bs';
interface RadioProps extends ComponentProps<'input'> {
    label?: string;
    error?: FieldError;
    inputs: {
        label: string;
        value: string | number;
    }[];
    orientation?: 'row' | 'column';
}

export const RadioGroup = React.forwardRef((props: RadioProps, ref: ForwardedRef<HTMLInputElement>) => {
    const LabelTheme = {
        root: {
            base: `text-xs flex  gap-2  ${props.disabled ? 'text-gray-300 ' : ''}`,
        },
    };

    return (
        <div className={`flex justify-between ${props.className}`}>
            <div
                className={`text-xs flex w-full ${
                    props.orientation === 'row' ? 'gap-5' : 'flex-col gap-4'
                } justify-center  radio-group ${props.disabled ? 'text-gray-300 ' : 'hover:text-blue-500'} `}
            >
                <span className={`${props.error ? 'text-red-600 hover:text-red-600' : ''}`}>
                    <div className="flex gap-1 items-center">
                        {props.label} {props.required && <BsAsterisk className="text-blue-500" />}
                    </div>
                </span>
                <div className="flex gap-4">
                    {props.inputs.map((item) => (
                        <div key={item.label} className="flex flex-row ">
                            <Label
                                theme={LabelTheme}
                                htmlFor={props.id}
                                className={`gap-2 ${props.disabled ? 'text-gray-300' : ''}`}
                            >
                                <Radio {...props} ref={ref} value={item.value} />
                                {item.label}
                            </Label>
                        </div>
                    ))}
                </div>
            </div>
            <span className={`mt-1 text-xs ${props.error ? 'text-red-600' : ''}`}>{props.error?.message}</span>
        </div>
    );
});
