export class ApiClientError extends Error {
    public statusCode: any;
    public responseData: any;
    constructor(message: string, status: number, responseData?: any) {
        super(message);
        this.name = 'ApiClientError';
        this.statusCode = status;
        this.responseData = responseData;
    }
}
