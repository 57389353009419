import { UserProfile } from '@auth0/nextjs-auth0/client';
import { Dropdown } from 'flowbite-react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { HiCog } from 'react-icons/hi';

export function Avatar({ user }: { user: UserProfile }) {
    const router = useRouter();
    return (
        <>
            <Dropdown
                label={
                    <Image
                        src={user?.picture ?? '/images/avatar.png'}
                        width={40}
                        height={40}
                        alt=""
                        className="rounded-full min-w-[40px]"
                    />
                }
                arrowIcon={false}
                inline={true}
                className="z-[99999999] rounded-xl p-0 "
            >
                <Dropdown.Item className="flex items-center gap-1" onClick={() => router.push('/configuration')}>
                    <HiCog className="h-4 w-4" />
                    Configuración
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                    className="text-red-500"
                    onClick={() => {
                        router.push('/api/auth/logout');
                    }}
                >
                    Cerrar Sesión
                </Dropdown.Item>
            </Dropdown>
        </>
    );
}
