import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { DateInput, Select, TextInput } from '@/components/Inputs';
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning';
import { useUuid } from '@/hooks/useUuid';
import { ISO31661ALPHA2, findCounrty } from '@/lib/utils/countries';
import { getOptions } from '@/lib/utils/getters';
import { getDocumentType, isValidDocument } from '@/lib/validators';
import { isValidPhoneNumber } from '@/lib/validators/phoneFormValidator';
import { DealBasicInfo } from '@/types';
import { DocumentType, ForeignDetail, Gender, LoanPurpose, MaritalStatus, PersonalDetail } from '@/types/Deal';
import { FormProps, SelectOption } from '@/types/generics';
import { updateDealForeignDetail, updatePersonalDetailMutation } from '@/views/deals/details/info';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const genderOptions: SelectOption<Gender>[] = [
    {
        label: 'Varon',
        value: 'MALE',
    },
    {
        label: 'Mujer',
        value: 'FEMALE',
    },
];

const maritalStatusOptions: SelectOption<MaritalStatus>[] = [
    {
        label: 'Soltero',
        value: 'SINGLE',
    },
    {
        label: 'Casado',
        value: 'MARRIED',
    },
    {
        label: 'Divorciado',
        value: 'DIVORCED',
    },
    {
        label: 'Viudo',
        value: 'WIDOWER',
    },
];

export const loanPurposeOptions: SelectOption<LoanPurpose>[] = [
    {
        label: 'Reforma de vivienda',
        value: 'HOME_RENOVATION',
    },
    {
        label: 'Vehículo Nuevo',
        value: 'NEW_VEHICLE',
    },
    {
        label: 'Vehículo Usado',
        value: 'USED_VEHICLE',
    },
    {
        label: 'Muebles/Equipamiento del hogar',
        value: 'HOME_FURNISHING_APPLIANCES',
    },
    {
        label: 'Estudios',
        value: 'TRAINING',
    },
    {
        label: 'Sanidad',
        value: 'HEALTHCARE',
    },
    {
        label: 'Viajes/Ocio',
        value: 'TRAVEL_OR_LEISURE',
    },
    {
        label: 'Acontecimiento familiar/Celebr',
        value: 'EVENT_OR_CELEBRATION',
    },
    {
        label: 'Creación nueva empresa',
        value: 'NEW_BUSINESS',
    },
    {
        label: 'Necesidad de tesorería',
        value: 'CASH_FLOW_NEEDS',
    },
    {
        label: 'Unificar créditos',
        value: 'UNIFY_CREDITS',
    },
    {
        label: 'Otros',
        value: 'OTHER',
    },
];

const legalNatureOptions = {
    NATURAL_PERSON: 'Persona física',
    LEGAL_PERSON: 'Persona jurídica',
};

export function PersonalDataForm({
    personalDetail,
    foreignDetail,
    onSuccess,
    onError,
    dealBasicInfo,
}: FormProps & {
    readonly personalDetail: PersonalDetail;
    readonly foreignDetail: ForeignDetail;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const dealUuid = useUuid();
    const [documentType, setDocumentType] = useState<DocumentType | undefined>('DNI');

    const { mutateAsync: updatePersonalDetail } = updatePersonalDetailMutation();
    const { mutateAsync: updateForeignDetail } = updateDealForeignDetail();
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { isDirty, errors, isSubmitting },
    } = useForm({
        values: {
            ...personalDetail,
            ...foreignDetail,
            dateOfBirth: personalDetail?.dateOfBirth ? utcToZonedTime(personalDetail?.dateOfBirth, 'UTC') : null,
            nationality: personalDetail.nationality ? findCounrty(personalDetail.nationality)?.isoCode : '',
            countryOfBirth: personalDetail.countryOfBirth
                ? findCounrty(personalDetail.countryOfBirth)?.isoCode
                : personalDetail.countryOfBirth,
            residenceStartDate: foreignDetail.residenceStartDate
                ? utcToZonedTime(foreignDetail.residenceStartDate, 'UTC')
                : null,
            residenceCardExpirationDate: foreignDetail.residenceCardExpirationDate
                ? utcToZonedTime(foreignDetail.residenceCardExpirationDate, 'UTC')
                : null,
            socialSecurityRegistrationDate: foreignDetail.socialSecurityRegistrationDate
                ? utcToZonedTime(foreignDetail.socialSecurityRegistrationDate, 'UTC')
                : null,
            gender: personalDetail?.gender ?? '',
            maritalStatus: personalDetail?.maritalStatus ?? '',
            loanPurpose: personalDetail?.loanPurpose ?? '',
            legalNature: personalDetail?.legalNature ?? 'NATURAL_PERSON',
            identificationNumber: {
                number: personalDetail.identificationNumber?.number ?? null,
            },
        },
        mode: 'onSubmit',
    });

    useUnsavedChangesWarning(isDirty, () => {
        return confirm('Parece que tienes cambios sin guardar, ¿deseas abandonar la página?');
    });

    useEffect(() => {
        if (personalDetail.identificationNumber?.type) {
            setDocumentType(personalDetail.identificationNumber.type);
        }
    }, [personalDetail]);

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            const res = await updatePersonalDetail({
                uuid: dealUuid,
                data: {
                    ...data,
                    dateOfBirth: data.dateOfBirth ? zonedTimeToUtc(data.dateOfBirth, 'UTC') : null,
                    identificationNumber:
                        data.identificationNumber?.number !== '' && data.identificationNumber?.number !== null
                            ? {
                                  number: data.identificationNumber?.number,
                              }
                            : null,
                    email: data.email !== '' ? data.email : null,
                    gender: data.gender === '' ? null : (data.gender as Gender),
                    phone: data.phone === '' ? null : data.phone,
                    secondPhone: data.secondPhone === '' ? null : data.secondPhone,
                    maritalStatus: data.maritalStatus === '' ? null : (data.maritalStatus as MaritalStatus),
                    loanPurpose: data.loanPurpose === '' ? null : (data.loanPurpose as LoanPurpose),
                    nationality: data.nationality === '' ? null : data.nationality,
                },
            });

            if (documentType === 'NIE') {
                await updateForeignDetail({
                    uuid: dealUuid,
                    data: {
                        residenceStartDate: data.residenceStartDate
                            ? zonedTimeToUtc(data.residenceStartDate, 'UTC').toISOString()
                            : null,
                        residenceCardExpirationDate: data.residenceCardExpirationDate
                            ? zonedTimeToUtc(data.residenceCardExpirationDate, 'UTC').toISOString()
                            : null,
                        socialSecurityRegistrationDate: data.socialSecurityRegistrationDate
                            ? zonedTimeToUtc(data.socialSecurityRegistrationDate, 'UTC').toISOString()
                            : null,
                        numberOfJobs: data.numberOfJobs,
                    },
                });
            }

            onSuccess();
        } catch (e) {
            onError();
        }
    });

    const handleValidateDocumentType = (value: string) =>
        isValidDocument(value) && setDocumentType(getDocumentType(value));

    return (
        <div className="space-y-2">
            <Accordion title="Datos personales" className="bg-gray-100">
                <form
                    className="grid grid-cols-3 gap-4 p-5 form-enabled"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmitForm();
                    }}
                >
                    {dealBasicInfo?.tier === 'C2C' && (
                        <Select
                            label="Naturaleza jurídica"
                            placeholder="Selecciona una opción"
                            options={getOptions(legalNatureOptions)}
                            {...register('legalNature')}
                            error={errors.legalNature}
                        />
                    )}
                    <TextInput
                        label="DNI / NIE"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('identificationNumber.number', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El DNI/NIE es obligatorio' : false,
                            validate: {
                                value: (value?: string | null) =>
                                    (value && isValidDocument(value.trim())) ||
                                    dealBasicInfo?.tier === 'C2C' ||
                                    'Introduce un documento válido',
                            },
                            onChange: (e) => {
                                setValue('identificationNumber.number', e.target.value.toUpperCase());
                            },
                        })}
                        type="text"
                        onBlur={(e) => {
                            setValue('identificationNumber.number', e.target.value.trim());
                            handleValidateDocumentType(e.target.value);
                        }}
                        error={errors.identificationNumber?.number}
                    />

                    <TextInput
                        label="Email"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('email', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El email es obligatorio' : false,
                            validate: {
                                value: (value?: any) => {
                                    if (value === null || value === '') {
                                        return true;
                                    }
                                    if (!value.match(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[\w-]{2,}$/gm)) {
                                        return 'Email no válido';
                                    }
                                },
                            },
                        })}
                        type="email"
                        error={errors.email}
                        onBlur={(e) => {
                            setValue('email', e.target.value.trim());
                        }}
                    />

                    <TextInput
                        label="Teléfono móvil"
                        required={dealBasicInfo?.tier !== 'C2C'}
                        {...register('phone', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El móvil es obligatorio' : false,
                            minLength: {
                                value: 9,
                                message: 'El móvil ha de tener como mínimo 9 dígitos',
                            },
                            maxLength: {
                                value: 25,
                                message: 'El móvil ha de tener como máximo 25 dígitos',
                            },
                            validate: {
                                value: (value?: any) => {
                                    if (value === '' || value == null) {
                                        return true;
                                    }
                                    if (!isValidPhoneNumber(value)) {
                                        return 'Número introducido no válido';
                                    }
                                },
                            },
                        })}
                        type="text"
                        error={errors.phone}
                        onBlur={(e) => {
                            setValue('phone', e.target.value.trim());
                        }}
                    />

                    <TextInput
                        label="Segundo teléfono móvil"
                        {...register('secondPhone', {
                            minLength: {
                                value: 9,
                                message: 'El móvil ha de tener como mínimo 9 dígitos',
                            },
                            maxLength: {
                                value: 25,
                                message: 'El móvil ha de tener como máximo 25 dígitos',
                            },
                            validate: {
                                value: (value?: any) => {
                                    if (value === '' || value == null) {
                                        return true;
                                    }
                                    if (!isValidPhoneNumber(value)) {
                                        return 'Número introducido no válido';
                                    }
                                },
                            },
                        })}
                        type="text"
                        error={errors.secondPhone}
                        onBlur={(e) => {
                            setValue('secondPhone', e.target.value.trim());
                        }}
                    />

                    <TextInput
                        required={dealBasicInfo?.tier !== 'C2C'}
                        label="Nombre"
                        {...register('name', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El nombre es obligatorio' : false,
                        })}
                        type="text"
                        error={errors.name}
                        onBlur={(e) => {
                            setValue('name', e.target.value.trim());
                        }}
                    />

                    <TextInput
                        required={dealBasicInfo?.tier !== 'C2C'}
                        label="Primer apellido"
                        {...register('lastName', {
                            required: dealBasicInfo?.tier !== 'C2C' ? 'El primer apellido es obligatorio' : false,
                        })}
                        type="text"
                        error={errors.lastName}
                        onBlur={(e) => {
                            setValue('lastName', e.target.value.trim());
                        }}
                    />

                    <TextInput
                        required={documentType === 'DNI' && dealBasicInfo?.tier !== 'C2C'}
                        label="Segundo apellido"
                        {...register('secondLastName', {
                            required:
                                documentType === 'DNI' && dealBasicInfo?.tier !== 'C2C'
                                    ? 'El segundo apellido es obligatorio'
                                    : false,
                        })}
                        type="text"
                        error={errors.secondLastName}
                        onBlur={(e) => {
                            setValue('secondLastName', e.target.value.trim());
                        }}
                    />

                    <Controller
                        name="dateOfBirth"
                        control={control}
                        rules={{
                            required: dealBasicInfo?.tier !== 'C2C' ? 'La fecha de nacimiento es obligatoria' : false,
                            validate: (value?: any) =>
                                (value instanceof Date && !isNaN(value.getTime())) ||
                                dealBasicInfo?.tier === 'C2C' ||
                                'Fecha no válida',
                        }}
                        render={({ field }) => (
                            <DateInput
                                label="Fecha de nacimiento"
                                error={errors.dateOfBirth}
                                {...field}
                                required={dealBasicInfo?.tier !== 'C2C'}
                            />
                        )}
                    />
                    <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                label="Género"
                                placeholder="Selecciona un género"
                                allowEmpty
                                options={genderOptions}
                                error={errors.gender}
                            />
                        )}
                    />

                    <Controller
                        name="maritalStatus"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Estado civil"
                                placeholder="Selecciona un estado civil"
                                allowEmpty
                                options={maritalStatusOptions}
                                {...field}
                                error={errors.maritalStatus}
                            />
                        )}
                    />

                    <TextInput
                        type="number"
                        label="Número de hijos"
                        {...register('numberOfChildren', {
                            valueAsNumber: true,
                            min: 0,
                        })}
                        error={errors.numberOfChildren}
                    />

                    <Controller
                        name="nationality"
                        control={control}
                        rules={{
                            required:
                                documentType === 'NIE' && dealBasicInfo?.tier !== 'C2C'
                                    ? 'La nacionalidad es obligatoria'
                                    : false,
                        }}
                        render={({ field }) => (
                            <Select
                                label="Nacionalidad"
                                placeholder="Selecciona una nacionalidad"
                                options={ISO31661ALPHA2.map((country) => {
                                    return { label: country.commonName, value: country.isoCode };
                                })}
                                required={dealBasicInfo?.tier !== 'C2C'}
                                error={errors.nationality}
                                {...field}
                            />
                        )}
                    />

                    <Controller
                        name="countryOfBirth"
                        control={control}
                        render={({ field }) => (
                            <Select
                                {...field}
                                value={field.value ?? ''}
                                label="País de nacimiento"
                                placeholder="Selecciona una nacionalidad"
                                options={ISO31661ALPHA2.map((country) => {
                                    return { label: country.commonName, value: country.isoCode };
                                })}
                                error={errors.countryOfBirth}
                            />
                        )}
                    />
                    {dealBasicInfo?.tier !== 'C2C' && (
                        <>
                            <Controller
                                name="loanPurpose"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        label="Motivo del préstamo"
                                        placeholder="Selecciona un motivo"
                                        allowEmpty
                                        {...field}
                                        options={loanPurposeOptions}
                                        error={errors.loanPurpose}
                                    />
                                )}
                            />

                            <TextInput
                                type="number"
                                label="Gastos hipoteca/alquiler"
                                {...register('rentOrMortgageAmount', {
                                    valueAsNumber: true,
                                    min: 0,
                                })}
                                error={errors.rentOrMortgageAmount}
                            />
                        </>
                    )}
                    {documentType === 'NIE' && (
                        <>
                            <TextInput
                                type="number"
                                label="Número de empleos"
                                {...register('numberOfJobs', {
                                    valueAsNumber: true,
                                })}
                                error={errors.numberOfJobs}
                            />
                            <Controller
                                name="residenceCardExpirationDate"
                                control={control}
                                rules={{
                                    required:
                                        dealBasicInfo?.tier !== 'C2C'
                                            ? 'La fecha de caducidad de la tarjeta de residencia es obligatoria'
                                            : false,
                                    validate: (value?: any) =>
                                        (value instanceof Date && !isNaN(value.getTime()) && value > new Date()) ||
                                        dealBasicInfo?.tier === 'C2C' ||
                                        'Fecha no válida',
                                }}
                                render={({ field }) => (
                                    <DateInput
                                        label="Fecha caducidad tarjeta residencia"
                                        disableFuture={false}
                                        error={errors.residenceCardExpirationDate}
                                        {...field}
                                        required={dealBasicInfo?.tier !== 'C2C'}
                                    />
                                )}
                            />

                            <Controller
                                name="socialSecurityRegistrationDate"
                                control={control}
                                rules={{
                                    required:
                                        dealBasicInfo?.tier !== 'C2C'
                                            ? 'La fecha de alta en la Seguridad Social es obligatoria'
                                            : false,
                                    validate: (value?: any) =>
                                        (value instanceof Date && !isNaN(value.getTime()) && value < new Date()) ||
                                        dealBasicInfo?.tier === 'C2C' ||
                                        'Fecha no válida',
                                }}
                                render={({ field }) => (
                                    <DateInput
                                        label="Antigüedad residente"
                                        error={errors.socialSecurityRegistrationDate}
                                        {...field}
                                        required={dealBasicInfo?.tier !== 'C2C'}
                                    />
                                )}
                            />
                            <Controller
                                name="residenceStartDate"
                                control={control}
                                rules={{
                                    required:
                                        dealBasicInfo?.tier !== 'C2C'
                                            ? 'La fecha de inicio de residencia es obligatoria'
                                            : false,
                                    validate: (value?: any) =>
                                        (value instanceof Date && !isNaN(value.getTime()) && value < new Date()) ||
                                        dealBasicInfo?.tier === 'C2C' ||
                                        'Fecha no válida',
                                }}
                                render={({ field }) => (
                                    <DateInput
                                        label="Fecha alta seguridad social"
                                        error={errors.residenceStartDate}
                                        {...field}
                                        required={dealBasicInfo?.tier !== 'C2C'}
                                    />
                                )}
                            />
                        </>
                    )}

                    <div className="grid col-span-3 grid-cols-6 gap-10">
                        <Button
                            disabled={!isDirty || isSubmitting}
                            className=" col-span-2 col-start-5"
                            type="submit"
                            color="blue"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Accordion>
        </div>
    );
}
