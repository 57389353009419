import { useApiClient } from '@/hooks/useApiClient';
import { useQuery } from '@tanstack/react-query';

const client = useApiClient();

export function useCarfaxQuery(dealId: string) {
    return useQuery({
        queryKey: ['carfax', dealId],
        queryFn: () => client.getDealCarfax(dealId),
        enabled: !!dealId,
        retry: false,
    });
}

export function useVehicleQuery(uuid: string) {
    return useQuery({
        queryKey: ['dealVehicle', uuid],
        queryFn: async () => {
            return await client.getDealVehicle(uuid);
        },
    });
}

export function useDealCatalogueVehicleQuery(uuid: string) {
    return useQuery({
        queryKey: ['dealCatalogueVehicle', uuid],
        queryFn: async () => {
            return await client.getDealCatalogueVehicle(uuid);
        },
    });
}
