export const UNITS: {
    [key: string]: {
        name: string;
        symbol: string;
    };
} = {
    LITERS: {
        name: 'Litros',
        symbol: 'l'
    },
    KM: {
        name: 'Kilómetros',
        symbol: 'km'
    },
    HOURS: {
        name: 'Horas',
        symbol: 'h'
    },
    LITERS_PER_100_KM: {
        name: 'Litros por 100 km',
        symbol: 'l/100km'
    }
}