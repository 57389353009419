import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Combobox } from '@/components/Combobox';
import { DateInput, DateRangeInput, Select, TextInput } from '@/components/Inputs';
import { getAgentRoleForTier } from '@/lib/shared/AgentRoleForTier';
import { getOptions } from '@/lib/utils/getters';
import { Roles, useProfileStore } from '@/stores';
import {
    Option,
    originSources,
    ReservationStatuses,
    situations,
    situationsC2C,
    stages,
    stagesC2C,
    StagesC2CKeys,
    starredFilterOptions,
    Tier,
} from '@/types';
import { transformUserListItems, useUserListMutation, useUserListPageData } from '@/views';
import { Controller, useFormContext } from 'react-hook-form';
import { HiOutlineTrash } from 'react-icons/hi';

type DealsFilterFormProps = {
    readonly handleSubmitFilterForm: () => void;
    readonly selectedDealTier: Tier;
};

export default function DealFilterForm({ handleSubmitFilterForm, selectedDealTier }: DealsFilterFormProps) {
    const { data, isLoading } = useUserListPageData({}, getAgentRoleForTier(selectedDealTier));
    let salesAgents: Option[] = [
        {
            label: '-- Sin agente --',
            value: null,
        },
    ];
    if (!isLoading && data) {
        salesAgents = [...salesAgents, ...data];
    }

    const { mutateAsync: filterUsers } = useUserListMutation();
    const { isAdmin } = useProfileStore();

    const {
        register,
        reset,
        control,
        setValue,
        getValues,
        formState: { errors },
    } = useFormContext();

    const handleClearFilterForm = async () => {
        reset(
            {
                dealIds: '',
                opsDealId: '',
                stage: '',
                situation: '',
                createdAt: null,
                licensePlate: '',
                receivedAmount: '',
                salesAgent: '',
                salesAgentUuid: '',
                clientEmail: '',
                clientPhone: '',
                isStarred: '',
                byOriginSources: '',
                firstWonTimeBetween: undefined,
            },
            { keepDefaultValues: false },
        );

        handleSubmitFilterForm();
    };
    const getSalesAgents = async (inputValue: string) => {
        const data = await filterUsers({
            query: inputValue,
        });

        const users = transformUserListItems(
            data,
            {
                query: inputValue,
            },
            Roles.SALES_AGENT,
        );

        return [
            {
                label: '-- Sin agente --',
                value: null,
            },
            ...users,
        ];
    };

    return (
        <Accordion collapseAll className="bg-gray-100 p-0" title="Filtro de Negocios">
            <div className="grid w-full grid-cols-4 gap-x-4 gap-y-1 p-4">
                {selectedDealTier !== 'C2C' && (
                    <TextInput
                        label="Id de Pipedrive"
                        {...register('opsDealId', {
                            pattern: {
                                value: /^\d+$/i,
                                message: 'Este campo solo acepta valores numéricos',
                            },
                        })}
                        type="text"
                        error={errors.opsDealId}
                    />
                )}
                {selectedDealTier === 'C2C' && isAdmin() && (
                    <TextInput
                        label="Id"
                        {...register('dealIds', {
                            pattern: {
                                value: /^\d+$/i,
                                message: 'Este campo solo acepta valores numéricos',
                            },
                        })}
                        type="text"
                        error={errors.dealIds}
                    />
                )}
                <TextInput label="Matrícula" {...register('licensePlate')} type="text" error={errors.licensePlate} />

                <Controller
                    name="createdAt"
                    control={control}
                    rules={{
                        validate: (value?: any) => {
                            if (value !== null && value !== undefined) {
                                return (value instanceof Date && !isNaN(value.getTime())) || 'Fecha no válida';
                            }
                            return true;
                        },
                    }}
                    render={({ field }) => (
                        <DateInput label="Fecha Creación" {...field} error={errors.createdAt} defaultValue={null} />
                    )}
                />

                <Controller
                    name="situation"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label={selectedDealTier === 'C2C' ? 'Estado' : 'Situación'}
                            id="situation"
                            placeholder="Selecciona una opción"
                            {...field}
                            allowEmpty
                            options={getOptions(selectedDealTier === 'C2C' ? situationsC2C : situations)}
                            error={errors.situation}
                        />
                    )}
                />

                {selectedDealTier !== 'RENT' && (
                    <Controller
                        name="stage"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label={selectedDealTier === 'C2C' ? 'Subestado' : 'Etapa'}
                                placeholder="Selecciona una opción"
                                {...field}
                                allowEmpty
                                options={getOptions(
                                    selectedDealTier === 'C2C'
                                        ? Object.keys(stagesC2C)
                                              .filter((k) => !['DEAL_WON', 'DEAL_LOST'].includes(k))
                                              .reduce<Record<string, string>>((r, c: string) => {
                                                  r[c] = stagesC2C[c as StagesC2CKeys];
                                                  return r;
                                              }, {})
                                        : stages,
                                )}
                                error={errors.stage}
                            />
                        )}
                    />
                )}
                <Controller
                    name="salesAgent"
                    control={control}
                    render={({ field }) => (
                        <Combobox
                            {...field}
                            defaultValue={undefined}
                            options={getSalesAgents}
                            defaultOptions={salesAgents}
                            label="Agente de ventas"
                            onChange={(e) => {
                                field.onChange(e);
                                !e && setValue('salesAgentUuid', undefined);
                            }}
                        />
                    )}
                />
                <TextInput label="Email cliente" {...register('clientEmail')} type="text" error={errors.clientEmail} />
                <TextInput
                    label="Teléfono cliente"
                    {...register('clientPhone')}
                    type="text"
                    error={errors.clientPhone}
                />

                {selectedDealTier === 'C2C' && (
                    <Controller
                        name="reservationStatuses"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Estado reserva"
                                placeholder="Selecciona una opción"
                                {...field}
                                allowEmpty
                                options={getOptions(ReservationStatuses)}
                                error={errors.stage}
                            />
                        )}
                    />
                )}

                <Controller
                    name="isStarred"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Favorito"
                            placeholder="Selecciona una opción"
                            {...field}
                            allowEmpty
                            options={getOptions(starredFilterOptions)}
                            error={errors.stage}
                        />
                    )}
                />

                {selectedDealTier === 'C2C' && (
                    <Controller
                        name="byOriginSources"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Canal"
                                placeholder="Selecciona un canal"
                                options={getOptions(originSources)}
                                {...field}
                            />
                        )}
                    />
                )}

                {selectedDealTier === 'C2C' && (
                    <Controller
                        name="firstWonTimeBetween"
                        control={control}
                        render={({ field }) => (
                            <DateRangeInput
                                label={'Fecha ganado'}
                                from={getValues('firstWonTimeBetween')?.[0]}
                                to={getValues('firstWonTimeBetween')?.[1]}
                                onChange={(date) => {
                                    field.onChange(date);
                                }}
                            />
                        )}
                    />
                )}

                <div className="col-span-4 flex justify-end gap-6">
                    <Button color="gray" variant="ghost" size="xs" type="reset" onClick={handleClearFilterForm}>
                        Limpiar filtros
                        <HiOutlineTrash className="h-4 w-4" />
                    </Button>
                    <Button color="blue" variant="outline" size="xs" type="submit">
                        Aplicar filtros
                    </Button>
                </div>
            </div>
        </Accordion>
    );
}
