import { Measurement } from '@/types';
import { UNITS } from '@/lib/shared/i18n';

export function numberToAmountWithPrecision(amount: number): { amount: number; precision: number } {
    const [_, decimals]: string[] = amount.toString().split('.');
    const precision: number = decimals ? decimals.length : 0;
    const amountInMinimalUnit: number = Math.round(amount * Math.pow(10, precision));
    return { amount: amountInMinimalUnit, precision };
}

function getAmountWithUnit(data: Measurement) {
    let amount = data.amount ?? 0;

    if (data.precision && data.precision > 0) {
        amount /= Math.pow(10, data.precision);
    }

    if (data.currency) {
        return formatNumber(amount, {
            style: 'currency',
            minimumFractionDigits: data.precision,
            currency: data.currency,
        });
    }

    const unit = data.unit ? UNITS[data.unit as string].symbol : '';
    return (
        new Intl.NumberFormat('de-DE', { style: 'decimal', minimumFractionDigits: data.precision }).format(amount) +
        ' ' +
        unit
    );
}

export function getAmount(measurement?: Measurement | null) {
    if (!measurement) {
        return 0;
    }
    return measurement.amount / Math.pow(10, measurement.precision ?? 0);
}

export function parseAmountToString(measurement?: Measurement | null) {
    if (!measurement) {
        return '';
    }
    return getAmountWithUnit(measurement);
}

export function parseStringToAmount(value: string | null): number | null {
    if (!value) {
        return null;
    }

    const amountWithoutCurrency = value.replace('€', '').trim();

    return Number(amountWithoutCurrency.replace('.', '').replace(',', '.'));
}

export function formatNumber(value: number, options?: Intl.NumberFormatOptions) {
    return new Intl.NumberFormat('de-DE', options).format(value);
}

export function formatPercentage(value: number, showPercentageSymbol = true) {
    return showPercentageSymbol ? formatNumber(value, { style: 'unit', unit: 'percent' }) : formatNumber(value);
}
