import { CardSkeleton } from '@/components/Card';
import { MainLayout } from '@/layouts';

export function DashboardViewSkeleton() {
    return (
        <MainLayout className="p-6">
            <h1 className="font-semibold text-2xl">Panel principal</h1>
            <div className="grid grid-cols-3 gap-8 py-4 max-w-7xl">
                {Array.from(Array(9).keys()).map((index) => (
                    <CardSkeleton key={index} />
                ))}
            </div>
        </MainLayout>
    );
}
