export const ISO31661ALPHA2 = [
    { commonName: 'España', isoName: 'España', isoCode: 'ES' },
    { commonName: 'Afganistán', isoName: 'Afganistán', isoCode: 'AF' },
    { commonName: 'Albania', isoName: 'Albania', isoCode: 'AL' },
    { commonName: 'Alemania', isoName: 'Alemania', isoCode: 'DE' },
    { commonName: 'Andorra', isoName: 'Andorra', isoCode: 'AD' },
    { commonName: 'Angola', isoName: 'Angola', isoCode: 'AO' },
    { commonName: 'Anguila', isoName: 'Anguila', isoCode: 'AI' },
    { commonName: 'Antigua y Barbuda', isoName: 'Antigua y Barbuda', isoCode: 'AG' },
    { commonName: 'Antilla Neeerlandesa', isoName: 'Antilla Neeerlandesa', isoCode: 'AN' },
    { commonName: 'Arabia Saudita', isoName: 'Arabia Saudita', isoCode: 'SA' },
    { commonName: 'Argelia', isoName: 'Argelia', isoCode: 'DZ' },
    { commonName: 'Argentina', isoName: 'Argentina', isoCode: 'AR' },
    { commonName: 'Armenia', isoName: 'Armenia', isoCode: 'AM' },
    { commonName: 'Aruba', isoName: 'Aruba', isoCode: 'AW' },
    { commonName: 'Australia', isoName: 'Australia', isoCode: 'AU' },
    { commonName: 'Austria', isoName: 'Austria', isoCode: 'AT' },
    { commonName: 'Azerbaiyán', isoName: 'Azerbaiyán', isoCode: 'AZ' },
    { commonName: 'Bahamas', isoName: 'Bahamas (las)', isoCode: 'BS' },
    { commonName: 'Baréin', isoName: 'Bahrein', isoCode: 'BH' },
    { commonName: 'Bangladés', isoName: 'Bangladesh', isoCode: 'BD' },
    { commonName: 'Barbados', isoName: 'Barbados', isoCode: 'BB' },
    { commonName: 'Bélgica', isoName: 'Bélgica', isoCode: 'BE' },
    { commonName: 'Belice', isoName: 'Belice', isoCode: 'BZ' },
    { commonName: 'Benín', isoName: 'Benin', isoCode: 'BJ' },
    { commonName: 'Bermudas', isoName: 'Bermudas', isoCode: 'BM' },
    { commonName: 'Bután', isoName: 'Bhután', isoCode: 'BT' },
    { commonName: 'Bielorrusia', isoName: 'Belarús', isoCode: 'BY' },
    { commonName: 'Bolivia', isoName: 'Bolivia (Estado Plurinacional de)', isoCode: 'BO' },
    { commonName: 'Bosnia y Herzegovina', isoName: 'Bosnia y Herzegovina', isoCode: 'BA' },
    { commonName: 'Botsuana', isoName: 'Botswana', isoCode: 'BW' },
    { commonName: 'Brasil', isoName: 'Brasil', isoCode: 'BR' },
    { commonName: 'Brunéi', isoName: 'Brunei Darussalam', isoCode: 'BN' },
    { commonName: 'Bulgaria', isoName: 'Bulgaria', isoCode: 'BG' },
    { commonName: 'Burkina Faso', isoName: 'Burkina Faso', isoCode: 'BF' },
    { commonName: 'Burundi', isoName: 'Burundi', isoCode: 'BI' },
    { commonName: 'Cabo Verde', isoName: 'Cabo Verde', isoCode: 'CV' },
    { commonName: 'Camboya', isoName: 'Camboya', isoCode: 'KH' },
    { commonName: 'Camerún', isoName: 'Camerún', isoCode: 'CM' },
    { commonName: 'Canadá', isoName: 'Canadá', isoCode: 'CA' },
    { commonName: 'Checoslovaquia', isoName: 'Checoslovaquia', isoCode: 'CS' },
    { commonName: 'Catar', isoName: 'Qatar', isoCode: 'QA' },
    { commonName: 'Chad', isoName: 'Chad', isoCode: 'TD' },
    { commonName: 'Chile', isoName: 'Chile', isoCode: 'CL' },
    { commonName: 'China', isoName: 'China', isoCode: 'CN' },
    { commonName: 'Chipre', isoName: 'Chipre', isoCode: 'CY' },
    { commonName: 'Ciudad del Vaticano', isoName: 'Ciudad del Vaticano', isoCode: 'VA' },
    { commonName: 'Colombia', isoName: 'Colombia', isoCode: 'CO' },
    { commonName: 'Comores', isoName: 'Comoras (las)', isoCode: 'KM' },
    { commonName: 'Corea del Norte', isoName: 'Corea (la República Popular Democrática de)', isoCode: 'KP' },
    { commonName: 'Corea del Sur', isoName: 'Corea (la República de)', isoCode: 'KR' },
    { commonName: 'Costa de Marfil', isoName: "Côte d'Ivoire", isoCode: 'CI' },
    { commonName: 'Costa Rica', isoName: 'Costa Rica', isoCode: 'CR' },
    { commonName: 'Croacia', isoName: 'Croacia', isoCode: 'HR' },
    { commonName: 'Cuba', isoName: 'Cuba', isoCode: 'CU' },
    { commonName: 'Dinamarca', isoName: 'Dinamarca', isoCode: 'DK' },
    { commonName: 'Dominica', isoName: 'Dominica', isoCode: 'DM' },
    { commonName: 'Ecuador', isoName: 'Ecuador', isoCode: 'EC' },
    { commonName: 'Egipto', isoName: 'Egipto', isoCode: 'EG' },
    { commonName: 'El Salvador', isoName: 'El Salvador', isoCode: 'SV' },
    { commonName: 'Emiratos Árabes Unidos', isoName: 'Emiratos Árabes Unidos (los)', isoCode: 'AE' },
    { commonName: 'Eritrea', isoName: 'Eritrea', isoCode: 'ER' },
    { commonName: 'Eslovaquia', isoName: 'Eslovaquia', isoCode: 'SK' },
    { commonName: 'Eslovenia', isoName: 'Eslovenia', isoCode: 'SI' },
    { commonName: 'Estados Unidos', isoName: 'Estados Unidos de América (los)', isoCode: 'US' },
    { commonName: 'Estonia', isoName: 'Estonia', isoCode: 'EE' },
    { commonName: 'Etiopía', isoName: 'Etiopía', isoCode: 'ET' },
    { commonName: 'Filipinas', isoName: 'Filipinas (las)', isoCode: 'PH' },
    { commonName: 'Finlandia', isoName: 'Finlandia', isoCode: 'FI' },
    { commonName: 'Fiyi', isoName: 'Fiji', isoCode: 'FJ' },
    { commonName: 'Francia', isoName: 'Francia', isoCode: 'FR' },
    { commonName: 'Gabón', isoName: 'Gabón', isoCode: 'GA' },
    { commonName: 'Gambia', isoName: 'Gambia (la)', isoCode: 'GM' },
    { commonName: 'Georgia', isoName: 'Georgia', isoCode: 'GE' },
    { commonName: 'Ghana', isoName: 'Ghana', isoCode: 'GH' },
    { commonName: 'Gibraltar', isoName: 'Gibraltar', isoCode: 'GI' },
    { commonName: 'Granada', isoName: 'Granada', isoCode: 'GD' },
    { commonName: 'Grecia', isoName: 'Grecia', isoCode: 'GR' },
    { commonName: 'Groenlandia', isoName: 'Groenlandia', isoCode: 'GL' },
    { commonName: 'Guadalupe', isoName: 'Guadeloupe', isoCode: 'GP' },
    { commonName: 'Guatemala', isoName: 'Guatemala', isoCode: 'GT' },
    { commonName: 'Guayana Francesa', isoName: 'Guayana Francesa', isoCode: 'GF' },
    { commonName: 'Guernsey', isoName: 'Guernsey', isoCode: 'GG' },
    { commonName: 'Guinea', isoName: 'Guinea', isoCode: 'GN' },
    { commonName: 'Guinea Bisáu', isoName: 'Guinea Bissau', isoCode: 'GW' },
    { commonName: 'Guinea Ecuatorial', isoName: 'Guinea Ecuatorial', isoCode: 'GQ' },
    { commonName: 'Guyana', isoName: 'Guyana', isoCode: 'GY' },
    { commonName: 'Haití', isoName: 'Haití', isoCode: 'HT' },
    { commonName: 'Honduras', isoName: 'Honduras', isoCode: 'HN' },
    { commonName: 'Hong Kong', isoName: 'Hong Kong', isoCode: 'HK' },
    { commonName: 'Hungría', isoName: 'Hungría', isoCode: 'HU' },
    { commonName: 'India', isoName: 'India', isoCode: 'IN' },
    { commonName: 'Indonesia', isoName: 'Indonesia', isoCode: 'ID' },
    { commonName: 'Irak', isoName: 'Iraq', isoCode: 'IQ' },
    { commonName: 'Irán', isoName: 'Irán (República Islámica de)', isoCode: 'IR' },
    { commonName: 'Irlanda', isoName: 'Irlanda', isoCode: 'IE' },
    { commonName: 'Isla de Man', isoName: 'Isla de Man', isoCode: 'IM' },
    { commonName: 'Islandia', isoName: 'Islandia', isoCode: 'IS' },
    { commonName: 'Islas Caimán', isoName: 'Caimán, (las) Islas', isoCode: 'KY' },
    { commonName: 'Islas Cook', isoName: 'Cook, (las) Islas', isoCode: 'CK' },
    { commonName: 'Islas Malvinas', isoName: 'Malvinas [Falkland], (las) Islas', isoCode: 'FK' },
    { commonName: 'Islas Marianas del Norte', isoName: 'Marianas del Norte, (las) Islas', isoCode: 'MP' },
    { commonName: 'Islas Marshall', isoName: 'Marshall, (las) Islas', isoCode: 'MH' },
    { commonName: 'Islas Pitcairn', isoName: 'Pitcairn', isoCode: 'PN' },
    { commonName: 'Islas Salomón', isoName: 'Salomón, Islas', isoCode: 'SB' },
    { commonName: 'Islas Turcas y Caicos', isoName: 'Turcas y Caicos, (las) Islas', isoCode: 'TC' },
    { commonName: 'Islas Vírgenes Británicas ', isoName: 'Vírgenes británicas, Islas', isoCode: 'VG' },
    {
        commonName: 'Islas Vírgenes de los Estados Unidos',
        isoName: 'Vírgenes de los Estados Unidos, Islas',
        isoCode: 'VI',
    },
    { commonName: 'Israel', isoName: 'Israel', isoCode: 'IL' },
    { commonName: 'Italia', isoName: 'Italia', isoCode: 'IT' },
    { commonName: 'Jamaica', isoName: 'Jamaica', isoCode: 'JM' },
    { commonName: 'Japón', isoName: 'Japón', isoCode: 'JP' },
    { commonName: 'Jordania', isoName: 'Jordania', isoCode: 'JO' },
    { commonName: 'Kazajistán', isoName: 'Kazajstán', isoCode: 'KZ' },
    { commonName: 'Kenia', isoName: 'Kenya', isoCode: 'KE' },
    { commonName: 'Kiribati', isoName: 'Kiribati', isoCode: 'KI' },
    { commonName: 'Kuwait', isoName: 'Kuwait', isoCode: 'KW' },
    { commonName: 'Laos', isoName: 'Lao, (la) República Democrática Popular', isoCode: 'LA' },
    { commonName: 'Lesoto', isoName: 'Lesotho', isoCode: 'LS' },
    { commonName: 'Letonia', isoName: 'Letonia', isoCode: 'LV' },
    { commonName: 'Líbano', isoName: 'Líbano', isoCode: 'LB' },
    { commonName: 'Liberia', isoName: 'Liberia', isoCode: 'LR' },
    { commonName: 'Libia', isoName: 'Libia', isoCode: 'LY' },
    { commonName: 'Liechtenstein', isoName: 'Liechtenstein', isoCode: 'LI' },
    { commonName: 'Lituania', isoName: 'Lituania', isoCode: 'LT' },
    { commonName: 'Luxemburgo', isoName: 'Luxemburgo', isoCode: 'LU' },
    { commonName: 'Macao', isoName: 'Macao', isoCode: 'MO' },
    { commonName: 'Madagascar', isoName: 'Madagascar', isoCode: 'MG' },
    { commonName: 'Malasia', isoName: 'Malasia', isoCode: 'MY' },
    { commonName: 'Malaui', isoName: 'Malawi', isoCode: 'MW' },
    { commonName: 'Maldivas', isoName: 'Maldivas', isoCode: 'MV' },
    { commonName: 'Malí', isoName: 'Malí', isoCode: 'ML' },
    { commonName: 'Malta', isoName: 'Malta', isoCode: 'MT' },
    { commonName: 'Marruecos', isoName: 'Marruecos', isoCode: 'MA' },
    { commonName: 'Martinica', isoName: 'Martinique', isoCode: 'MQ' },
    { commonName: 'Mauricio', isoName: 'Mauricio', isoCode: 'MU' },
    { commonName: 'Mauritania', isoName: 'Mauritania', isoCode: 'MR' },
    { commonName: 'Mayotte', isoName: 'Mayotte', isoCode: 'YT' },
    { commonName: 'México', isoName: 'México', isoCode: 'MX' },
    { commonName: 'Micronesia', isoName: 'Micronesia (Estados Federados de)', isoCode: 'FM' },
    { commonName: 'Moldavia', isoName: 'Moldova (la República de)', isoCode: 'MD' },
    { commonName: 'Mónaco', isoName: 'Mónaco', isoCode: 'MC' },
    { commonName: 'Mongolia', isoName: 'Mongolia', isoCode: 'MN' },
    { commonName: 'Montserrat', isoName: 'Montserrat', isoCode: 'MS' },
    { commonName: 'Mozambique', isoName: 'Mozambique', isoCode: 'MZ' },
    { commonName: 'Myanmar', isoName: 'Myanmar', isoCode: 'MM' },
    { commonName: 'Namibia', isoName: 'Namibia', isoCode: 'NA' },
    { commonName: 'Nauru', isoName: 'Nauru', isoCode: 'NR' },
    { commonName: 'Nepal', isoName: 'Nepal', isoCode: 'NP' },
    { commonName: 'Nicaragua', isoName: 'Nicaragua', isoCode: 'NI' },
    { commonName: 'Níger', isoName: 'Níger (el)', isoCode: 'NE' },
    { commonName: 'Nigeria', isoName: 'Nigeria', isoCode: 'NG' },
    { commonName: 'Noruega', isoName: 'Noruega', isoCode: 'NO' },
    { commonName: 'Nueva Caledonia', isoName: 'Nueva Caledonia', isoCode: 'NC' },
    { commonName: 'Nueva Zelandia', isoName: 'Nueva Zelandia', isoCode: 'NZ' },
    { commonName: 'Omán', isoName: 'Omán', isoCode: 'OM' },
    { commonName: 'Países Bajos', isoName: 'Países Bajos (los)', isoCode: 'NL' },
    { commonName: 'Pakistán', isoName: 'Pakistán', isoCode: 'PK' },
    { commonName: 'Palaos', isoName: 'Palau', isoCode: 'PW' },
    { commonName: 'Panamá', isoName: 'Panamá', isoCode: 'PA' },
    { commonName: 'Papúa Nueva Guinea', isoName: 'Papua Nueva Guinea', isoCode: 'PG' },
    { commonName: 'Paraguay', isoName: 'Paraguay', isoCode: 'PY' },
    { commonName: 'Perú', isoName: 'Perú', isoCode: 'PE' },
    { commonName: 'Polinesia Francesa', isoName: 'Polinesia Francesa', isoCode: 'PF' },
    { commonName: 'Polonia', isoName: 'Polonia', isoCode: 'PL' },
    { commonName: 'Portugal', isoName: 'Portugal', isoCode: 'PT' },
    { commonName: 'Reino Unido', isoName: 'Reino Unido de Gran Bretaña e Irlanda del Norte (el)', isoCode: 'GB' },
    { commonName: 'República Centroafricana', isoName: 'República Centroafricana (la)', isoCode: 'CF' },
    { commonName: 'República Checa', isoName: 'Chequia', isoCode: 'CZ' },
    { commonName: 'República del Congo', isoName: 'Congo (el)', isoCode: 'CG' },
    { commonName: 'República Dominicana', isoName: 'Dominicana, (la) República', isoCode: 'DO' },
    { commonName: 'Reunión', isoName: 'Reunión', isoCode: 'RE' },
    { commonName: 'Ruanda', isoName: 'Rwanda', isoCode: 'RW' },
    { commonName: 'Rumania', isoName: 'Rumania', isoCode: 'RO' },
    { commonName: 'Rusia', isoName: 'Rusia, (la) Federación de', isoCode: 'RU' },
    { commonName: 'Samoa', isoName: 'Samoa', isoCode: 'WS' },
    { commonName: 'San Cristóbal y Nieves', isoName: 'Saint Kitts y Nevis', isoCode: 'KN' },
    { commonName: 'San Marino', isoName: 'San Marino', isoCode: 'SM' },
    { commonName: 'San Pedro y Miquelón', isoName: 'San Pedro y Miquelón', isoCode: 'PM' },
    { commonName: 'San Vicente y las Granadinas', isoName: 'San Vicente y las Granadinas', isoCode: 'VC' },
    {
        commonName: 'Santa Helena, Ascensión y Tristán de Acuña',
        isoName: 'Santa Helena, Ascensión y Tristán de Acuña',
        isoCode: 'SH',
    },
    { commonName: 'Santa Lucía', isoName: 'Santa Lucía', isoCode: 'LC' },
    { commonName: 'Santo Tomé y Príncipe', isoName: 'Santo Tomé y Príncipe', isoCode: 'ST' },
    { commonName: 'Senegal', isoName: 'Senegal', isoCode: 'SN' },
    { commonName: 'Seychelles', isoName: 'Seychelles', isoCode: 'SC' },
    { commonName: 'Sierra leona', isoName: 'Sierra leona', isoCode: 'SL' },
    { commonName: 'Singapur', isoName: 'Singapur', isoCode: 'SG' },
    { commonName: 'Siria', isoName: 'República Árabe Siria', isoCode: 'SY' },
    { commonName: 'Somalia', isoName: 'Somalia', isoCode: 'SO' },
    { commonName: 'Sri Lanka', isoName: 'Sri Lanka', isoCode: 'LK' },
    { commonName: 'Suazilandia', isoName: 'Swazilandia', isoCode: 'SZ' },
    { commonName: 'Sudáfrica', isoName: 'Sudáfrica', isoCode: 'ZA' },
    { commonName: 'Sudán', isoName: 'Sudán (el)', isoCode: 'SD' },
    { commonName: 'Suecia', isoName: 'Suecia', isoCode: 'SE' },
    { commonName: 'Suiza', isoName: 'Suiza', isoCode: 'CH' },
    { commonName: 'Surinam', isoName: 'Suriname', isoCode: 'SR' },
    { commonName: 'Svalbard y Jan Mayen', isoName: 'Svalbard y Jan Mayen', isoCode: 'SJ' },
    { commonName: 'Tailandia', isoName: 'Tailandia', isoCode: 'TH' },
    { commonName: 'Taiwán (República de China)', isoName: 'Taiwán (Provincia de China)', isoCode: 'TW' },
    { commonName: 'Tanzania', isoName: 'Tanzania, República Unida de', isoCode: 'TZ' },
    { commonName: 'Tayikistán', isoName: 'Tayikistán', isoCode: 'TJ' },
    {
        commonName: 'Territorio Británico del Océano Índico',
        isoName: 'Territorio Británico del Océano Índico (el)',
        isoCode: 'IO',
    },
    {
        commonName: 'Tierras Australes y Antárticas Francesas',
        isoName: 'Tierras Australes Francesas (las)',
        isoCode: 'TF',
    },
    { commonName: 'Togo', isoName: 'Togo', isoCode: 'TG' },
    { commonName: 'Tonga', isoName: 'Tonga', isoCode: 'TO' },
    { commonName: 'Trinidad y Tobago', isoName: 'Trinidad y Tobago', isoCode: 'TT' },
    { commonName: 'Túnez', isoName: 'Túnez', isoCode: 'TN' },
    { commonName: 'Turkmenistán', isoName: 'Turkmenistán', isoCode: 'TM' },
    { commonName: 'Turquía', isoName: 'Turquía', isoCode: 'TR' },
    { commonName: 'Tuvalu', isoName: 'Tuvalu', isoCode: 'TV' },
    { commonName: 'Ucrania', isoName: 'Ucrania', isoCode: 'UA' },
    { commonName: 'Uganda', isoName: 'Uganda', isoCode: 'UG' },
    { commonName: 'Uruguay', isoName: 'Uruguay', isoCode: 'UY' },
    { commonName: 'Uzbekistán', isoName: 'Uzbekistán', isoCode: 'UZ' },
    { commonName: 'Vanuatu', isoName: 'Vanuatu', isoCode: 'VU' },
    { commonName: 'Venezuela', isoName: 'Venezuela (República Bolivariana de)', isoCode: 'VE' },
    { commonName: 'Vietnam', isoName: 'Viet Nam', isoCode: 'VN' },
    { commonName: 'Wallis y Futuna', isoName: 'Wallis y Futuna', isoCode: 'WF' },
    { commonName: 'Yemen', isoName: 'Yemen', isoCode: 'YE' },
    { commonName: 'Yibuti', isoName: 'Djibouti', isoCode: 'DJ' },
    { commonName: 'Zambia', isoName: 'Zambia', isoCode: 'ZM' },
    { commonName: 'Zimbabue', isoName: 'Zimbabwe', isoCode: 'ZW' },
];

export const findCounrty = (value: string) => {
    return ISO31661ALPHA2.find(
        (counrty) => counrty.isoName === value || counrty.commonName === value || counrty.isoCode === value,
    );
};
