import { Label, TextInput as FTextInput } from 'flowbite-react';
import React, { ComponentProps, ForwardedRef } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { BsAsterisk } from 'react-icons/bs';

interface InputProps extends ComponentProps<'input'> {
    label?: string;
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
    withoutBorder?: boolean;
    className?: string;
    id?: string;
    placeholder?: string;
}

export const TextInput = React.forwardRef((props: InputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { withoutBorder, ...rest } = props;
    const InputTheme = {
        base: 'p-0 w-full',
        field: {
            input: {
                base: 'p-0 w-full flowbiteInput',
            },
        },
    };

    const LabelTheme = {
        root: {
            base: `text-xs font-normal ${props.disabled && 'text-gray-300'}`,
        },
    };

    return (
        <div className="flex flex-col">
            <Label theme={LabelTheme} htmlFor={props.id} color={props.error && 'failure'}>
                <div className="flex gap-1 items-center">
                    {props.label}
                    {props.required && <BsAsterisk className={props.error ? 'text-red-500' : 'text-blue-600'} />}
                </div>
                <FTextInput
                    {...rest}
                    required={false}
                    ref={ref}
                    theme={InputTheme}
                    color={props.error && 'failure'}
                    id={props.id}
                    placeholder={props.placeholder}
                />
            </Label>
            <span className={`mt-1 text-xs ${props.error ? 'text-red-600' : ''}`}>
                {props.error?.message?.toString()}
            </span>
        </div>
    );
});
