import { Table } from 'flowbite-react';

export function TableSkeleton({ cols = 5, rows = 4, className }: { cols: number; rows: number; className?: string }) {
    return (
        <Table className={className}>
            <Table.Head>
                {Array.from(Array(cols).keys()).map((col) => (
                    <Table.HeadCell
                        theme={{
                            base: 'group-first/head:last:rounded-tr-lg bg-gray-50 dark:bg-gray-700 px-6 py-3 transition-colors duration-200 c',
                        }}
                        key={col}
                    >
                        <div className="h-4 animate-pulse rounded bg-gray-300" />
                    </Table.HeadCell>
                ))}
            </Table.Head>
            <Table.Body className="bg-white">
                {Array.from(Array(rows).keys()).map((row) => (
                    <Table.Row key={row}>
                        {Array.from(Array(cols).keys()).map((col) => (
                            <Table.Cell key={col}>
                                <div className="h-4 animate-pulse rounded bg-gray-300" />
                            </Table.Cell>
                        ))}
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
}
