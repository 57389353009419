import { TableSkeleton } from '@/components/Table';
import { DealDetailLayout } from '@/layouts';

export function DealSubmissionListSkeleton() {
    return (
        <DealDetailLayout>
            <div className="w-full">
                <TableSkeleton cols={8} rows={5} />
            </div>
        </DealDetailLayout>
    );
}
