import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { SubmissionCard } from '@/components/Card';
import { parseAmountToString } from '@/lib/utils/amount';
import { isEmpty } from '@/lib/utils/helpers';
import { DealBasicInfo } from '@/types';
import { DealSubmissionListProps, mainStatuses, subStatuses } from '@/types/submission';
import { DetailsButton } from '@/views/shared/DetailsButton';
import { format } from 'date-fns';
import { Table } from 'flowbite-react';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

const HEADERS = ['PVP', 'Banco Final', 'Estado', 'Subestado', 'Creación', 'Detalle'];

export function DealSubmissionList({
    submissions,
    dealBasicInfo,
}: {
    readonly submissions: DealSubmissionListProps;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const router = useRouter();

    if (submissions === undefined)
        return <div className="flex items-center justify-center p-8">No se ha obtenido información de las ofertas</div>;

    return (
        <div className="w-full flex flex-col gap-6">
            {submissions.COLLECTED && (
                <>
                    <span className="text-gray-900 font-semibold">
                        {dealBasicInfo?.situation === 'WON' ? 'Financiación final' : 'Financiación contratada'}
                    </span>
                    <div className="flex flex-col gap-6">
                        <SubmissionCard
                            key={submissions.COLLECTED?.uuid}
                            submission={submissions.COLLECTED}
                            variant="horizontal"
                        />
                    </div>
                </>
            )}

            {!isEmpty(submissions.RETURNED) && (
                <>
                    <span className="text-gray-900 font-semibold">Devoluciones y Cancelaciones</span>
                    <div className="flex flex-col gap-6">
                        {submissions.RETURNED.map((submission) => {
                            return (
                                <SubmissionCard key={submission.uuid} submission={submission} variant="horizontal" />
                            );
                        })}
                    </div>
                </>
            )}

            {!isEmpty(submissions.ONGOING) && (
                <>
                    <span className="text-gray-900 font-semibold">Financiaciones en progreso</span>
                    <div className="flex flex-col gap-6">
                        {submissions.ONGOING.map((submission) => {
                            return (
                                <SubmissionCard key={submission.uuid} submission={submission} variant="horizontal" />
                            );
                        })}
                    </div>
                </>
            )}
            {!isEmpty(submissions.PENDING) && (
                <>
                    <span className="text-gray-900 font-semibold">Financiaciones pendientes</span>
                    <div className="grid grid-cols-3 gap-6">
                        {submissions.PENDING.map((submission) => {
                            return <SubmissionCard key={submission.uuid} submission={submission} variant="vertical" />;
                        })}
                    </div>
                </>
            )}
            {!isEmpty(submissions.REJECTED) && (
                <>
                    <span className="text-gray-900 font-semibold">Financiaciones sin éxito</span>
                    <Accordion
                        title="Financiaciones rechazadas"
                        collapseAll={Boolean(!isEmpty(submissions.PENDING) && submissions.COLLECTED)}
                    >
                        <Table className="bg-white text-left text-sm text-gray-500">
                            <Table.Head className="bg-gray-50 text-xs uppercase text-gray-900">
                                {HEADERS.map((item) => (
                                    <Table.HeadCell
                                        theme={{
                                            base: 'border-b-1 border-b-gray-300 text-gray-400 text-center p-2',
                                        }}
                                        key={item}
                                    >
                                        {item}
                                    </Table.HeadCell>
                                ))}
                            </Table.Head>
                            <Table.Body className="divide-y text-xs text-center">
                                {submissions.REJECTED.map((submission) => (
                                    <Table.Row className="text-xs text-gray-900" key={submission.uuid}>
                                        <TableCell>{parseAmountToString(submission.loanAmount)}</TableCell>
                                        <TableCell>
                                            <div className="flex flex-col justify-center items-center">
                                                <img
                                                    src={'/images/banks/' + submission.financier + '.png'}
                                                    alt={submission.financier}
                                                    className="h-4 w-28 object-contain"
                                                />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <span>{mainStatuses[submission.mainStatus]}</span>
                                        </TableCell>
                                        <TableCell>
                                            {submission.subStatus ? (
                                                <span>{subStatuses[submission.subStatus]}</span>
                                            ) : null}
                                        </TableCell>
                                        <TableCell>
                                            {format(new Date(submission.createdAt), 'dd/MM/yyyy HH:mm')}
                                        </TableCell>
                                        <TableCell>
                                            <div className="flex flex-col justify-center items-center">
                                                <DetailsButton
                                                    onClick={() =>
                                                        router.push(
                                                            `/deals/${router.query.uuid}/submissions/${submission.uuid}`,
                                                        )
                                                    }
                                                />
                                            </div>
                                        </TableCell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    </Accordion>
                </>
            )}
            {isEmpty(submissions.PENDING) && isEmpty(submissions.REJECTED) && !submissions.COLLECTED && (
                <div className="flex flex-1 items-center rounded-lg flex-col gap-10 bg-white px-6 py-10 text-xl">
                    <span className="font-bold">Todavía no se han generado ofertas para este deal...</span>
                    <span className="text-center">
                        Puedes ir a la sección Oferta y generar las primeras haciendo click en el botón de abajo ;)
                    </span>
                    <div>
                        <Button
                            color="blue"
                            className="px-6"
                            onClick={() => router.push(`/deals/${router.query.uuid}/offer`)}
                        >
                            Generar ofertas
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
}

export function TableCell({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) {
    return (
        <Table.Cell
            theme={{
                base: `py-2 px-1 text-gray-900 ${className}`,
            }}
        >
            {children}
        </Table.Cell>
    );
}
