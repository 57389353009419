import { Button } from '@/components/Button';
import { handleDismissModal, useDialogHandler } from './DialogHandler';
import { useUser } from '@auth0/nextjs-auth0/client';
import { Textarea } from '../Inputs/Textarea';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { HiUpload } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import { Attachment, EditDealNoteRequest, Note, NoteDialogFormData } from '@/types';
import { useState } from 'react';

export function NoteDetailsDialog({
    data,
    saveNote,
    onSuccess,
    onError,
}: {
    data?: Note;
    saveNote: (newData: EditDealNoteRequest, deleteAttachments: Attachment[], newAttachments?: File[]) => Promise<void>;
    onSuccess: () => void;
    onError: () => void;
}) {
    const { user } = useUser();
    const { state, setState } = useDialogHandler();
    const {
        register,
        getValues,
        setValue,
        watch,
        formState: { isDirty, isSubmitting },
        handleSubmit,
    } = useForm<NoteDialogFormData>({
        values: {
            content: data ? data.content : '',
            previousAttachments: data ? data.attachments : [],
            newAttachments: [],
        },
        mode: 'onSubmit',
    });

    const previousAttachments: Attachment[] = watch('previousAttachments');
    const newAttachments: File[] = Array.from(watch('newAttachments'));

    const [attachmentsToDelete, setAttachmentsToDelete] = useState<Attachment[]>([]);

    const handleSubmitForm = handleSubmit(async () => {
        try {
            saveNote(
                {
                    content: getValues('content'),
                },
                attachmentsToDelete,
                newAttachments,
            );
            onSuccess();
        } catch (e) {
            onError();
        }
    });

    return (
        <div className="fixed z-[99999] flex h-auto min-h-screen w-screen items-center justify-center bg-gray-900/50 text-black">
            <div className="absolute flex flex-col gap-4 rounded-lg bg-white opacity-100 py-6 px-12 max-w-[678px]">
                <span className="flex-1 text-lg font-semibold">{data ? data.title : `${user?.name} — Nota`}</span>

                <form className="min-w-[585px] flex flex-col gap-6" onSubmit={handleSubmitForm}>
                    <Textarea
                        label="Descripción de la nota"
                        className="resize-none p-4"
                        rows={5}
                        {...register('content', { required: true })}
                    />
                    <div className="flex flex-col gap-4">
                        <span className="text-sm text-gray-500 font-medium">Añadir documentos</span>
                        <div className="flex gap-6 flex-wrap">
                            {previousAttachments.map((attachment, index) => (
                                <span
                                    className="flex items-center gap-4 rounded-full bg-gray-100 text-xs py-1 px-2"
                                    key={attachment.uuid}
                                >
                                    {attachment.fileName}
                                    <AiOutlineCloseCircle
                                        className="w-4 h-4 cursor-pointer"
                                        onClick={() => {
                                            const updatedAttachments = [...previousAttachments];
                                            updatedAttachments.splice(index, 1);
                                            setAttachmentsToDelete([...attachmentsToDelete, attachment]);
                                            setValue('previousAttachments', updatedAttachments, { shouldDirty: true });
                                        }}
                                    />
                                </span>
                            ))}

                            {newAttachments.map((file: File, index: number) => (
                                <span
                                    className="flex items-center gap-4 rounded-full bg-gray-100 text-xs py-1 px-2"
                                    key={`${file.name}.${file.type}`}
                                >
                                    {file.name}
                                    <AiOutlineCloseCircle
                                        className="w-4 h-4 cursor-pointer"
                                        onClick={() => {
                                            const updatedAttachments = [...newAttachments];
                                            updatedAttachments.splice(index, 1);
                                            setValue('newAttachments', updatedAttachments);
                                        }}
                                    />
                                </span>
                            ))}
                        </div>
                        <input hidden {...register('previousAttachments')} />
                        <label className="cursor-pointer border flex items-center gap-3 justify-center bg-white hover:bg-gray-200 focus:bg-gray-300 focus:!outline-none px-3 py-2 text-sm rounded-lg border-black font-medium w-fit">
                            <HiUpload className="w-4 h-4" />
                            Subir documento
                            <input type="file" {...register('newAttachments')} multiple hidden />
                        </label>
                    </div>
                    <div className="flex justify-end gap-4">
                        <Button
                            size="small"
                            color="blue"
                            variant="outline"
                            onClick={() => handleDismissModal(state, setState)}
                        >
                            Cancelar
                        </Button>
                        <Button
                            size="small"
                            color="blue"
                            className="px-6"
                            type="submit"
                            disabled={!isDirty || isSubmitting}
                        >
                            Guardar nota
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
