import { isEmpty } from '@/lib/utils/helpers';
import { Roles, useProfileStore } from '@/stores/useProfileStore';
import { AppraisalView, DealBasicInfo, lostReasons, PreOfferView } from '@/types';
import { CalculatorFormData, OfferListView } from '@/types/offer';
import { PersonalDetail } from '@/types/Deal';
import {
    AppraisalsSection,
    BankRejectionReasonsSection,
    ClientInfoSection,
    DealCalculatorSection,
    GeneralRejectionReasonsSection,
    OfferSection,
} from '@/views/deals/details/offer/sections';
import { LostReasonCard } from '@/views/deals/details/offer/cards';
import { GetCatalogueVehicleDetails } from '@/types/Vehicle';

export function DealOfferCalculatorView({
    dealBasicInfo,
    personalDetail,
    vehicleDetails,
    preOffer,
    offers,
    calculatorData,
    appraisals,
}: {
    readonly dealBasicInfo: DealBasicInfo | undefined;
    readonly personalDetail: PersonalDetail;
    readonly vehicleDetails: GetCatalogueVehicleDetails | undefined;
    readonly preOffer: PreOfferView;
    readonly offers: OfferListView;
    readonly calculatorData: CalculatorFormData;
    readonly appraisals: AppraisalView;
}) {
    const lostReason = dealBasicInfo?.lostReason as keyof typeof lostReasons;
    const { isAdmin, hasRequiredRoles } = useProfileStore();

    const isThereNoEligibleFinancierReasons =
        dealBasicInfo?.noEligibleFinancierReasons &&
        Object.entries(dealBasicInfo?.noEligibleFinancierReasons).length > 0;
    const isThereEligibleFinancierRejections =
        dealBasicInfo?.eligibleFinancierRejections && dealBasicInfo?.eligibleFinancierRejections.length > 0;

    return (
        <div className="w-full">
            {(!(hasRequiredRoles([Roles.SALES_AGENT], false) && !isAdmin()) || dealBasicInfo?.tier !== 'C2C') && (
                <section
                    className={`space-y-6 group ${
                        isThereNoEligibleFinancierReasons ||
                        isThereEligibleFinancierRejections ||
                        lostReason ||
                        (isAdmin() && dealBasicInfo?.tier !== 'C2C') ||
                        preOffer
                            ? 'pb-5'
                            : ''
                    }`}
                >
                    {dealBasicInfo?.eligibleFinancierRejections &&
                    dealBasicInfo?.eligibleFinancierRejections.length > 0 ? (
                        <GeneralRejectionReasonsSection reasons={dealBasicInfo?.eligibleFinancierRejections} />
                    ) : null}
                    {isThereNoEligibleFinancierReasons && !isEmpty(dealBasicInfo?.noEligibleFinancierReasons) && (
                        <BankRejectionReasonsSection reasons={dealBasicInfo?.noEligibleFinancierReasons} />
                    )}
                    {lostReason && <LostReasonCard reason={lostReasons[lostReason] || lostReason} />}
                    {dealBasicInfo?.tier !== 'C2C' && <ClientInfoSection personalDetail={personalDetail} />}
                    <OfferSection preOffer={preOffer} />
                    {isAdmin() && dealBasicInfo?.tier !== 'C2C' && <AppraisalsSection appraisals={appraisals} />}
                </section>
            )}
            <DealCalculatorSection
                data={calculatorData}
                offers={offers}
                dealBasicInfo={dealBasicInfo}
                vehicleDetails={vehicleDetails}
            />
        </div>
    );
}
