import { Button } from '@/components/Button';
import { handleDismissModal, useDialog, useDialogHandler } from '@/components/Dialog/DialogHandler';
import { DateInput, Select, TextInput } from '@/components/Inputs';
import { useCopyToClipboard, useToast, useUuid } from '@/hooks';
import {
    useDealReservationQuery,
    usePostDealReservationMutation,
    usePutDealReservationMutation,
} from '@/layouts/DealDetailLayout/useDealReservationQuery';
import { getOptions } from '@/lib/utils/getters';
import {
    Currency,
    DealReservationFormData,
    DealReservationResponse,
    ReservationMethods,
    ReservationStatuses,
} from '@/types';
import { differenceInMilliseconds, differenceInMonths, format, isFuture } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Tooltip } from 'flowbite-react';
import { Controller, useForm } from 'react-hook-form';
import { BiEdit } from 'react-icons/bi';
import { HiLink, HiPlus } from 'react-icons/hi';
import { HiXMark } from 'react-icons/hi2';
import { TbLockDollar } from 'react-icons/tb';

enum ReservationAction {
    CREATE = 'Crear',
    EDIT = 'Editar',
}

type ReservationDialogProps = {
    readonly reservationDetails: DealReservationResponse | undefined;
    readonly action: ReservationAction;
};

function ReservationDialog({ reservationDetails, action }: ReservationDialogProps) {
    const uuid = useUuid();
    const { successToast, errorToast } = useToast();
    const { state, setState } = useDialogHandler();
    const { mutateAsync: postDealReservation } = usePostDealReservationMutation();
    const { mutateAsync: putDealReservation } = usePutDealReservationMutation();

    const {
        register,
        control,
        watch,
        formState: { isDirty, isSubmitting, errors },
        handleSubmit,
    } = useForm<DealReservationFormData>({
        defaultValues: {
            amount: reservationDetails?.amount?.amount || 290,
            reservationDate: reservationDetails?.reservationDate
                ? utcToZonedTime(reservationDetails.reservationDate, 'UTC')
                : undefined,
            currency: Currency.enum.EUR,
            status: reservationDetails?.status as ReservationStatuses,
            method: reservationDetails?.method as ReservationMethods,
            returnedDate: reservationDetails?.returnedDate
                ? utcToZonedTime(reservationDetails.returnedDate, 'UTC')
                : undefined,
            discardedNoRefundDate: reservationDetails?.discardedNoRefundDate
                ? utcToZonedTime(reservationDetails.discardedNoRefundDate, 'UTC')
                : undefined,
        },
        mode: 'onSubmit',
    });

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            if (action === ReservationAction.CREATE) {
                await postDealReservation({
                    dealId: uuid,
                    data: {
                        ...data,
                        reservationDate: zonedTimeToUtc(data.reservationDate, 'UTC').toISOString(),
                        returnedDate: data.returnedDate ? zonedTimeToUtc(data.returnedDate, 'UTC').toISOString() : null,
                        status: 'RECEIVED',
                    },
                });

                successToast('Reserva creada correctamente');
            }
            if (action === ReservationAction.EDIT) {
                await putDealReservation({
                    dealId: uuid,
                    reservationUuid: reservationDetails?.uuid || '',
                    data: {
                        ...data,
                        reservationDate: zonedTimeToUtc(data.reservationDate, 'UTC').toISOString(),
                        returnedDate: data.returnedDate ? zonedTimeToUtc(data.returnedDate, 'UTC').toISOString() : null,
                    },
                });

                successToast('Reserva editada correctamente');
            }
        } catch (e: any) {
            errorToast(e.message);
        }
    });

    const getDialogTitle = () => {
        if (action === ReservationAction.CREATE) {
            return 'Crear nueva reserva';
        }
        if (action === ReservationAction.EDIT) {
            return 'Editar detalles reserva';
        }
    };

    return (
        <div className="fixed z-[999] flex h-auto min-h-screen w-screen items-center justify-center bg-gray-900/50 text-black">
            <div className="absolute flex flex-col gap-8 rounded-lg bg-white opacity-100 py-6 px-12 max-w-[678px]">
                <div className="flex items-center">
                    <span className="flex-1 text-lg font-semibold">{getDialogTitle()}</span>
                    <HiXMark
                        className="h-7 w-7 p-1 cursor-pointer hover:bg-gray-200 rounded-lg"
                        onClick={() => handleDismissModal(state, setState)}
                    />
                </div>
                <form className="min-w-[585px] flex flex-col gap-6" onSubmit={handleSubmitForm}>
                    <div className="grid grid-cols-2 gap-4">
                        <Controller
                            name="reservationDate"
                            control={control}
                            rules={{
                                validate: (value?: any) => {
                                    if (!(value instanceof Date && !isNaN(value.getTime()))) return 'Fecha no válida';
                                    if (isFuture(value)) return 'La fecha de reserva no puede ser futura';
                                    if (differenceInMonths(new Date(), value) > 3)
                                        return 'La fecha de reserva no puede tener más de 3 meses de antigüedad';
                                },
                                required: 'Campo requerido',
                            }}
                            render={({ field }) => (
                                <DateInput
                                    label="Fecha de reserva"
                                    {...field}
                                    error={errors.reservationDate}
                                    disableFuture
                                    required
                                />
                            )}
                        />
                        <TextInput
                            label="Importe"
                            {...register('amount', { required: 'Campo requerido', valueAsNumber: true })}
                            required
                            step=".01"
                            type="number"
                            error={errors.amount}
                        />
                        <Select
                            label="Método de pago"
                            placeholder="Selecciona una opción"
                            options={getOptions(ReservationMethods)}
                            {...register('method')}
                            error={errors.method}
                        />
                        {action === ReservationAction.EDIT && (
                            <Select
                                label="Estado"
                                placeholder="Selecciona una opción"
                                options={getOptions(ReservationStatuses)}
                                {...register('status')}
                                error={errors.status}
                            />
                        )}
                        {action === ReservationAction.EDIT && watch('status') === 'RETURNED' && (
                            <Controller
                                name="returnedDate"
                                control={control}
                                rules={{
                                    validate: (value?: any) => {
                                        if (!(value instanceof Date && !isNaN(value.getTime())))
                                            return 'Fecha no válida';
                                        if (differenceInMilliseconds(value, watch('reservationDate')) < 0)
                                            return 'La fecha de devolución no puede ser anterior a la fecha de reserva';
                                        if (value > new Date())
                                            return 'La fecha no puede ser posterior a la fecha actual';
                                    },
                                }}
                                render={({ field }) => (
                                    <DateInput
                                        label="Fecha de devolución"
                                        {...field}
                                        error={errors.returnedDate}
                                        disableFuture
                                        required
                                    />
                                )}
                            />
                        )}
                        {action === ReservationAction.EDIT && watch('status') === 'DISCARDED_NO_REFUND' && (
                            <Controller
                                name="discardedNoRefundDate"
                                control={control}
                                rules={{
                                    validate: (value?: any) => {
                                        if (!(value instanceof Date && !isNaN(value.getTime())))
                                            return 'Fecha no válida';
                                        if (differenceInMilliseconds(value, watch('reservationDate')) < 0)
                                            return 'La fecha de cancelada sin devolución no puede ser anterior a la fecha de reserva';
                                        if (value > new Date())
                                            return 'La fecha no puede ser posterior a la fecha actual';
                                    },
                                }}
                                render={({ field }) => (
                                    <DateInput
                                        label="Fecha cancelada sin devolución"
                                        {...field}
                                        error={errors.discardedNoRefundDate}
                                        disableFuture
                                        required
                                    />
                                )}
                            />
                        )}
                    </div>
                    <div className="ms-auto">
                        <Button
                            size="small"
                            color="blue"
                            className="px-6"
                            type="submit"
                            disabled={!isDirty || isSubmitting}
                        >
                            {action} reserva
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export function ReservationModule({
    reservationAssociatedWithLicensePlate,
}: {
    readonly reservationAssociatedWithLicensePlate: DealReservationResponse | undefined;
}) {
    const uuid = useUuid();
    const dialog = useDialog();
    const { successToast } = useToast();
    const [_, setCopy] = useCopyToClipboard();
    const { data: reservationDetails } = useDealReservationQuery(uuid);

    const handleOpenReservationDialog = async (action: ReservationAction) => {
        dialog({
            type: 'modal',
            content: <ReservationDialog reservationDetails={reservationDetails} action={action} />,
        });
    };

    return (
        <div className="bg-white rounded-md p-3">
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <TbLockDollar className="w-7 h-7 p-1 bg-green-300 rounded-md text-gray-900" />
                    <span className="flex items-center gap-1 text-lg font-semibold">
                        Reserva
                        <Tooltip style="light" content="Link reserva stripe">
                            <HiLink
                                className="p-1 w-6 h-6 rounded-md cursor-pointer hover:bg-gray-200"
                                onClick={() => {
                                    setCopy(`https://buy.stripe.com/6oE3fAblv3OC9ywfZ4?client_reference_id=${uuid}`);
                                    successToast('Link de la reserva de Stripe copiado al portapapeles');
                                }}
                            />
                        </Tooltip>
                    </span>
                </div>
                {!reservationDetails ? (
                    <Tooltip
                        className={`w-32 ${!reservationAssociatedWithLicensePlate && 'hidden'}`}
                        content={'El vehículo asociado a este deal ya cuenta con una reserva'}
                    >
                        <Button
                            color="gray"
                            variant="outline"
                            size="xxs"
                            onClick={() => handleOpenReservationDialog(ReservationAction.CREATE)}
                            disabled={!!reservationAssociatedWithLicensePlate}
                        >
                            <HiPlus className="h-5 w-5" />
                        </Button>
                    </Tooltip>
                ) : (
                    <Button
                        color="gray"
                        variant="outline"
                        size="xxs"
                        onClick={() => handleOpenReservationDialog(ReservationAction.EDIT)}
                    >
                        <BiEdit className="h-6 w-6" />
                    </Button>
                )}
            </div>
            {reservationDetails && (
                <div className="flex flex-col gap-1 mt-2 text-sm">
                    <div className="space-x-2">
                        <span className="">Estado:</span>
                        <span className="font-semibold">{ReservationStatuses[reservationDetails?.status]}</span>
                    </div>
                    <div className="space-x-2">
                        <span className="">Reserva:</span>
                        <span className="font-semibold">
                            {format(new Date(reservationDetails?.reservationDate), 'dd/MM/yyyy')}
                        </span>
                    </div>
                    <div className="space-x-2">
                        <span className="">Método:</span>
                        <span className="font-semibold">{ReservationMethods[reservationDetails?.method]}</span>
                    </div>
                    <div className="space-x-2">
                        <label className="">Importe:</label>
                        <span className="font-semibold">{reservationDetails?.amount?.amount} €</span>
                    </div>
                    {reservationDetails?.returnedDate && (
                        <div className="space-x-2">
                            <span className="">Devolución:</span>
                            <span className="font-semibold">
                                {format(new Date(reservationDetails?.returnedDate), 'dd/MM/yyyy')}
                            </span>
                        </div>
                    )}
                    {reservationDetails?.discardedNoRefundDate && (
                        <div className="space-x-2">
                            <span className="">Cancelada sin devolución:</span>
                            <span className="font-semibold">
                                {format(new Date(reservationDetails?.discardedNoRefundDate), 'dd/MM/yyyy')}
                            </span>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
