import { AccordionSkeleton } from '@/components/Accordion';

export function DealAlertsViewSkeleton() {
    return (
        <div className="flex flex-col w-full gap-6">
            <div className="min-w-[500px]">
                <AccordionSkeleton />
            </div>
            <div className="w-full">
                <AccordionSkeleton />
            </div>
        </div>
    );
}
