import { AccordionSkeleton } from '@/components/Accordion';
import { DealDetailLayout } from '@/layouts';

export function DealCarfaxViewSkeleton() {
    return (
        <DealDetailLayout>
            <div className="flex w-full gap-2">
                <div className="min-w-[500px]">
                    <AccordionSkeleton />
                </div>
                <div className="w-full">
                    <AccordionSkeleton />
                </div>
            </div>
        </DealDetailLayout>
    );
}
