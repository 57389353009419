import { Accordion as FAccordion } from 'flowbite-react';
import { PropsWithChildren } from 'react';
type AccordionProps = {
    title: string;
    className?: string;
    collapseAll?: boolean;
};

export function Accordion({ children, title, className, collapseAll }: PropsWithChildren<AccordionProps>) {
    return (
        <FAccordion
            className={`border border-gray-200  bg-gray-100 focus:ring-0 ${className}`}
            theme={{ root: { base: 'ring-0' } }}
            collapseAll={collapseAll}
        >
            <FAccordion.Panel>
                <FAccordion.Title className="rounded-t-lg font-normal text-gray-900 px-4 py-2">
                    {title}
                </FAccordion.Title>
                <FAccordion.Content theme={{ base: 'p-0' }} className="bg-white">
                    {children}
                </FAccordion.Content>
            </FAccordion.Panel>
        </FAccordion>
    );
}
