import { z } from 'zod';

export const User = z.object({
    uuid: z.string(),
    externalId: z.string(),
    email: z.string(),
    givenName: z.string(),
    familyName: z.string(),
    roles: z.array(z.string()),
    createdAt: z.string(),
    updatedAt: z.string(),
});

export const GetUserResponse = z.object({
    max: z.number(),
    offset: z.number(),
    totalItems: z.number(),
    items: z.array(User),
});

export const GetUserFilters = z.object({
    max: z.number().optional(),
    offset: z.number().optional(),
    query: z.string().optional(),
});

export const UserList = z.array(
    z.object({
        label: z.string(),
        value: z.string(),
    }),
);

export const PatchUserRequest = z.object({
    roles: z.array(z.string()),
});

export type GetUserResponse = z.infer<typeof GetUserResponse>;
export type GetUserFilters = z.infer<typeof GetUserFilters>;
export type User = z.infer<typeof User>;
export type UserList = z.infer<typeof UserList>;
export type PatchUserRequest = z.infer<typeof PatchUserRequest>;
