import { z } from 'zod';
import { Measurement } from '../generics';

export const Gender = z.ZodEnum.create(['MALE', 'FEMALE']);
export const MaritalStatus = z.ZodEnum.create(['SINGLE', 'MARRIED', 'DIVORCED', 'WIDOWER']);
export const ProfessionalStatus = z.ZodEnum.create(['NOT_WORK', 'EMPLOYEE', 'UNEMPLOYED', 'PENSIONER', 'FREELANCE']);
export const JobPosition = z.ZodEnum.create([
    'EXECUTIVE',
    'QUALIFIED_TECHNICIAN',
    'ADMINISTRATIVE',
    'MANUAL_WORKER',
    'REPRESENTATIVE',
    'SPECIALIST_WORKER',
    'LIBERAL_PROFESSION',
    'PEDDLER',
    'OTHER',
]);
export const JobType = z.ZodEnum.create([
    'INDUSTRY',
    'COMMERCE',
    'SERVICES',
    'HOSPITALITY',
    'CONSTRUCTION',
    'TRANSPORTATION',
    'AGRICULTURE_FARMING_FISHING',
    'PUBLIC_ADMINISTRATION',
    'OTHER_ACTIVITIES',
]);

export const LoanPurpose = z.ZodEnum.create([
    'HOME_RENOVATION',
    'NEW_VEHICLE',
    'USED_VEHICLE',
    'HOME_FURNISHING_APPLIANCES',
    'TRAINING',
    'HEALTHCARE',
    'TRAVEL_OR_LEISURE',
    'EVENT_OR_CELEBRATION',
    'NEW_BUSINESS',
    'CASH_FLOW_NEEDS',
    'UNIFY_CREDITS',
    'OTHER',
]);
export const CompanyBillingType = z.ZodEnum.create(['ANNUAL_TURNOVER', 'NET_ANNUAL_YIELD']);
export const DocumentType = z.ZodEnum.create(['DNI', 'NIE', 'CIF']);
export const houseType = {
    RENT: 'Alquiler',
    PARENTS_ADDRESS: 'Domicilio padres',
    DEED_PAID: 'Escritura pagada totalmente',
    DEED_PENDING_PAYMENT: 'Escritura pdte de pago',
    OTHER: 'Otros',
};

export const combustibleType: Record<string, string> = {
    GASOLINE: 'Gasolina',
    DIESEL: 'Diésel',
    ELECTRIC: 'Eléctrico',
    HYBRID_DIESEL: 'Híbrido (Diésel)',
    HYBRID_GASOLINE: 'Híbrido (Gasolina)',
    PLUG_IN_HYBRID: 'Híbrido enchufable',
    GASOLINE_LPG: 'Gasolina (Gasolina - GLP)',
    GASOLINE_CNG: 'Gasolina (Gasolina - GNC)',
};

const houseTypeEnum: readonly string[] = Object.keys(houseType) as (keyof typeof houseType)[];

export const HouseType = z.enum([houseTypeEnum[0], ...houseTypeEnum]);

export const combustibleTypeEnum: readonly string[] = Object.keys(combustibleType) as (keyof typeof combustibleType)[];
export const CombustibleType = z.enum([combustibleTypeEnum[0], ...combustibleTypeEnum]);

export const LegalNatures = z.ZodEnum.create(['NATURAL_PERSON', 'LEGAL_PERSON']);

export const GearboxType = z.ZodEnum.create(['MANUAL', 'AUTOMATIC', 'DELETE']);

export type Gender = z.infer<typeof Gender>;
export type MaritalStatus = z.infer<typeof MaritalStatus>;
export type ProfessionalStatus = z.infer<typeof ProfessionalStatus>;
export type JobPosition = z.infer<typeof JobPosition>;
export type JobType = z.infer<typeof JobType>;
export type CompanyBillingType = z.infer<typeof CompanyBillingType>;
export type DocumentType = z.infer<typeof DocumentType>;
export type LoanPurpose = z.infer<typeof LoanPurpose>;
export type HouseType = z.infer<typeof HouseType>;
export type GearboxType = z.infer<typeof GearboxType>;

export const IdentificationNumber = z.object({
    number: z.string().optional(),
    type: DocumentType.nullable(),
});

export const Address = z.object({
    houseType: HouseType.nullable().optional(),
    route: z.string().nullable().optional(),
    streetNumber: z.string().nullable().optional(),
    postalCode: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    province: z.string().nullable().optional(),
    domicile: z.string().nullable().optional(),
    complete: z.string().nullable().optional(),
});

export const Addresses = z.object({
    billingAddress: Address.nullable(),
});

export const BankDetail = z.object({
    iban: z.string().nullable().optional(),
    swift: z.string().nullable().optional(),
    isAccountAtLeast6MonthsOlder: z.string().or(z.boolean()).nullable().optional(),
    isBalanceAtLeast200: z.string().or(z.boolean()).nullable().optional(),
});
export const CoownerDetail = z.object({
    exists: z.boolean(),
    name: z.string().nullable(),
    lastName: z.string().nullable().optional(),
    secondLastName: z.string().nullable().optional(),
    identificationNumber: IdentificationNumber.nullable(),
    email: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
});

export const Employment = z.object({
    jobPosition: JobPosition.optional().nullable(),
    monthlyIncome: z.number().optional(),
    annualIncome: z.number().optional(),
    companyName: z.string().optional().nullable(),
    professionalStatus: ProfessionalStatus.optional(),
    seniorityDate: z.string().datetime().nullable().optional(),
    incomeSource: z.string().optional(),
    permanentContract: z.string().optional(),
    jobType: JobType.optional().nullable(),
    companyBillingType: CompanyBillingType.optional().nullable(),
});

export const ContractInfo = z.object({
    warranty: z.boolean().nullable().optional(),
    maintenance: z.boolean().nullable().optional(),
    certifyingAgent: z.boolean().nullable().optional(),
});

export const Vehicle = z.object({
    uuid: z.string().optional(),
    licensePlate: z.string().optional(),
    brand: z.string().nullable().optional(),
    vin: z.string().nullable().optional(),
    model: z.string().nullable().optional(),
    version: z.string().nullable().optional(),
    yearRegistration: z.number().nullable().optional(),
    gearboxType: GearboxType.nullable().optional(),
    combustibleType: CombustibleType.nullable().optional(),
    lastOdometerReadingValue: z.number().nullable().optional(),
    firstRegistrationDate: z.string().datetime().nullable().optional(),
    transferable: z.string().nullable().optional(),
    color: z.string().nullable().optional(),
    titleReservation: z.boolean().nullable().optional(),
    hasDebt: z.boolean().nullable().optional(),
});

export const ForeignDetail = z.object({
    residenceStartDate: z.string().datetime().nullable().optional(),
    residenceCardExpirationDate: z.string().datetime().nullable().optional(),
    socialSecurityRegistrationDate: z.string().datetime().nullable().optional(),
    numberOfJobs: z.number().nullable().optional(),
});
export const GetMktDetailsResponse = z.object({
    utmSource: z.string().optional(),
    utmMedium: z.string().optional(),
    utmCampaign: z.string().optional(),
    productSource: z.string().nullable(),
    originSource: z.string().nullable(),
});

export const MktDetail = GetMktDetailsResponse;

export const PersonalDetail = z.object({
    email: z.string().nullable().optional(),
    phone: z.string().nullable().optional(),
    secondPhone: z.string().nullable().optional(),
    identificationNumber: IdentificationNumber.nullable().optional(),
    gender: Gender.nullable().optional(),
    name: z.string().nullable().optional(),
    lastName: z.string().nullable().optional(),
    secondLastName: z.string().nullable().optional(),
    dateOfBirth: z.string().datetime().nullable().optional(),
    maritalStatus: MaritalStatus.nullable().optional(),
    nationality: z.string().nullable().optional(),
    numberOfChildren: z.number().nullable().optional(),
    rentOrMortgageAmount: z.number().nullable().optional(),
    countryOfBirth: z.string().nullable().optional(),
    loanPurpose: z.string().nullable().optional(),
    legalNature: LegalNatures.nullable(),
    extId: z.string().nullable(),
});
export const GetDealPersonalDetailsResponse = PersonalDetail;
export const PutDealPersonalDetailsRequest = PersonalDetail.extend({
    dateOfBirth: z.date().nullable().optional(),
    identificationNumber: z
        .object({
            number: z.string().optional(),
        })
        .nullable()
        .optional(),
});
export const GetDealEmploymentResponse = Employment;
export const PutDealEmploymentRequest = Employment.extend({
    seniorityDate: z.date().nullable().optional(),
});
export const GetDealBankDetailResponse = BankDetail.extend({
    isAccountAtLeast6MonthsOlder: z.boolean().nullable().optional(),
    isBalanceAtLeast200: z.boolean().nullable().optional(),
});
export const PutDealBankDetailRequest = BankDetail;

export const GetDealCoownerDetailResponse = CoownerDetail;
export const PutDealCoownerDetailRequest = CoownerDetail;

export const GetDealAddressesResponse = Addresses;
export const PutDealAddressesRequest = Addresses;

export const GetDealForeignDetailResponse = ForeignDetail;
export const PutDealForeignDetailResponse = ForeignDetail;

export const GetDealVehicleReponse = Vehicle;

export const PutDealVehicleRequest = z.object({
    transferable: z.string().nullable().optional(),
    color: z.string().nullable().optional(),
    brand: z.string().nullable().optional(),
    model: z.string().nullable().optional(),
    version: z.string().nullable().optional(),
    yearRegistration: z.number().nullable().optional(),
    gearboxType: GearboxType.nullable().optional(),
    combustibleType: CombustibleType.nullable().optional(),
    lastOdometerReadingValue: z.number().nullable().optional(),
    firstRegistrationDate: z.string().datetime().nullable().optional(),
    vin: z.string().nullable().optional(),
    titleReservation: z.boolean().nullable().optional(),
    hasDebt: z.boolean().nullable().optional(),
});

export const PutDealVehicleResponse = Vehicle;

export const DealInfoDataView = z.object({
    personalDetail: PersonalDetail,
    employment: Employment,
    addresses: Addresses,
    vehicle: GetDealVehicleReponse,
    foreignDetail: ForeignDetail,
    mktDetail: MktDetail,
    bankDetail: BankDetail,
    coownerDetail: CoownerDetail,
});

export const GetDealCatalogueVehicle = z.object({
    adPrice: Measurement.nullable().optional(),
    purchasePrice: Measurement.nullable().optional(),
    preparationExpenses: Measurement.nullable().optional(),
    premiumWarranty: Measurement.nullable().optional(),
    deliveryExpenses: Measurement.nullable().optional(),
    brandWarrantyExpiredAt: z.string().datetime().nullable(),
});

export type PersonalDetail = z.infer<typeof PersonalDetail>;
export type Address = z.infer<typeof Address>;
export type Addresses = z.infer<typeof Addresses>;
export type Employment = z.infer<typeof Employment>;
export type Vehicle = z.infer<typeof Vehicle>;
export type ForeignDetail = z.infer<typeof ForeignDetail>;
export type MktDetail = z.infer<typeof MktDetail>;
export type BankDetail = z.infer<typeof BankDetail>;
export type CoownerDetail = z.infer<typeof CoownerDetail>;
export type ContractInfo = z.infer<typeof ContractInfo>;
export type GetMktDetailsResponse = z.infer<typeof GetMktDetailsResponse>;
export type GetDealPersonalDetailsResponse = z.infer<typeof GetDealPersonalDetailsResponse>;
export type PutDealPersonalDetailsRequest = z.infer<typeof PutDealPersonalDetailsRequest>;
export type GetDealVehicleReponse = z.infer<typeof GetDealVehicleReponse>;
export type PutDealVehicleRequest = z.infer<typeof PutDealVehicleRequest>;
export type PutDealVehicleResponse = z.infer<typeof PutDealVehicleResponse>;
export type GetDealEmploymentResponse = z.infer<typeof GetDealEmploymentResponse>;
export type PutDealEmploymentRequest = z.infer<typeof PutDealEmploymentRequest>;
export type GetDealBankDetailResponse = z.infer<typeof GetDealBankDetailResponse>;
export type PutDealBankDetailRequest = z.infer<typeof PutDealBankDetailRequest>;
export type GetDealCoownerDetailResponse = z.infer<typeof GetDealCoownerDetailResponse>;
export type PutDealCoownerDetailRequest = z.infer<typeof PutDealCoownerDetailRequest>;
export type GetDealAddressesResponse = z.infer<typeof GetDealAddressesResponse>;
export type PutDealAddressesRequest = z.infer<typeof PutDealAddressesRequest>;
export type GetDealForeignDetailResponse = z.infer<typeof GetDealForeignDetailResponse>;
export type PutDealForeignDetailResponse = z.infer<typeof PutDealForeignDetailResponse>;
export type DealInfoDataView = z.infer<typeof DealInfoDataView>;
export type LegalNatures = z.infer<typeof LegalNatures>;
export type GetDealCatalogueVehicle = z.infer<typeof GetDealCatalogueVehicle>;
