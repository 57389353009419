import { Card } from '@/components/Card';

import { Badge } from '@/components/Badge';
import { Combobox } from '@/components/Combobox/Combobox';
import { useStorage } from '@/hooks';
import { objectToQueryString } from '@/lib/utils/queryParams';
import type { Role } from '@/pages/index';
import { Roles } from '@/stores/useProfileStore';
import { DealFormFilters, Statistics } from '@/types';
import { transformUserListItems, useDashboardUsersMutation, useUserListMutation, useUserListPageData } from '@/views';
import { useRouter } from 'next/router';
import { Controller, useForm } from 'react-hook-form';

type CardGroupProps = {
    role: Role;
    data: Statistics;
};

export function DashboardView({ data }: CardGroupProps) {
    return <DefaultView {...data} />;
}

function DefaultView(data: Statistics) {
    const router = useRouter();
    const { data: defaultUsers } = useUserListPageData({}, Roles.SALES_AGENT);
    const { mutateAsync: filterUsers } = useUserListMutation();
    const { mutateAsync: useDashboardUsers } = useDashboardUsersMutation();
    const { control, handleSubmit, trigger, watch, getValues } = useForm({
        mode: 'onSubmit',
    });
    const watchSalesAgent = watch('salesAgent');
    const setItem = useStorage().setItem;

    const getUsers = async (inputValue: string) => {
        const data = await filterUsers({
            query: inputValue,
        });

        const users = transformUserListItems(
            data,
            {
                query: inputValue,
            },
            Roles.SALES_AGENT,
        );

        return users;
    };

    const handleSubmitFilterForm = handleSubmit(async (filters, e) => {
        e?.preventDefault();

        let filteredFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== '' && value));

        if (filters.salesAgent && typeof filters.salesAgent === 'object') {
            filteredFilters.salesAgentUuid = filters.salesAgent?.value;
            delete filteredFilters.salesAgent;
        }

        useDashboardUsers(filteredFilters);
    });

    const handleClickCard = (formFilters: DealFormFilters) => {
        const salesAgent = getValues('salesAgent');
        setItem('dealsInitialParams', objectToQueryString({ ...formFilters, ...salesAgent }));
        setItem('dealTier', 'PAID');
        router.push(`/deals`);
    };
    return (
        <div className="space-y-8 gap-8">
            <div className="flex items-center gap-8">
                <h1 className="font-semibold text-2xl">Panel principal</h1>
                <Badge color="gray">
                    <span className="text-base">🚘 PAID</span>
                </Badge>
                <form className="w-64 bg-gray-100 rounded-lg" onSubmit={handleSubmitFilterForm}>
                    <Controller
                        name="salesAgent"
                        control={control}
                        render={({ field }) => (
                            <Combobox
                                label=""
                                {...field}
                                defaultValue={undefined}
                                options={getUsers}
                                defaultOptions={defaultUsers}
                                onChange={(object: { label: string; value: string }) => {
                                    field.onChange(object);
                                    trigger().then((isValid) => {
                                        if (isValid) {
                                            handleSubmitFilterForm();
                                        }
                                    });
                                }}
                            />
                        )}
                    />
                </form>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                <Card
                    onClick={() =>
                        handleClickCard({
                            situation: 'OPEN',
                            tier: 'PAID',
                            ...(watchSalesAgent && { salesAgentUuid: watchSalesAgent?.value }),
                        })
                    }
                >
                    <div className="flex flex-col ps-2">
                        <h2 className=" text-3xl font-bold">{data.deals.total}</h2>
                        <span className="text-sm text-gray-500">Total de deals</span>
                    </div>
                </Card>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {data.deals.stages.map((stage) => (
                    <Card
                        key={stage.stage}
                        onClick={() =>
                            handleClickCard({
                                stage: stage.stage,
                                situation: 'OPEN',
                                tier: 'PAID',
                                ...(watchSalesAgent && { salesAgentUuid: watchSalesAgent?.value }),
                            })
                        }
                    >
                        <div className="flex flex-col ps-2">
                            <h2 className="text-3xl font-bold">
                                {new Intl.NumberFormat('de-DE', { minimumIntegerDigits: 2 }).format(stage.count)}
                            </h2>
                            <span className="text-sm text-gray-500">
                                {stage.stage.toLowerCase().split('_').join(' ').charAt(0).toUpperCase() +
                                    stage.stage.toLowerCase().split('_').join(' ').slice(1)}
                            </span>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
}
