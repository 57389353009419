import { useApiClient } from '@/hooks/useApiClient';
import { parseAmountToString, parseStringToAmount } from '@/lib/utils/amount';
import { isEmpty } from '@/lib/utils/helpers';
import {
    ApiGetPreOfferResponse,
    AppraisalsError,
    AppraisalView,
    GetAppraisalsResponse,
    GetFinancialAggregationsResponse,
    GetPreOfferResponse,
    PreOfferView,
} from '@/types';
import { CalculatorFormData, GetOffersResponse, OfferListView, PostOfferRequest } from '@/types/offer';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const client = useApiClient();

function transformPreOfferData(preOffer: GetPreOfferResponse) {
    const selectedProposal = getSelectedProposal(preOffer);

    if (!selectedProposal) {
        return null;
    }
    return {
        ask: parseAmountToString(preOffer.pvpAsk),
        pvpMax: parseAmountToString(preOffer.pvpMax),
        term: selectedProposal.term,
        due: parseAmountToString(selectedProposal?.due),
        tin: selectedProposal.tin,
        tae: selectedProposal.tae,
        total: parseAmountToString(selectedProposal.total),
        totalInterest: parseAmountToString(selectedProposal.totalInterest),
        totalCostCredit: parseAmountToString(selectedProposal.totalCostCredit),
        pvp: parseAmountToString(preOffer.pvp),
    };
}

function transfromToCalculatorFormData(data: GetPreOfferResponse) {
    const selectedProposal = getSelectedProposal(data);
    return {
        pvpMax: data.pvpMax,
        tin: selectedProposal?.tin,
        terms: selectedProposal?.term,
    };
}

export function transformCalculatorFormDataToBE(data: CalculatorFormData): PostOfferRequest {
    return {
        askedPvp: Number(data.pvpMax),
        nir: Number(data.tin),
        terms: Number(data.terms),
        isInsuranceNeeded: data.lifeInsurance,
        insuranceType: data.insuranceType,
    };
}

function transformDealOffers(offers: GetOffersResponse) {
    return offers.items.map((offer) => ({
        uuid: offer.uuid,
        loanAmount: parseAmountToString(offer.loanAmount),
        askedAmount: parseAmountToString(offer.askedAmount),
        loanTotalCost: parseAmountToString(offer.loanTotalCost),
        terms: offer.terms,
        tin: offer.nir,
        tae: offer.apr,
        monthlyFee: parseAmountToString(offer.monthlyFee),
        insuranceType: offer.insuranceType,
        insuranceFee: parseAmountToString(offer.insuranceFee),
        adminFee: parseAmountToString(offer.adminFee),
        financier: offer.financier,
        isSelected: offer.isSelected,
        notAllowedReason: offer.notAllowedReason,
    }));
}

function transformAppraisalsData(data: GetAppraisalsResponse) {
    return {
        maxPrice: parseAmountToString(data.maxPrice),
        ganvam: parseAmountToString(data.ganvam),
        motorEs: parseAmountToString(data.motorEs),
        bca: parseAmountToString(data.bca),
        carMatrix: parseAmountToString(data.carMatrix),
    };
}

function getSelectedProposal(preOfferResponse: GetPreOfferResponse) {
    return preOfferResponse.proposals.find((proposal) => proposal.isSelected);
}

export function useDealPreOfferQuery(uuid: string, select?: (data: ApiGetPreOfferResponse) => any) {
    return useQuery({
        queryKey: ['dealPreOffer'],
        queryFn: async () => await client.getDealPreOffer(uuid),
        select,
        refetchOnWindowFocus: false,
        enabled: !!uuid,
        retry: false,
        throwOnError: true,
    });
}

export function useDealOffersQuery(uuid: string, select?: (data: GetOffersResponse) => any) {
    return useQuery({
        queryKey: ['dealOffers'],
        queryFn: async () => await client.getDealOffers(uuid),
        select,
        refetchOnWindowFocus: false,
        enabled: !!uuid,
        retry: false,
        throwOnError: true,
    });
}

export function useDealOfferPageData(uuid: string): {
    preOffer: PreOfferView;
    offers: OfferListView;
    appraisals: AppraisalView;
    calculatorData: any;
    error: any;
    isLoading: boolean;
    isError: boolean;
} {
    const {
        data: appraisals,
        isLoading: isAppraisalsLoading,
        isError: appraisalsError,
        error: appraisalsErrorData,
    } = useDealAppraisalsQuery(uuid, (data: GetAppraisalsResponse | AppraisalsError) => {
        if ('errorCode' in data) {
            return {
                ...data,
            };
        }
        return transformAppraisalsData(data);
    });

    const {
        data: preOffer,
        isLoading: isPreOfferLoading,
        isError: preOfferError,
        error: preOfferErrorData,
    } = useDealPreOfferQuery(uuid, (data: ApiGetPreOfferResponse) => {
        if (isEmpty(data)) {
            return null;
        }
        return transformPreOfferData(data as GetPreOfferResponse);
    });

    const {
        data: offers,
        isLoading: isOfferLoading,
        isError: offersError,
        error: offersErrorData,
    } = useDealOffersQuery(uuid, (data: GetOffersResponse) => transformDealOffers(data));

    const {
        data: calculatorData,
        isLoading: isCalculatorLoading,
        isError: isCalculatorError,
        error: calculatorErrorData,
    } = useDealPreOfferQuery(uuid, (data: ApiGetPreOfferResponse) => {
        if (isEmpty(data)) {
            return {
                pvpMax: parseStringToAmount(appraisals?.maxPrice ?? ''),
                tin: null,
                terms: null,
            };
        }
        data = { ...data, pvpMax: parseStringToAmount(appraisals?.maxPrice ?? '') };
        return transfromToCalculatorFormData(data as GetPreOfferResponse);
    });

    return {
        preOffer,
        offers,
        appraisals,
        calculatorData,
        error: appraisalsErrorData || preOfferErrorData || offersErrorData || calculatorErrorData,
        isLoading: isPreOfferLoading || isOfferLoading || isAppraisalsLoading || isCalculatorLoading,
        isError: preOfferError || offersError || appraisalsError || isCalculatorError,
    };
}

export function useDealCreateOfferMutation() {
    const queryKey = ['dealOffers'];
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ uuid, data }: { uuid: string; data: CalculatorFormData }) =>
            await client.postDealOffer(uuid, transformCalculatorFormDataToBE(data)),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err: any, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function useDealSubmitOfferMutation() {
    const queryKey = ['dealOffers'];
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ uuid, offerUuid }: { uuid: string; offerUuid: string }) =>
            await client.patchDealSelectedOffer(uuid, offerUuid, {
                isSelected: true,
            }),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err: any, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
            throw new Error(err.message);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function useDealAppraisalsQuery(uuid: string, select?: (data: GetAppraisalsResponse | AppraisalsError) => any) {
    return useQuery({
        queryKey: ['dealAppraisals'],
        queryFn: async () => await client.getDealAppraisals(uuid),
        select,
        refetchOnWindowFocus: false,
        enabled: !!uuid,
        retry: false,
    });
}

export function useDealFinancialAgregationQuery(
    uuid: string,
    select?: (data: GetFinancialAggregationsResponse) => any,
) {
    return useQuery({
        queryKey: ['dealFinancialAgregation'],
        queryFn: async () => await client.getDealFinancialAggregations(uuid),
        select,
        refetchOnWindowFocus: false,
        enabled: !!uuid,
        retry: false,
    });
}
