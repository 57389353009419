import { useApiClient } from '@/hooks';
import { DealReservationResponse, PostDealReservationRequest, ReservationsFilters } from '@/types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const queryKey = ['dealReservation'];
const client = useApiClient();

export function useReservationsQuery(filters?: ReservationsFilters, associateLicensePlate?: boolean) {
    return useQuery({
        queryKey: ['reservations'],
        queryFn: () => client.getReservations(filters),
        refetchOnWindowFocus: false,
        enabled: associateLicensePlate !== false,
        retry: false,
    });
}

export function useDealReservationQuery(uuid: string) {
    return useQuery({
        queryKey,
        queryFn: () => client.getReservation(uuid),
        select: (data: DealReservationResponse) => data,
        enabled: !!uuid,
        refetchOnWindowFocus: false,
        retry: false,
    });
}

export function usePostDealReservationMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({ dealId, data }: { dealId: string; data: PostDealReservationRequest }) => {
            return await client.postReservation(dealId, data);
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
            await queryClient.invalidateQueries({ queryKey: ['reservations'] });
        },
    });
}

export function usePutDealReservationMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async ({
            dealId,
            reservationUuid,
            data,
        }: {
            dealId: string;
            reservationUuid: string;
            data: PostDealReservationRequest;
        }) => {
            const reservation = await client.putReservation(dealId, reservationUuid, data);
            await queryClient.invalidateQueries({ queryKey: ['dealBreakdownAmount', dealId] });
            return reservation;
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
            await queryClient.invalidateQueries({ queryKey: ['reservations'] });
        },
    });
}
