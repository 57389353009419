import { AccordionSkeleton } from '@/components/Accordion';
import { TableSkeleton } from '@/components/Table';

export function DealSubmissionSkeleton() {
    return (
        <div className="flex w-full gap-4 transition-colors duration-200">
            <div className="flex-1">
                <AccordionSkeleton />

                <TableSkeleton cols={2} rows={10} />
            </div>
            <div className="flex max-w-[300px] flex-col justify-between  bg-white p-4">
                <div className="space-y-2">
                    <div className="h-5 w-[150px] animate-pulse rounded bg-gray-300"></div>
                    <div className="h-5 w-[200px] animate-pulse rounded bg-gray-300"></div>
                </div>

                <div className="space-y-2">
                    <div className="h-5 w-[150px] animate-pulse rounded bg-gray-300"></div>
                    <div className="h-5 w-[200px] animate-pulse rounded bg-gray-300"></div>
                </div>
                <div className="space-y-2">
                    <div className="h-5 w-[150px] animate-pulse rounded bg-gray-300"></div>
                    <div className="h-5 w-[200px] animate-pulse rounded bg-gray-300"></div>
                </div>
                <div className="space-y-2">
                    <div className="h-5 w-[150px] animate-pulse rounded bg-gray-300"></div>
                    <div className="h-5 w-[200px] animate-pulse rounded bg-gray-300"></div>
                </div>

                <div className="h-[200px] w-[200px] animate-pulse justify-self-end rounded bg-gray-300"></div>
            </div>
        </div>
    );
}
