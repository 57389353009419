import { useUuid } from '@/hooks';
import { SidebarItem } from '@/layouts/DealDetailLayout/ClientDataModule';
import { useGetCatalogueVehicleSellerDetails } from '@/views/vehicles/details/contact/useVehicleContactDetails';
import { ListGroup } from 'flowbite-react';
import Link from 'next/link';
import { HiOutlinePhone, HiOutlineUser } from 'react-icons/hi';
import { useGetVehicleDetail } from './useVehicleDetailQuery';

export function SellerDataModule() {
    const uuid = useUuid();

    const { data: sellerInfo } = useGetCatalogueVehicleSellerDetails(uuid);
    const { data: sellerAdInfo } = useGetVehicleDetail(uuid);

    const displayName = sellerInfo?.firstName || (sellerInfo ? 'Propietario' : sellerAdInfo?.sellerAd?.sellerName);
    const displayPhone = sellerInfo ? sellerInfo.phone : sellerAdInfo?.sellerAd?.sellerPhone;

    const SideClientNavigation: SidebarItem[] = [
        {
            label: 'Aircall',
            url: `tel:${displayPhone}`,
            icon: <HiOutlinePhone className="h-4 w-4" />,
            disabled: !sellerInfo?.phone && !sellerAdInfo?.sellerAd?.sellerPhone,
        },
    ];

    return sellerAdInfo?.sellerAd?.sellerName || sellerInfo ? (
        <ListGroup className="flex flex-col gap-2 py-3 items-start min-w-[240px] ">
            <div className="flex gap-2 px-4">
                <HiOutlineUser className="p-1 w-7 h-7 bg-blue-100 rounded-md" />
                <span className="text-lg">{displayName}</span>
            </div>
            <div className="w-full py-1">
                {SideClientNavigation.map((item) => {
                    if (item.disabled) return null;

                    return (
                        <ListGroup.Item
                            key={item.label}
                            theme={{
                                link: {
                                    base: 'w-full shadow-transparent',
                                },
                            }}
                        >
                            <Link
                                className="flex px-6 py-1 w-full items-center gap-1 text-gray-900 hover:text-blue-500"
                                href={item.url}
                                target={item.label === 'Respond.io' ? '_blank' : undefined}
                            >
                                {item.icon} {item.label}
                            </Link>
                        </ListGroup.Item>
                    );
                })}
            </div>
        </ListGroup>
    ) : (
        <></>
    );
}
