import { Currency, Measurement, typedObjectKeys } from '@/types/generics';
import { z } from 'zod';

export const ReservationStatuses = {
    RECEIVED: 'Recibida',
    RETURNED: 'Devuelta',
    CHANGE_OF_VEHICLE: 'Cambio de coche',
    DISCARDED_NO_REFUND: 'Cancelada sin devolución',
};
const [firstReservationStatusKey, ...restReservationStatusKey] = typedObjectKeys(ReservationStatuses);
export const ReservationStatus = z.enum([firstReservationStatusKey, ...restReservationStatusKey]);

export const ReservationMethods = {
    BANK_TRANSFER: 'Transferencia bancaria',
    ONLINE_PAYMENT: 'Stripe',
};
const [firstReservationMethodKey, ...restReservationMethodKey] = typedObjectKeys(ReservationMethods);
export const ReservationMethod = z.enum([firstReservationMethodKey, ...restReservationMethodKey]);

export const DealReservationFormData = z.object({
    reservationDate: z.date(),
    amount: z.number(),
    currency: Currency,
    status: ReservationStatus,
    method: ReservationMethod,
    returnedDate: z.date(),
    discardedNoRefundDate: z.date(),
});

export const PostDealReservationRequest = z.object({
    reservationDate: z.string().datetime(),
    amount: z.number(),
    currency: Currency,
    status: ReservationStatus,
    method: ReservationMethod,
    returnedDate: z.string().datetime().nullable(),
});

export const DealReservationResponse = z.object({
    uuid: z.string(),
    reservationDate: z.string().datetime(),
    amount: Measurement,
    status: ReservationStatus,
    method: ReservationMethod,
    returnedDate: z.string().datetime().nullable(),
    discardedNoRefundDate: z.string().datetime().nullable(),
});

export const DealReservationResponseV2 = DealReservationResponse.extend({
    dealUuid: z.string(),
});

// Reservations

export const ReservationsList = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(DealReservationResponseV2),
    totalItems: z.number(),
});

export const ReservationsFilters = z.object({
    licensePlate: z.string().nullable().optional(),
    statuses: z.array(ReservationStatus).optional(),
});

export type ReservationStatuses = z.infer<typeof ReservationStatus>;
export type ReservationMethods = z.infer<typeof ReservationMethod>;
export type DealReservationFormData = z.infer<typeof DealReservationFormData>;
export type PostDealReservationRequest = z.infer<typeof PostDealReservationRequest>;
export type DealReservationResponse = z.infer<typeof DealReservationResponse>;
export type DealReservationResponseV2 = z.infer<typeof DealReservationResponseV2>;

export type ReservationsList = z.infer<typeof ReservationsList>;
export type ReservationsFilters = z.infer<typeof ReservationsFilters>;
