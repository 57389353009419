export function DealDetailHeaderSkeleton() {
    return (
        <div className="w-full divide-y transition-colors duration-200">
            <section className=" flex border-separate items-center justify-between gap-6 bg-gray-50 px-5 py-3.5">
                <div className="divide-x-1">
                    <div className="h-5 w-[600px] animate-pulse rounded bg-gray-300 p-1" />
                </div>
                <div className="flex gap-6">
                    <div className="h-5 w-[80px] animate-pulse rounded bg-gray-300 p-1" />
                    <div className="h-5 w-[80px] animate-pulse rounded bg-gray-300 p-1" />
                </div>
            </section>
            <section className="flex justify-between bg-gray-50 px-5 py-2">
                <div className="flex gap-y-1 space-x-12 ">
                    <div className="flex flex-col space-y-3">
                        <div className="h-5 w-[100px] animate-pulse rounded bg-gray-300 p-1 " />
                        <div className="h-5 w-[100px] animate-pulse rounded bg-gray-300 p-1" />
                    </div>
                    <div className="flex flex-col justify-end space-y-3 ">
                        <div className="h-5 w-[100px] animate-pulse rounded bg-gray-300 p-1" />
                        <div className="flex gap-3">
                            <div className="h-5 w-[100px] animate-pulse rounded bg-gray-300 p-1 " />
                            <div className="h-5 w-[100px] animate-pulse rounded bg-gray-300 p-1" />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="h-10 w-[150px] animate-pulse rounded bg-gray-300 p-1" />
                </div>
            </section>
        </div>
    );
}
