import { Accordion } from '@/components/Accordion';
import { Card } from '@/components/Card';
import { isEmpty } from '@/lib/utils/helpers';
import { AlertDetails, AlertsPageData } from '@/types/alerts';
import { HiCheckCircle, HiExclamation, HiShieldExclamation } from 'react-icons/hi';
import { TbInfoCircle } from 'react-icons/tb';

type AlertsGroup = {
    fraudAlerts: {
        [key: string]: {
            title: string;
            details: (data: AlertDetails) => string;
        };
    };
    carAlerts: {
        [key: string]: {
            title: string;
            details: (data: AlertDetails) => string;
        };
    };
};

export function DealAlertsView({ data }: { data: AlertsPageData }) {
    if (isEmpty(data.carAlerts) && isEmpty(data.fraudAlerts)) {
        return (
            <section className="flex gap-6 w-full h-full">
                <span className="w-full bg-white p-16 text-center">No hay alertas</span>
            </section>
        );
    }

    function getAlertStatus(alert: string) {
        return statusIconMap[alert as keyof typeof statusIconMap];
    }

    const statusIconMap = {
        EMPTY: (
            <div className="rounded-lg bg-gray-200 p-2">
                <TbInfoCircle className="h-6 w-6 text-gray-800" />
            </div>
        ),
        OK: (
            <div className="rounded-lg bg-green-200 p-2">
                <HiCheckCircle className="h-6 w-6 text-green-500" />
            </div>
        ),
        DANGER: (
            <div className="rounded-lg bg-red-200 p-2">
                <HiExclamation className="h-6 w-6 text-red-400" />
            </div>
        ),
        WARNING: (
            <div className="rounded-lg bg-orange-200 p-2">
                <HiShieldExclamation className="h-6 w-6 text-orange-400" />
            </div>
        ),
    };

    const alertsGroupsMap: AlertsGroup = {
        fraudAlerts: {
            CAR_IMPORT: {
                title: 'Importado',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días Fecha de alerta`,
            },
            CAR_OWNERS: {
                title: 'Número de propietarios',
                details: (data: AlertDetails) =>
                    `Ha tenido ${
                        data.numberOwners! > 1
                            ? data.numberOwners + ' propietarios'
                            : data.numberOwners + ' propietario'
                    }`,
            },
            CAR_STOLEN: {
                title: 'Robado',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            CHANGE_OWNERSHIP: {
                title: 'Cambio de titularidad',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días desde el cambio`,
            },
            INSURANCE: {
                title: 'Seguro',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            LEASING: {
                title: 'Leasing',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            RENTING: {
                title: 'Renting',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            TAXI: {
                title: 'Taxi',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            USE_TYPE: {
                title: 'Tipo de uso',
                details: (data: AlertDetails) => `${data.type}`,
            },
        },
        carAlerts: {
            ACCIDENT: {
                title: 'Accidente',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            CHASSIS_MODS: {
                title: 'Modificaciones del chasis',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            DAMAGE: {
                title: 'Daños',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            INCONSISTENT_MILEAGE: {
                title: 'Kilometraje inconsistente',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            ITV_FAIL: {
                title: 'ITV Fallada',
                details: (data: AlertDetails) => `Fecha ${data.date} / ${data.numberDays} días fecha de alerta`,
            },
            PROVINCE: {
                title: 'Provincia',
                details: (data: AlertDetails) => `${data.registrationCity}`,
            },
        },
    };

    return (
        <section className="flex flex-col w-full items-start gap-6">
            <Accordion title="Alertas de fraude" className="w-full">
                <div className="grid grid-cols-2 gap-4 p-5">
                    {data.fraudAlerts.map((alert) => (
                        <Card key={`${alert.group}_${alert.type}`}>
                            <div className="flex items-center gap-6 p-0">
                                {getAlertStatus(alert.details === null ? 'EMPTY' : alert.status)}
                                <div className="flex flex-col">
                                    <span className="text-sm font-light text-gray-500">
                                        {alertsGroupsMap.fraudAlerts[alert.type].title}
                                    </span>
                                    {alert.details === null ? (
                                        <span className="text-md text-gray-500">Sin información</span>
                                    ) : (
                                        <span className="text-md font-bold text-gray-900">
                                            {alertsGroupsMap.fraudAlerts[alert.type].details(alert.details)}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Card>
                    ))}
                </div>
            </Accordion>

            <Accordion title="Alertas del coche" className="w-full">
                <div className="grid grid-cols-2 gap-4 p-5">
                    {data.carAlerts.map((alert) => (
                        <Card key={`${alert.group}${alert.type}`}>
                            <div className="flex items-center gap-6 p-0">
                                {getAlertStatus(alert.details === null ? 'EMPTY' : alert.status)}
                                <div className="flex flex-col">
                                    <span className="text-sm font-light text-gray-500">
                                        {alertsGroupsMap.carAlerts[alert.type].title}
                                    </span>

                                    {alert.details === null ? (
                                        <span className="text-md text-gray-500">Sin información</span>
                                    ) : (
                                        <span className="text-md font-bold text-gray-900">
                                            {alertsGroupsMap.carAlerts[alert.type].details(alert.details)}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Card>
                    ))}
                </div>
            </Accordion>
        </section>
    );
}
