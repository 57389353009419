import { BadgeColor } from '@/components/Badge';

type Colors = {
    [key: string]: BadgeColor;
};

export const statusColors: Colors = {
    NEW: 'blue',
    NOT_ELIGIBLE: 'red',
    CONTACTED: 'blue',
    APPROVED: 'blue',
    PUBLISHED: 'green',
    UNPUBLISHED: 'indigo',
    DISCARDED: 'red',
};
