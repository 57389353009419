import { create } from 'zustand';

type SubmissionStore = {
    selectedDealHasContractedOffer: boolean;
    setSelectedDealHasContractedOffer: (hasContractedOffer: boolean) => void;
};

export const useDealsStore = create<SubmissionStore>()((set) => ({
    selectedDealHasContractedOffer: false,
    setSelectedDealHasContractedOffer: (hasContractedOffer: boolean) =>
        set(() => ({ selectedDealHasContractedOffer: hasContractedOffer })),
}));
