import { z } from 'zod';
import { Measurement, typedObjectKeys } from './generics';

/* GENERAL */
export enum TransactionActions {
    CREATE = 'Crear',
    EDIT = 'Editar',
}

export const transactionMethods = {
    BANK_TRANSFER: 'Transferencia bancaria',
    ONLINE_PAYMENT: 'Tarjeta',
};
const [firstTransactionMethodKey, ...restTransactionMethodKeys] = typedObjectKeys(transactionMethods);

export enum DisccountActions {
    CREATE = 'Crear',
    EDIT = 'Editar',
}

/* SECTIONS */
export const DealAmountBreakdownMetricsData = z.object({
    status: z.string().nullable(),
    reservationAmount: Measurement.nullable().optional(),
});

export const DealAmountBreakdownServicesData = z.object({
    preparationExpenses: Measurement.nullable(),
    premiumWarranty: Measurement.nullable(),
    deliveryExpenses: Measurement.nullable(),
});

export const DealAmountBreakdownCollectedSubmission = z.object({
    uuid: z.string().nullable(),
    financer: z.string().nullable(),
    loanAmount: Measurement.nullable(),
    commissionFee: Measurement.nullable(),
    collectedAt: z.string().nullable(),
});

export const DealTransaction = z.object({
    uuid: z.string(),
    method: z.enum([firstTransactionMethodKey, ...restTransactionMethodKeys]).nullable(),
    payment: Measurement.nullable(),
    transactionDate: z.string().nullable(),
});
export const DealAmountBreakdownTransactionsData = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(DealTransaction),
    totalItems: z.number(),
});
export const DealTransactionForm = z.object({
    method: z.enum([firstTransactionMethodKey, ...restTransactionMethodKeys]).optional(),
    payment: z.number().optional(),
    transactionDate: z.date().optional(),
});

export const DealDiscount = z.object({
    uuid: z.string(),
    concept: z.string(),
    discount: Measurement.nullable(),
});
export const DealAmountBreakdownDiscountsData = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(DealDiscount),
    totalItems: z.number(),
});
export const DealDiscountForm = z.object({
    concept: z.string().optional(),
    discount: z.number().optional(),
});

/* ENDPOINTS */

export const GetDealAmountBreakdown = z.object({
    metrics: DealAmountBreakdownMetricsData.nullable(),
    servicesData: DealAmountBreakdownServicesData.nullable(),
    collectedSubmission: DealAmountBreakdownCollectedSubmission.nullable(),
    transactions: DealAmountBreakdownTransactionsData.nullable(),
    discounts: DealAmountBreakdownDiscountsData.nullable(),
});

export const PatchDealAmountBreakdown = z.object({
    adPrice: Measurement.nullable().optional(),
    purchasePrice: Measurement.nullable().optional(),
    preparationExpenses: Measurement.nullable().optional(),
    premiumWarranty: Measurement.nullable().optional(),
    deliveryExpenses: Measurement.nullable().optional(),
});

export const PutDealAmountBreakdownTransaction = z.object({
    method: z.string().optional(),
    payment: Measurement.optional(),
    transactionDate: z.date().optional(),
});

export const PutDealAmountBreakdownDiscount = z.object({
    concept: z.string().optional(),
    discount: Measurement.optional(),
});

export type DealAmountBreakdownMetricsData = z.infer<typeof DealAmountBreakdownMetricsData>;
export type DealAmountBreakdownServicesData = z.infer<typeof DealAmountBreakdownServicesData>;
export type DealAmountBreakdownCollectedSubmission = z.infer<typeof DealAmountBreakdownCollectedSubmission>;
export type DealTransaction = z.infer<typeof DealTransaction>;
export type DealAmountBreakdownTransactionsData = z.infer<typeof DealAmountBreakdownTransactionsData>;
export type DealTransactionForm = z.infer<typeof DealTransactionForm>;
export type DealDiscount = z.infer<typeof DealDiscount>;
export type DealAmountBreakdownDiscountsData = z.infer<typeof DealAmountBreakdownDiscountsData>;
export type DealDiscountForm = z.infer<typeof DealDiscountForm>;
export type GetDealAmountBreakdown = z.infer<typeof GetDealAmountBreakdown>;
export type PatchDealAmountBreakdown = z.infer<typeof PatchDealAmountBreakdown>;
export type PutDealAmountBreakdownTransaction = z.infer<typeof PutDealAmountBreakdownTransaction>;
export type PutDealAmountBreakdownDiscount = z.infer<typeof PutDealAmountBreakdownDiscount>;
