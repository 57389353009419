import { z } from 'zod';

export const RentTrackingDetails = z
    .object({
        hasSecondKey: z.boolean().nullable().optional(),
        gpsInstallationDateTime: z.string().nullable().optional(),
        gpsUninstallationDateTime: z.string().nullable().optional(),
        takeInsuranceDateTime: z.string().nullable().optional(),
        cancelInsuranceDateTime: z.string().nullable().optional(),
        certifierAgent: z
            .object({
                uuid: z.string().nullable().optional(),
                name: z.string().nullable().optional(),
            })
            .nullable(),
    })
    .nullable();

export const VehicleInspection = z.object({
    vehicleInspectionPassed: z.boolean().nullable().optional(),
    validVehicleInspection: z.boolean().nullable().optional(),
    nextVehicleInspectionDate: z.date().nullable().optional(),
});

export const PutRentTrackingDetails = z.object({
    hasSecondKey: z.boolean().nullable().optional(),
    gpsInstallationDateTime: z.date().nullable().optional(),
    gpsUninstallationDateTime: z.date().nullable().optional(),
    takeInsuranceDateTime: z.date().nullable().optional(),
    cancelInsuranceDateTime: z.date().nullable().optional(),
    certifierAgentExternalId: z.string().nullable().optional(),
});

export const DealRentingDataView = z.object({
    rentTrackingDetails: RentTrackingDetails,
    vehicleInspection: VehicleInspection,
});

export type RentTrackingDetails = z.infer<typeof RentTrackingDetails>;
export type VehicleInspection = z.infer<typeof VehicleInspection>;
export type DealRentingDataView = z.infer<typeof DealRentingDataView>;
export type PutRentTrackingDetails = z.infer<typeof PutRentTrackingDetails>;
