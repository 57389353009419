import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { DocumentationForm } from '@/components/Forms/DocumentationForm';
import { useToast } from '@/hooks/useToast';
import { Label } from 'flowbite-react';
import { useState } from 'react';

export function DealDocumentationView({ link }: { link: string }) {
    const { successToast, errorToast } = useToast();
    const [isEditing, setIsEditing] = useState(false);

    if (isEditing) {
        return (
            <Accordion title="Documentación" className="w-full">
                <DocumentationForm
                    link={link}
                    onClose={() => setIsEditing(false)}
                    onSuccess={() => {
                        setIsEditing(false);
                        successToast();
                    }}
                    onError={errorToast}
                />
            </Accordion>
        );
    }
    const LabelTheme = {
        root: {
            base: 'text-xs  flex flex-col',
        },
    };

    return (
        <Accordion title="Documentación" className="w-full">
            <div className="flex flex-col space-y-8 p-5">
                <Label theme={LabelTheme}>
                    Link a la documentación
                    <a
                        target="_blank"
                        className="text-sm text-blue-500 underline hover:text-blue-600 mt-3 flex gap-2 items-center"
                        href={link}
                    >
                        {link}
                    </a>
                </Label>

                <div className="flex justify-end">
                    <Button variant="flat" color="blue" onClick={() => setIsEditing(true)}>
                        Editar link
                    </Button>
                </div>
            </div>
        </Accordion>
    );
}
