import { Avatar } from '@/components/Avatar';
import { useStorage } from '@/hooks/useStorage';
import { useUser } from '@auth0/nextjs-auth0/client';
import { Sidebar as FSidebar } from 'flowbite-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import type { IconType } from 'react-icons';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';

export type SidebarItem = {
    pathname: string;
    label: string;
    icon?: IconType;
    disabled?: boolean;
};
type SidebarLogoProps = {
    icon: string;
    name: React.ReactNode;
};

type SidebarProps = {
    logo: SidebarLogoProps;
    items: SidebarItem[];
};

export function Sidebar({ logo, items }: SidebarProps) {
    const router = useRouter();
    const { user } = useUser();
    const { setItem, getItem } = useStorage();

    const [isExpanded, setIsExpanded] = useState(getItem('isSidebarExpanded') === 'true');

    return (
        <div className="h-auto bg-white shadow-lg">
            <FSidebar className={`${isExpanded ? 'w-fit' : 'w-[65px]'} gap-32 border-r-1 transition-width ease-in`}>
                <div className="h-full flex flex-col justify-between">
                    <div>
                        <FSidebar.Logo
                            href="#"
                            img={logo.icon}
                            className="flex w-10 cursor-pointer items-center rounded-none px-2 pt-3"
                            onClick={() => router.push('/')}
                        >
                            {isExpanded && logo.name}
                        </FSidebar.Logo>
                        <FSidebar.Items>
                            <FSidebar.ItemGroup className="flex flex-col items-start justify-center border-none text-blue-500">
                                {items
                                    .filter((item) => !item.disabled)
                                    .map((item) => {
                                        const ROOT = '/';
                                        const isHighlighted =
                                            (item.pathname == ROOT && window.location.pathname == ROOT) ||
                                            (item.pathname != ROOT &&
                                                window.location.pathname.startsWith(item.pathname));

                                        return (
                                            <FSidebar.Item
                                                key={item.label}
                                                className={`min-h-[40px] cursor-pointer hover:text-blue-600 ${
                                                    isHighlighted ? '!text-blue-500' : ''
                                                }`}
                                                onClick={() => {
                                                    router.push(item.pathname);
                                                }}
                                                icon={item.icon}
                                            >
                                                {isExpanded && item.label}
                                            </FSidebar.Item>
                                        );
                                    })}
                            </FSidebar.ItemGroup>
                        </FSidebar.Items>
                    </div>

                    {isExpanded ? (
                        <div>
                            <div>
                                <div className="flex gap-2 border-1 border-gray-300 shadow-sm rounded-md p-2 h-fit">
                                    {user && (
                                        <>
                                            <Avatar user={user} />
                                            <div className="flex flex-col">
                                                <p>{user.name}</p>
                                                <p className=" text-xs text-gray-400">{user.email}</p>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            <HiChevronDoubleLeft
                                className="w-10 h-10 p-2 hover:bg-gray-100 rounded-lg cursor-pointer mt-2"
                                onClick={() => {
                                    setIsExpanded(false);
                                    setItem('isSidebarExpanded', 'false');
                                }}
                            />
                        </div>
                    ) : (
                        <div className="space-y-4">
                            <div>{user && <Avatar user={user} />}</div>
                            <HiChevronDoubleRight
                                className="w-10 h-10 p-2 hover:bg-gray-100 rounded-lg cursor-pointer mt-2"
                                onClick={() => {
                                    setIsExpanded(true);
                                    setItem('isSidebarExpanded', 'true');
                                }}
                            />
                        </div>
                    )}
                </div>
            </FSidebar>
        </div>
    );
}
