import { AppraisalView } from '@/types';
import { Accordion } from '@/components/Accordion';
import { HiOutlineExclamation, HiPresentationChartLine } from 'react-icons/hi';

interface AppraisalsSectionProps {
    readonly appraisals: AppraisalView;
}

export function AppraisalsSection({ appraisals }: AppraisalsSectionProps) {
    if ('errorCode' in appraisals) {
        return (
            <Accordion title="Valoración">
                <div className="w-full bg-white p-10 text-center">{appraisals.message}</div>
            </Accordion>
        );
    }

    const Appraisals = [
        {
            label: 'GANVAM',
            value: appraisals.ganvam,
        },
        {
            label: 'BCA Appraisal',
            value: appraisals.bca,
        },
        {
            label: 'MOTOR.ES',
            value: appraisals.motorEs,
        },
        {
            label: 'Table Matrix Value',
            value: appraisals.carMatrix,
        },
    ];

    const maxPrice = () => {
        const stringValue = appraisals.maxPrice.replace('.', '').replace(',', '.').replace(' €', '');
        let intValue = Math.round(parseFloat(stringValue));

        return intValue;
    };

    return (
        <Accordion title="Valoración">
            <div className="p-5 flex flex-col gap-4">
                {maxPrice() < 3000 && (
                    <div className="flex items-center gap-4 rounded-lg border border-gray-300 p-3">
                        <div className="flex flex-row items-center gap-4">
                            <div className="flex items-center justify-center rounded-lg bg-orange-100 p-2">
                                <HiOutlineExclamation className="h-6 w-6 text-orange-400" />
                            </div>
                            <div className="text-gray-500">
                                <span className="font-bold ">¡Atención!</span> La valoración del vehículo es inferior a
                                3.000€, la generación de ofertas puede verse afectada
                            </div>
                        </div>
                    </div>
                )}

                <div className="grid grid-cols-2 gap-4">
                    {Appraisals.map((financer) => (
                        <div
                            className="flex items-center gap-4 rounded-lg border border-gray-300 p-3"
                            key={financer.label}
                        >
                            <div className="flex gap-3">
                                <div className="flex items-center justify-center rounded-lg bg-teal-400 p-1 px-2">
                                    <HiPresentationChartLine className="h-6 w-6 text-green-100" />
                                </div>
                                <div>
                                    <h3 className="text-xs">{financer.label}</h3>
                                    <span className="text-sm font-bold">{financer.value}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Accordion>
    );
}
