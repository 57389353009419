import { useEffect } from 'react';
import { useRouter } from 'next/router';

export default function useUnsavedChangesWarning(isFormDirty: boolean, callback: () => boolean) {
    const router = useRouter();

    useEffect(() => {
        if (isFormDirty) {
            const routeChangeStart = () => {
                const ok = callback();
                if (!ok) {
                    router.events.emit('routeChangeError');
                    throw 'Abort route change. Please ignore this error.';
                }
            };
            router.events.on('routeChangeStart', routeChangeStart);

            return () => {
                router.events.off('routeChangeStart', routeChangeStart);
            };
        }
    }, [isFormDirty]);
}
