import { z } from 'zod';

export function getClientEnv(): Record<string, string> {

    const clientProcessEnv = {
        PIPEDRIVE_URL: process.env.NEXT_PUBLIC_PIPEDRIVE_URL,
    };
    try {
        return getSchema(clientProcessEnv).parse(clientProcessEnv);
    } catch (err: any) {

        throw new Error(`Invalid client env: ${err.message}`);
    }
}

export function getServerEnv(): Record<string, string> {
    const serverProcessEnv = {
        AUTH0_SECRET: process.env.AUTH0_SECRET,
        AUTH0_BASE_URL: process.env.AUTH0_BASE_URL,
        AUTH0_ISSUER_BASE_URL: process.env.AUTH0_ISSUER_BASE_URL,
        AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
        AUTH0_CLIENT_SECRET: process.env.AUTH0_CLIENT_SECRET,
        API_BASE_URL: process.env.API_BASE_URL,
    }

    try {
        return getSchema(serverProcessEnv).parse(serverProcessEnv);
    } catch (err: any) {
        throw new Error(`Invalid server env: ${err.message}`);
    }

}
function getSchema(env: Record<string, string | undefined>) {
    const processEnvKeys = Object.keys(env);
    return z.object(
        processEnvKeys.reduce((acc, key) => {
            return {
                ...acc,
                [key]: z.string(),
            };
        }, {}),
    );
}