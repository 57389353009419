import { z } from 'zod';
import { DocumentType } from '@/types/Deal';

export const FinancialAggregationResults = z.enum([
    'PENDING',
    'CLIENT_ERROR',
    'INVALID_DATA_RECEIVED',
    'DATA_RECEIVED',
    'EVALUATION_ERROR',
    'EVALUATED',
]);

export const FinancialAggregationCodes = z.enum([
    'approved',
    'approved_to_rent',
    'rejected_no_checking_account',
    'rejected_no_checking_account_holder',
    'rejected_no_transactions',
    'rejected_checking_account_seniority',
    'rejected_gambling_betting',
    'rejected_negative_balance',
    'approved_with_micro',
    'rejected_n_ops_microloans_exceeded',
    'approved_just_confia',
    'approved_just_lendrock',
    'rejected_by_paid_score_model',
]);

export const financialAgregationMap = {
    approved: 'Aprobado - PAID y RENT',
    approved_to_rent: 'Aprobado - RENT',
    rejected_no_checking_account: 'Rechazo - el cliente no es el titular de la cuenta',
    rejected_no_checking_account_holder: 'Rechazo - el cliente no es el titular de la cuenta',
    rejected_no_transactions: 'Rechazo - cuenta sin transacciones',
    rejected_checking_account_seniority: 'Rechazo',
    rejected_gambling_betting: 'Rechazo',
    rejected_negative_balance: 'Rechazo',
    approved_with_micro: 'Aprobado - PAID y RENT con Micro',
    rejected_n_ops_microloans_exceeded: 'Rechazo',
    approved_just_confia: 'Aprobado PAID (Confia, BBVA) y RENT',
    approved_just_lendrock: 'Aprobado - PAID (solo LENDROCK) y RENT',
    rejected_by_paid_score_model: 'Rechazado',
};

export const FinancialAggregation = z.object({
    uuid: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    status: z.string(),
    receivedAt: z.string().nullable(),
    evaluatedAt: z.string().nullable(),
    error: z.string().nullable(),
    result: FinancialAggregationCodes.nullable(),
    link: z.string().nullable(),
    service: z.string().nullable(),
    identificationNumber: z
        .object({
            value: z.string(),
            type: DocumentType,
        })
        .nullable(),
});

export const PatchFinancialAggregation = z.object({
    error: z.string(),
    status: FinancialAggregationResults,
});

export const GetFinancialAggregationsResponse = z.array(FinancialAggregation);

export type FinancialAggregationCodes = z.infer<typeof FinancialAggregationCodes>;
export type FinancialAggregation = z.infer<typeof FinancialAggregation>;
export type GetFinancialAggregationsResponse = z.infer<typeof GetFinancialAggregationsResponse>;
export type PatchFinancialAggregation = z.infer<typeof PatchFinancialAggregation>;
