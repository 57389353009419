import { NoteCardSkeleton } from '@/components/Card/NoteCardSkeleton';
import { DealDetailLayout } from '@/layouts';

export function DealNotesViewSkeleton() {
    return (
        <DealDetailLayout>
            <div className="flex flex-1 flex-col border-x border-x-gray-200 shadow-sm">
                <div className="rounded-t-lg border-b border-b-gray-200 bg-gray-100 p-4">
                    <div>
                        <span className="font-medium text-gray-900">Notas</span>
                    </div>
                </div>
                <div className="flex flex-1 rounded-b-lg flex-col gap-6 bg-white p-6 ">
                    <NoteCardSkeleton />
                    <NoteCardSkeleton />
                </div>
            </div>
        </DealDetailLayout>
    );
}
