import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { DateInput, RadioGroup, Select, TextInput } from '@/components/Inputs';
import { useUuid } from '@/hooks/useUuid';
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning';
import { DealBasicInfo } from '@/types';
import { CompanyBillingType, Employment, JobPosition, JobType, ProfessionalStatus } from '@/types/Deal';
import { FormProps, SelectOption } from '@/types/generics';
import { updateDealEmployment } from '@/views/deals/details/info';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const professionaStatusOptions: SelectOption<ProfessionalStatus>[] = [
    {
        label: 'Autónomo',
        value: 'FREELANCE',
    },
    {
        label: 'Empleado',
        value: 'EMPLOYEE',
    },
    {
        label: 'Desempleado',
        value: 'UNEMPLOYED',
    },
    {
        label: 'Pensionista',
        value: 'PENSIONER',
    },
    {
        label: 'No trabaja',
        value: 'NOT_WORK',
    },
];

const jobPositionsOptions: SelectOption<JobPosition>[] = [
    {
        value: 'EXECUTIVE',
        label: 'Directivo',
    },
    {
        value: 'QUALIFIED_TECHNICIAN',
        label: 'Técnico titulado',
    },
    {
        value: 'ADMINISTRATIVE',
        label: 'Administrativo',
    },
    {
        value: 'MANUAL_WORKER',
        label: 'Trabajador Manual',
    },
    {
        value: 'REPRESENTATIVE',
        label: 'Representante',
    },
    {
        value: 'SPECIALIST_WORKER',
        label: 'Trabajador especialista',
    },
    {
        value: 'LIBERAL_PROFESSION',
        label: 'Profesión liberal',
    },
    {
        value: 'PEDDLER',
        label: 'Vendedor ambulante',
    },
    {
        value: 'OTHER',
        label: 'Otro',
    },
];

const jobTypeOptions: SelectOption<JobType>[] = [
    {
        value: 'INDUSTRY',
        label: 'Industria',
    },
    {
        value: 'COMMERCE',
        label: 'Comercio',
    },
    {
        value: 'SERVICES',
        label: 'Servicios',
    },
    {
        value: 'HOSPITALITY',
        label: 'Hostelería',
    },
    {
        value: 'CONSTRUCTION',
        label: 'Construcción',
    },
    {
        value: 'TRANSPORTATION',
        label: 'Transporte',
    },
    {
        value: 'AGRICULTURE_FARMING_FISHING',
        label: 'Agricultura, ganadería y pesca',
    },
    {
        value: 'PUBLIC_ADMINISTRATION',
        label: 'Administración pública',
    },
    {
        value: 'OTHER_ACTIVITIES',
        label: 'Otras actividades',
    },
];

const companyBillingOptions: SelectOption<CompanyBillingType>[] = [
    {
        value: 'ANNUAL_TURNOVER',
        label: 'Facturación anual',
    },
    {
        value: 'NET_ANNUAL_YIELD',
        label: 'Rendimiento neto anual',
    },
];

export function CurrentJobForm({
    employment,
    onSuccess,
    onError,
    dealBasicInfo,
}: FormProps & {
    employment: Employment;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const [professionalStatusOption, setProfessionalStatusOption] = useState<ProfessionalStatus | undefined>(
        employment?.professionalStatus,
    );
    const { mutateAsync: updateEmployment } = updateDealEmployment();

    function handleProfessionalStatusChange(e: React.ChangeEvent<HTMLSelectElement>) {
        setProfessionalStatusOption(e.target.value as ProfessionalStatus);
    }

    const dealUuid = useUuid();
    const {
        register,
        control,
        handleSubmit,
        formState: { isDirty, errors, isSubmitting },
    } = useForm({
        values: {
            ...employment,
            seniorityDate: employment?.seniorityDate ? utcToZonedTime(employment?.seniorityDate, 'UTC') : null,
            jobType: employment?.jobType ?? '',
            jobPosition: employment?.jobPosition ?? '',
            companyBillingType: employment?.companyBillingType ?? '',
            companyName: employment?.companyName ?? null,
        },

        mode: 'onSubmit',
    });

    useUnsavedChangesWarning(isDirty, () => {
        return confirm('Parece que tienes cambios sin guardar, ¿deseas abandonar la página?');
    });

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            await updateEmployment({
                uuid: dealUuid,
                data: {
                    ...data,
                    jobPosition: data.jobPosition !== '' ? (data.jobPosition as JobPosition) : null,
                    seniorityDate: data.seniorityDate ? zonedTimeToUtc(data.seniorityDate, 'UTC') : null,
                    jobType: data.jobType === '' ? null : (data.jobType as JobType),
                    companyBillingType:
                        data.companyBillingType === '' ? null : (data.companyBillingType as CompanyBillingType),
                },
            });
            onSuccess();
        } catch (e) {
            onError();
        }
    });

    const freelanceForm = () => {
        return (
            <>
                <Select
                    label="Cargo actual"
                    placeholder="Selecciona una opción"
                    options={jobPositionsOptions}
                    required={dealBasicInfo?.tier !== 'C2C'}
                    {...register('jobPosition', {
                        required: dealBasicInfo?.tier !== 'C2C' ? 'Selecciona una opción' : false,
                    })}
                    error={errors.jobPosition}
                />
                <Controller
                    name="jobType"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Tipo de empleo"
                            placeholder="Seleciona una opción"
                            allowEmpty
                            options={jobTypeOptions}
                            {...field}
                            error={errors.jobType}
                        />
                    )}
                />

                <Controller
                    name="companyBillingType"
                    control={control}
                    render={({ field }) => (
                        <Select
                            label="Tipo de facturación"
                            placeholder="Selecciona una opción"
                            allowEmpty
                            options={companyBillingOptions}
                            {...field}
                            error={errors.companyBillingType}
                        />
                    )}
                />

                <TextInput
                    label="Nombre de la empresa"
                    {...register('companyName')}
                    type="text"
                    error={errors.companyName}
                />
                <TextInput
                    label="Ingresos netos anuales"
                    required={dealBasicInfo?.tier !== 'C2C'}
                    {...register('annualIncome', {
                        required: dealBasicInfo?.tier !== 'C2C' ? 'Este campo es obligatorio' : false,
                        valueAsNumber: true,
                        min: { value: 1, message: 'El ingreso neto anual debe ser mayor que 0' },
                    })}
                    type="text"
                    error={errors.annualIncome}
                />

                <Controller
                    name="seniorityDate"
                    control={control}
                    rules={{
                        required: dealBasicInfo?.tier !== 'C2C' ? 'Este campo es obligatorio' : false,
                        validate: (value?: any) =>
                            (value instanceof Date && !isNaN(value.getTime())) || dealBasicInfo?.tier === 'C2C',
                    }}
                    render={({ field }) => (
                        <DateInput
                            label="Fecha antigüedad empresa"
                            {...field}
                            error={errors.seniorityDate}
                            required={dealBasicInfo?.tier !== 'C2C'}
                        />
                    )}
                />
            </>
        );
    };

    const employeeForm = () => {
        return (
            <>
                <div className="col-span-3 grid grid-cols-6">
                    <RadioGroup
                        label="Contrato indefinido"
                        inputs={[
                            { value: 'YES', label: 'Sí' },
                            { value: 'NO', label: 'No' },
                        ]}
                        {...register('permanentContract')}
                        error={errors.permanentContract}
                    />
                </div>
                <Select
                    label="Cargo actual"
                    placeholder="Selecciona una opción"
                    options={jobPositionsOptions}
                    required={dealBasicInfo?.tier !== 'C2C'}
                    {...register('jobPosition', {
                        required: dealBasicInfo?.tier !== 'C2C' ? 'Este campo es obligatorio' : false,
                    })}
                    error={errors.jobPosition}
                />
                <TextInput
                    label="Nombre de la empresa"
                    {...register('companyName')}
                    type="text"
                    error={errors.companyName}
                />

                <Controller
                    name="seniorityDate"
                    control={control}
                    rules={{
                        validate: (value?: any) =>
                            (value instanceof Date && !isNaN(value.getTime())) || dealBasicInfo?.tier === 'C2C',
                        required: dealBasicInfo?.tier !== 'C2C' ? 'Este campo es obligatorio' : false,
                    }}
                    render={({ field }) => (
                        <DateInput
                            label="Fecha antigüedad empresa"
                            {...field}
                            error={errors.seniorityDate}
                            required={dealBasicInfo?.tier !== 'C2C'}
                        />
                    )}
                />
                <TextInput
                    label="Ingresos netos mensuales"
                    required={dealBasicInfo?.tier !== 'C2C'}
                    {...register('monthlyIncome', {
                        required: dealBasicInfo?.tier !== 'C2C' ? 'Este campo es obligatorio' : false,
                        valueAsNumber: true,
                    })}
                    type="text"
                    error={errors.monthlyIncome}
                />
            </>
        );
    };

    const pensionerForm = () => {
        return (
            <TextInput
                label="Ingresos netos mensuales"
                required={dealBasicInfo?.tier !== 'C2C'}
                {...register('monthlyIncome', {
                    required: dealBasicInfo?.tier !== 'C2C' ? 'Este campo es obligatorio' : false,
                    min: 0,
                    valueAsNumber: true,
                })}
                type="text"
                error={errors.monthlyIncome}
            />
        );
    };

    return (
        <div className="space-y-2">
            <Accordion title="Datos profesionales" className="bg-gray-100">
                <form
                    className="grid grid-cols-3 gap-4 p-5 form-enabled"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmitForm();
                    }}
                >
                    <div className="col-span-3 grid grid-cols-3">
                        <Select
                            label="Situación profesional"
                            placeholder="Selecciona una opción"
                            options={professionaStatusOptions}
                            required={dealBasicInfo?.tier !== 'C2C'}
                            {...register('professionalStatus', {
                                required: dealBasicInfo?.tier !== 'C2C' ? 'Este campo es obligatorio' : false,
                                onChange: (e) => handleProfessionalStatusChange(e),
                            })}
                            error={errors.professionalStatus}
                        />
                    </div>

                    {professionalStatusOption === 'FREELANCE' && freelanceForm()}

                    {professionalStatusOption === 'EMPLOYEE' && employeeForm()}

                    {professionalStatusOption === 'PENSIONER' && pensionerForm()}

                    <div className="grid col-span-3 gap-10 grid-cols-6">
                        <Button
                            disabled={!isDirty || isSubmitting}
                            className=" col-span-2 col-start-5"
                            type="submit"
                            color="blue"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Accordion>
        </div>
    );
}
