import { Card as FCard, CardProps as FCardProps } from 'flowbite-react';
import { PropsWithChildren } from 'react';

type CardProps = FCardProps & {
    className?: string;
    onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
};

export function Card({ children, href, onClick, className }: PropsWithChildren<CardProps>) {
    return (
        <FCard
            onClick={onClick}
            href={href}
            theme={{
                root: {
                    base: `flex rounded-lg border border-gray-200 bg-white shadow-md ${
                        onClick ? 'cursor-pointer' : ''
                    } ${className}`,
                    href: 'hover:bg-gray-100 dark:hover:bg-gray-700 cursor-pointer',
                    children: 'flex flex-col justify-center gap-4 p-3',
                },
            }}
        >
            {children}
        </FCard>
    );
}
