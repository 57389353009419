import { useToast, useUuid } from '@/hooks';
import { getAmount } from '@/lib/utils/amount';
import { Roles, useProfileStore } from '@/stores';
import { CalculatorFormData, DealBasicInfo, OfferListView, OfferView, stagesC2C, StagesC2CKeys } from '@/types';
import { GetCatalogueVehicleDetails } from '@/types/Vehicle';
import { useDealCreateOfferMutation, useDealSubmitOfferMutation } from '@/views';
import { OfferCard, OfferCardSkeleton, OfferStatusCard } from '@/views/deals/details/offer/cards';
import { CalculatorForm } from '@/views/deals/details/offer/forms';
import { useState } from 'react';

export function isBeforeDealCreatedC2C(stage: StagesC2CKeys) {
    const dealCreatedIndex = Object.keys(stagesC2C).indexOf('DEAL_CREATED');
    const stageIndex = Object.keys(stagesC2C).indexOf(stage);

    return stageIndex < dealCreatedIndex;
}

interface DealCalculatorSectionProps {
    readonly data: CalculatorFormData;
    readonly offers: OfferListView;
    readonly dealBasicInfo: DealBasicInfo | undefined;
    readonly vehicleDetails: GetCatalogueVehicleDetails | undefined;
}

export function DealCalculatorSection({ data, offers, dealBasicInfo, vehicleDetails }: DealCalculatorSectionProps) {
    const uuid = useUuid();
    const { successToast, errorToast } = useToast();
    const { isPending, mutateAsync: createOffers } = useDealCreateOfferMutation();
    const { mutateAsync: submitOffer } = useDealSubmitOfferMutation();
    const [offerSelected, setOfferSelected] = useState<OfferView>();
    const filteredOffers = offers.filter((offer) => offer.notAllowedReason === null);
    const isOfferSelected = (uuid: string) => offerSelected?.uuid === uuid;
    const { hasRequiredRoles, isAdmin } = useProfileStore();

    const handleOfferSelected = async (offer: OfferView) => {
        setOfferSelected(offer);
        try {
            await submitOffer({ uuid, offerUuid: offer?.uuid });
            successToast(
                'Se ha guardado la financiación correctamente. Puedes ver los detalles en la pestaña “Datos financiación”.',
            );
        } catch (e: any) {
            errorToast(e.message);
        }
    };

    const handleCalculateOffers = async (data: CalculatorFormData) => {
        try {
            await createOffers({ uuid, data });
        } catch (error: any) {
            errorToast(error.message);
        }
    };

    offers.sort((a, b) => {
        if (a.notAllowedReason === null && b.notAllowedReason === null) return 0;
        if (a.notAllowedReason === null) return -1;
        if (b.notAllowedReason === null) return 1;
        return 0;
    });

    const displayOffers = () => {
        const isC2CAgent = dealBasicInfo?.tier === 'C2C' && hasRequiredRoles([Roles.SALES_AGENT], false) && !isAdmin();

        if (offers.length === 0) {
            return (
                <div className="flex h-full w-full items-center justify-center rounded-lg bg-white shadow-lg w-50">
                    <span className="w-3/4 text-center">
                        Para generar ofertar introduzca la cantidad solicitada, TIN y cuotas y haga clic en calcular.
                    </span>
                </div>
            );
        }

        if (isC2CAgent && filteredOffers.length > 0) {
            return (
                <OfferCard
                    financier={filteredOffers[0].financier}
                    details={filteredOffers[0]}
                    selected={isOfferSelected(filteredOffers[0].uuid) || filteredOffers[0].isSelected}
                    offerSelected={() => handleOfferSelected(filteredOffers[0])}
                    dealTier={dealBasicInfo?.tier}
                    vehicleDetails={vehicleDetails}
                />
            );
        }

        if (!isC2CAgent && offers.length > 0) {
            return (
                <>
                    <ul className="flex flex-col gap-4 relative">
                        {offers.map((offer) => (
                            <li key={offer.uuid}>
                                <OfferCard
                                    financier={offer.financier}
                                    details={offer}
                                    selected={isOfferSelected(offer.uuid) || offer.isSelected}
                                    offerSelected={() => handleOfferSelected(offer)}
                                    dealTier={dealBasicInfo?.tier}
                                    vehicleDetails={vehicleDetails}
                                />
                            </li>
                        ))}
                    </ul>
                </>
            );
        }

        return (
            <div className="flex h-full w-full items-center justify-center rounded-lg bg-white shadow-lg w-50">
                <span className="w-3/4 text-center">
                    No se han encontrado ofertas para los valores que has introducido, por favor introduce otros para
                    poder calcular las ofertas.
                </span>
            </div>
        );
    };

    return (
        <section className="space-y-4">
            {dealBasicInfo?.tier === 'C2C' && (
                <OfferStatusCard dealBasicInfo={dealBasicInfo} filteredOffers={filteredOffers} />
            )}

            <div className="flex w-full space-x-6">
                <div>
                    <CalculatorForm
                        dealBasicInfo={dealBasicInfo}
                        formData={{
                            tin: data.tin,
                            terms: data.terms,
                            lifeInsurance: true,
                            insuranceType: 'NO_INSURANCE',
                            pvpMax:
                                dealBasicInfo?.tier === 'C2C'
                                    ? getAmount(vehicleDetails?.agreedPrices.sellingPrice).toString() || 'NaN'
                                    : data.pvpMax,
                        }}
                        onSubmit={handleCalculateOffers}
                        isBeforeDealCreatedC2C={isBeforeDealCreatedC2C(dealBasicInfo?.stage?.stage as StagesC2CKeys)}
                    />
                </div>
                {isPending ? (
                    <div className="flex-1 space-y-6">
                        {Array.from(Array(3).keys()).map((item) => (
                            <OfferCardSkeleton key={item} />
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-1 flex-col">{displayOffers()}</div>
                )}
            </div>
        </section>
    );
}
