import { ComponentProps, ForwardedRef, forwardRef } from 'react';
import { Label, Checkbox as FCheckbox } from 'flowbite-react';

interface CheckboxProps extends ComponentProps<'input'> {
    label?: string;
    name?: string;
    id?: string;
}

export const Checkbox = forwardRef((props: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
    const { className, ...rest } = props;
    const LabelTheme = {
        root: {
            base: 'text-sm flex items-center gap-2 text-gray-900',
        },
    };

    return (
        <Label theme={LabelTheme} className="text-sm cursor-pointer">
            <FCheckbox {...rest} ref={ref} />
            <span className={`${className}`}>{props.label}</span>
        </Label>
    );
});
