import { Accordion } from '@/components/Accordion';
import { getFilteredOptions } from '@/lib/utils/getters';
import { GetCarfaxResponse } from '@/types/carfax';
import { Table } from 'flowbite-react';
import { PropsWithChildren } from 'react';

const Header: string[] = ['Field', 'Value'];

const cardFaxDataInfo = [
    'car',
    'make',
    'model',
    'country',
    'useType',
    'bodyType',
    'fuelType',
    'province',
    'driveType',
    'ownerType',
    'engineSize',
    'postalCode',
    'fiscalPower',
    'gearBoxType',
    'modelFamily',
    'municipality',
    'enginePowerHp',
    'enginePowerKW',
];

export function DealCarfaxView({ carfax }: { readonly carfax: GetCarfaxResponse }) {
    return (
        <Accordion title="Carfax Detail" className="min-w-xl h-fit flex-1">
            <Table striped={true}>
                <Table.Head>
                    {Header.map((label) => (
                        <Table.HeadCell className=" border-b-1 border-b-gray-300 py-2 text-gray-400" key={label}>
                            {label}
                        </Table.HeadCell>
                    ))}
                </Table.Head>

                <Table.Body>
                    {getFilteredOptions(carfax, cardFaxDataInfo).map(({ label, value }) => (
                        <Table.Row key={`${label}-detail`} className="bg-white even:bg-gray-50">
                            <TableCellCarfax className="">{label}</TableCellCarfax>
                            <TableCellCarfax className=" ">{value}</TableCellCarfax>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </Accordion>
    );
}

export function TableCellCarfax({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) {
    return (
        <Table.Cell
            theme={{
                base: `py-2 px-6 text-gray-900 ${className}`,
            }}
        >
            {children}
        </Table.Cell>
    );
}
