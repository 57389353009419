import { z } from 'zod';
import { Stage } from './deals';

export const Stages = z.object({
    stage: Stage,
    count: z.number(),
});

export const Statistics = z.object({
    deals: z.object({
        stages: z.array(Stages),
        total: z.number(),
    }),
});

export const GetStatisticsResponse = Statistics;

export const StatisticsFilters = z.object({
    salesAgent: z.string(),
});

export type Stages = z.infer<typeof Stages>;
export type StatisticsFilters = z.infer<typeof StatisticsFilters>;
export type Statistics = z.infer<typeof Statistics>;
export type GetStatisticsResponse = z.infer<typeof GetStatisticsResponse>;
