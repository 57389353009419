import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { NoteCard } from '@/components/Card';
import { NoteDetailsDialog } from '@/components/Dialog';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { useUuid } from '@/hooks/useUuid';
import { useToast } from '@/hooks/useToast';
import { Attachment, EditDealNoteRequest, GetNotesResponse, NotesPageData } from '@/types';
import { usePostNoteMutation, useUploadAttachmentMutation } from '@/views/deals/details/notes/useNotesQuery';
import { useUser } from '@auth0/nextjs-auth0/client';
import { Pagination } from 'flowbite-react';
import { HiPlus } from 'react-icons/hi';

export function DealNotesView({
    notes,
    pinnedNotes,
    handlePageChange,
    queryParams,
}: {
    notes: NotesPageData;
    pinnedNotes: GetNotesResponse;
    handlePageChange: (page: number) => void;
    queryParams: { [key: string]: string };
}) {
    const { user } = useUser();
    const { successToast, errorToast } = useToast();
    const dialog = useDialog();
    const dealUuid = useUuid();
    const { mutateAsync: useNewDealNote } = usePostNoteMutation();
    const { mutateAsync: useUploadAttachment } = useUploadAttachmentMutation();

    const handleOpenAssignModal = async () => {
        dialog({
            type: 'modal',
            content: (
                <NoteDetailsDialog
                    saveNote={handleCreateNote}
                    onSuccess={() => successToast('Nota creada correctamente 👍🏼')}
                    onError={() => errorToast('Ha ocurrido un problema al intentar crear la nota')}
                />
            ),
        });
    };

    const handleCreateNote = async (
        newNoteData: EditDealNoteRequest,
        deleteAttachments: Attachment[],
        newAttachments?: File[],
    ) => {
        const noteData = await useNewDealNote({
            dealUuid: dealUuid,
            data: { ...newNoteData, title: `${user?.name ? user.name : 'no-title'}` },
        });

        if (newAttachments && newAttachments.length > 0) {
            try {
                await useUploadAttachment({ dealUuid: dealUuid, noteUuid: noteData.uuid, data: newAttachments });
            } catch (error) {
                errorToast('Error al intentar subir archivos');
            }
        }
    };

    return (
        <div className="w-full flex flex-col gap-6">
            {pinnedNotes.items.length > 0 && (
                <Accordion title="Notas Ancladas" className="w-full">
                    <div className="flex flex-1 rounded-b-lg flex-col gap-6 bg-white p-6">
                        {pinnedNotes.items.map((note) => (
                            <NoteCard noteData={note} isPinned={note.isPinned} key={note.uuid} />
                        ))}
                    </div>
                </Accordion>
            )}

            <div className="flex flex-1 flex-col border-x border-x-gray-200 shadow-sm">
                <div className="rounded-t-lg border-b border-b-gray-200 bg-gray-100 px-6 py-1">
                    <div className="flex items-center justify-between">
                        <span className="text-gray-900">Notas</span>
                        {notes.items.length > 0 ? (
                            <Button variant="ghost" color="gray" onClick={handleOpenAssignModal}>
                                <HiPlus /> Crear nueva nota
                            </Button>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                {notes.items.length > 0 ? (
                    <div className="flex flex-1 rounded-b-lg flex-col gap-4 bg-white p-4">
                        {[...notes.items].map((note) => (
                            <NoteCard noteData={note} isPinned={note.isPinned} key={note.uuid} />
                        ))}
                    </div>
                ) : (
                    <div className="flex flex-1 items-center rounded-b-lg flex-col gap-10 bg-white px-6 py-10 text-xl">
                        <span className="font-bold">Vaya, todavía no hay ninguna nota creada...</span>
                        <span>Puedes crear la primera clicando el botón de abajo ;)</span>
                        <div>
                            <Button color="blue" onClick={handleOpenAssignModal} className="px-6">
                                <HiPlus /> Crear nota
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            {Number(notes?.pages) > 1 && (
                <div className="flex justify-center">
                    <Pagination
                        className="mx-auto"
                        theme={{
                            pages: {
                                selector: {
                                    base: 'w-12 border border-gray-300 bg-white py-2 leading-tight aria-[current]:bg-blue-500 aria-[current]:text-white aria-[current]:hover:bg-blue-500 hover:bg-gray-100 hover:text-gray-700',
                                },
                            },
                        }}
                        currentPage={Number(queryParams.page ?? 1)}
                        onPageChange={handlePageChange}
                        showIcons
                        totalPages={Number(notes?.pages)}
                        nextLabel={''}
                        previousLabel={''}
                    />
                </div>
            )}
        </div>
    );
}
