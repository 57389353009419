type QueryParams<T> = {
    [key in keyof T]: string;
};
export function useUrlQueryParams<T>() {
    function setQueryParams(params: QueryParams<T>) {
        const searchParams = new URLSearchParams();
        Object.entries(params).forEach(([key, values]) => {
            if (Array.isArray(values)) {
                values.forEach((value) => {
                    searchParams.append(key, value);
                });
            } else {
                searchParams.append(key, values as string);
            }
        });

        window.history.replaceState(null, '', `?${searchParams.toString()}`);
    }

    function getQueryParams() {
        const searchParams = new URLSearchParams(window.location.search.slice(1));
        return Object.fromEntries(searchParams) as QueryParams<T>;
    }
    return {
        queryParams: getQueryParams(),
        setQueryParams,
    };
}
