import { useApiClient } from '@/hooks/useApiClient';
import { getAmount } from '@/lib/utils/amount';
import { BankDetail } from '@/types/Deal';
import {
    ChangeSubmissionsStatusProps,
    GetSubmissionDetails,
    SubmissionInfoData,
    SubmissionOfferFormData,
    SubmissionView,
    TransitionHistory,
} from '@/types/submission';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

const apiClient = useApiClient();
const queryKey = ['submissionDetails'];

type SubmissionInfo = { submission: SubmissionView } | { history: TransitionHistory } | { bankDetails: BankDetail };
const SubmissionInfoInitial = {
    submission: {},
    history: {},
    bankDetails: {},
};

const queryOptions = {
    refetchOnWindowFocus: false,
    retry: false,
};

const queries = (dealId: string, submissionUuid: string) => [
    {
        queryKey,
        queryFn: async () => {
            const data = await apiClient.getSubmissionDetails(dealId, submissionUuid);
            return {
                submission: transformSubmissionDetailsData(data),
            };
        },
        ...queryOptions,
    },
    {
        queryKey: [...queryKey, 'history'],
        queryFn: async () => {
            const data = await apiClient.getSubmissionTransitionHistory(dealId, submissionUuid);
            return {
                history: data,
            };
        },
        ...queryOptions,
    },
    {
        queryKey: [...queryKey, 'bankDetails'],
        queryFn: async () => {
            const data = await apiClient.getDealBankDetail(dealId);
            return {
                bankDetails: data,
            };
        },
        ...queryOptions,
    },
];
export const useSubmissionsDetailsQuery = (dealId: string, submissionUuid: string) => {
    const result = useQueries({ queries: queries(dealId, submissionUuid) });
    const isLoading = result.some((result) => result.isLoading);

    if (!isLoading) {
        return {
            isLoading,
            isError: result.some((result) => result.isError),
            data: transformSubmissionInfo(result),
        };
    }
    return {
        isLoading,
        isError: result.some((result) => result.isError),
        data: SubmissionInfoInitial,
    };
};

function transformSubmissionInfo(
    result: Partial<{
        data: SubmissionInfo;
    }>[],
): SubmissionInfoData {
    const getItem = (name: string, items: Array<any>) => {
        return (
            items?.find((item) => {
                if (item.data === undefined) return false;
                return Object.keys(item.data).includes(name);
            })?.data[name] ?? {}
        );
    };
    return {
        submission: getItem('submission', result),
        history: getItem('history', result),
        bankDetails: getItem('bankDetails', result),
    };
}

export function useSubmissionOfferMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            dealUuid,
            submissionUuid,
            data,
        }: {
            dealUuid: string;
            submissionUuid: string;
            data: SubmissionOfferFormData;
        }) => await apiClient.patchSubmissionOfferDetails(dealUuid, submissionUuid, { ...data, extraData: null }),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}

export function usePostSubmissionStatusChange() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            dealUuid,
            submissionUuid,
            data,
        }: {
            dealUuid: string;
            submissionUuid: string;
            data: ChangeSubmissionsStatusProps;
        }) => await apiClient.postSubmissionStatusChange(dealUuid, submissionUuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });
            const prevData = queryClient.getQueryData(queryKey);
            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
        onError: (error: any, _, context) => {
            throw new Error(error.message);
        },
    });
}

const transformSubmissionDetailsData = (submission: GetSubmissionDetails): SubmissionView => {
    return {
        offer: transformSubmissionOffer(submission),
        status: transformSubmissionStatus(submission),
    };
};

const transformSubmissionStatus = (submission: GetSubmissionDetails) => {
    return {
        ...submission,
        createdAt: formatDate(submission.createdAt),
        clientContractedAt: formatDate(submission.clientContractedAt),
        bankRejectedAt: formatDate(submission.bankRejectedAt),
        clientRejectedAt: formatDate(submission.clientRejectedAt),
    };
};

const transformSubmissionOffer = (submission: GetSubmissionDetails) => {
    return {
        uuid: submission.uuid,
        externalId: submission.externalId,
        insuranceType: submission.insuranceType,
        lifeInsuranceFee: getAmount(submission.lifeInsuranceFee),
        loanAmount: getAmount(submission.loanAmount),
        askedAmount: getAmount(submission.askedAmount),
        nir: submission.nir,
        apr: submission.apr,
        financier: submission.financier,
        adminFee: getAmount(submission.adminFee ?? undefined),
        downPayment: getAmount(submission.downPayment ?? undefined),
        terms: submission.terms,
        monthlyFee: getAmount(submission.monthlyFee),
        commissionFee: getAmount(submission.commissionFee ?? undefined),
        commissionFeeRate: submission.commissionFeeRate,
        commitmentFee: getAmount(submission.commitmentFee ?? undefined),
        isAmountReceivedFromBank: submission.isAmountReceivedFromBank,
        isAmountTransferredToClient: submission.isAmountTransferredToClient,
        earlyTermination: submission.earlyTermination,
        refundedFinancialCommission: getAmount(submission.refundedFinancialCommission ?? undefined),
        clientInDefault: submission.clientInDefault,
    };
};

function formatDate(date: string | null): string {
    return date ? format(new Date(date), 'dd/MM/yyyy') : '-';
}
