import { z } from 'zod';
import { Feature, PaginatedList } from '../generics';

export const VehicleFeatureItem = Feature;

export const GetVehicleFeatureResponse = PaginatedList.extend({
    items: z.array(Feature),
});

export const PostFeatureResponse = Feature.omit({ code: true, value: true, slug: true });

export const PostVeicleModelFeature = z.object({
    makeUuid: z.string(),
    name: z.string(),
});

export const PostVeicleVersionFeature = z.object({
    modelUuid: z.string(),
    name: z.string(),
});

export type GetVehicleFeatureResponse = z.infer<typeof GetVehicleFeatureResponse>;
export type VehicleFeatureItem = z.infer<typeof VehicleFeatureItem>;
export type PostFeatureResponse = z.infer<typeof PostFeatureResponse>;

export type PostVeicleModelFeature = z.infer<typeof PostVeicleModelFeature>;
export type PostVeicleVersionFeature = z.infer<typeof PostVeicleVersionFeature>;
