import { FormSkeleton } from '@/components/Forms';
import { DealDetailLayout } from '@/layouts';

export function DealInfoViewSkeleton() {
    return (
        <DealDetailLayout>
            <section className="space-y-2 w-full">
                {Array.from(Array(9).keys()).map((item) => (
                    <FormSkeleton key={item} />
                ))}
            </section>
        </DealDetailLayout>
    );
}
