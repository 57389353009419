import { z } from 'zod';
import { GenericError, Measurement } from './generics';

export const lifeInsuranceType = {
    NO_INSURANCE: 'Sin seguro',
    BASIC_LIFE_INSURANCE: 'Seguro de vida básico',
    LIFE_INSURANCE_PLUS: 'Seguro de vida plus',
    GAP_INSURANCE: 'Seguro GAP',
    LIFE_INSURANCE_PLUS_GAP: 'Seguro de vida plus + GAP',
};

export enum lifeInsuranceEnum {
    NO_INSURANCE = 'Sin seguro',
    BASIC_LIFE_INSURANCE = 'Seguro de vida básico',
    LIFE_INSURANCE_PLUS = 'Seguro de vida plus',
    GAP_INSURANCE = 'Seguro GAP',
    LIFE_INSURANCE_PLUS_GAP = 'Seguro de vida plus + GAP',
}

export type LifeInsuranceEnum = keyof typeof lifeInsuranceEnum;

export const lifeInsuranceTypeEnum: readonly string[] = Object.keys(
    lifeInsuranceType,
) as (keyof typeof lifeInsuranceType)[];
export const LifeInsuranceType = z.enum([lifeInsuranceTypeEnum[0], ...lifeInsuranceTypeEnum]);

export const OfferView = z.object({
    uuid: z.string(),
    loanAmount: z.string(),
    totalAmount: z.string(),
    askedAmount: z.string(),
    loanTotalCost: z.string(),
    terms: z.number(),
    tin: z.number(), // nir
    tae: z.number(), // apr
    monthlyFee: z.string(),
    insuranceType: LifeInsuranceType.nullable(),
    insuranceFee: z.string(),
    adminFee: z.string(),
    financier: z.string(),
    isSelected: z.boolean(),
    notAllowedReason: z.string().optional().nullable(),
});

export const OfferListView = z.array(OfferView);

export const GetOfferItemResponse = z.object({
    uuid: z.string(),
    loanAmount: Measurement.nullable(),
    loanTotalCost: Measurement.nullable(),
    askedAmount: Measurement.nullable(),
    terms: z.number(),
    nir: z.number(),
    apr: z.number(),
    monthlyFee: Measurement.nullable(),
    insuranceType: LifeInsuranceType.nullable(),
    insuranceFee: Measurement.nullable(),
    commissionFee: Measurement.nullable(),
    adminFee: Measurement.nullable(),
    financier: z.string().optional(),
    isSelected: z.boolean(),
    notAllowedReason: z.string().optional().nullable(),
});

export const GetOffersResponse = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(GetOfferItemResponse),
    totalItems: z.number(),
});

export const PostOfferRequest = z.object({
    askedPvp: z.number(),
    nir: z.number(),
    terms: z.number(),
    isInsuranceNeeded: z.boolean(),
    insuranceType: LifeInsuranceType,
});

export const CalculatorFormData = z.object({
    pvpMax: z.string(),
    tin: z.number(),
    terms: z.number(),
    lifeInsurance: z.boolean(),
    insuranceType: LifeInsuranceType,
});

export const PostOfferResponse = z.array(GetOfferItemResponse);

export const PendingSubmissionsError = GenericError;

export type LifeInsuranceType = z.infer<typeof LifeInsuranceType>;
export type OfferView = z.infer<typeof OfferView>;
export type OfferListView = z.infer<typeof OfferListView>;
export type GetOffersResponse = z.infer<typeof GetOffersResponse>;
export type GetOfferItemResponse = z.infer<typeof GetOfferItemResponse>;
export type PostOfferRequest = z.infer<typeof PostOfferRequest>;
export type PostOfferResponse = z.infer<typeof PostOfferResponse>;
export type CalculatorFormData = z.infer<typeof CalculatorFormData>;
export type PendingSubmissionsError = z.infer<typeof PendingSubmissionsError>;
