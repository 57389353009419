import { useClientBasicInfoQuery } from '@/layouts/useDealBasicInfoQuery';
import { DealBasicInfo } from '@/types';
import { ListGroup } from 'flowbite-react';
import Link from 'next/link';
import React from 'react';
import { HiOutlineChat, HiOutlineMail, HiOutlinePhone, HiOutlineUser } from 'react-icons/hi';

export interface SidebarItem {
    label: string;
    url: string;
    isAction?: boolean;
    icon: React.ReactNode;
    disabled: boolean;
}

export function ClientDataModule({ dealBasicInfo }: { readonly dealBasicInfo: DealBasicInfo | undefined }) {
    const { data: clientInfo } = useClientBasicInfoQuery(dealBasicInfo?.uuid || '');

    const SideClientNavigation: SidebarItem[] = [
        {
            label: 'Aircall',
            url: `tel:${clientInfo?.phone}`,
            icon: <HiOutlinePhone className="h-4 w-4" />,
            disabled: !clientInfo?.phone,
        },
        {
            label: 'Respond.io',
            url: `https://app.respond.io/space/243850/inbox/${clientInfo?.extId}`,
            icon: <HiOutlineChat className="h-4 w-4" />,
            disabled: !clientInfo?.extId || dealBasicInfo?.tier !== 'C2C',
        },
        {
            label: 'Correo',
            url: `mailto:${clientInfo?.email}`,
            icon: <HiOutlineMail className="h-4 w-4" />,
            disabled: !clientInfo?.email,
        },
    ];

    return (
        <ListGroup className="flex flex-col gap-2 py-3 items-start min-w-[240px] ">
            <div className="flex gap-2 px-4">
                <HiOutlineUser className="p-1 w-7 h-7 bg-blue-100 rounded-md" />
                <span className="text-lg">{clientInfo?.name}</span>
            </div>
            <div className="w-full py-1">
                {SideClientNavigation.map((item) => {
                    if (item.disabled) return null;

                    return (
                        <ListGroup.Item
                            key={item.label}
                            theme={{
                                link: {
                                    base: 'w-full shadow-transparent',
                                },
                            }}
                        >
                            <Link
                                className="flex px-6 py-1 w-full items-center gap-1 text-gray-900 hover:text-blue-500"
                                href={item.url}
                                target={item.label === 'Respond.io' ? '_blank' : undefined}
                            >
                                {item.icon} {item.label}
                            </Link>
                        </ListGroup.Item>
                    );
                })}
            </div>
        </ListGroup>
    );
}
