import { useApiClient } from '@/hooks';
import {
    CatalogueVehicleSellerDetails,
    GetCatalogueVehicleBankAccountDetails,
    GetCatalogueVehicleBillingAddressDetails,
    PutCatalogueVehicleBankAccountDetails,
    PutCatalogueVehicleBillingAddressDetails,
    PutCatalogueVehicleSellerAdDetails,
    PutCatalogueVehicleSellerDetails,
    VehicleContactData,
    VehicleContactDetailsView,
} from '@/types/Vehicle';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';

const apiClient = useApiClient();
const queryKey = ['vehicleContactDetails'];

const VehicleContactInitial = {
    seller: {},
    bankAccount: {},
    billingAddress: {},
};

const queryOptions = {
    refetchOnWindowFocus: false,
    retry: 1,
};

const queries = (uuid: string) => [
    {
        queryKey: ['vehicleContactSeller', uuid],
        queryFn: async () => {
            const data = await apiClient.getCatalogueVehicleSellerDetails(uuid);
            return {
                seller: data,
            };
        },
        ...queryOptions,
    },
    {
        queryKey: ['vehicleContactBankAccount', uuid],
        queryFn: async () => {
            const data = await apiClient.getCatalogueVehicleBankAccountDetails(uuid);
            return {
                bankAccount: data,
            };
        },
        ...queryOptions,
    },
    {
        queryKey: ['vehicleContactBillingAddress', uuid],
        queryFn: async () => {
            const data = await apiClient.getCatalogueVehicleBillingAddressDetails(uuid);
            return {
                billingAddress: data,
            };
        },
        ...queryOptions,
    },
];

function transformVehicleContactData(
    result: Partial<{
        data: VehicleContactData;
    }>[],
): VehicleContactDetailsView {
    const getItem = (name: string, items: Array<any>) => {
        return (
            items?.find((item) => {
                if (item.data === undefined) return false;
                return Object.keys(item.data).includes(name);
            })?.data[name] ?? {}
        );
    };

    return {
        seller: getItem('seller', result),
        bankAccount: getItem('bankAccount', result),
        billingAddress: getItem('billingAddress', result),
    };
}

export const useCatalogueVehicleContactPageData = (uuid: string) => {
    const result = useQueries({ queries: queries(uuid) });
    const isLoading = result.some((result) => result.isLoading);

    if (!isLoading) {
        return {
            isLoading,
            isError: result.some((result) => result.isError),
            data: transformVehicleContactData(result),
        };
    }
    return {
        isLoading,
        isError: result.some((result) => result.isError),
        data: VehicleContactInitial,
    };
};

function updateContactDetails<K, T>(queryKey: string[], queryFn: (uuid: string, data: K) => Promise<T>) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ uuid, data }: { uuid: string; data: K }) => queryFn(uuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err: any, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}

export function useGetCatalogueVehicleSellerDetails(vehicleUuid: string) {
    return useQuery<CatalogueVehicleSellerDetails>({
        queryKey,
        queryFn: async () => await apiClient.getCatalogueVehicleSellerDetails(vehicleUuid),
        retry: 1,
    });
}

export function usePutCatalogueVehicleSellerAdDetailsMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ uuid, data }: { uuid: string; data: PutCatalogueVehicleSellerAdDetails }) =>
            await apiClient.putCatalogueVehicleSellerAdDetails(uuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });
            const prevData = queryClient.getQueryData(queryKey);
            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
            await queryClient.invalidateQueries({ queryKey: ['vehicleDetails'] });
        },
        onError: (error: any, _, context) => {
            throw new Error(error.message);
        },
    });
}

export const updateCatalogueVehicleSellerDetails = () =>
    updateContactDetails<PutCatalogueVehicleSellerDetails, CatalogueVehicleSellerDetails>(
        ['vehicleContactSeller'],
        apiClient.putCatalogueVehicleSellerDetails,
    );
export const updateCatalogueVehicleBankAccountDetails = () =>
    updateContactDetails<PutCatalogueVehicleBankAccountDetails, GetCatalogueVehicleBankAccountDetails>(
        ['vehicleContactBankAccount'],
        apiClient.putCatalogueVehicleBankAccountDetails,
    );
export const updateCatalogueVehicleBillingAddressDetails = () =>
    updateContactDetails<PutCatalogueVehicleBillingAddressDetails, GetCatalogueVehicleBillingAddressDetails>(
        ['vehicleContactBillingAddress'],
        apiClient.putCatalogueVehicleBillingAddressDetails,
    );
