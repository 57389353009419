import * as React from 'react';
import { HiOutlineCalendar } from 'react-icons/hi';
import { format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { es } from 'date-fns/locale';

import { Label } from 'flowbite-react';
import { ForwardedRef } from 'react';
import { DatePickerProps } from '@mui/x-date-pickers';
import { FieldError } from 'react-hook-form';
import { BsAsterisk } from 'react-icons/bs';
import { Calendar } from '@/components/Calendar';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover';

interface InputProps extends DatePickerProps<any> {
    id?: string;
    error?: FieldError;
    required?: boolean;
    from?: Date;
    to?: Date;
    onChange: (date: (Date | undefined)[]) => void;
}

export const DateRangeInput = React.forwardRef((props: InputProps, ref: ForwardedRef<any>) => {
    const date = [props.from, props.to];

    const LabelTheme = {
        root: {
            base: `text-xs font-normal ${props.disabled && 'text-gray-300'}`,
        },
    };

    return (
        <div>
            <Label theme={LabelTheme} htmlFor={props.id} className="w-full" color={props.error && 'failure'}>
                <div className="flex gap-1 items-center">
                    {props.label}
                    {props.required && <BsAsterisk className={props.error ? 'text-red-500' : 'text-blue-600'} />}
                </div>
            </Label>
            <div className={`text-sm py-0.5 ps-2 bg-gray-50 border-1 border-gray-300 rounded-lg ${props.className}`}>
                <Popover>
                    <PopoverTrigger asChild>
                        <div
                            className={`flex items-center justify-between cursor-text ${
                                !date && 'text-muted-foreground'
                            }`}
                        >
                            {date[0] ? (
                                date[1] ? (
                                    <>
                                        {format(date[0], 'dd/MM/Y')} – {format(date[1], 'dd/MM/Y')}
                                    </>
                                ) : (
                                    format(date[0], 'dd/MM/Y')
                                )
                            ) : (
                                <span>Rango de fechas</span>
                            )}
                            <HiOutlineCalendar className="mr-2 h-8 w-8 text-gray-500 p-2 hover:bg-gray-100 hover:rounded-full cursor-pointer" />
                        </div>
                    </PopoverTrigger>
                    <PopoverContent className="bg-white w-auto p-0 mt-2" align="start">
                        <Calendar
                            locale={es}
                            initialFocus
                            mode="range"
                            defaultMonth={date[0]}
                            selected={{
                                from: date[0],
                                to: date[1],
                            }}
                            onSelect={(date: DateRange | undefined) => {
                                props.onChange([date?.from, date?.to]);
                            }}
                            numberOfMonths={2}
                        />
                    </PopoverContent>
                </Popover>
            </div>
        </div>
    );
});
