import { useApiClient } from '@/hooks/useApiClient';
import { useUploadAttachment } from '@/hooks/useUploadAttachment';
import { getTotalPages } from '@/lib/utils/helpers';
import { NOTES_PER_PAGE } from '@/pages/deals/[uuid]/notes';
import { EditDealNoteRequest, GetNotesResponse, NoteFilters, NotesPageData } from '@/types';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const queryKey = ['notes'];
const apiClient = useApiClient();

export function useNotesQuery(uuid: string, filters: Partial<NoteFilters>) {
    return useQuery({
        queryKey: [...queryKey, filters.page],
        queryFn: () => apiClient.getDealNotes(uuid, { ...filters }),
        select: (data: GetNotesResponse) => transformNotesData(data),
        placeholderData: keepPreviousData,
        enabled: !!uuid,
        refetchOnWindowFocus: false,
    });
}

export function usePinnedNotesQuery(uuid: string, filters: Partial<NoteFilters>) {
    return useQuery({
        queryKey: ['pinnedNotes', uuid],
        queryFn: () => apiClient.getPinnedDealNotes(uuid, { ...filters }),
        select: (data: GetNotesResponse) => data,
        enabled: !!uuid,
        refetchOnWindowFocus: false,
    });
}

function transformNotesData(data: GetNotesResponse): NotesPageData {
    return {
        items: data.items,
        pages: getTotalPages(data.totalItems, NOTES_PER_PAGE),
        offset: data.offset,
        totalItems: data.totalItems,
    };
}

export function useNotesPageData(dealId: string, filters: Partial<NoteFilters>) {
    const {
        data: notesData,
        isLoading: isNotesLoading,
        isError: notesError,
    } = useNotesQuery(dealId, {
        ...filters,
        max: NOTES_PER_PAGE,
    });

    const {
        data: pinnedNotesData,
        isLoading: isPinnedNotesLoading,
        isError: pinnedNotesError,
    } = usePinnedNotesQuery(dealId, { max: 200 });

    return {
        notesData,
        pinnedNotesData,
        isLoading: isNotesLoading || isPinnedNotesLoading,
        isError: notesError || pinnedNotesError,
    };
}

export function useNotesFiltersMutation() {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (variables: { uuid: string; filters: NoteFilters }) => {
            const { uuid, filters } = variables;
            const data = await apiClient.getDealNotes(uuid, {
                ...filters,
                max: NOTES_PER_PAGE,
            });
            return data;
        },
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });
            const prevData = queryClient.getQueryData(queryKey);
            return { prevData };
        },
        onError: (err: any, _: any, context: any) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async (data: any) => {
            queryClient.setQueriesData({ queryKey }, data);
        },
    });
}

export function usePostNoteMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ dealUuid, data }: { dealUuid: string; data: EditDealNoteRequest }) =>
            await apiClient.postDealNotes(dealUuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}

export function usePutNotePinnedMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            dealUuid,
            noteUuid,
            isPinned,
        }: {
            dealUuid: string;
            noteUuid: string;
            isPinned: boolean;
        }) => await apiClient.putNotePinned(dealUuid, noteUuid, isPinned),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
            await queryClient.invalidateQueries({ queryKey: ['pinnedNotes'] });
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}

export function usePatchNoteMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            dealUuid,
            noteUuid,
            data,
        }: {
            dealUuid: string;
            noteUuid: string;
            data: EditDealNoteRequest;
        }) => await apiClient.patchDealNotes(dealUuid, noteUuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
            await queryClient.invalidateQueries({ queryKey: ['pinnedNotes'] });
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}

export function useDeleteNoteMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ dealUuid, noteUuid }: { dealUuid: string; noteUuid: string }) =>
            await apiClient.deleteNote(dealUuid, noteUuid),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
            await queryClient.invalidateQueries({ queryKey: ['pinnedNotes'] });
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}

export function useUploadAttachmentMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ dealUuid, noteUuid, data }: { dealUuid: string; noteUuid: string; data: File[] }) =>
            await useUploadAttachment(dealUuid, noteUuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSettled: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries({ queryKey });
            }, 1000);
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}

export function useDeleteNoteAttachmentMutation() {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({
            dealUuid,
            noteUuid,
            attachmentUuid,
        }: {
            dealUuid: string;
            noteUuid: string;
            attachmentUuid: string;
        }) => await apiClient.deleteNoteAttachment(dealUuid, noteUuid, attachmentUuid),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}
