import { DealDetailLayout } from '@/layouts';
import { DealNotesView, DealNotesViewSkeleton } from '@/views/deals/details/notes';
import { useUuid } from '@/hooks/useUuid';
import { useNotesFiltersMutation, useNotesPageData } from '@/views/deals/details/notes/useNotesQuery';
import { NoteFilters } from '@/types';
import { useUrlQueryParams } from '@/hooks/useUrlQueryParams';
import { getOffset } from '@/views/operations/helpers';

export const NOTES_PER_PAGE = 25;

export default function DealNotes() {
    const dealUuid = useUuid();

    const { queryParams, setQueryParams } = useUrlQueryParams<NoteFilters>();

    const { notesData, pinnedNotesData, isLoading, isError } = useNotesPageData(dealUuid, {
        ...queryParams,
        offset: getOffset(NOTES_PER_PAGE, queryParams.page ? parseInt(queryParams.page) : 1),
    });
    const { mutateAsync: filterNotes } = useNotesFiltersMutation();

    async function handlePageChange(page: number) {
        setQueryParams({ ...queryParams, page: page.toString() });
        await filterNotes({ uuid: dealUuid, filters: { ...queryParams, offset: getOffset(NOTES_PER_PAGE, page) } });
    }

    if (isLoading || isError || !notesData || !pinnedNotesData) {
        return <DealNotesViewSkeleton />;
    }

    return (
        <DealDetailLayout>
            <DealNotesView
                notes={notesData}
                pinnedNotes={pinnedNotesData}
                handlePageChange={handlePageChange}
                queryParams={queryParams}
            />
        </DealDetailLayout>
    );
}
