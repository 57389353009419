import { Breadcrumb as FBreadcrumb, BreadcrumbComponentProps, BreadcrumbItemProps } from 'flowbite-react';
import Link from 'next/link';

export const Breadcrumb = (props: BreadcrumbComponentProps) => (
    <FBreadcrumb theme={{ list: 'gap-2 flex items-center text-gray-500' }} {...props} />
);

const BreadcrumbItem = ({ href, children }: BreadcrumbItemProps) => (
    <>
        {href ? (
            <Link href={href}>
                <FBreadcrumb.Item
                    theme={{
                        base: 'flex items-center gap-2 font-normal',
                    }}
                >
                    {children}
                </FBreadcrumb.Item>
            </Link>
        ) : (
            <FBreadcrumb.Item
                theme={{
                    base: 'flex items-center gap-2 font-normal',
                }}
            >
                {children}
            </FBreadcrumb.Item>
        )}
    </>
);
Breadcrumb.Item = BreadcrumbItem;
