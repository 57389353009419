import { PreOfferView } from '@/types';
import { HiOutlineCalendar, HiOutlineClipboardCheck, HiOutlineCurrencyEuro, HiOutlineReceiptTax } from 'react-icons/hi';
import { formatPercentage } from '@/lib/utils/amount';
import { Accordion } from '@/components/Accordion';

interface OfferSectionProps {
    readonly preOffer: PreOfferView;
}

export function OfferSection({ preOffer }: OfferSectionProps) {
    if (!preOffer) {
        return null;
    }

    const preOfferCards = [
        {
            label: 'Ask',
            icon: <HiOutlineCurrencyEuro className="h-5 w-5" />,
            value: preOffer.ask,
        },
        {
            label: 'Cuota mensual',
            icon: <HiOutlineCurrencyEuro className="h-5 w-5" />,
            value: preOffer.due,
        },
        {
            label: 'TIN/TAE',
            icon: <HiOutlineReceiptTax className="h-5 w-5" />,
            value: `${formatPercentage(preOffer.tin)} / ${formatPercentage(preOffer.tae)}`,
        },
        {
            label: 'Precio venta cliente',
            icon: <HiOutlineCurrencyEuro className="h-5 w-5" />,
            value: preOffer.pvp,
        },
        {
            label: 'Meses',
            icon: <HiOutlineCalendar className="h-5 w-5" />,
            value: preOffer.term,
        },
        {
            label: 'Coste total crédito',
            icon: <HiOutlineClipboardCheck className="h-5 w-5" />,
            value: preOffer.total,
        },
    ];

    return (
        <Accordion title="Pre-Oferta">
            <div className="grid grid-cols-3 gap-3 p-5">
                {preOfferCards.map((item) => (
                    <div key={item.label} className="flex items-center gap-4 rounded-lg border border-gray-300 p-3">
                        <div className="flex h-8 w-8 items-center justify-center rounded-lg bg-blue-100 p-1">
                            {item.icon}
                        </div>
                        <div className="flex flex-col">
                            <span className="text-xs text-gray-500">{item.label}</span>
                            <span className=" text-sm font-bold text-gray-900">{item.value}</span>
                        </div>
                    </div>
                ))}
            </div>
        </Accordion>
    );
}
