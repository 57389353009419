import { Badge } from '@/components/Badge';
import { DropdownButton } from '@/components/Button';
import { DealAssignDialog } from '@/components/Dialog/DealAssignDialog';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { useToast } from '@/hooks';
import {
    useDealAssignedAgentMutation,
    useDealSituationMutation,
    useDealStageMutation,
    usePatchDealMutation,
} from '@/layouts/useDealBasicInfoQuery';
import { situationColors } from '@/lib/shared/DealsData';
import { throwConfetti } from '@/lib/utils/confettiAnimation';
import { Roles, useProfileStore } from '@/stores';
import { Agent, Stage, Submission } from '@/types';
import { GetCatalogueVehicleDetails } from '@/types/Vehicle';
import { DealBasicInfo, LostReason, SituationC2C, allC2CLostReasons, situationsC2C, stagesC2C } from '@/types/deals';
import { useUser } from '@auth0/nextjs-auth0/client';
import { differenceInMonths, differenceInSeconds, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { HiOutlineStar, HiStar } from 'react-icons/hi';
import { DealLostDialog } from '../Dialog';
import { DateInput } from '../Inputs';

export function DealDetailHeaderC2C({
    info,
    catalogueVehicleDetails,
    submissions,
}: {
    readonly info: DealBasicInfo;
    readonly catalogueVehicleDetails?: GetCatalogueVehicleDetails;
    readonly submissions?: Submission[];
}) {
    const { user } = useUser();
    const { isAdmin, hasRequiredRoles } = useProfileStore();
    const { errorToast, successToast } = useToast();
    const { mutateAsync: updateSituation } = useDealSituationMutation();
    const { mutateAsync: updateStage } = useDealStageMutation();
    const { mutateAsync: patchDeal } = usePatchDealMutation();
    const { mutateAsync: updateAssignedAgent } = useDealAssignedAgentMutation();
    const dialog = useDialog();

    const handleSelectDealStage = async (stage: Stage) => {
        try {
            await updateStage({ dealId: info.uuid, stage });
        } catch (e: any) {
            errorToast(e.message);
        }
    };

    const handleChangeWonTime = async (date: Date) => {
        try {
            if (isNaN(date.getTime())) {
                return;
            }
            if (differenceInMonths(new Date(), date) > 2 || differenceInSeconds(new Date(), date) < 0) {
                errorToast('La fecha debe tener dos meses de antigüedad como máximo');
                return;
            }
            await patchDeal({
                dealUuid: info.uuid,
                data: { firstWonTime: zonedTimeToUtc(date, 'UTC').toDateString() },
            });
            successToast('La fecha de vendido se ha actualizado correctamente');
        } catch (e: any) {
            errorToast(e.message);
        }
    };

    const handleSelectSituation = async (situation: SituationC2C, lostReason?: LostReason) => {
        try {
            await updateSituation({
                dealId: info.uuid,
                situation,
                lostReason,
            });
            if (situation === 'WON') {
                throwConfetti();
            }
        } catch (e: any) {
            errorToast(e.message);
        }
    };

    const handleSelectLostReason = async (reason: LostReason) => {
        try {
            await handleSelectSituation('LOST', reason);
        } catch (e: any) {
            errorToast(e.message);
        }
    };

    const handleOpenLostSituationModal = () => {
        dialog({
            type: 'modal',
            content: (
                <DealLostDialog
                    onConfirm={handleSelectLostReason}
                    tier={info?.tier}
                    status={catalogueVehicleDetails?.status}
                    submissions={submissions}
                />
            ),
        });
    };

    const handleSwitchStarred = async (uuid: string, starred: boolean | undefined) => {
        try {
            await patchDeal({
                dealUuid: uuid,
                data: { isStarred: !starred },
            });
        } catch (e: any) {
            errorToast(e.message);
        }
    };

    const displayDropdown = () => {
        if (info.stage && info.situation === 'OPEN') {
            return (
                <DropdownButton
                    variant="outline"
                    color={situationColors[info.situation]}
                    size="xs"
                    label={stagesC2C[info.stage.stage as keyof typeof stagesC2C] ?? info.stage.stage}
                    direction="left"
                >
                    <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                        {Object.entries(stagesC2C)
                            .filter((e) => !['LEAD_CREATED', 'DEAL_WON', 'DEAL_LOST', info.stage?.stage].includes(e[0]))
                            .map(([key, value]) => (
                                <li
                                    key={key}
                                    className="block px-4 py-2 hover:bg-gray-100"
                                    onClick={() => handleSelectDealStage(key as Stage)}
                                >
                                    {value}
                                </li>
                            ))}
                    </ul>
                </DropdownButton>
            );
        }

        if (info.situation === 'LOST' && info.lostTime) {
            return (
                <>
                    <Badge className="min-w-[96px] py-2 px-3" color={situationColors[info.situation ?? 'OPEN']}>
                        {allC2CLostReasons[info.lostReason as keyof typeof allC2CLostReasons]}
                    </Badge>
                    <p className="text-gray-500 text-sm">
                        Fecha descarte: {format(new Date(info.lostTime), 'dd/MM/yyyy')}
                    </p>
                </>
            );
        }

        if (info.situation === 'WON' && info.wonTime) {
            return (
                <div className="ps-4">
                    {isAdmin() ? (
                        <DateInput
                            label="Fecha vendido:"
                            value={new Date(info.wonTime)}
                            onChange={handleChangeWonTime}
                            disableFuture
                        />
                    ) : (
                        <p className="text-gray-500 text-sm">
                            Fecha vendido: {format(new Date(info.wonTime), 'dd/MM/yyyy')}
                        </p>
                    )}
                </div>
            );
        }
    };

    const handleOpenAssignModal = async (roles: Roles, agent: Agent | null, selfAssign?: boolean) => {
        dialog({
            type: 'modal',
            content: (
                <DealAssignDialog
                    role={roles}
                    agent={agent}
                    onConfirm={handleAssignAgentToDeal}
                    selfAssign={selfAssign}
                />
            ),
        });
    };

    const handleAssignAgentToDeal = async (agentUuid: string | null) => {
        try {
            await updateAssignedAgent({
                dealId: info.uuid,
                agents: {
                    certifierAgentExternalId: null,
                    financeAgentExternalId: null,
                    salesAgentExternalId: agentUuid,
                },
            });
            dialog({
                type: 'toast',
                variant: 'success',
                content: info.salesAgent ? 'Asignación quitada con éxito 👍' : 'Deal asignado con éxito 👍',
            });
        } catch (e: any) {
            errorToast(e.message);
        }
    };

    return (
        <section className="w-full flex justify-between items-center bg-gray-50 ps-5 pe-2 py-3 border-t-1">
            <div className="flex items-center gap-8">
                <div className="flex gap-2 items-center">
                    {info.situation && (
                        <DropdownButton
                            color={situationColors[info.situation]}
                            variant="flat"
                            size="xs"
                            label={situationsC2C[info.situation as keyof typeof situationsC2C] ?? ''}
                            direction="left"
                        >
                            <ul className="cursor-pointer py-1 text-sm text-gray-700">
                                {info.situation === 'OPEN' ? (
                                    <>
                                        <button
                                            className="block px-4 py-2 hover:bg-gray-100 w-full text-start"
                                            onClick={() => handleSelectSituation('WON')}
                                        >
                                            Mover a Vendido
                                        </button>
                                        <button
                                            className="block px-4 py-2 hover:bg-gray-100 w-full text-start"
                                            onClick={() => handleOpenLostSituationModal()}
                                        >
                                            Mover a Descartado
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        className="block px-4 py-2 hover:bg-gray-100 w-full text-start"
                                        onClick={() => handleSelectSituation('OPEN')}
                                    >
                                        Reabrir
                                    </button>
                                )}
                            </ul>
                        </DropdownButton>
                    )}
                    {displayDropdown()}
                </div>
                <button
                    className={`cursor-default py-1 px-2 font-normal
                    ${hasRequiredRoles([Roles.DELIVERIES]) && 'hover:bg-gray-200 hover:cursor-pointer hover:rounded-lg'}`}
                    onClick={() => {
                        if (hasRequiredRoles([Roles.DELIVERIES])) {
                            handleOpenAssignModal(Roles.C2C_SALES_AGENT, info.salesAgent ?? null);
                        }
                    }}
                >
                    <span className="m-auto">🗣️</span>
                    {info.salesAgent?.name ? (
                        <span className="font-semibold">{info.salesAgent?.name}</span>
                    ) : (
                        <span className="text-sm"> Sin Asignar</span>
                    )}
                </button>
            </div>
            <div className="flex gap-2">
                <div className="flex flex-col gap-1 items-end">
                    <div className="flex place-items-center gap-2">
                        <span className="text-gray-500 text-xs font-medium">
                            Deal ID: <span className=" text-sm font-bold text-gray-900 ">{info.id}</span>
                        </span>
                    </div>
                    <span className="text-gray-500 text-xs font-medium">
                        Creación:{' '}
                        <span className=" text-sm  ">
                            {info.createdAt && format(new Date(info.createdAt), 'dd/MM/yyyy - HH:mm')}
                        </span>
                    </span>
                </div>
                <div className="flex items-center">
                    <button
                        className="p-2 hover:bg-gray-200 rounded-lg"
                        onClick={() => handleSwitchStarred(info.uuid, info.isStarred)}
                    >
                        {info.isStarred ? (
                            <HiStar className="w-5 h-5 text-blue-500"></HiStar>
                        ) : (
                            <HiOutlineStar className="w-5 h-5 text-gray-400"></HiOutlineStar>
                        )}
                    </button>
                </div>
            </div>
        </section>
    );
}
