import { z } from 'zod';

export const AlertDetails = z.object({
    date: z.string().optional(),
    numberDays: z.number().optional(),
    registrationCity: z.string().optional(),
    numberOwners: z.number().optional(),
    type: z.string().optional(),
});

const alertStatus = z.enum(['OK', 'WARNING', 'DANGER']);
const alertGroup = z.enum(['CAR', 'FRAUD']);
const alertType = z.enum([
    'ACCIDENT',
    'CHASSIS_MODS',
    'DAMAGE',
    'INCONSISTENT_MILEAGE',
    'ITV_FAIL',
    'PROVINCE',
    'CAR_IMPORT',
    'CAR_OWNERS',
    'CAR_STOLEN',
    'CHANGE_OWNERSHIP',
    'INSURANCE',
    'LEASING',
    'RENTING',
    'TAXI',
    'USE_TYPE',
]);

export const Alert = z.object({
    status: alertStatus,
    group: alertGroup,
    type: alertType,
    details: AlertDetails.nullable(),
});

export const GetAlertsResponse = z
    .object({
        max: z.number(),
        offset: z.number(),
        totalItems: z.number(),
        items: z.array(Alert),
    })
    .required();

export const AlertsPageData = z.object({
    fraudAlerts: z.array(Alert),
    carAlerts: z.array(Alert),
});

export type AlertDetails = z.infer<typeof AlertDetails>;
export type Alert = z.infer<typeof Alert>;
export type GetAlertsResponse = z.infer<typeof GetAlertsResponse>;
export type AlertsPageData = z.infer<typeof AlertsPageData>;
export type AlertStatus = z.infer<typeof alertStatus>;
export type AlertGroup = z.infer<typeof alertGroup>;
export type AlertType = z.infer<typeof alertType>;
