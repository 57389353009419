import { cva } from 'class-variance-authority';
import { PropsWithChildren } from 'react';

export type BadgeColor = 'red' | 'yellow' | 'green' | 'blue' | 'gray' | 'indigo' | 'orange';
type BadgeProps = {
    className?: string;
    color: BadgeColor;
};

const badge = cva(
    'px-2 py-1 text-xs text-center rounded-lg flex items-center justify-center font-medium min-w-fit min-h-[2rem] ',
    {
        variants: {
            color: {
                red: 'bg-pink-100 text-pink-800',
                yellow: 'bg-yellow-100 text-yellow-800',
                green: 'bg-green-100 text-green-800',
                blue: 'bg-blue-100 text-blue-800',
                gray: 'bg-gray-100 text-gray-800',
                indigo: 'bg-indigo-100 text-indigo-800',
                orange: 'bg-orange-100 text-orange-800',
            },
        },
        defaultVariants: {
            color: 'red',
        },
    },
);

export function Badge({ className, children, color }: PropsWithChildren<BadgeProps>) {
    return <div className={`${badge({ color })} ${className}`}>{children}</div>;
}
