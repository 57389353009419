import { AccordionSkeleton } from '@/components/Accordion';
import { TableSkeleton } from '@/components/Table';
import { MainLayout } from '@/layouts';

export function DealListViewSkeleton() {
    return (
        <MainLayout className="max-w-screen p-6">
            <h1 className="font-semibold text-2xl pb-6">Negocios</h1>
            <AccordionSkeleton />
            <TableSkeleton cols={5} rows={8} />
        </MainLayout>
    );
}
