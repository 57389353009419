import { AccordionSkeleton } from '@/components/Accordion';
import { CalculatorFormSkeleton } from '@/views/deals/details/offer/forms';
import { OfferCardSkeleton } from '@/views/deals/details/offer/cards';
import { DealDetailLayout } from '@/layouts';

export function DealOfferViewSkeleton() {
    return (
        <DealDetailLayout>
            <div className="w-full space-y-6">
                <section className="space-y-6">
                    <AccordionSkeleton />
                    <AccordionSkeleton columns={2} cards={4} />
                </section>
                <section className="flex w-full space-x-6">
                    <div>
                        <CalculatorFormSkeleton />
                    </div>
                    <div className="flex-1 space-y-6">
                        {Array.from(Array(3).keys()).map((_) => (
                            <OfferCardSkeleton key={_} />
                        ))}
                    </div>
                </section>
            </div>
        </DealDetailLayout>
    );
}
