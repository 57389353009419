import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { mainStatuses, Submission, subStatuses } from '@/types/submission';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Badge } from 'flowbite-react';
import router from 'next/router';
import { BadgeColor } from '../Badge';

const currecyMap = {
    EUR: '€',
    USD: '$',
    NONE: '',
};

const InfoCard = ({
    variant,
    title,
    badgeText,
    badgeColor,
}: {
    variant: 'vertical' | 'horizontal' | 'amountBreakdown';
    title: string;
    badgeText: string;
    badgeColor: BadgeColor;
}) => {
    if (variant === 'horizontal') {
        return (
            <div className="w-full flex items-center gap-4 rounded-lg border border-gray-300 p-3">
                <div className="w-full flex justify-between gap-2 items-center">
                    <span className="font-light text-gray-500">{title}</span>
                    <Badge
                        className="min-w-[144px] min-h-[32px] flex justify-center truncate rounded-lg"
                        color={badgeText === '' ? '' : badgeColor}
                    >
                        {badgeText === '' ? 'Sin estado' : badgeText}
                    </Badge>
                </div>
            </div>
        );
    }

    if (variant === 'vertical') {
        return (
            <div className="w-full flex flex-col justify-between gap-2 items-center">
                <span className="font-light text-gray-500">{title}</span>
                <Badge
                    className="min-w-[144px] min-h-[32px] flex justify-center truncate rounded-lg"
                    color={badgeText === '' ? '' : badgeColor}
                >
                    {badgeText === '' ? 'Sin estado' : badgeText}
                </Badge>
            </div>
        );
    }

    if (variant === 'amountBreakdown') {
        return (
            <div className="w-full flex items-center gap-4 rounded-lg border border-gray-300 p-3">
                <div className="w-full flex justify-between gap-2 items-center">
                    <span className="font-light text-gray-500">{title}</span>
                    <Badge
                        className="min-w-[144px] min-h-[32px] flex justify-center truncate rounded-lg"
                        color={badgeText === '' ? '' : badgeColor}
                    >
                        {badgeText === '' ? 'Sin estado' : badgeText}
                    </Badge>
                </div>
            </div>
        );
    }

    return null;
};

export function SubmissionCard({
    submission,
    variant,
}: {
    readonly submission: Submission;
    readonly variant: 'vertical' | 'horizontal' | 'amountBreakdown';
}) {
    return (
        <Card>
            <div className="flex items-center justify-between">
                <img
                    src={'/images/banks/' + submission.financier + '.png'}
                    alt={submission.financier}
                    className="h-4 object-contain"
                />
                <span className="text-xl font-bold text-gray-900">
                    {submission.loanAmount.amount.toLocaleString('es-ES')}{' '}
                    {currecyMap[(submission.loanAmount.currency ?? 'NONE') as keyof typeof currecyMap]}
                </span>
            </div>

            <div
                className={variant === 'horizontal' ? 'grid grid-cols-2 gap-4' : 'flex flex-col justify-between gap-4'}
            >
                <InfoCard
                    variant={variant}
                    title="Estado"
                    badgeText={mainStatuses[submission.mainStatus] ?? ''}
                    badgeColor="indigo"
                />
                <InfoCard
                    variant={variant}
                    title="Subestado"
                    badgeText={subStatuses[submission.subStatus ?? 'null'] ?? ''}
                    badgeColor="green"
                />
            </div>

            <div className="flex justify-between items-center">
                <span className="text-xs text-gray-800 font-medium">
                    {format(new Date(submission.createdAt), 'dd/MM/yyyy - HH:mm', { locale: es })}
                </span>
                <Button
                    className="h-[31px] w-14 font-medium"
                    size="xs"
                    type={'button'}
                    variant={'outline'}
                    color={'gray'}
                    onClick={() => router.push(`/deals/${router.query.uuid}/submissions/${submission.uuid}`)}
                >
                    Ver
                </Button>
            </div>
        </Card>
    );
}
