import { DealBasicInfo, OfferView, StagesC2CKeys } from '@/types';
import { HiCheckCircle, HiOutlineClock } from 'react-icons/hi';
import { Roles, useProfileStore } from '@/stores';
import { Button } from '@/components/Button';
import { Card } from '@/components/Card';
import { useDealStageMutation } from '@/layouts/useDealBasicInfoQuery';
import { useToast, useUuid } from '@/hooks';
import { isBeforeDealCreatedC2C } from '@/views/deals/details/offer/sections';

interface OfferStatusCardProps {
    readonly dealBasicInfo: DealBasicInfo | undefined;
    readonly filteredOffers: OfferView[];
}

export function OfferStatusCard({ dealBasicInfo, filteredOffers }: OfferStatusCardProps) {
    const uuid = useUuid();
    const { errorToast } = useToast();
    const { hasRequiredRoles } = useProfileStore();
    const { mutateAsync: updateStage } = useDealStageMutation();
    const moveStageToStartFinance = async () => {
        try {
            await updateStage({ dealId: uuid, stage: 'DEAL_CREATED' });
        } catch (e: any) {
            errorToast(e.message);
        }
    };

    return (
        <Card>
            <div className="flex items-center justify-between">
                <div className="flex gap-2 items-center">
                    {isBeforeDealCreatedC2C(dealBasicInfo?.stage?.stage as StagesC2CKeys) ? (
                        <HiOutlineClock className="h-9 w-9 text-gray-900 p-2 bg-blue-100 rounded-lg" />
                    ) : (
                        <HiCheckCircle className="h-9 w-9 text-green-500 p-2 bg-green-100 rounded-lg" />
                    )}
                    {isBeforeDealCreatedC2C(dealBasicInfo?.stage?.stage as StagesC2CKeys) ? (
                        <span>
                            {filteredOffers.length > 0
                                ? 'Pendiente de que el cliente acepte la oferta'
                                : 'Aún no se han calculado ofertas para este cliente'}
                        </span>
                    ) : (
                        <span>El cliente ha aceptado la oferta</span>
                    )}
                </div>

                {hasRequiredRoles([Roles.SALES_AGENT]) && (
                    <div>
                        {isBeforeDealCreatedC2C(dealBasicInfo?.stage?.stage as StagesC2CKeys) &&
                            filteredOffers.length > 0 && (
                                <Button color="gray" size="small" variant="outline" onClick={moveStageToStartFinance}>
                                    Aceptar oferta
                                </Button>
                            )}
                    </div>
                )}
            </div>
        </Card>
    );
}
