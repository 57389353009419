import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Checkbox } from '@/components/Inputs';
import { FormProps } from '@/types';
import { ContractInfo } from '@/types/Deal';
import { useForm } from 'react-hook-form';

export function ContractingInfoForm({ contractInfo, onSuccess, onError }: FormProps & { contractInfo: ContractInfo }) {
    const {
        register,
        formState: { isDirty, isSubmitting },
    } = useForm({
        values: contractInfo,
        mode: 'onSubmit',
    });

    return (
        <div className="space-y-2">
            <Accordion title="Contrataciones" className="bg-gray-100">
                <form
                    className="grid grid-cols-2 gap-10 p-5"
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <div className="flex flex-col text-xs space-y-6 bg-indigo-50">
                        <span>A contratar</span>
                        <ul className="flex flex-col text-xs space-y-6">
                            <li>
                                <Checkbox label="Mantenimiento" {...register('maintenance')} />
                            </li>
                            <li>
                                <Checkbox label="Garantía" {...register('warranty')} />
                            </li>
                        </ul>
                    </div>

                    <div className="flex flex-col text-xs space-y-6  bg-indigo-50">
                        <span>Agente certificador</span>
                        <ul className="flex flex-col text-xs space-y-6">
                            <li>
                                <Checkbox label="Nombre Persona" {...register('certifyingAgent')} />
                            </li>
                        </ul>
                    </div>

                    <div className="grid col-span-2 grid-cols-6 gap-10">
                        <Button
                            disabled={!isDirty || isSubmitting}
                            className="col-span-2 col-start-5"
                            type="submit"
                            color="blue"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Accordion>
        </div>
    );
}
