import { Tier } from '@/types';
import { Roles } from '@/stores';

export function getAgentRoleForTier(selectedDealTier: Tier) {
    switch (selectedDealTier) {
        case 'C2C':
            return Roles.C2C_SALES_AGENT;
        case 'RENT':
            return Roles.SALES_AGENT;
        case 'PAID':
            return Roles.SALES_AGENT;
        default:
            return [Roles.SALES_AGENT, Roles.C2C_SALES_AGENT];
    }
}
