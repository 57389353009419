import { Button } from '@/components/Button';
import { LostReason, Submission, lostReasonsC2C, lostReasonsCommon, lostReasonsPaid, lostReasonsRent } from '@/types';
import { CatalogueVehicleMainStatuses } from '@/types/Vehicle';
import { useState } from 'react';
import { DialogDismissButton, handleDismissModal, useDialogHandler } from './DialogHandler';

export function DealLostDialog({
    onConfirm,
    tier,
    status,
    submissions,
}: {
    readonly onConfirm: (value: LostReason) => void;
    readonly tier?: string | null;
    readonly status?: CatalogueVehicleMainStatuses;
    readonly submissions?: Submission[];
}) {
    const { state, setState } = useDialogHandler();
    const [reason, setReason] = useState<LostReason>();
    const handleConfirmModal = (reason?: LostReason) => {
        if (reason) {
            onConfirm(reason);
        }
        handleDismissModal(state, setState);
    };

    let lostReasons;
    switch (tier) {
        case 'PAID':
            lostReasons = { ...lostReasonsPaid, ...lostReasonsCommon };
            break;
        case 'RENT':
            lostReasons = { ...lostReasonsRent, ...lostReasonsCommon };
            break;
        case 'C2C':
            lostReasons = { ...lostReasonsC2C };
            if (status !== 'UNPUBLISHED') {
                const { UNPUBLISHED_CAR, ...rest } = lostReasons;
                lostReasons = { ...rest };
            }
            if (!submissions?.some((submission) => submission.status === 'REJECTED')) {
                const { REJECTED_BY_BANK, ...rest } = lostReasons;
                lostReasons = { ...rest };
            }
            break;
        default:
            lostReasons = { ...lostReasonsCommon };
    }

    return (
        <div className="fixed z-[99999] flex h-auto min-h-screen w-screen items-center justify-center bg-gray-900/50 text-black">
            <div className="absolute flex flex-col rounded-lg bg-white  opacity-100">
                <div className="flex items-center p-6">
                    <h2 className="flex-1 text-lg font-semibold">Motivo de perdido</h2>
                    <DialogDismissButton />
                </div>
                <div className="mx-auto border-y border-y-gray-300">
                    <div className="min-w-[420px] space-y-5 p-6">
                        <span className=" text-base  font-normal text-gray-500">Selecciona un motivo de perdido</span>
                        <ul className="space-y-3">
                            {Object.entries(lostReasons).map(([key, value]) => (
                                <li key={key}>
                                    <label className="flex items-center gap-2 text-sm" htmlFor={key}>
                                        <input
                                            type="radio"
                                            name="lostReason"
                                            id={key}
                                            onChange={() => setReason(key)}
                                        />
                                        {value}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="p-6">
                    <Button size="xs" disabled={!reason} color="blue" onClick={() => handleConfirmModal(reason)}>
                        Confirmar
                    </Button>
                </div>
            </div>
        </div>
    );
}
