import { useApiClient } from '@/hooks/useApiClient';
import { isEmpty } from '@/lib/utils/helpers';
import { Alert, AlertsPageData, GetAlertsResponse } from '@/types/alerts';
import { useQuery } from '@tanstack/react-query';

const apiClient = useApiClient();

export function useAlertsQuery(uuid: string) {
    return useQuery({
        queryKey: ['alerts', uuid],
        queryFn: () => apiClient.getDealAlerts(uuid),
        select: (data: GetAlertsResponse) => transformAlertsData(data),
        enabled: !!uuid,
        refetchOnWindowFocus: false,
        retry: 1,
    });
}

function transformAlertsData(data: GetAlertsResponse): AlertsPageData {
    if (isEmpty(data.items)) {
        return { carAlerts: [], fraudAlerts: [] };
    }

    return data.items.reduce(
        (obj: AlertsPageData, item: Alert) => {
            if (item.group === 'CAR') {
                obj.carAlerts.push(item);
            } else if (item.group === 'FRAUD') {
                obj.fraudAlerts.push(item);
            }
            return obj;
        },
        { carAlerts: [], fraudAlerts: [] },
    );
}

export const useAlertsPageData = (dealId: string) => useAlertsQuery(dealId);
