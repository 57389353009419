import { z } from 'zod';

export const deliveryProviderType = {
    INHOUSE_TRANSPORT: 'Transporte interno',
    LPS: 'LPS',
    WEDELIVERY: 'Wedelivery'
};
export const deliverySignedDocStatus = {
    PENDING: 'Sin enviar',
    SENT: 'Enviado',
    SIGNED: 'Firmado',
};
export const deliverySentDocStatus = {
    PENDING: 'Sin enviar',
    SENT: 'Enviado',
};
export const deliveryRequestedDocStatus = {
    NOT_REQUESTED: 'Sin solicitar',
    REQUESTED: 'Solicitada',
    RECEIVED: 'Recibida',
};
export const deliveryCleaningStatus = {
    NOT_CLEANED: 'Sin limpiar',
    CLEANING: 'Limpiando',
    CLEAN: 'Limpio',
};

const deliveryProviderEnum: readonly string[] = Object.keys(
    deliveryProviderType,
) as (keyof typeof deliveryProviderType)[];
const deliverySignedDocStatusEnum: readonly string[] = Object.keys(
    deliverySignedDocStatus,
) as (keyof typeof deliverySignedDocStatus)[];
const deliverySentDocStatusEnum: readonly string[] = Object.keys(
    deliverySentDocStatus,
) as (keyof typeof deliverySentDocStatus)[];
const deliveryRequestedDocStatusEnum: readonly string[] = Object.keys(
    deliveryRequestedDocStatus,
) as (keyof typeof deliveryRequestedDocStatus)[];
const deliveryCleaningStatusEnum: readonly string[] = Object.keys(
    deliveryCleaningStatus,
) as (keyof typeof deliveryCleaningStatus)[];

export const DeliveryProviderType = z.enum([deliveryProviderEnum[0], ...deliveryProviderEnum]);
export const DeliverySignedDocStatus = z.enum([deliverySignedDocStatusEnum[0], ...deliverySignedDocStatusEnum]);
export const DeliverySentDocStatus = z.enum([deliverySentDocStatusEnum[0], ...deliverySentDocStatusEnum]);
export const DeliveryRequestedDocStatus = z.enum([
    deliveryRequestedDocStatusEnum[0],
    ...deliveryRequestedDocStatusEnum,
]);
export const DeliveryCleaningStatus = z.enum([deliveryCleaningStatusEnum[0], ...deliveryCleaningStatusEnum]);

export const DeliveryDetails = z.object({
    deliveryDate: z.string().datetime().nullable().optional(),
    deliveryProvider: DeliveryProviderType.nullable().optional(),
    contract: DeliverySignedDocStatus.nullable().optional(),
    mandate: DeliverySignedDocStatus.nullable().optional(),
    certification: DeliverySignedDocStatus.nullable().optional(),
    photoConsent: DeliverySignedDocStatus.nullable().optional(),
    invoice: DeliveryRequestedDocStatus.nullable().optional(),
    professionalJustification: DeliverySentDocStatus.nullable().optional(),
    cleaning: DeliveryCleaningStatus.nullable().optional(),
    deliveryAddress: z.object({
        route: z.string().nullable().optional(),
        streetNumber: z.string().nullable().optional(),
        postalCode: z.string().nullable().optional(),
        city: z.string().nullable().optional(),
        province: z.string().nullable().optional(),
        domicile: z.string().nullable().optional(),
        complete: z.string().nullable().optional(),
    }),
});

export type DeliveryDetails = z.infer<typeof DeliveryDetails>;
