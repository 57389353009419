import { Button, DropdownButton } from '@/components/Button';
import { useUuid } from '@/hooks/useUuid';
import {
    ChangeSubmissionsStatusProps,
    GetNextStatuses,
    GetSubmissionDetails,
    MainStatuses,
    mainStatuses,
    Substatuses,
    subStatuses,
} from '@/types/submission';
import { Tooltip } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { Textarea } from '../Inputs/Textarea';
import { handleDismissModal, useDialogHandler } from './DialogHandler';

export function ChangeSubmissionStatusDialog({
    submissionDetails,
    changeStatus,
}: {
    readonly submissionDetails: GetSubmissionDetails;
    readonly changeStatus: (data: ChangeSubmissionsStatusProps) => void;
}) {
    const { state, setState } = useDialogHandler();
    const [newStatuses, setNewStatuses] = useState<GetNextStatuses>();
    const dealUuid = useUuid();

    const getStatuses = async () => {
        try {
            const response = await fetch(`/api/deals/${dealUuid}/submissions/${submissionDetails.uuid}/statuses`, {
                method: 'GET',
            });

            return await response.json();
        } catch (e) {
            return [];
        }
    };

    useEffect(() => {
        const getStatusesData = async () => {
            const statuses = await getStatuses();
            setNewStatuses(await statuses);
            return statuses;
        };

        getStatusesData();
    }, []);

    const [mainStatus, setMainStatus] = useState<MainStatuses>();
    const [subStatus, setSubStatus] = useState<Substatuses>();
    const [reason, setReason] = useState('');

    const possibleSubStatuses = newStatuses?.nextMainStatuses.find((status) => status.name === mainStatus)?.subStatuses;
    const submitButtonDisabled =
        !mainStatus || (!subStatus && possibleSubStatuses !== undefined && possibleSubStatuses?.length > 0);

    const handleChangeSubmissionStatus = async () => {
        if (!mainStatus) {
            return;
        }
        const data = {
            mainStatus: mainStatus,
            subStatus: subStatus || null,
            reason: reason || null,
        };
        changeStatus(data);
    };

    return (
        <div className="fixed z-[99999] flex h-auto min-h-screen w-screen items-center justify-center bg-gray-900/50 text-black">
            <div className="absolute flex flex-col gap-4 rounded-lg bg-white opacity-100 py-6 px-12 max-w-[678px]">
                <span className="flex-1 text-lg font-semibold">Cambiar estados de financiación</span>

                <form
                    className="min-w-[585px] flex flex-col gap-8"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleChangeSubmissionStatus();
                    }}
                >
                    <div className={'flex justify-between gap-16'}>
                        <div className="space-y-2 w-full">
                            <span className={'text-sm text-gray-600'}>Estado</span>
                            <DropdownButton
                                className="w-full justify-between min-w-fit"
                                type={'button'}
                                variant="flat"
                                color="purple"
                                size="xs"
                                label={mainStatus ? mainStatuses[mainStatus] : ''}
                                direction={'left'}
                            >
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200 border-1 border-gray-500 rounded-lg">
                                    {newStatuses?.nextMainStatuses.map((status) => (
                                        <li
                                            key={status.name}
                                            className="block px-4 py-2 hover:bg-gray-100"
                                            onClick={() => {
                                                setMainStatus(status.name as MainStatuses);
                                                setSubStatus(undefined);
                                            }}
                                        >
                                            {mainStatuses[status.name]}
                                        </li>
                                    ))}
                                </ul>
                            </DropdownButton>
                        </div>

                        <div className="space-y-2 w-full">
                            <span className={'text-sm text-gray-600'}>Subestado</span>
                            <DropdownButton
                                className="w-full justify-between min-w-fit"
                                type={'button'}
                                color="green"
                                size="xs"
                                label={subStatus ? subStatuses[subStatus] : null}
                                direction={'left'}
                                disabled={possibleSubStatuses === undefined || possibleSubStatuses.length === 0}
                            >
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200 border-1 border-gray-500 rounded-lg">
                                    {possibleSubStatuses?.map((subStatus) => (
                                        <li
                                            key={subStatus}
                                            className="block px-4 py-2 hover:bg-gray-100"
                                            onClick={() => {
                                                setSubStatus(subStatus as Substatuses);
                                            }}
                                        >
                                            {subStatuses[subStatus as Substatuses]}
                                        </li>
                                    ))}
                                </ul>
                            </DropdownButton>
                        </div>
                    </div>

                    <Textarea
                        label="Comentarios cambio de estado"
                        className="resize-none p-4"
                        placeholder="Escribe un comentario aquí..."
                        rows={5}
                        value={reason}
                        onChange={(event) => {
                            setReason(event.target.value);
                        }}
                    />

                    <div className="flex justify-end gap-4">
                        <Button
                            size="small"
                            color="blue"
                            variant="outline"
                            onClick={() => handleDismissModal(state, setState)}
                        >
                            Cancelar
                        </Button>
                        <Tooltip
                            content="Faltan campos por rellenar"
                            style={'light'}
                            className={`${!submitButtonDisabled ? 'hidden' : ''}`}
                        >
                            <Button
                                size="small"
                                color="blue"
                                className="px-8"
                                type="submit"
                                disabled={submitButtonDisabled}
                            >
                                Cambiar
                            </Button>
                        </Tooltip>
                    </div>
                </form>
            </div>
        </div>
    );
}
