import {
    BankDetailsForm,
    BillingAddressForm,
    CoownerDetailsForm,
    CurrentJobForm,
    PersonalDataForm,
} from '@/components/Forms';
import { useToast } from '@/hooks/useToast';
import { DealBasicInfo } from '@/types';
import { DealInfoDataView } from '@/types/Deal';
import { GetVehicleFeatureResponse } from '@/types/Vehicle/features';

export function DealInfoView({
    data,
    colorList,
    dealBasicInfo,
}: {
    readonly data: DealInfoDataView;
    readonly colorList: GetVehicleFeatureResponse | undefined;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const { successToast, errorToast } = useToast();

    return (
        <section className="space-y-2 w-full">
            {/* <ContractDataForm contractData={{ contractNumber: '' }} onSuccess={successToast} onError={errorToast} /> */}
            <PersonalDataForm
                personalDetail={data.personalDetail}
                foreignDetail={data.foreignDetail}
                dealBasicInfo={dealBasicInfo}
                onSuccess={successToast}
                onError={errorToast}
            />
            <BillingAddressForm
                address={data.addresses.billingAddress}
                onSuccess={successToast}
                onError={errorToast}
                dealBasicInfo={dealBasicInfo}
            />
            <CurrentJobForm
                employment={data.employment}
                onSuccess={successToast}
                onError={errorToast}
                dealBasicInfo={dealBasicInfo}
            />
            <BankDetailsForm
                bankDetail={data.bankDetail}
                onSuccess={successToast}
                onError={errorToast}
                dealBasicInfo={dealBasicInfo}
            />
            <CoownerDetailsForm
                coownerDetail={data.coownerDetail}
                onSuccess={successToast}
                onError={errorToast}
                dealBasicInfo={dealBasicInfo}
            />
        </section>
    );
}
