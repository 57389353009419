import Slider, { SliderProps } from '@mui/material/Slider';
import { Label } from 'flowbite-react';
import { ForwardedRef, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

interface RangeInputProps extends SliderProps {
    label: string;
    error?: FieldError;
    value?: number | number[];
}

export const RangeInput = forwardRef(
    ({ min = 0, max = 10, step = 1, ...props }: RangeInputProps, ref: ForwardedRef<HTMLInputElement>) => {
        function valuetext(value: number) {
            return `${value}`;
        }

        const LabelTheme = {
            root: {
                base: `text-xs gap-2 flex flex-col gap-1 ${props.disabled ? 'text-gray-300' : 'hover:text-blue-500'} ${
                    props.error ? 'text-red-600 hover:text-red-600' : ''
                }`,
            },
        };

        const defaultMarks = [
            {
                label: valuetext(min),
                value: min,
            },
            {
                label: valuetext(max),
                value: max,
            },
        ];

        return (
            <div className="flex flex-col">
                <Label theme={LabelTheme}>
                    {props.label}
                    <div className="h-fit px-3">
                        <Slider
                            sx={{
                                '& .MuiSlider-thumb': {
                                    backgroundColor: '#FFFFFF',
                                    border: '1px',
                                    width: '20px',
                                    height: '20px',
                                    borderColor: 'red',
                                    borderRadius: '8px',
                                },
                                '& .MuiSlider-track ': {
                                    backgroundColor: `${props.disabled ? '#d1d5db' : '#00ADDF'}`,
                                    borderColor: `${props.disabled ? '#d1d5db' : '#00ADDF'}`,
                                },
                                '& .MuiSlider-rail': {
                                    background: '#9CA3AF',
                                },
                                '& .MuiSlider-mark': {
                                    background: '#9CA3AF',
                                },
                            }}
                            marks={props.marks ?? defaultMarks}
                            defaultValue={props.defaultValue ?? [min, max]}
                            valueLabelDisplay="auto"
                            getAriaValueText={valuetext}
                            step={step}
                            min={min}
                            max={max}
                            ref={ref}
                            {...props}
                        />
                    </div>
                </Label>
            </div>
        );
    },
);
