import { useDialog } from "@/components/Dialog/DialogHandler";


export function useToast() {
    const toast = useDialog();

    const successToast = (message: string = 'Los cambios se han guardado correctamente 👍🏼') => {
        toast({
            type: 'toast',
            content: message,
            variant: 'success',
        });
    };
    const errorToast = (message: string = "Ha ocurrido un problema y no se ha podido guardar") => {
        toast({
            type: 'toast',
            content: message,
            variant: 'error',
        });
    };

    return {
        successToast,
        errorToast
    }
}