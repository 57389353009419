import { useRouter } from 'next/router';
import { useMemo } from 'react';

export function useUuid() {
    const router = useRouter();
    return useMemo(() => {
        const { uuid } = router.query as {
            uuid: string;
        };
        return uuid;
    }, [router.query]);
}
