import { User } from '@/types/users';
import { z } from 'zod';
import { BankDetail } from './Deal';
import { Measurement, PartialRecord, typedObjectKeys } from './generics';
import { LifeInsuranceType } from '@/types/offer';

export const clientStatus = {
    APPROVED: 'Aceptada cliente',
    REJECTED: 'Rechazada cliente',
    CONTRACTED: 'Contratada',
};

export const bankStatus = {
    BBVA_IN_RECOVERY: 'En recuperación',
    BBVA_APPOINTMENT_PENDING: 'Cita pendiente',
    BBVA_APPOINTMENT_CONFIRMED: 'Cita confirmada',
    LENDROCK_SMS_SENT: 'Enviado SMS',
    SANTANDER_DIRECT_CIRBE_PENDING: 'Pdte CIRBE',
    SANTANDER_DIRECT_CIRBE_BELENDER_PENDING: 'CIRBE pdte Belender',
    SANTANDER_DIRECT_CIRBE_CLIENT_PENDING: 'CIRBE pdte Cliente',
    SANTANDER_DIRECT_CIRBE_COMPLETED: 'CIRBE en carpeta',
    CONFIA_SMS_SENT: 'Enviado SMS',
};

export const submissionStatus = {
    PENDING: 'Por gestionar',
    ONGOING: 'Tramitando',
    ACCEPTED: 'Aprobada',
    ACCEPTED_WITH_REVISIONS: 'Aceptada con cambios',
    REJECTED: 'Rechazada',
};

export const contractStatus = {
    CONTRACT_MISSING: 'Sin contrato aún',
    MORE_INFO_NEEDED: 'Falta info para crearlo',
    CREATING_CONTRACT: 'Elaborando',
    DRAFT_CREATED: 'Borrador creado',
    CONTRACT_SENT: 'Enviado',
    CONTRACT_AND_INVOICE_SENT: 'Enviado con factura',
    CONTRACT_SIGNED: 'Firmado',
};

export const mainStatuses = {
    PENDING: 'Por gestionar',
    PROCESSING: 'Tramitando',
    WAITING: 'Esperando',
    IN_ANALYSIS: 'En análisis',
    PRE_APPROVED: 'Pre-aprobada',
    ACCEPTED: 'Aprobada',
    REJECTED: 'Rechazada',
    COLLECTED: 'Cobrada',
    UNSUCCESSFULLY_APPROVED: 'Aprobada sin éxito',
    RETURN_IN_PROCESS: 'Devolución en trámite',
    RETURN: 'Devolución',
    EARLY_TERMINATION: 'Cancelación anticipada',
    CANCELLED: 'Anulada',
};

export const subStatuses = {
    INCOMPLETE_INFO: 'Info Incompleta',
    PENDING_FORM: 'PTE. Formulario',
    PENDING_BANK_VALIDATION: 'PTE. VB',
    BANK_UNDER_ANALYSIS: 'Banco en estudio',
    PENDING_ADDITIONAL_INFO: 'PTE. info adicional',
    PENDING_CO_OWNER: 'PTE. Cotitular',
    BANK_VALIDATION_ISSUE: 'Incidencia en VB',
    DOCUMENT_REVIEW: 'Revisión documental',
    PENDING_CUSTOMER_DOC: 'PTE. docu cliente',
    PENDING_CAR_DOC: 'PTE. docu Coche',
    PENDING_CONTRACT_SIGNATURE: 'PTE. firma contrato',
    SIGNED: 'Firmado',
    PENDING_CONFIRMATION: 'PTE. de sondeo',
    INVOICE_BEING_APPROVED: 'Factura en aprobación',
    PENDING_CLIENT_OFFICE: 'Cliente PTE. oficina',
    PENDING_BANK_OFFICE: 'Banco gestión oficina',
    OFFICE_APPOINTMENT_CONFIRMED: 'Cita oficina confirmada',
    ACCEPTED_WITHOUT_APPOINTMENT: 'Autorizado sin cita',
    NON_FINANCEABLE: 'No Financiable',
    WITHOUT_CO_OWNER: 'Sin cotitular',
    WITH_CO_OWNER: 'Con cotitular',
    CLIENT_DESISTS: 'Cliente desiste',
    ALTERNATIVE_NOT_ACCEPTED: 'Alternativa no aceptada',
    DATA_INCONSISTENCIES: 'Incongruencias en datos',
    UNREACHABLE: 'Ilocalizable',
    BANK_VALIDATION_NOT_COMPLETED: 'VB no realizada',
    BANK_ANALYSIS_NOT_COMPLETED: 'Estudio no completado (sin VB)',
    CLIENT_WITH_ASNEF: 'Cliente con ASNEF',
    CREATED_BY_MISTAKE: 'Creada por error',
    RESUMED_BY_BANK: 'Retomada por banco',
    null: '',
};

export const subStatusesMap: Record<MainStatuses, PartialRecord<Substatuses, string>> = {
    PENDING: {},
    PROCESSING: {},
    COLLECTED: {},
    RETURN_IN_PROCESS: {},
    RETURN: {},
    EARLY_TERMINATION: {},
    WAITING: {
        INCOMPLETE_INFO: 'Info Incompleta',
        PENDING_FORM: 'PTE. Formulario',
        PENDING_BANK_VALIDATION: 'PTE. VB',
    },
    IN_ANALYSIS: {
        BANK_UNDER_ANALYSIS: 'Banco en estudio',
        PENDING_ADDITIONAL_INFO: 'PTE. info adicional',
        PENDING_CO_OWNER: 'PTE. Cotitular',
        BANK_VALIDATION_ISSUE: 'Incidencia en VB',
        RESUMED_BY_BANK: 'Retomada por banco',
    },
    PRE_APPROVED: {
        DOCUMENT_REVIEW: 'Revisión documental',
        PENDING_CUSTOMER_DOC: 'PTE. docu cliente',
        PENDING_CAR_DOC: 'PTE. docu Coche',
    },
    ACCEPTED: {
        PENDING_CONTRACT_SIGNATURE: 'PTE. firma contrato',
        SIGNED: 'Firmado',
        PENDING_CONFIRMATION: 'PTE. de sondeo',
        INVOICE_BEING_APPROVED: 'Factura en aprobación',
        PENDING_CLIENT_OFFICE: 'Cliente PTE. oficina',
        PENDING_BANK_OFFICE: 'Banco gestión oficina',
        OFFICE_APPOINTMENT_CONFIRMED: 'Cita oficina confirmada',
        ACCEPTED_WITHOUT_APPOINTMENT: 'Autorizado sin cita',
    },
    REJECTED: {
        NON_FINANCEABLE: 'No Financiable',
        WITHOUT_CO_OWNER: 'Sin cotitular',
        WITH_CO_OWNER: 'Con cotitular',
    },
    UNSUCCESSFULLY_APPROVED: {
        CLIENT_DESISTS: 'Cliente desiste',
        ALTERNATIVE_NOT_ACCEPTED: 'Alternativa no aceptada',
        DATA_INCONSISTENCIES: 'Incongruencias en datos',
    },
    CANCELLED: {
        UNREACHABLE: 'Ilocalizable',
        BANK_VALIDATION_NOT_COMPLETED: 'VB no realizada',
        BANK_ANALYSIS_NOT_COMPLETED: 'Estudio no completado (sin VB)',
        CLIENT_WITH_ASNEF: 'Cliente con ASNEF',
        CREATED_BY_MISTAKE: 'Creada por error',
    },
};

export const financiers = {
    BBVA: 'BBVA',
    LEABANK: 'Lea Bank',
    LENDROCK: 'Lendrock',
    SANTANDER: 'Santander',
    SANTANDER_DIRECT: 'Santander Direct',
    CONFIA: 'Confia',
    SOFINCO: 'Sofinco',
    COFIDIS: 'Cofidis',
};

export type DealSubmissionListProps =
    | {
          REJECTED: Submission[];
          PENDING: Submission[];
          ONGOING: Submission[];
          RETURNED: Submission[];
          COLLECTED: Submission | null;
      }
    | undefined;

const [firstClientStatusKey, ...restClientStatusKeys] = typedObjectKeys(clientStatus);
const [firstBankStatusKey, ...restBankStatusKeys] = typedObjectKeys(bankStatus);
const [firstSubmissionStatusKey, ...restSubmissionStatusKeys] = typedObjectKeys(submissionStatus);
const [firstContractStatusKey, ...restContractStatusKeys] = typedObjectKeys(contractStatus);
const [firstMainStatusStatusesKey, ...restMainStatusStatusesKeys] = typedObjectKeys(mainStatuses);
const [firstSubStatusStatusesKey, ...restsubStatusStatusesKeys] = typedObjectKeys(subStatuses);
const [firstFinanciersKey, ...restFinanciersKeys] = typedObjectKeys(financiers);

/* – SUBMISSION – */

export const Submission = z.object({
    uuid: z.string(),
    externalId: z.string().nullable(),
    loanAmount: Measurement,
    askedAmount: Measurement,
    financier: z.enum([firstFinanciersKey, ...restFinanciersKeys]),
    status: z.enum([firstSubmissionStatusKey, ...restSubmissionStatusKeys]),
    bankStatus: z.enum([firstBankStatusKey, ...restBankStatusKeys]).nullable(),
    clientStatus: z.enum([firstClientStatusKey, ...restClientStatusKeys]).nullable(),
    contractStatus: z.enum([firstContractStatusKey, ...restContractStatusKeys]).nullable(),
    mainStatus: z.enum([firstMainStatusStatusesKey, ...restMainStatusStatusesKeys]),
    subStatus: z.enum([firstSubStatusStatusesKey, ...restsubStatusStatusesKeys]).nullable(),
    createdAt: z.string(),
});

export const SubmissionWithAggregates = z.object({
    bankStatus: z.enum([firstBankStatusKey, ...restBankStatusKeys]).nullable(),
    clientStatus: z.enum([firstClientStatusKey, ...restClientStatusKeys]).nullable(),
    contractStatus: z.enum([firstContractStatusKey, ...restContractStatusKeys]).nullable(),
    createdAt: z.string(),
    deal: z.object({
        uuid: z.string(),
        firstWonTime: z.string().nullable(),
        stageDealCreatedTime: z.string().nullable(),
    }),
    externalId: z.string().nullable(),
    financeAgent: z
        .object({
            name: z.string(),
        })
        .nullable(),
    financier: z.enum([firstFinanciersKey, ...restFinanciersKeys]),
    salesAgent: z
        .object({
            name: z.string(),
        })
        .nullable(),
    status: z.enum([firstSubmissionStatusKey, ...restSubmissionStatusKeys]),
    uuid: z.string(),
    vehicle: z.object({
        uuid: z.string(),
        licensePlate: z.string(),
    }),
    mainStatus: z.enum([firstMainStatusStatusesKey, ...restMainStatusStatusesKeys]),
    subStatus: z.enum([firstSubStatusStatusesKey, ...restsubStatusStatusesKeys]).nullable(),
    isAmountReceivedFromBank: z.boolean(),
    isAmountTransferredToClient: z.boolean(),
    client: z
        .object({
            name: z.string().nullable(),
            lastName: z.string().nullable(),
            secondLastName: z.string().nullable(),
        })
        .nullable(),
    lastSubmissionTransition: z
        .object({
            currentMainStatus: z.string(),
            createdAt: z.string(),
            reason: z.string().nullable(),
        })
        .nullable(),
    askedAmount: z.object({
        amount: z.number(),
        currency: z.string(),
        precision: z.number(),
    }),
    loanAmount: z.object({
        amount: z.number(),
        currency: z.string(),
        precision: z.number(),
    }),
});

/* – SUBMISSION VIEW – */

export const SubmissionOffer = z.object({
    externalId: z.string().nullable(),
    financier: z.enum([firstFinanciersKey, ...restFinanciersKeys]),
    loanAmount: z.number(),
    askedAmount: z.number(),
    terms: z.number(),
    nir: z.number(),
    apr: z.number(),
    adminFee: z.number().nullable(),
    commissionFee: z.number().nullable(),
    commissionFeeRate: z.number(),
    commitmentFee: z.number().nullable(),
    downPayment: z.number().nullable(),
    monthlyFee: z.number(),
    lifeInsuranceFee: z.number(),
    insuranceType: LifeInsuranceType.nullable(),
    isAmountReceivedFromBank: z.boolean(),
    isAmountTransferredToClient: z.boolean(),
    earlyTermination: z.string().nullable(),
    refundedFinancialCommission: z.number().nullable(),
    clientInDefault: z.boolean(),
});

export const GetSubmissionDetails = z.object({
    apr: z.number(),
    bankRejectedAt: z.string().nullable(),
    bankStatus: z.enum([firstBankStatusKey, ...restBankStatusKeys]).nullable(),
    clientContractedAt: z.string().nullable(),
    clientRejectedAt: z.string().nullable(),
    clientStatus: z.enum([firstClientStatusKey, ...restClientStatusKeys]).nullable(),
    contractStatus: z.enum([firstContractStatusKey, ...restContractStatusKeys]).nullable(),
    commissionFee: Measurement.nullable(),
    commissionFeeRate: z.number(),
    adminFee: Measurement.nullable(),
    commitmentFee: Measurement.nullable(),
    createdAt: z.string(),
    downPayment: Measurement.nullable(),
    externalId: z.string().nullable(),
    extraData: z
        .object({
            totalToReturn: Measurement.nullable(),
            totalInterest: Measurement.nullable(),
            startingInterestAt: Measurement.nullable(),
            validUntil: z.any(),
        })
        .nullable(),
    financier: z.enum([firstFinanciersKey, ...restFinanciersKeys]),
    isCosignerRequired: z.boolean(),
    isDocumentationRequired: z.boolean(),
    insuranceType: LifeInsuranceType.nullable(),
    lifeInsuranceFee: Measurement,
    loanAmount: Measurement,
    askedAmount: Measurement,
    monthlyFee: Measurement,
    nir: z.number(),
    rejectionReason: z.string().nullable(),
    remarks: z.string().nullable(),
    status: z.enum([firstSubmissionStatusKey, ...restSubmissionStatusKeys]),
    mainStatus: z.enum([firstMainStatusStatusesKey, ...restMainStatusStatusesKeys]),
    subStatus: z.enum([firstSubStatusStatusesKey, ...restsubStatusStatusesKeys]).nullable(),
    terms: z.number(),
    uuid: z.string(),
    isAmountReceivedFromBank: z.boolean(),
    isAmountTransferredToClient: z.boolean(),
    earlyTermination: z.string().nullable(),
    refundedFinancialCommission: Measurement.nullable(),
    clientInDefault: z.boolean(),
});

export const SubmissionView = z.object({
    offer: SubmissionOffer,
    status: GetSubmissionDetails,
});

/* – SUBMISSION OFFER DATA – */

export const SubmissionOfferFormData = z.object({
    externalId: z.string().nullable(),
    loanAmount: z.number(),
    terms: z.number(),
    nir: z.number(),
    apr: z.number(),
    downPayment: z.number().nullable(),
    commitmentFee: z.number().nullable(),
    adminFee: z.number().nullable(),
    commissionFee: z.number().nullable(),
    commissionFeeRate: z.number(),
    monthlyFee: z.number(),
    lifeInsuranceFee: z.number(),
    isAmountReceivedFromBank: z.boolean(),
    isAmountTransferredToClient: z.boolean(),
    earlyTermination: z.date().nullable(),
    refundedFinancialCommission: z.number().nullable(),
});

export const PatchSubmissionOfferRequest = z.object({
    externalId: z.string().nullable(),
    loanAmount: z.number(),
    terms: z.number(),
    nir: z.number(),
    apr: z.number(),
    downPayment: z.number().nullable(),
    commitmentFee: z.number().nullable(),
    adminFee: z.number().nullable(),
    commissionFee: z.number().nullable(),
    commissionFeeRate: z.number(),
    monthlyFee: z.number(),
    lifeInsuranceFee: z.number(),
    extraData: z
        .object({
            totalToReturn: z.number().nullable(),
            totalInterest: z.number().nullable(),
            startingInterestAt: z.string().nullable(),
            validUntil: z.string(),
        })
        .nullable(),
    isAmountReceivedFromBank: z.boolean(),
    isAmountTransferredToClient: z.boolean(),
    earlyTermination: z.date().nullable(),
    refundedFinancialCommission: z.number().nullable(),
});

/* – LIST OF SUBMISSIONS FROM DEAL – */

export const GetDealSubmissionList = z
    .object({
        max: z.number(),
        offset: z.number(),
        items: z.array(Submission),
        totalItems: z.number(),
    })
    .required();

/* – SUBMISSION STATUS CHANGE – */

export const GetNextStatuses = z.object({
    nextMainStatuses: z.array(
        z.object({
            name: z.enum([firstMainStatusStatusesKey, ...restMainStatusStatusesKeys]),
            subStatuses: z.array(z.string()),
        }),
    ),
});

export const ChangeSubmissionsStatusProps = z.object({
    mainStatus: z.enum([firstMainStatusStatusesKey, ...restMainStatusStatusesKeys]),
    subStatus: z.enum([firstSubStatusStatusesKey, ...restsubStatusStatusesKeys]).nullable(),
    reason: z.string().nullable().optional(),
});

/* – SUBMISSION TRANSITION HISTORY – */

export const TransitionHistoryRecord = z.object({
    uuid: z.string(),
    currentMainStatus: z.enum([firstMainStatusStatusesKey, ...restMainStatusStatusesKeys]).nullable(),
    previousMainStatus: z.enum([firstMainStatusStatusesKey, ...restMainStatusStatusesKeys]).nullable(),
    currentSubStatus: z.enum([firstSubStatusStatusesKey, ...restsubStatusStatusesKeys]).nullable(),
    previousSubStatus: z.enum([firstSubStatusStatusesKey, ...restsubStatusStatusesKeys]).nullable(),
    author: z.nullable(User),
    createdAt: z.string(),
    reason: z.string().nullable().optional(),
});

export const TransitionHistory = z.array(TransitionHistoryRecord);

export const SubmissionInfoData = z.object({
    submission: SubmissionView,
    history: TransitionHistory,
    bankDetails: BankDetail,
});

/* – EXPORTS – */

export type MainStatuses = keyof typeof mainStatuses;
export type Substatuses = keyof typeof subStatuses;
export type Submission = z.infer<typeof Submission>;
export type GetDealSubmissionList = z.infer<typeof GetDealSubmissionList>;
export type ChangeSubmissionsStatusProps = z.infer<typeof ChangeSubmissionsStatusProps>;
export type SubmissionInfoData = z.infer<typeof SubmissionInfoData>;
export type TransitionHistory = z.infer<typeof TransitionHistory>;
export type TransitionHistoryRecord = z.infer<typeof TransitionHistoryRecord>;
export type GetNextStatuses = z.infer<typeof GetNextStatuses>;
export type SubmissionWithAggregates = z.infer<typeof SubmissionWithAggregates>;
export type SubmissionOffer = z.infer<typeof SubmissionOffer>;
export type GetSubmissionDetails = z.infer<typeof GetSubmissionDetails>;
export type SubmissionView = z.infer<typeof SubmissionView>;
export type SubmissionOfferFormData = z.infer<typeof SubmissionOfferFormData>;
export type PatchSubmissionOfferRequest = z.infer<typeof PatchSubmissionOfferRequest>;
