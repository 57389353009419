import { Accordion } from '@/components/Accordion';
import { TextInput } from '@/components/Inputs';
import { FormProps } from '@/types';

export function ContractDataForm({
    contractData,
    onSuccess,
    onError,
}: FormProps & {
    contractData: {
        contractNumber: string;
    };
}) {
    return (
        <div className="space-y-2">
            <Accordion title="Datos contrato" className="bg-gray-100" collapseAll>
                <div className="grid grid-cols-1 gap-10 p-5">
                    <TextInput label="Número de contrato" value={contractData.contractNumber} type="text" disabled />
                </div>
            </Accordion>
        </div>
    );
}
