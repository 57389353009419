import { Roles, useProfileStore } from '@/stores';
import { CatalogueVehicleMainStatuses, CatalogueVehicleSubStatuses } from '@/types/Vehicle';
import { Tooltip } from 'flowbite-react';
import { HiUpload } from 'react-icons/hi';

interface PublicationDetailButtonProps {
    readonly uuid: string;
    readonly status: CatalogueVehicleMainStatuses;
    readonly subStatus: CatalogueVehicleSubStatuses | null;
    readonly openInNewTab?: boolean;
}

export function PublicationDetailButton({
    uuid,
    status,
    subStatus,
    openInNewTab = true,
}: PublicationDetailButtonProps) {
    const { hasRequiredRoles } = useProfileStore();

    if (
        !hasRequiredRoles([Roles.MARKETING_AGENT]) ||
        (status !== 'PUBLISHED' && subStatus !== 'PENDING_PUBLICATION' && subStatus !== 'PENDING_EXTRAS')
    ) {
        return null;
    }

    return (
        <Tooltip content={status === 'PUBLISHED' ? 'Editar Publicación' : 'Publicar'} style={'light'}>
            <button
                className="flex flex-col justify-center items-center w-fit"
                onClick={(e) => {
                    e.preventDefault();
                    window.open(`/vehicles/${uuid}/publication`, openInNewTab ? '_blank' : '_self');
                }}
            >
                <span className="border-1 border-blue-500 p-1 rounded-lg bg-white text-xs">
                    <HiUpload className="w-4 h-4  text-blue-500" />
                </span>
            </button>
        </Tooltip>
    );
}
