import { Button } from '@/components/Button';
import { DialogDismissButton, handleDismissModal, useDialogHandler } from '@/components/Dialog/DialogHandler';
import { TextInput } from '@/components/Inputs';
import { useToast, useUuid } from '@/hooks';
import { GetCatalogueVehicleDetails } from '@/types/Vehicle';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiOutlineExclamation } from 'react-icons/hi';
import { usePutVehicleLicensePlateMutation } from '../info';

export default function LicensePlateDialog() {
    const { successToast, errorToast } = useToast();
    const dealUuid = useUuid();
    const { state, setState } = useDialogHandler();
    const [vehicleDetails, setVehicleDetails] = useState<GetCatalogueVehicleDetails | null | undefined>(undefined);
    const [catalogueVehicleExists, setCatalogueVehicleExists] = useState<boolean>(false);
    const { mutateAsync: putDealVehicleLicensePlate } = usePutVehicleLicensePlateMutation();

    const getVehicleDetails = async (licensePlate: string) => {
        try {
            const response = await fetch(`/api/catalogue-vehicles/vehicle-details/${licensePlate}`, {
                method: 'GET',
            });
            const responseBody = await response.json();
            if (responseBody.make) {
                setVehicleDetails(responseBody);
                setCatalogueVehicleExists(true);
            } else {
                setVehicleDetails(null);
                setCatalogueVehicleExists(false);
            }
        } catch (error) {
            setVehicleDetails(null);
            errorToast('Ha ocurrido un error al intentar recibir los datos del vehículo 😔');
        }
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: {
            licensePlate: vehicleDetails?.licensePlate ?? undefined,
        },
        mode: 'onSubmit',
    });

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            await putDealVehicleLicensePlate({
                dealUuid: dealUuid,
                data: {
                    licensePlate: data.licensePlate,
                },
            });

            handleDismissModal(state, setState);
            successToast('Matrícula cambiada correctamente');
        } catch (e: any) {
            errorToast(e.message);
        }
    });

    return (
        <div className="fixed z-[99999] flex h-auto min-h-screen w-screen items-center justify-center bg-gray-900/50 text-black">
            <div className="absolute flex flex-col rounded-lg bg-white  opacity-100">
                <div className="flex items-center p-6">
                    <h2 className="flex-1 text-lg font-semibold">Vehículo asociado</h2>
                    <DialogDismissButton />
                </div>
                <div className="flex items-center px-6 pb-6">
                    <p className=" text-sm">
                        Al cambiar la matrícula desde aquí asociarás otro vehículo de los existentes en el catálogo de
                        vehículos.
                    </p>
                </div>
                <form className="min-w-[420px] pb-6 px-6" onSubmit={handleSubmitForm}>
                    <div className="space-y-2">
                        <div className="w-1/2">
                            <TextInput
                                label={'Matrícula'}
                                placeholder={'0000AAA'}
                                className="w-1/2"
                                required
                                type="text"
                                {...register('licensePlate', {
                                    required: 'La matrícula es obligatoria',
                                    validate: (value) => {
                                        const pattern = /^[0-9]{4}[A-Z]{3}$/;
                                        if (value) {
                                            return (
                                                pattern.test(value.trim().toUpperCase()) || 'La matrícula no es válida'
                                            );
                                        }
                                        return 'La matrícula no es válida';
                                    },
                                })}
                                error={errors.licensePlate}
                                onChange={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                    const pattern = /^[0-9]{4}[A-Z]{3}$/;
                                    if (pattern.test(e.target.value.trim().toUpperCase())) {
                                        getVehicleDetails(e.target.value.trim().toUpperCase());
                                    } else {
                                        setVehicleDetails(undefined);
                                        setCatalogueVehicleExists(false);
                                    }
                                }}
                            />
                        </div>
                        <div
                            className={`flex bg-gray-100 rounded-lg px-4 ${
                                vehicleDetails ? 'justify-around' : 'justify-start'
                            }`}
                        >
                            {vehicleDetails && (
                                <>
                                    <div className="flex items-center gap-2 py-3 px-1">
                                        <span className="text-sm text-gray-400">Marca: </span>
                                        <span className="text-gray-500 font-light">
                                            {vehicleDetails?.make?.name ?? 'Sin información'}
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-2 py-3 px-1">
                                        <span className="text-sm text-gray-400">Modelo: </span>
                                        <span className="text-gray-500 font-light">
                                            {vehicleDetails?.model?.name ?? 'Sin información'}
                                        </span>
                                    </div>
                                    <div className="flex items-center gap-2 py-3 px-1">
                                        <span className="text-sm text-gray-400">Color de coche: </span>
                                        <span className="text-gray-500 font-light">
                                            {vehicleDetails?.color?.name ?? 'Sin información'}
                                        </span>
                                    </div>
                                </>
                            )}
                            {vehicleDetails === undefined && (
                                <span className="text-gray-500 py-3 px-1">
                                    Para obtener marca, modelo y color del vehículo introduce su matrícula
                                </span>
                            )}
                            {vehicleDetails === null && (
                                <span className="text-gray-500 py-3 px-1 flex items-center gap-2">
                                    <HiOutlineExclamation className="text-orange-500 w-6 h-6" />
                                    No se ha podido obtener información sobre este vehículo
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="w-fit ms-auto mt-2">
                        <Button size="small" color="blue" type="submit" disabled={!catalogueVehicleExists}>
                            Guardar
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}
