import { Button } from '@/components/Button';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { Checkbox, HighlightInfo, TextInput } from '@/components/Inputs';
import Highlight from '@/components/Inputs/Highlight';
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning';
import { useUuid } from '@/hooks/useUuid';
import { Roles, useProfileStore } from '@/stores/useProfileStore';
import { DealBasicInfo, lifeInsuranceEnum, LifeInsuranceEnum } from '@/types';
import { BankDetail } from '@/types/Deal';
import { GetSubmissionDetails, SubmissionOffer, SubmissionOfferFormData } from '@/types/submission';
import { useSubmissionOfferMutation } from '@/views/deals/details/submissions/details/useSubmissionDetailsQuery';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Tooltip } from 'flowbite-react';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { HiCheck, HiOutlineInformationCircle, HiX } from 'react-icons/hi';

const CARDS: HighlightInfo<GetSubmissionDetails>[] = [
    {
        mapKey: 'loanAmount',
        label: 'Importe del préstamo',
        required: true,
        type: 'currency',
        disabled: false,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
    {
        mapKey: 'nir',
        label: 'TIN',
        required: true,
        type: 'percentage',
        disabled: false,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
    {
        mapKey: 'apr',
        label: 'TAE',
        required: true,
        type: 'percentage',
        disabled: false,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
    {
        mapKey: 'monthlyFee',
        label: 'Cuota mensual',
        required: true,
        type: 'currency',
        disabled: false,
        gtZero: true,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
    {
        mapKey: 'terms',
        label: 'Número de cuotas',
        required: true,
        type: 'amount',
        disabled: false,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
    {
        mapKey: 'commissionFeeRate',
        label: '% Comisión',
        required: true,
        type: 'percentage',
        disabled: false,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
    {
        mapKey: 'commissionFee',
        label: 'Comisión',
        required: true,
        type: 'currency',
        disabled: true,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
    {
        mapKey: 'earlyTermination',
        label: 'Cancelación anticipada',
        required: false,
        type: 'date',
        disabled: false,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
    {
        mapKey: 'refundedFinancialCommission',
        label: 'Comisión financiera devuelta',
        required: false,
        type: 'currency',
        disabled: false,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
    {
        mapKey: 'adminFee',
        label: 'Comisión Clidrive',
        required: false,
        type: 'currency',
        disabled: false,
        roles: [Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST],
    },
];

type DealSubmissionOfferProps = {
    submissionDetails: SubmissionOffer;
    bankDetails: BankDetail;
    dealBasicInfo: DealBasicInfo | undefined;
};

export function DealSubmissionOffer({
    submissionDetails,
    bankDetails,
    dealBasicInfo,
}: Readonly<DealSubmissionOfferProps>) {
    const dealUuid = useUuid();
    const router = useRouter();
    const { hasRequiredRoles, isAdmin } = useProfileStore();
    const dialog = useDialog();
    const { mutateAsync: updateSubmission } = useSubmissionOfferMutation();
    const { id: submissionUuid } = router.query as {
        id: string;
    };

    const methods = useForm({
        values: {
            externalId: submissionDetails.externalId === null ? '' : submissionDetails.externalId,
            apr: submissionDetails.apr,
            adminFee: submissionDetails.adminFee,
            commitmentFee: submissionDetails.commitmentFee,
            commissionFee: submissionDetails.commissionFee,
            commissionFeeRate: submissionDetails.commissionFeeRate,
            downPayment: submissionDetails.downPayment,
            loanAmount: submissionDetails.loanAmount,
            monthlyFee: submissionDetails.monthlyFee,
            nir: submissionDetails.nir,
            lifeInsuranceFee: submissionDetails.lifeInsuranceFee,
            terms: submissionDetails.terms,
            isAmountReceivedFromBank: submissionDetails.isAmountReceivedFromBank,
            isAmountTransferredToClient: submissionDetails.isAmountTransferredToClient,
            earlyTermination: submissionDetails.earlyTermination
                ? utcToZonedTime(submissionDetails.earlyTermination, 'UTC')
                : null,
            refundedFinancialCommission: submissionDetails.refundedFinancialCommission,
            clientInDefault: submissionDetails.clientInDefault,
        },
    });

    useUnsavedChangesWarning(methods.formState.isDirty, () => {
        return confirm('Parece que tienes cambios sin guardar, ¿deseas abandonar la página?');
    });

    const [isProcessingSubmissionRequest, setIsProcessingSubmissionRequest] = useState(false);

    const handleSubmitSubmission = methods.handleSubmit(async (data: SubmissionOfferFormData) => {
        setIsProcessingSubmissionRequest(true);

        try {
            await updateSubmission({
                dealUuid,
                submissionUuid,
                data: {
                    ...data,
                    externalId: data.externalId === '' ? null : data.externalId,
                    earlyTermination: data.earlyTermination ? zonedTimeToUtc(data.earlyTermination, 'UTC') : null,
                },
            });

            dialog({
                type: 'toast',
                variant: 'success',
                content: 'Información guardada con éxito',
            });
        } catch (error) {
            dialog({
                type: 'toast',
                variant: 'error',
                content: 'Error al enviar los datos, por favor inténtalo de nuevo',
            });
        } finally {
            setIsProcessingSubmissionRequest(false);
        }
    });

    const getLifeInsuranceHighlight = (hasLifeInsurance: boolean) => {
        if (hasLifeInsurance) {
            return (
                <div className={'flex flex-1 gap-3.5 items-center rounded-lg'}>
                    <div className="flex h-10 w-11 items-center justify-center rounded-lg bg-green-100">
                        <HiCheck className=" text-green-500 h-6 w-6"></HiCheck>
                    </div>
                    <div className={'flex flex-col w-full'}>
                        <span className={'text-md font-normal leading-tight text-gray-500'}>Seguro</span>
                    </div>
                </div>
            );
        }

        return (
            <div className={'flex flex-1 gap-3.5 items-center rounded-lg'}>
                <div className="flex h-10 w-11 items-center justify-center rounded-lg bg-blue-100">
                    <HiX className="text-red-500 h-6 w-6" />
                </div>
                <div className={'flex flex-col w-full'}>
                    <span className={'text-md font-normal leading-tight text-gray-500'}>Seguro</span>
                </div>
            </div>
        );
    };

    const isCardDisabled = (card: HighlightInfo<GetSubmissionDetails>) => {
        if (card.disabled === true) return true;
        if (card.roles) return !hasRequiredRoles(card.roles);
        return false;
    };

    if (!submissionDetails) {
        return null;
    }

    return (
        <FormProvider {...methods}>
            <form
                className={'flex w-full flex-col gap-6'}
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitSubmission();
                }}
            >
                <div className="flex flex-1 flex-col border-x border-x-gray-200 shadow-sm">
                    <div className="rounded-t-lg border-b border-b-gray-200 bg-gray-100 p-4">
                        <div>
                            <span className="font-medium text-gray-900">Financiación</span>
                        </div>
                    </div>
                    <div className="flex flex-1 flex-col gap-4 bg-white px-4 py-6">
                        <div>
                            <img
                                src={'/images/banks/' + submissionDetails.financier + '.png'}
                                alt={submissionDetails.financier}
                                className="h-4 object-contain"
                            />
                        </div>

                        <TextInput
                            className={'max-w-[21rem] py-3'}
                            label="Número solicitud"
                            type="text"
                            {...methods.register('externalId')}
                        />
                        <p className="text-gray-500 text-xs">
                            * Los valores se pueden editar haciendo clic en ellos y guardando posteriormente.
                            <br />* <span className={'font-bold text-red-500'}>¡IMPORTANTE!</span> El punto separa los
                            decimales, no los miles.
                        </p>
                        <div className="grid grid-cols-3 gap-4">
                            {CARDS.map((card) => (
                                <Highlight {...card} key={card.mapKey} disabled={isCardDisabled(card)} />
                            ))}

                            {dealBasicInfo?.tier !== 'C2C' ||
                            (dealBasicInfo?.tier === 'C2C' &&
                                hasRequiredRoles([Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST])) ? (
                                <div className="flex gap-4">
                                    <Highlight
                                        mapKey="lifeInsuranceFee"
                                        label="Seguro"
                                        required={true}
                                        type="currency"
                                        disabled={!hasRequiredRoles([Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST])}
                                    />
                                    {dealBasicInfo?.tier === 'C2C' &&
                                        submissionDetails.insuranceType &&
                                        hasRequiredRoles([Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST]) && (
                                            <div className="flex flex-col justify-center gap-1.5">
                                                <p className="text-xs text-gray-500">Tipo de seguro</p>
                                                <div className="flex items-center gap-1">
                                                    <p className="text-gray-800">
                                                        {
                                                            lifeInsuranceEnum[
                                                                submissionDetails.insuranceType as LifeInsuranceEnum
                                                            ]
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                </div>
                            ) : (
                                getLifeInsuranceHighlight(submissionDetails.lifeInsuranceFee > 0)
                            )}
                        </div>

                        {(dealBasicInfo?.situation === 'WON' || isAdmin()) && (
                            <div>
                                <span className={'text-xs uppercase text-gray-400 font-bold'}>Flujo de dinero</span>
                                <div className={'mt-2 flex gap-12'}>
                                    <div className="flex gap-2 items-center">
                                        <Checkbox
                                            {...methods.register('isAmountReceivedFromBank')}
                                            label={'DINERO RECIBIDO BANCO'}
                                        />{' '}
                                        <Tooltip id="tooltip-default" content="Cantidad solicitada" style={'light'}>
                                            <HiOutlineInformationCircle className="text-gray-800" />
                                        </Tooltip>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <Checkbox
                                            {...methods.register('isAmountTransferredToClient')}
                                            label="DINERO TRANSFERIDO CLIENTE"
                                        />
                                        <Tooltip content="Cantidad solicitada - Comisión Clidrive" style={'light'}>
                                            <HiOutlineInformationCircle className="text-gray-800" />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div>
                            <span className={'text-xs uppercase text-gray-400 font-bold'}>Información Bancaria</span>
                            <div className={'flex gap-12 mt-2'}>
                                <p>
                                    <span className="text-gray-500 text-sm">IBAN:</span>{' '}
                                    {bankDetails?.iban ?? 'Sin IBAN'}
                                </p>
                                <p>
                                    <span className="text-gray-500 text-sm">SWIFT:</span>{' '}
                                    {bankDetails?.swift ?? 'Sin SWIFT'}
                                </p>
                            </div>
                        </div>
                        {((hasRequiredRoles([Roles.FINANCE_USER, Roles.FINANCIAL_ANALYST]) &&
                            dealBasicInfo?.situation === 'WON') ||
                            isAdmin()) && (
                            <div className="flex flex-col gap-2">
                                <span className={'text-xs uppercase text-gray-400 font-bold'}>Mora</span>
                                <Checkbox {...methods.register('clientInDefault')} label="CLIENTE EN MORA" />
                            </div>
                        )}

                        <div className={'flex justify-center'}>
                            <Button
                                type="submit"
                                color="blue"
                                variant={'outline'}
                                disabled={isProcessingSubmissionRequest || !methods.formState.isDirty}
                            >
                                Guardar cambios
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}
