import { Card } from './Card';
import { Button, DropdownButton } from '../Button';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { BsPinAngle, BsPinAngleFill } from 'react-icons/bs';
import { NoteDetailsDialog } from '../Dialog';
import { useDialog } from '../Dialog/DialogHandler';
import { Attachment, EditDealNoteRequest, Note } from '@/types';
import {
    useDeleteNoteAttachmentMutation,
    useDeleteNoteMutation,
    usePatchNoteMutation,
    usePutNotePinnedMutation,
    useUploadAttachmentMutation,
} from '@/views/deals/details/notes/useNotesQuery';
import { useUuid } from '@/hooks/useUuid';
import { useToast } from '@/hooks/useToast';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useUserListMutation } from '@/views';
import { FiFile, FiFileText, FiFolder, FiImage, FiVideo, FiVolume1 } from 'react-icons/fi';
import { useAttachmentDownloadUrl } from '@/hooks/useAttachmentDownloadUrl';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';

export function NoteCard({ noteData, isPinned }: { readonly noteData: Note; readonly isPinned: boolean }) {
    const { user } = useUser();
    const dealUuid = useUuid();
    const { mutateAsync: filterUsers } = useUserListMutation();
    const { successToast, errorToast } = useToast();
    const { mutateAsync: usePutNotePinned } = usePutNotePinnedMutation();
    const { mutateAsync: usePatchDealNote } = usePatchNoteMutation();
    const { mutateAsync: useDeleteNote } = useDeleteNoteMutation();
    const { mutateAsync: useUploadAttachment } = useUploadAttachmentMutation();
    const { mutateAsync: useDeleteNoteAttachment } = useDeleteNoteAttachmentMutation();

    const createDate = new Date(noteData.createdAt);
    const updateDate = new Date(noteData.updatedAt);
    const dialog = useDialog();

    const userHasPermission = async () => {
        if (!user?.email) return errorToast('No se ha podido obtener el email del usuario');

        const userInfo = await filterUsers({ query: user?.email });

        if (userInfo.items.length !== 1)
            return errorToast('No se ha podido obtener la información del usuario para editar la nota');

        if (userInfo.items[0].uuid !== noteData.author?.uuid)
            return errorToast('No tienes permisos para editar esta nota');

        return true;
    };

    const handleOpenNoteModal = async () => {
        if (!(await userHasPermission())) return;

        dialog({
            type: 'modal',
            content: (
                <NoteDetailsDialog
                    data={noteData}
                    saveNote={handleEditNote}
                    onSuccess={() => successToast('Nota actualizada correctamente 👍🏼')}
                    onError={() => errorToast('Ha ocurrido un problema al intentar actualizar la nota')}
                />
            ),
        });
    };

    const handleTogglePin = async () => {
        await usePutNotePinned({
            dealUuid: dealUuid,
            noteUuid: noteData.uuid,
            isPinned: !isPinned,
        });
    };

    const handleEditNote = async (
        newData: EditDealNoteRequest,
        deleteAttachments: Attachment[],
        newAttachments?: File[],
    ) => {
        if (!(await userHasPermission())) return;

        if (newAttachments && newAttachments.length > 0) {
            try {
                await useUploadAttachment({ dealUuid: dealUuid, noteUuid: noteData.uuid, data: newAttachments });
            } catch (error) {
                errorToast('Error al intentar subir archivos');
            }
        }

        if (deleteAttachments.length > 0) {
            deleteAttachments.forEach((attachment) => {
                useDeleteNoteAttachment({
                    dealUuid: dealUuid,
                    noteUuid: noteData.uuid,
                    attachmentUuid: attachment.uuid,
                });
            });
        }

        await usePatchDealNote({
            dealUuid: dealUuid,
            noteUuid: noteData.uuid,
            data: newData,
        });
    };

    const handleDownloadAttachment = async (attachment: Attachment) => {
        try {
            const response = await useAttachmentDownloadUrl(dealUuid, noteData.uuid, attachment.uuid);
            window.open(response.downloadUrl, '_blank');
        } catch (error) {
            errorToast('Ha ocurrido un problema al intentar descargar el archivo');
        }
    };

    const handleDeleteNote = async () => {
        if (!(await userHasPermission())) return;

        try {
            await useDeleteNote({
                dealUuid: dealUuid,
                noteUuid: noteData.uuid,
            });
            successToast('Nota eliminada correctamente 👍🏼');
        } catch (e) {
            errorToast('Ha ocurrido un problema al intentar eliminar la nota');
        }
    };

    const handleContainerClick = (event: any) => {
        const tagNames = ['BUTTON', 'svg', 'LI', 'path'];
        if (
            tagNames.includes(event.target.tagName) ||
            event.target.id === 'attachment' ||
            window.getSelection()?.toString()
        )
            return;
        if (window.getSelection()?.toString()) return;

        handleOpenNoteModal();
    };

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
    };

    const mapFileIcon = {
        image: ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp', 'bmp', 'tiff', 'tif'],
        document: ['doc', 'docx', 'pdf', 'txt', 'odt', 'rtf', 'tex', 'wpd', 'wps', 'xml', 'xps', 'md'],
        audio: ['mp3', 'wav', 'wma', 'm4a', 'aac', 'flac', 'ogg', 'aiff', 'alac', 'pcm', 'dsd'],
        video: ['mp4', 'mov', 'wmv', 'flv', 'avi', 'avchd', 'webm', 'mkv', 'm4v', '3gp', '3g2', 'm2v', 'm4v', 'ts'],
        compressed: ['zip', 'rar', '7z', 'tar', 'gz', 'pkg', 'deb', 'rpm', 'zipx', 'sitx', 'gz', 'bz2', 'ace', 'arj'],
    };

    const setFileIcon = (ext: string) => {
        if (mapFileIcon.image.includes(ext)) return <FiImage className="h-4 w-4" />;
        if (mapFileIcon.document.includes(ext)) return <FiFileText className="h-4 w-4" />;
        if (mapFileIcon.audio.includes(ext)) return <FiVolume1 className="h-4 w-4" />;
        if (mapFileIcon.video.includes(ext)) return <FiVideo className="h-4 w-4" />;
        if (mapFileIcon.compressed.includes(ext)) return <FiFolder className="h-4 w-4" />;
        return <FiFile className="h-4 w-4" />;
    };

    return (
        <Card
            className={`${
                isPinned ? 'bg-yellow-50' : 'bg-white'
            } flex columns-2 text-sm border-2 border-gray-100 shadow-none hover:border-2 hover:border-gray-500 hover:shadow-md hover:shadow-blue-200/40`}
            onClick={(e) => handleContainerClick(e)}
        >
            <div className="flex gap-2">
                {isPinned ? (
                    <div>
                        <Button color="gray" variant="ghost" size="xxs" onClick={handleTogglePin}>
                            <BsPinAngleFill className="h-4 w-4 text-gray-500 pointer-events-none" />
                        </Button>
                    </div>
                ) : (
                    <div>
                        <Button color="gray" variant="ghost" size="xxs" onClick={handleTogglePin}>
                            <BsPinAngle className="h-4 w-4 text-gray-500 pointer-events-none" />
                        </Button>
                    </div>
                )}
                <div
                    className={`w-full space-y-2 transition-colors duration-200 text-gray-500 ${
                        isPinned ? 'bg-yellow-50' : 'bg-white'
                    }`}
                >
                    <div className="flex justify-between">
                        <span className="text-base flex gap-2 font-medium">
                            {format(createDate, 'd MMMM yyyy - HH:mm', { locale: es })}
                        </span>
                        <div className="flex gap-3">
                            {noteData.createdAt === noteData.updatedAt ? (
                                ''
                            ) : (
                                <span className="italic">
                                    Actualizado — {format(updateDate, 'dd/MM/yyyy - HH:mm', { locale: es })}
                                </span>
                            )}
                            <DropdownButton
                                color="gray"
                                variant="ghost"
                                size="xxs"
                                className="p-1"
                                chevron={false}
                                label={<HiOutlineDotsVertical className="h-5 w-5" />}
                                onClick={handleButtonClick}
                            >
                                <ul className="cursor-pointer py-1 text-sm text-gray-700">
                                    <>
                                        <li className="block px-4 py-2 hover:bg-gray-100" onClick={handleOpenNoteModal}>
                                            Editar
                                        </li>
                                        <li className="block px-4 py-2 hover:bg-gray-100" onClick={handleTogglePin}>
                                            {isPinned ? 'Desanclar' : 'Anclar'}
                                        </li>
                                    </>
                                    <li
                                        className="block px-4 py-3 text-red-500 hover:bg-gray-100 border-t-1"
                                        onClick={handleDeleteNote}
                                    >
                                        Eliminar
                                    </li>
                                </ul>
                            </DropdownButton>
                        </div>
                    </div>
                    <div>
                        <p
                            className="text-anywhere"
                            dangerouslySetInnerHTML={{ __html: `<b><i>${noteData.title}:</i></b> ${noteData.content}` }}
                        ></p>
                        {noteData.attachments.length > 0 ? (
                            <div className="flex gap-6 pt-4">
                                <span className="whitespace-nowrap">Archivos adjuntos</span>
                                <div className="flex gap-6 flex-wrap">
                                    {noteData.attachments.map((attachment) => (
                                        <span
                                            className="flex items-center gap-2 rounded-full bg-gray-100 text-xs py-1 px-2 hover:bg-gray-200 hover:shadow-md"
                                            key={attachment.uuid}
                                            onClick={() => handleDownloadAttachment(attachment)}
                                            id="attachment"
                                        >
                                            {attachment.fileName}
                                            {setFileIcon(attachment.fileName.split('.').pop() || '')}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                </div>
            </div>
        </Card>
    );
}
