import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';

export function HelperBot() {
    const [firstRender, setFirstRender] = useState(true);

    useEffect(() => {
        if (firstRender) {
            const helperBotScript = document.getElementById('helper-bot-script');
            helperBotScript?.parentNode?.removeChild(helperBotScript);

            setFirstRender(false);
        } else {
            const script = document.createElement('script');
            script.src = 'https://clibot-release-plugin.web.app/plugin.js';
            script.id = 'helper-bot-script';
            script.type = 'module';
            script.async = true;
            script.defer = true;

            document.body.appendChild(script);
        }

        return () => {
            const helperBotScript = document.getElementById('helper-bot-script');
            helperBotScript?.parentNode?.removeChild(helperBotScript);
        };
    }, [firstRender]);

    if (firstRender) {
        return null;
    }

    return (
        <div>
            {createPortal(
                <div>
                    <div
                        className={
                            'fixed bottom-[70px] right-[16px] bg-[#E5F7FE] py-[6px] px-[8px] text-black text-[12px] font-700 rounded-[4px]'
                        }
                    >
                        Bot helper
                    </div>
                    <div
                        className="monster-bot-chat-plugin"
                        data-title="Connect"
                        data-project-id="2a462614-fdaa-461e-b137-69662c08fe0d"
                        data-verify-key="80b1b9f375574a1b86c224bd71f40dd1"
                        data-endpoint="https://web-bot.clibrain.com"
                        data-skip-login="true"
                        data-theme-base="#e1f8ff"
                        data-theme-other-chat-bubble-text="#000000"
                        data-theme-other-chat-bubble-background="#ffffff"
                        data-theme-chat-bubble-background="#e1f8ff"
                        data-theme-chat-bubble-text="#000000"
                        data-theme-text="#000000"
                        data-theme-app-bar="#e1f8ff"
                        data-theme-background="#ffffff"
                        data-theme-primary="#e1f8ff"
                        data-theme-primary-variant="#e1f8ff"
                        data-theme-on-secondary="#3F83F8"
                        data-setup-style="floating"
                        data-restart-allowed="true"
                        data-height="650px"
                        data-width="350px"
                        data-logo="https://production-clibot-realbot-storage.s3.amazonaws.com/media/project/2a462614-fdaa-461e-b137-69662c08fe0d/images/2a462614-fdaa-461e-b137-69662c08fe0d_46tEFKZ.png"
                        data-brand-logo="https://production-clibot-realbot-storage.s3.amazonaws.com/media/project/2a462614-fdaa-461e-b137-69662c08fe0d/images/2a462614-fdaa-461e-b137-69662c08fe0d_Lrct6Pf.png"
                    ></div>
                </div>,
                document.body,
            )}
        </div>
    );
}
