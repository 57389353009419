import { z } from "zod";
import { Feature, PaginatedList } from "./generics";




export const GetProvinceResponse = PaginatedList.extend({
    items: z.array(Feature)
})


export type GetProvinceResponse = z.infer<typeof GetProvinceResponse>