import { FormProps } from '@/types';
import { Button } from '@/components/Button';
import { useForm } from 'react-hook-form';
import { TextInput } from '@/components/Inputs';
import { useDealDocumentationLinkMutation } from '@/views/deals/details/documentation';
import { useUuid } from '@/hooks/useUuid';
import { isValidUrl } from '@/lib/validators/urlValidator';

export function DocumentationForm({
    link,
    onSuccess,
    onError,
    onClose,
}: FormProps & { link: string; onClose: () => void }) {
    const { mutateAsync: updateDocumentationLink } = useDealDocumentationLinkMutation();
    const dealUuuid = useUuid();
    const {
        register,
        formState: { isDirty, isSubmitting, errors },
        handleSubmit,
    } = useForm({
        values: {
            link,
        },
        mode: 'onSubmit',
    });

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            await updateDocumentationLink({
                dealUuuid,
                data,
            });

            onSuccess();
        } catch (e) {
            onError();
        }
    });

    return (
        <form className="p-5 space-y-4" onSubmit={handleSubmitForm}>
            <TextInput
                label="Link a la documentación"
                {...register('link', {
                    validate: (value) => isValidUrl(value) || 'Introduce una url válida',
                })}
                error={errors.link}
            />
            <div className="grid col-span-1 grid-cols-6 gap-10">
                <Button className="col-span-1 col-start-5" variant="outline" color="white" onClick={onClose}>
                    Cerrar
                </Button>
                <Button
                    disabled={!isDirty || isSubmitting}
                    className="col-span-1 col-start-6"
                    type="submit"
                    color="blue"
                >
                    Guardar
                </Button>
            </div>
        </form>
    );
}
