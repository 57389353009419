import { FilesToUpload } from '@/types';

export async function useUploadFiles(attachments: FilesToUpload) {
    await Promise.all(
        attachments.map(async (fileUploadData) => {
            const formData = new FormData();
            formData.append('contentType', (await fileUploadData).file.type);
            formData.append('uploadUrl', (await fileUploadData).uploadData.uploadUrl);
            formData.append('file', (await fileUploadData).file);

            try {
                await fetch('/api/files/upload', {
                    method: 'PUT',
                    body: formData,
                });
            } catch (error: any) {
                throw error;
            }
        }),
    );
}
