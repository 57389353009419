import React, { ComponentProps, ForwardedRef } from 'react';

type TextareaProps = ComponentProps<'textarea'> & {
    label?: string;
    className?: string;
};

export const Textarea = React.forwardRef(
    ({ label, className, ...rest }: TextareaProps, ref: ForwardedRef<HTMLTextAreaElement>) => {
        return (
            <div className={'flex flex-col gap-3'}>
                {label && (
                    <label htmlFor={rest.id} className="block text-xs font-medium text-gray-500 dark:text-white">
                        {label}
                    </label>
                )}
                <textarea
                    rows={10}
                    {...rest}
                    className={`text-gray-900 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500 ${className}`}
                    ref={ref}
                ></textarea>
            </div>
        );
    },
);
