import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Combobox } from '@/components/Combobox/Combobox';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { Checkbox, DateInput, RadioGroup, Select, TextInput } from '@/components/Inputs';
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning';
import { useUuid } from '@/hooks/useUuid';
import { useReservationsQuery } from '@/layouts/DealDetailLayout/useDealReservationQuery';
import { getOptions } from '@/lib/utils/getters';
import { Roles, useProfileStore } from '@/stores/useProfileStore';
import { DealBasicInfo, FormProps } from '@/types';
import { combustibleType, GetDealVehicleReponse } from '@/types/Deal';
import { GetVehicleFeatureResponse } from '@/types/Vehicle/features';
import { updateDealVehicle } from '@/views/deals/details/info';
import LicensePlateDialog from '@/views/deals/details/vehicle/LicensePlateDialog';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { Tooltip } from 'flowbite-react';
import { Controller, useForm } from 'react-hook-form';
import { BiEdit } from 'react-icons/bi';
import { HiCheckCircle, HiMinusCircle, HiXCircle } from 'react-icons/hi';
import { useDealCatalogueVehicleQuery } from '../useCarfaxQuery';

export function VehicleDataForm({
    vehicle,
    colorList,
    onSuccess,
    onError,
    dealBasicInfo,
}: FormProps & {
    readonly vehicle: GetDealVehicleReponse;
    readonly colorList: GetVehicleFeatureResponse | undefined;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const { isAdmin, hasRequiredRoles } = useProfileStore();
    const isLoggedUserAdmin = isAdmin();
    const { mutateAsync: updateVehicle } = updateDealVehicle();
    const dealUuid = useUuid();
    const { data: dealCatalogueVehicleDetails } = useDealCatalogueVehicleQuery(dealUuid);
    const { data: reservation } = useReservationsQuery(
        {
            licensePlate: dealBasicInfo?.vehicle?.licensePlate,
            statuses: ['RECEIVED'],
        },
        !!dealBasicInfo?.vehicle?.licensePlate,
    );
    const dealRecivedReservation =
        reservation && reservation.totalItems > 0 && reservation.items[0].dealUuid === dealUuid
            ? reservation.items[0]
            : undefined;
    const dialog = useDialog();

    const {
        register,
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { isDirty, isSubmitting, errors },
    } = useForm({
        values: {
            ...vehicle,
            color: {
                label: colorList?.items.find((color) => color.slug === vehicle.color)?.name,
                value: vehicle.color,
            },
            firstRegistrationDate: vehicle?.firstRegistrationDate
                ? utcToZonedTime(vehicle?.firstRegistrationDate, 'UTC')
                : null,
            gearboxType: vehicle.gearboxType ?? 'DELETE',
            titleReservation: vehicle.titleReservation ?? false,
            hasDebt: vehicle.hasDebt ?? false,
        },
        mode: 'onSubmit',
    });

    useUnsavedChangesWarning(isDirty, () => {
        return confirm('Parece que tienes cambios sin guardar, ¿deseas abandonar la página?');
    });

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            await updateVehicle({
                uuid: dealUuid,
                data: {
                    transferable: data.transferable,
                    color: data.color?.value,
                    brand: data.brand,
                    model: data.model,
                    version: data.version,
                    firstRegistrationDate: data.firstRegistrationDate
                        ? zonedTimeToUtc(data.firstRegistrationDate, 'UTC').toISOString()
                        : null,
                    yearRegistration: data.yearRegistration ? Number(data.yearRegistration) : null,
                    gearboxType: data.gearboxType === 'DELETE' ? null : data.gearboxType,
                    combustibleType: data.combustibleType,
                    vin: data.vin,
                    lastOdometerReadingValue: data.lastOdometerReadingValue
                        ? Number(data.lastOdometerReadingValue)
                        : null,
                    titleReservation: data.titleReservation,
                    hasDebt: data.hasDebt,
                },
            });
            onSuccess();
        } catch (e) {
            onError();
        }
    });

    const transformVehicleListItems = (data: GetVehicleFeatureResponse, filters: { query: string }) => {
        const inputValue = filters.query ?? '';
        const colors = data.items.filter((color) => {
            if (color.name) {
                return (
                    color.name.toLowerCase().startsWith(inputValue.toLowerCase()) ||
                    color.name.toLocaleLowerCase().startsWith(inputValue.toLowerCase()) ||
                    color.name.toLowerCase().startsWith(inputValue.toLowerCase())
                );
            }
        });
        return colors.map((color) => ({
            label: color.name,
            value: color.slug,
        }));
    };

    const getColors = async (inputValue: string) => {
        if (colorList) {
            return transformVehicleListItems(colorList, {
                query: inputValue,
            });
        }
    };

    const requiredOption = {
        required: isLoggedUserAdmin && dealBasicInfo?.tier !== 'C2C' ? 'Este campo es obligatorio' : false,
    };

    const hasBrandWarranty = dealCatalogueVehicleDetails?.brandWarrantyExpiredAt
        ? utcToZonedTime(dealCatalogueVehicleDetails.brandWarrantyExpiredAt, 'UTC') > new Date()
        : null;

    const handleOpenLicensePlateModal = () => {
        dialog({
            type: 'modal',
            content: <LicensePlateDialog />,
        });
    };

    return (
        <div className="space-y-2">
            <Accordion title="Datos vehículo" className="bg-gray-100">
                <form
                    className="grid grid-cols-3 gap-4 p-5 form-enabled"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmitForm();
                    }}
                >
                    <div className="col-span-3 flex gap-8">
                        <RadioGroup
                            label="DGT Transferible"
                            inputs={[
                                {
                                    label: 'Sí',
                                    value: 'YES',
                                },
                                {
                                    label: 'No',
                                    value: 'NO',
                                },
                            ]}
                            {...register('transferable', {})}
                            onChange={(e) => {
                                setValue('transferable', e.target.value);
                                if (e.target.value === 'YES') {
                                    setValue('titleReservation', false, { shouldDirty: true });
                                    setValue('hasDebt', false, { shouldDirty: true });
                                }
                            }}
                            error={errors.transferable}
                        />
                        {dealBasicInfo?.tier !== 'C2C' && (
                            <div className="flex flex-col justify-between">
                                {watch('transferable') === 'NO' && (
                                    <Checkbox
                                        label="Reserva de dominio"
                                        {...register('titleReservation')}
                                        onChange={(e) => {
                                            setValue('titleReservation', e.target.checked);
                                            if (e.target.checked) {
                                                setValue('hasDebt', false, { shouldDirty: true });
                                            }
                                        }}
                                    />
                                )}

                                {watch('titleReservation') && <Checkbox label="Deuda" {...register('hasDebt')} />}
                            </div>
                        )}
                        {dealBasicInfo?.tier === 'C2C' && (
                            <div className="h-full flex flex-col gap-2">
                                <p className="text-xs text-gray-800">Garantía de marca</p>
                                <div className="flex items-center gap-1">
                                    {hasBrandWarranty === null && <HiMinusCircle className="h-5 w-5 text-gray-500" />}
                                    {hasBrandWarranty === true && <HiCheckCircle className="h-5 w-5 text-green-500" />}
                                    {hasBrandWarranty === false && <HiXCircle className="h-5 w-5 text-red-500" />}
                                    {dealCatalogueVehicleDetails?.brandWarrantyExpiredAt ? (
                                        <p className="text-gray-800">
                                            Garantía {hasBrandWarranty ? 'expira' : 'expiró'} el{' '}
                                            {format(
                                                utcToZonedTime(
                                                    dealCatalogueVehicleDetails.brandWarrantyExpiredAt,
                                                    'UTC',
                                                ),
                                                'dd/MM/yyyy',
                                            )}
                                        </p>
                                    ) : (
                                        <p className="text-gray-800">
                                            Sin datos, falta fecha matriculación en el vehículo del catálogo
                                        </p>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="grid grid-cols-3 col-span-3 gap-4">
                        <div className="flex gap-2">
                            <div className="grow">
                                <TextInput
                                    label="Matrícula"
                                    {...register('licensePlate')}
                                    type="text"
                                    disabled
                                    error={errors.licensePlate}
                                />
                            </div>

                            {dealBasicInfo?.tier === 'C2C' &&
                                (isLoggedUserAdmin || hasRequiredRoles([Roles.C2C_SALES_AGENT])) && (
                                    <div className="flex items-end pb-1">
                                        <Tooltip
                                            id="tooltip-vehicle-licenseplate"
                                            content={
                                                dealRecivedReservation
                                                    ? 'La matrícula no se puede modificar si el negocio tiene una reserva'
                                                    : 'La matrícula solo se puede modificar si el negocio está abierto'
                                            }
                                            style={'light'}
                                            className={`cursor-not-allowed ${dealBasicInfo?.situation !== 'OPEN' || dealRecivedReservation ? '' : 'hidden'}`}
                                        >
                                            <button
                                                className={`flex h-[38px] w-[38px] border-1 border-black rounded-lg justify-center items-center
                                                ${dealBasicInfo?.situation !== 'OPEN' || dealRecivedReservation ? 'border-gray-400 cursor-not-allowed' : 'cursor-pointer'}`}
                                                type="button"
                                                onClick={() => {
                                                    dealBasicInfo?.situation === 'OPEN' &&
                                                        !dealRecivedReservation &&
                                                        handleOpenLicensePlateModal();
                                                }}
                                            >
                                                <BiEdit
                                                    className={`h-6 w-6 ${(dealBasicInfo?.situation !== 'OPEN' || dealRecivedReservation) && ' text-gray-400'}`}
                                                />
                                            </button>
                                        </Tooltip>
                                    </div>
                                )}
                        </div>
                    </div>

                    <TextInput
                        label="Marca"
                        {...register('brand', requiredOption)}
                        required={dealBasicInfo?.tier !== 'C2C' && isLoggedUserAdmin}
                        disabled={!isLoggedUserAdmin}
                        type="text"
                        error={errors.brand}
                    />
                    <TextInput
                        label="Modelo"
                        {...register('model', requiredOption)}
                        required={dealBasicInfo?.tier !== 'C2C' && isLoggedUserAdmin}
                        disabled={!isLoggedUserAdmin}
                        type="text"
                        error={errors.model}
                    />
                    <TextInput
                        label="Versión"
                        {...register('version')}
                        disabled={!isLoggedUserAdmin}
                        type="text"
                        error={errors.version}
                    />

                    <TextInput
                        label="Año"
                        {...register('yearRegistration')}
                        disabled={!isLoggedUserAdmin}
                        type="text"
                        error={errors.yearRegistration}
                    />
                    <Select
                        label="Cambio"
                        {...register('gearboxType')}
                        options={[
                            {
                                label: 'Automático',
                                value: 'AUTOMATIC',
                            },
                            {
                                label: 'Manual',
                                value: 'MANUAL',
                            },
                        ]}
                        allowEmpty
                        disabled={!isLoggedUserAdmin}
                        error={errors.gearboxType}
                    />
                    <TextInput
                        label="Número de bastidor"
                        {...register('vin')}
                        type="text"
                        disabled={!isLoggedUserAdmin}
                        error={errors.vin}
                    />
                    <TextInput
                        label="Kilómetros del coche"
                        {...register('lastOdometerReadingValue', requiredOption)}
                        type="text"
                        required={dealBasicInfo?.tier !== 'C2C' && hasRequiredRoles([Roles.SALES_AGENT])}
                        disabled={!hasRequiredRoles([Roles.SALES_AGENT])}
                        error={errors.lastOdometerReadingValue}
                    />

                    <Select
                        label="Combustible"
                        {...register('combustibleType', requiredOption)}
                        options={getOptions(combustibleType)}
                        disabled={!isLoggedUserAdmin}
                        required={dealBasicInfo?.tier !== 'C2C' && isLoggedUserAdmin}
                        error={errors.combustibleType}
                    />
                    <Controller
                        name="firstRegistrationDate"
                        control={control}
                        rules={{
                            validate: (value?: any) =>
                                (value instanceof Date && !isNaN(value.getTime())) ||
                                dealBasicInfo?.tier === 'C2C' ||
                                'Fecha no válida',
                            ...requiredOption,
                        }}
                        render={({ field }) => (
                            <DateInput
                                label="Fecha de matriculación"
                                {...field}
                                disabled={!isLoggedUserAdmin}
                                required={dealBasicInfo?.tier !== 'C2C' && isLoggedUserAdmin}
                                error={errors.firstRegistrationDate}
                            />
                        )}
                    />
                    {dealBasicInfo?.tier !== 'C2C' && (
                        <Controller
                            name="color"
                            control={control}
                            render={({ field }) => (
                                <Combobox
                                    {...field}
                                    defaultValue={undefined}
                                    options={getColors}
                                    defaultOptions={
                                        colorList
                                            ? colorList.items.map((color) => ({
                                                  label: color.name,
                                                  value: color.slug,
                                              }))
                                            : null
                                    }
                                    label="Color"
                                />
                            )}
                        />
                    )}
                    <div className="grid col-span-3 grid-cols-6 gap-10">
                        <Button
                            disabled={!isDirty || isSubmitting}
                            className=" col-span-2 col-start-5"
                            type="submit"
                            color="blue"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Accordion>
        </div>
    );
}
