import { Accordion } from '@/components/Accordion';
import { useToast } from '@/hooks';
import { isEmpty } from '@/lib/utils/helpers';
import { DealBasicInfo, GetCarfaxResponse } from '@/types';
import { GetDealVehicleReponse } from '@/types/Deal';
import { GetVehicleFeatureResponse } from '@/types/Vehicle/features';
import { VehicleMatchCard } from './cards/VehicleMatchCard';
import { DealCarfaxView } from './DealCarfaxView';
import { VehicleDataForm } from './forms/VehicleDataForm';
import { HiPlus } from 'react-icons/hi';
import { Button } from '@/components/Button';
import LicensePlateDialog from '@/views/deals/details/vehicle/LicensePlateDialog';
import { useDialog } from '@/components/Dialog/DialogHandler';

export function DealVehicleView({
    carfax,
    vehicle,
    colorList,
    dealBasicInfo,
}: {
    readonly carfax?: GetCarfaxResponse;
    readonly vehicle?: GetDealVehicleReponse;
    readonly colorList: GetVehicleFeatureResponse | undefined;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const dialog = useDialog();
    const { successToast, errorToast } = useToast();

    const handleOpenLicensePlateModal = () => {
        dialog({
            type: 'modal',
            content: <LicensePlateDialog />,
        });
    };

    return (
        <section className="space-y-6 w-full">
            {dealBasicInfo?.tier === 'C2C' && vehicle && (
                <VehicleMatchCard dealBasicInfo={dealBasicInfo} vehicle={vehicle} />
            )}
            {vehicle && !isEmpty(vehicle) ? (
                <VehicleDataForm
                    vehicle={vehicle}
                    colorList={colorList}
                    onSuccess={successToast}
                    onError={errorToast}
                    dealBasicInfo={dealBasicInfo}
                />
            ) : (
                <Accordion title="Vehicle Detail" className="min-w-xl h-fit flex-1">
                    <section className="flex gap-6 w-full">
                        <div className="w-full p-4 flex flex-col gap-2 items-center">
                            <span>No hay ningún vehículo asociado a este deal</span>
                            {dealBasicInfo?.situation === 'OPEN' && dealBasicInfo.tier === 'C2C' && (
                                <Button variant="outline" color="blue" size="xs" onClick={handleOpenLicensePlateModal}>
                                    <HiPlus /> Asociar vehículo
                                </Button>
                            )}
                        </div>
                    </section>
                </Accordion>
            )}

            {carfax && !isEmpty(carfax) ? (
                <DealCarfaxView carfax={carfax} />
            ) : (
                <Accordion title="Carfax Detail" className="min-w-xl h-fit flex-1">
                    <section className="flex gap-6 w-full">
                        <span className="w-full bg-white p-8 text-center">No se encontraron datos de Carfax</span>
                    </section>
                </Accordion>
            )}
        </section>
    );
}
